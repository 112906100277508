import React, { useState, useEffect } from "react";
import { AttachMoney, ShoppingCart, Equalizer, Phone, MailOutline, Edit, Brush, Sync, Save } from "@material-ui/icons";
import {
  Avatar,
  FormControl,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  IconButton,
  ListSubheader,
  Typography,
  withStyles,
  Theme,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { Level, FloorplanStatus, OrderStatus } from "../../models";

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    maxWidth: 380,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

type EditorInfoListProps = {
  editorInfo: {
    stripe_id: string;
    email: string;
    phone: string;
    orderLevel: string;
    paymentStatus: string;
    floorplanner_id: string;
    floorplanStatus: string;
    orderStatus: string;
    floorplanShareable: boolean;
  };
  handleOrderLevelChange?: (event: any) => Promise<void>;
  handleFloorplanStatusChange?: (event: any) => Promise<void>;
  handleOrderStatusChange?: (event: any) => Promise<void>;
  handleSetFloorplannerId?: (floorplannerId: string) => Promise<void>;
  handleShareableSwitch?: (event: any) => Promise<void>;
};

const EditorInfoList: React.FC<EditorInfoListProps> = ({
  editorInfo,
  handleOrderLevelChange,
  handleFloorplanStatusChange,
  handleOrderStatusChange,
  handleSetFloorplannerId,
  handleShareableSwitch,
}: EditorInfoListProps) => {
  const [floorplannerId, setFloorplannerId] = useState(editorInfo.floorplanner_id);

  useEffect(() => {
    setFloorplannerId(editorInfo.floorplanner_id);
  }, [editorInfo.floorplanner_id]);

  return (
    <List>
      <ListSubheader>User Info</ListSubheader>
      <ListItem key="user_email">
        <Tooltip title="Customer Email" placement="left">
          <ListItemAvatar>
            <Avatar>
              <MailOutline />
            </Avatar>
          </ListItemAvatar>
        </Tooltip>
        <Link href={`mailto:${editorInfo.email}`}>{editorInfo.email}</Link>
      </ListItem>
      {editorInfo.phone && (
        <ListItem key="user_phone">
          <Tooltip title="Phone" placement="left">
            <ListItemAvatar>
              <Avatar>
                <Phone />
              </Avatar>
            </ListItemAvatar>
          </Tooltip>
          <Link href={`tel:${editorInfo.phone}`}>{editorInfo.phone}</Link>
        </ListItem>
      )}

      <ListSubheader>Project Status</ListSubheader>
      {editorInfo.orderLevel && (
        <ListItem key="OL">
          <Tooltip title="Order Level" placement="left">
            <ListItemAvatar>
              <Avatar>
                <ShoppingCart />
              </Avatar>
            </ListItemAvatar>
          </Tooltip>
          <FormControl>
            <Select onChange={handleOrderLevelChange} value={editorInfo.orderLevel}>
              {Object.keys(Level).map((level) => {
                return (
                  <MenuItem key={level} value={level}>
                    {level}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </ListItem>
      )}
      {editorInfo.floorplanStatus && (
        <>
          <ListItem key="FS">
            <HtmlTooltip
              placement="top"
              title={
                <>
                  <Typography color="inherit">Floorplan Status:</Typography>
                  <ul>
                    <li> INITIALIZED: Initial status </li>
                    <li> GENERATED: Generated by ML </li>
                    <li> IN_PROGRESS: Contractor is working on floor plan.</li>
                    <li> REJECTED: Contractor has rejected the floor plan.</li>
                    <li> QA_CREATED: Contractor has finished working on floor plan, please review. </li>
                    <li> REWORK: Floor plan needs to be reworked. </li>
                    <li> CREATED: Floor plan has been reviewed and is ready for the customer </li>
                  </ul>
                </>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <Brush />
                </Avatar>
              </ListItemAvatar>
            </HtmlTooltip>
            <FormControl>
              <Select onChange={handleFloorplanStatusChange} value={editorInfo.floorplanStatus}>
                {Object.keys(FloorplanStatus).map((status) => {
                  return (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </ListItem>
        </>
      )}
      {editorInfo.orderStatus && (
        <>
          <ListItem key="OS">
            <HtmlTooltip
              placement="top"
              title={
                <>
                  <Typography color="inherit">Order Status:</Typography>
                  <ul>
                    <li> CONFIRMED: Order was confirmed/accepted by Vloor. </li>
                    <li> DECLINED: Order had to be declined by Vloor. </li>
                    <li> PROCESSED: Order has been successfully processed. Customer can see the results! </li>
                    <li> INVOICED: An invoice has been sent to the customer. </li>
                    <li> FINISHED: Invoice has been paid by the customer. </li>
                  </ul>
                </>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <Sync />
                </Avatar>
              </ListItemAvatar>
            </HtmlTooltip>
            <FormControl>
              <Select onChange={handleOrderStatusChange} value={editorInfo.orderStatus}>
                {Object.keys(OrderStatus).map((status) => {
                  return (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </ListItem>
        </>
      )}

      <ListItem>
        <FormControlLabel
          control={
            <Switch
              checked={editorInfo.floorplanShareable}
              onChange={handleShareableSwitch}
              name="floorplanShareable"
              color="primary"
            />
          }
          label="Enable Sharing"
        />
      </ListItem>

      <ListSubheader>Floorplanner Project</ListSubheader>
      <ListItem key="FPL">
        <Tooltip title="Floorplanner Link" placement="left">
          <ListItemAvatar>
            <Avatar>
              <Edit />
            </Avatar>
          </ListItemAvatar>
        </Tooltip>
        {editorInfo.floorplanner_id !== null ? (
          <Link href={`https://floorplanner.com/projects/${editorInfo.floorplanner_id}/editor`} target="_blank">
            Link to Floorplanner
          </Link>
        ) : (
          "No Id set"
        )}
      </ListItem>
      <ListItem key="FPID">
        <TextField
          required
          label="Floorplanner ID"
          name="floorplanner id"
          value={floorplannerId || ""}
          variant="outlined"
          onChange={(event) => {
            setFloorplannerId(event.target.value);
          }}
          fullWidth
        />
        <ListItemIcon>
          <Tooltip title="Floorplanner ID speichern" placement="right">
            <IconButton
              edge="end"
              onClick={() => {
                handleSetFloorplannerId(floorplannerId);
              }}
            >
              <Save color="primary" />
            </IconButton>
          </Tooltip>
        </ListItemIcon>
      </ListItem>

      <ListSubheader>Payment Info</ListSubheader>
      <ListItem key="Stripe_ID">
        <Tooltip title="Stripe ID" placement="left">
          <ListItemAvatar>
            <Avatar>
              <AttachMoney />
            </Avatar>
          </ListItemAvatar>
        </Tooltip>
        <Link href={`https://dashboard.stripe.com/test/customers/${editorInfo.stripe_id}`} target="_blank">
          Link to Stripe
        </Link>
      </ListItem>
      {editorInfo.paymentStatus && (
        <ListItem key="Stripe_PS">
          <Tooltip title="Payment Status" placement="left">
            <ListItemAvatar>
              <Avatar>
                <Equalizer />
              </Avatar>
            </ListItemAvatar>
          </Tooltip>
          <ListItemText primary={editorInfo.paymentStatus} />
        </ListItem>
      )}
    </List>
  );
};

export default EditorInfoList;
