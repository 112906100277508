/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:16410acb-3255-46bf-8ae1-cada7c34093a",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_fEYDQUK2v",
    "aws_user_pools_web_client_id": "7nu6b328ec59ds0rkgiliahjk3",
    "oauth": {
        "domain": "vloorapp-prod.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://develop.app.vloor.com/,http://localhost:3000/,https://www.app.vloor.com/",
        "redirectSignOut": "https://develop.app.vloor.com/,http://localhost:3000/,https://www.app.vloor.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "webapp-floorplan-upload-bucket154612-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://fl4v34xntfhr7c7woqnnoihp3q.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://r62slatu6l.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "stripeapi",
            "endpoint": "https://v8wqw2y752.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "customerFunctions",
            "endpoint": "https://joob18745d.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        }
    ],
    "predictions": {
        "identify": {
            "identifyLabels": {
                "proxy": false,
                "region": "eu-central-1",
                "defaults": {
                    "type": "LABELS"
                }
            }
        }
    }
};


export default awsmobile;
