import React from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import { FlrPaper } from "../../components/layout/StyledComponents";
import { Floorplan } from "../../models";
import { CloudDownload } from "@material-ui/icons";
import useCreatePresignedUrl from "../../hooks/exports/useCreatePresignedUrl";
import { useEditorUrlDownload } from "../../hooks/exports/DownloadImagesHook";
import { useDigitizeFloorplan } from "../../hooks/floorplan/useDigitizeFloorplan";
import { useToastProvider } from "../../providers/ToastProvider";
import FunctionsIcon from "@material-ui/icons/Functions";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useHistory } from "react-router-dom";

type ShowcasePanelProps = {
  floorplan: Floorplan;
};

const ShowcasePanel: React.FC<ShowcasePanelProps> = ({ floorplan }) => {
  const { createPresignedURLs } = useCreatePresignedUrl();
  const { startEditorDownload } = useEditorUrlDownload(floorplan.id);
  const { digitizeFloorplan } = useDigitizeFloorplan();
  const { showToast } = useToastProvider();
  const history = useHistory();

  const downloadOriginalImageAndModel = async () => {
    const filesToDownloadKeys = [
      floorplan.originalImage.key,
      floorplan.floorplannerModel.key,
      floorplan.preprocessedImage.key,
      floorplan.visual2DImage.key,
      floorplan.croppedImage.key,
    ];
    const urls = await createPresignedURLs(filesToDownloadKeys, "GET");
    startEditorDownload(urls);
  };

  const digitize = async () => {
    try {
      showToast({
        severity: "success",
        content: "Grundriss wird digitalisiert. Dies kann ein paar Minuten dauern.",
      });
      await digitizeFloorplan(floorplan);
    } catch (err) {
      console.error("digitize Floorplan:", err);
    }
  };

  return (
    <FlrPaper>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12}>
          <Typography variant="h5" component="h2" align="center">
            Showcase Panel
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1} direction="row" justify="flex-start" alignItems="flex-start">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={downloadOriginalImageAndModel}
                startIcon={<CloudDownload />}
              >
                Download
              </Button>
            </Grid>

            <Grid item>
              <Button variant="contained" color="primary" startIcon={<FunctionsIcon />} onClick={digitize}>
                Erneut Digitalisieren
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<VisibilityIcon />}
                onClick={() => history.push("/ai")}
              >
                AI Preview testen
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FlrPaper>
  );
};

export default ShowcasePanel;
