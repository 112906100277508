export const PUBLIC_URL = new URL(process.env.PUBLIC_URL, window.location.href);
export const BASE_URL = PUBLIC_URL.origin;

// Date Locale
export const DEFAULT_LOCALE = "de-DE";
export const LOCALE_US = "us-EN";

// AWS S3
export const S3_BUCKET = {
  dev: "webapp-floorplan-upload-bucket172331-dev",
  test: "webapp-floorplan-upload-bucket152243-test",
  prod: "webapp-floorplan-upload-bucket154612-prod",
};
export const S3_REGION = "eu-central-1";
export const S3_PRIVACY_LEVEL = "private";
export const S3_PREFIX_FOLDER = "floorplans";

// AWS API Endpoints
export const FLOORPLAN_FUNCTIONS_API_NAME = "floorplanFunctions";
export const PRESIGNEDURL_API_ENDPOINT = "/floorplan/getPresignedUrl";
export const IMPROVE_FLOORPLAN_ENDPOINT = "/floorplan/improve";
export const EXPORT_FLOORPLAN_ENDPOINT = "/floorplan/export"; // @deprecated

export const STRIPE_API_NAME = "stripeapi";
export const API_CHECKOUT_ENDPOINT = "/checkout";
export const API_CUSTOMER_PORTAL_ENDPOINT = "/customer-portal";
export const API_PAYMENT_METHODS_ENDPOINT = "/hasPaymentMethod";

export const CUSTOMER_API_NAME = "customerFunctions";
export const CUSTOMER_API_ENDPOINT = "/customer";
export const CUSTOMER_IDENTITY_ENDPOINT = "/persistIdentityId";

export const MAIL_API_NAME = "mailFunctions";
export const MAIL_API_ENDPOINT = "/mail";

// for admin queries
export const ADMIN_API_NAME = "AdminQueries";
export const ADMIN_API_GET_USER_PATH = "/getUser";

// Stripe redirect Urls
const CHECKOUT_SUCCESS_PATH = "/dashboard/success?session_id={CHECKOUT_SESSION_ID}";
const CHECKOUT_CANCEL_PATH = "/dashboard/cancel";
const BILLING_PORTAL_RETURN_PATH = "/settings";

export const CHECKOUT_SUCCESS_URL = BASE_URL + CHECKOUT_SUCCESS_PATH;
export const CHECKOUT_CANCEL_URL = BASE_URL + CHECKOUT_CANCEL_PATH;
export const BILLING_PORTAL_RETURN_URL = BASE_URL + BILLING_PORTAL_RETURN_PATH;

// Validation Regular Expressions
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
export const PHONE_REGEX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// Links to S3 objects
export const TERMS_LINK = `https://vloor-logo-bucket.s3.eu-central-1.amazonaws.com/terms_and_conditions/AGB+Draft_HK_210308_clean.docx.pdf`;
export const GDPR_LINK = `https://vloor-logo-bucket.s3.eu-central-1.amazonaws.com/terms_and_conditions/Datenschutzerkla%CC%88rung_Vloor_GmbH_0321.pdf`;

// Accepted FileTypes
export enum FILE_TYPE {
  JPG = "jpg", // do not use
  JPEG = "jpeg",
  PDF = "pdf",
  PNG = "png",
  JSON = "json",
  FML = "fml",
  IFC = "ifc",
  SVG = "svg",
}

export enum MIME_TYPE {
  PNG = "image/png",
  JPG = "image/jpeg", // image/jpg is not the official mimetype
  JPEG = "image/jpeg",
  BMP = "image/bmp",
  PDF = "application/pdf",
  JSON = "application/json",
  SVG = "image/svg+xml",
  IFC = "application/x-step",
}
export const ACCEPTED_FILETYPES = [MIME_TYPE.JPEG, MIME_TYPE.PNG, MIME_TYPE.PDF];

//used for creation of floorplan db entries
export const FLOORPLAN_TYPE = "Floorplan";

/******************** 
  CDK API ENDPOINTS 
********************/

// FLOORPLANNER API ENDPOINT
export const FLOORPLANNER_API_NAME = "FloorplannerAPI";
export const FLOORPLANNER_PROJECT_ENDPOINT_BASE = "/projects";
export const FLOORPLANNER_FLOORPLAN_ENDPOINT_BASE = "/floorplans";
export const FLOORPLANNER_DESIGN_ENDPOINT_BASE = "/designs";

export const floorplannerAPI = {
  prod: "https://q1a47w13a9.execute-api.eu-central-1.amazonaws.com/prod/v1",
  test: "https://fvtery7o2h.execute-api.eu-central-1.amazonaws.com/test/v1",
  dev: "https://ox58h25au9.execute-api.eu-central-1.amazonaws.com/dev/v1",
};

//FLOORPLANNER URL INTEGRATIONS VIEWER/EDITOR
export const VIEWER_FLOORPLANNER_BASE_URL = "https://floorplanner.com/projects/";
export const ROOMPLANNER_FLOORPLANNER_BASE_URL = "https://vloor.roomplanner.com/projects/";
