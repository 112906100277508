import { Typography, LinearProgress } from "@material-ui/core";
import { ACCEPTED_FILETYPES } from "../../constants";
import { DropzoneArea } from "material-ui-dropzone";
import styled from "styled-components";
import { useState } from "react";
import { useIdentifyFloorplan } from "hooks/floorplan/IdentifyFloorplanHook";
import usePreprocessImageHook, { PreprocessingResult } from "hooks/floorplan/PreprocessImageHook";
import { useAIPreviewState } from "./context/AIPreviewContext";
import { AIPreviewActionTypes } from "./context/AIPreviewReducer";
import { AIPreviewResult, useCreateAIPreview } from "hooks/floorplan/RecognitionServiceHooks";

const DropzoneContainer = styled.div`
  padding: 12px;
`;

const InfoContainer = styled.div`
  margin-top: 6px;
`;

const DisclaimerContainer = styled.div`
  margin-top: 6px;
  padding: 6px;
`;

const DISCLAIMERTEXT =
  "* Bitte beachten Sie das wir derzeit nur Pläne von einzelnen Wohneinheiten unter 200m2 automatisch digitalisieren können, keine Geschoßpläne, oder Dachgeschoßwohnungen. Wir arbeiten stetig daran auch diese Pläne für Sie digitalisieren zu können und informieren Sie alsbald. Maximale Dateigröße 5MB.";
const DESCRIPTION =
  "Erläuterung zum Ergebnis: Die angezeigte Visualisierung dient als Vorschau zur Veranschaulichung des Ergebnisses unserer künstlichen Intelligenz und dient als Grundlage für die fertige Visualisierung.";
const FLOORPLAN_ADD = "Bitte fügen Sie einen Grundriss hinzu. *";
const FLOORPLAN_RECOGNISED = "Grundriss wurde erkannt. Sie erhalten in wenigen Sekunden eine Vorschau.";
const FLOORPLAN_NOT_DETECTED = "Es konnte kein Grundriss erkannt werden. Bitte versuchen Sie ein anderes Bild.";
const ANALYSIS_ERROR = "Bei der Analyse des Bildes ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.";
const FILE_TO_LARGE = "Das gewählte Bild ist zu groß, maximale Dateigröße 5MB. Bitte versuchen Sie ein anderes Bild.";

const FloorplanDropzone = () => {
  const [status, setStatus] = useState(FLOORPLAN_ADD);
  const [fileInputValue, setFileInputvalue] = useState("");
  const initialFile = [];
  const { dispatch } = useAIPreviewState();

  const { containsFloorplan } = useIdentifyFloorplan();
  const { preprocessImage } = usePreprocessImageHook();
  const { createAIPreview } = useCreateAIPreview();

  const handleFileChange = async (fileObjects: File[]) => {
    if (fileObjects.length === 0) return;
    const newFile: File = fileObjects[0];
    setFileInputvalue(newFile.name);

    dispatch({
      type: AIPreviewActionTypes.resetAIPreviewContext,
    });

    dispatch({
      type: AIPreviewActionTypes.editOriginalImageFile,
      key: "original",
      value: newFile,
    });

    handleSketch(newFile);
  };

  const handleSketch = async (newFile: File) => {
    if (newFile) {
      setStatus("analyzing");
      try {
        const preprocessingResult = await preprocessImage(newFile);
        const isValid = await containsFloorplan(preprocessingResult.file);

        if (isValid === true) {
          setStatus(FLOORPLAN_RECOGNISED);
          setFileInputvalue(newFile.name);

          const aiPreviewResult = await createAIPreview(preprocessingResult.imageUri);

          dispatchResults(preprocessingResult, aiPreviewResult);
        } else {
          setStatus(FLOORPLAN_NOT_DETECTED);
          setFileInputvalue("");
        }
      } catch (e) {
        console.log("Error during analysis: ", e);
        setStatus(ANALYSIS_ERROR);
        setFileInputvalue("");
      }
    }
  };

  const handleFileDelete = () => {
    setStatus(FLOORPLAN_ADD);
    setFileInputvalue("");
    dispatch({
      type: AIPreviewActionTypes.resetAIPreviewContext,
    });
  };

  const handleFileRejected = (files: File[], event: any) => {
    setStatus(FILE_TO_LARGE);
  };

  const dispatchResults = (preprocessingResult: PreprocessingResult, aiPreviewResult: AIPreviewResult) => {
    dispatch({
      type: AIPreviewActionTypes.editPreprocessingResult,
      key: "preprocessed",
      value: preprocessingResult,
    });

    dispatch({
      type: AIPreviewActionTypes.editAIPreviewResult,
      key: "preview",
      value: aiPreviewResult,
    });
  };

  return (
    <>
      <DropzoneContainer>
        <DropzoneArea
          onChange={handleFileChange}
          onDelete={handleFileDelete}
          onDropRejected={handleFileRejected}
          acceptedFiles={ACCEPTED_FILETYPES}
          showPreviews={false}
          maxFileSize={5000000}
          filesLimit={1}
          dropzoneText={"Klicken oder Datei hinzufügen"}
          initialFiles={initialFile}
          showAlerts={false}
        />
        <input name="file" id="file" required={true} defaultValue={fileInputValue} style={{ opacity: 0 }} />
        <InfoContainer>
          {status === "analyzing" ? (
            <>
              <Typography>Analysiere...</Typography>
              <LinearProgress />
            </>
          ) : (
            <>
              <Typography>{status}</Typography>
              <div style={{ height: "4px" }} />
            </>
          )}
        </InfoContainer>
        <DisclaimerContainer>
          <Typography style={{ fontSize: 16 }}>{DESCRIPTION}</Typography>
        </DisclaimerContainer>

        <DisclaimerContainer>
          <Typography style={{ fontSize: 12 }}>{DISCLAIMERTEXT}</Typography>
        </DisclaimerContainer>
      </DropzoneContainer>
    </>
  );
};

export default FloorplanDropzone;
