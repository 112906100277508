import { createFilenameWithPostfix } from "../../utils/KeyUtils";
import { PreprocessImageLambdaQuery } from "API";
import { gqlOperation } from "hooks/GraphQLHooks";
import * as queries from "../../graphql/queries";
import { FILE_TYPE } from "../../constants";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

const PREPROCESSED_POSTFIX = "-preprocessed";
const PREPROCESSED_EXTENSION = FILE_TYPE.JPEG;

export type PreprocessingResult = {
  imageUri: string; // base64 URL string
  file: File;
};

const usePreprocessImageHook = () => {
  const preprocessImage = async (image: File) => {
    return new Promise<PreprocessingResult>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = async () => {
        try {
          const data = await gqlOperation<PreprocessImageLambdaQuery>(
            queries.preprocessImageLambda,
            {
              image: reader.result,
            },
            GRAPHQL_AUTH_MODE.AWS_IAM
          );
          const result: PreprocessingResult = JSON.parse(data.preprocessImageLambda);
          const imageUri = result.imageUri;

          //turn base64 string to file
          fetch(result.imageUri)
            .then((res) => {
              return res.blob();
            })
            .then((blob) => {
              const filename = createFilenameWithPostfix(image, PREPROCESSED_POSTFIX, PREPROCESSED_EXTENSION);
              const file = new File([blob], filename, { type: blob.type });
              resolve({ imageUri, file });
            });
        } catch (err) {
          reject(err);
        }
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
        reject(error);
      };
      //turn file into base64 string
      reader.readAsDataURL(image);
    });
  };

  return { preprocessImage };
};

export default usePreprocessImageHook;
