import { Floorplan } from "../../models";
import { useCallback } from "react";

import { GetPreSignedUrlsQuery, GetPreSignedUrlsInput, GetPreSignedUrlsQueryVariables } from "../../API";
import * as queries from "../../graphql/queries";
import { gqlOperation } from "../../hooks/GraphQLHooks";

// BucketName and ExpiresIn are set in Lambda function

type FloorplanPresignedUrls = {
  originalImageUrl: string;
  visual2DImageUrl: string;
  reactplannerModelUrl: string;
};

const useCreatePresignedUrl = () => {
  // call API to get pre-signed Url from S3
  const createPresignedURLs = useCallback(async (keys: string[], type: string, expiresIn?: number): Promise<any> => {
    const input: GetPreSignedUrlsInput = {
      keys: keys,
      type: type,
      expiresIn: expiresIn,
    };

    const variables: GetPreSignedUrlsQueryVariables = {
      input: input,
    };

    try {
      const data = await gqlOperation<GetPreSignedUrlsQuery>(queries.getPreSignedUrls, variables);
      console.log("Response GetPreSignedUrlsQuery: ", data.getPreSignedUrls.urls);
      return data.getPreSignedUrls.urls;
    } catch (err) {
      console.log("Error getPreSigendUrl:", err);
    }
  }, []);

  const getFloorplanPresignedURLs = useCallback(
    async (floorplan: Floorplan): Promise<FloorplanPresignedUrls> => {
      const keys = [floorplan?.originalImage?.key, floorplan?.visual2DImage?.key, floorplan?.reactplannerModel?.key];

      const urls = await createPresignedURLs(keys, "GET");
      const floorplanPresignedUrls: FloorplanPresignedUrls = {
        originalImageUrl: urls[0],
        visual2DImageUrl: urls[1],
        reactplannerModelUrl: urls[2],
      };
      console.log("getFloorplanPresignedURLs: ", floorplanPresignedUrls);

      return floorplanPresignedUrls;
    },
    [createPresignedURLs]
  );

  return { getFloorplanPresignedURLs, createPresignedURLs };
};

export default useCreatePresignedUrl;
