import React, { createContext, useContext, useReducer } from "react";
import { reducer, initialState } from "./CheckoutReducer";

// type CheckoutStateType = {
//   useReducer: (state: CheckoutState, action: any) => CheckoutState;
// };

const StateContext = createContext(undefined);

const StateProvider = ({ children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>{children}</StateContext.Provider>
);
export default StateProvider;

export const useStateValue = () => useContext(StateContext);
