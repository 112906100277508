import { useFormik } from "formik";
import { useContext, useState } from "react";
import sanitize from "sanitize-filename";

import { useMutation } from "react-query";
import { CreateFloorplanInput, CreateFloorplanMutation } from "../../API";
import * as mutations from "../../graphql/mutations";
import { gqlOperation } from "../GraphQLHooks";

import * as FloorplanDBUtils from "../../utils/FloorplanDBUtils";
import { useCustomer } from "providers/CustomerContextProvider";
import { Floorplan, OriginalInputType } from "models";
import { mapCreateFloorplanMutation } from "../../types/types";
import { CheckoutContext } from "providers/CheckoutContextProvider";
import useCreatePresignedUrl from "hooks/exports/useCreatePresignedUrl";
import { useToastProvider } from "providers/ToastProvider";
import { useDigitizeFloorplan } from "./useDigitizeFloorplan";

type Props = {
  successCallback: () => void;
};

export const useCreateFloorplan = ({ successCallback }: Props) => {
  const [file, setFile] = useState<File>();
  const [preprocessedFile, setPreprocessedFile] = useState<File>();
  const currentCustomer = useCustomer();
  const { digitizeFloorplan } = useDigitizeFloorplan();

  const { checkout } = useContext(CheckoutContext);
  const { createPresignedURLs } = useCreatePresignedUrl();
  const { showToast } = useToastProvider();

  const initialContent: CreateFloorplanInput = {
    name: "Neuer Grundriss",
    description: "Eine Anmerkung zum Grundriss",
    address: "Beispieladresse",
    user_scaling_cm: 0,
    user_scaling_selected_px: 0,
    customerId: currentCustomer?.id,
    owner: currentCustomer?.user,
    originalInputType: OriginalInputType.SKETCH,
  };

  const { mutateAsync: createFloorplanAsync } = useMutation((content: CreateFloorplanInput) =>
    gqlOperation<CreateFloorplanMutation>(mutations.createFloorplan, {
      input: content,
    })
  );

  // create floorplan: upload image to S3 and create floorplan DB entry
  const createFloorplan = async (productId, identityId) => {
    const data: CreateFloorplanInput = formik.values; // data that was entered in the forms

    // the id of the current-S3-user (Auth.currentUserInfo()),
    // e.g. eu-central-1:690fbf1c-8866-4d1f-84f7-430e27f9cf8a,
    // is different from the Cognito-User = the owner (Auth.currentAuthenticatedUser())
    // because one owner can own multiple Storages in different regions, e.g. eu-west-1

    const filename = sanitize(file.name).replace(" ", "");
    const content = FloorplanDBUtils.createDBEntryContent(identityId, filename, data);

    await uploadImage(content.originalImage.key, file);
    await uploadImage(content.preprocessedImage.key, preprocessedFile);
    // cropped file is the same as preprocessed file here
    // because Editor creates floorplan for customer and there is no cropping before
    await uploadImage(content.croppedImage.key, preprocessedFile);

    const result = await createFloorplanAsync(content);
    console.log("Floorplan created: ", result);

    //call digitize
    const floorplan: Floorplan = mapCreateFloorplanMutation(result.createFloorplan);
    digitizeFloorplan(floorplan);

    //create order
    // TODO maybe add a new function that directly sends a purchase request to stripe instead of a checkout session
    checkout(floorplan, productId, true);

    successCallback();
  };

  const uploadImage = async (key: string, file: File) => {
    const url = await createPresignedURLs([key], "PUT");
    const fetchConfig = {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
      },
      body: file,
    };
    const result = await fetch(url, fetchConfig);
    if (result.status === 200) {
      showToast({
        severity: "success",
        content: `${file.name} wurde hochgeladen`,
      });
    } else {
      showToast({
        severity: "danger",
        content: `${file.name} Fehler beim hochladen`,
      });
    }
  };

  const formik = useFormik<CreateFloorplanInput>({
    initialValues: initialContent,
    onSubmit: createFloorplan,
  });

  return { ...formik, setFile, setPreprocessedFile, createFloorplan };
};
