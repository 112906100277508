import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Container, Grid, Typography } from "@material-ui/core";

import { Formik, Field, Form, FormikHelpers } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";

import { useAuthUIState } from "./context/AuthUIStateContext";
import { AuthUIForm } from "./context/AuthUIStateReducer";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
  subtext: {
    margin: theme.spacing(1, 0, 1),
  },
  textButton: {
    textTransform: "none",
  },
}));
interface Values {
  email: string;
  code: string;
}

interface ConfirmSignUpProps {
  confirmSignUp: (username: string, verificationCode: string) => void;
  changeForm: (formType: AuthUIForm) => void;
  resendSignUp: (username: string) => void;
}

const ConfirmSignUp = ({ confirmSignUp, changeForm, resendSignUp }: ConfirmSignUpProps) => {
  const classes = useStyles();
  const { state } = useAuthUIState();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          E-Mail Adresse bestätigen
        </Typography>
        <Formik
          initialValues={{
            email: state.username,
            code: "",
          }}
          validationSchema={Yup.object({
            email: Yup.string().email("Ungültige Email Adresse").required("Bitte geben Sie ihre Email Adresse an."),
            code: Yup.string().required("Bitte geben Sie ihren Verifizierungscode an."),
          })}
          onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
            console.log("submit");
            confirmSignUp(values.email, values.code.toString());
            setSubmitting(false);
          }}
        >
          {({ values }) => (
            <Form className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    required
                    component={TextField}
                    variant="outlined"
                    fullWidth
                    id="email"
                    name="email"
                    label="Email Adresse"
                    autoComplete="email"
                    placeholder="example@email.com"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    required
                    component={TextField}
                    variant="outlined"
                    fullWidth
                    id="code"
                    name="code"
                    label="Verifizierungscode"
                    type="number"
                    placeholder="123456"
                  />
                  <Typography variant={"body2"} className={classes.subtext}>
                    Verifizierungscode verloren?
                    <Button className={classes.textButton} color="primary" onClick={() => resendSignUp(values.email)}>
                      Erneut senden
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                Registrierung abschließen
              </Button>
            </Form>
          )}
        </Formik>
        <Grid container justify="flex-end">
          <Grid item>
            <Typography variant={"body2"} className={classes.subtext}>
              Bereits registriert?
              <Button className={classes.textButton} color="primary" onClick={() => changeForm(AuthUIForm.SIGNIN)}>
                Zum Login
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default ConfirmSignUp;
