import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { GetFloorplanQuery, ModelSortDirection, FloorplansByOwnerQuery, FloorplansByDateQuery } from "../../API";
import * as queries from "../../graphql/queries";
import { Floorplan, FloorplanStatus } from "../../models";
import { mapGetFloorplanQuery, mapListFloorplansQuery } from "../../types/types";
import { gqlOperation } from "../GraphQLHooks";

import * as advanced from "../../graphql/advanced_queries";

export const useGetFloorplan = (id: string) => {
  const { data, error, isLoading, refetch } = useQuery<Floorplan | undefined>(
    `getFloorplan-${id}`, // the unique identifier is important to avoid caching for different queries
    async () => {
      const data = await gqlOperation<GetFloorplanQuery>(queries.getFloorplan, {
        id: id,
      });
      console.log("get-floorplan query fired");
      return mapGetFloorplanQuery(data.getFloorplan);
    }
  );

  return { floorplan: data, error, isLoading, refetch };
};

export const useGetFloorplans = (limit: number = 100) => {
  const { data, error, isLoading, refetch } = useQuery<Floorplan[] | undefined>(
    "getFloorplansByDateBasic",
    async () => {
      const data = await gqlOperation<FloorplansByDateQuery>(advanced.floorplansByDateBasic, {
        limit: limit,
        sortDirection: ModelSortDirection.DESC,
      });
      console.log("Index: floorplansByDate; list-all floorplans by date basic query fired: ", data);
      return mapListFloorplansQuery(data.floorplansByDate);
    },
    {
      // If set to `false`, the query will not refetch on window focus.
      refetchOnWindowFocus: false,
      // Infinity, results will not be considered stale
      staleTime: Infinity,
    }
  );

  return { floorplans: data, error, isLoading, refetch };
};

export const useGetFloorplansByOwner = (owner: string) => {
  const { data, error, isLoading, refetch } = useQuery<Floorplan[] | undefined>(
    `floorplansByOwner-${owner}`,
    async () => {
      const data = await gqlOperation<FloorplansByOwnerQuery>(advanced.floorplansByOwnerBasic, {
        owner: owner,
        sortDirection: ModelSortDirection.DESC,
      });
      console.log("list-all floorplans by owner fired:", owner);
      return mapListFloorplansQuery(data.floorplansByOwner);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  return { floorplans: data, error, isLoading, refetch };
};

export const useGetFloorplansByStatusAfterDate = (status: FloorplanStatus, createdAfter?: string) => {
  const { data, error, isLoading, refetch } = useQuery<Floorplan[] | undefined>(
    `floorplansByStatus-${status}-createdAfter-${createdAfter}`,
    async () => {
      const data = await gqlOperation<FloorplansByDateQuery>(advanced.floorplansByFloorplanStatusAfterDate, {
        status: status,
        createdAfter: createdAfter,
        sortDirection: ModelSortDirection.DESC,
      });
      console.log(`Index: floorplansByDate; query by status ${status}, after Date ${createdAfter} fired`);
      return mapListFloorplansQuery(data.floorplansByDate);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  return { floorplans: data, error, isLoading, refetch };
};

export const useGetFloorplansAfterDate = (createdAfter?: string) => {
  const { data, error, isLoading, refetch } = useQuery<Floorplan[] | undefined>(
    `floorplansCreatedAfter-${createdAfter}`,
    async () => {
      const data = await gqlOperation<FloorplansByDateQuery>(advanced.floorplansAfterDate, {
        createdAfter: createdAfter,
        sortDirection: ModelSortDirection.DESC,
      });
      console.log(`Index: floorplansByDate; query floorplans after Date ${createdAfter} fired`);
      return mapListFloorplansQuery(data.floorplansByDate);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  return { floorplans: data, error, isLoading, refetch };
};

// Unused
// query to get floorplan and the connected orders
export const useGetFloorplanOrders = (id: string) => {
  const [result, setResult] = useState<GetFloorplanQuery>();
  const { data, error, isLoading, refetch } = useQuery<GetFloorplanQuery | undefined>(
    `getFloorplanOrders-${id}`, // the unique identifier is important to avoid caching for different queries
    async () => {
      const data = await gqlOperation<GetFloorplanQuery>(advanced.getFloorplanOrders, {
        id: id,
      });
      console.log("get-floorplanOrders query fired");
      return data;
    }
  );

  // map floorplanQuery type to floorplan type
  useEffect(() => {
    if (!error && !isLoading) {
      setResult(data);
      console.log("FloorplanOrders converter fired");
    }
  }, [data, error, isLoading]);

  return { result, error, isLoading, refetch };
};
