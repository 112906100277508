import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import React, { ReactElement } from "react";
import styled from "styled-components";

const Root = styled.div`
  padding: ${(p) => p.theme.spacing(4)}px 0;
`;

const Copyright = (): ReactElement => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.vloor.com/">
        Vloor {" " + new Date().getFullYear()}
      </Link>
      {" | "}
      <Link color="inherit" href="https://www.vloor.com/imprint">
        Impressum
      </Link>
    </Typography>
  );
};

// This site is protected by reCAPTCHA and the Google
//     <a href="https://policies.google.com/privacy">Privacy Policy</a> and
//     <a href="https://policies.google.com/terms">Terms of Service</a> apply.

const RecaptchaMessage = (): ReactElement => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"This site is protected by reCAPTCHA and the Google "}
      <Link color="inherit" href="https://policies.google.com/privacy">
        {" Privacy Policy "}
      </Link>{" "}
      and
      <Link color="inherit" href="https://policies.google.com/terms">
        {" Terms of Service "}
      </Link>{" "}
      apply.
    </Typography>
  );
};

const Footer = (): ReactElement => (
  <Root>
    <Box pt={4}>
      <Copyright />
    </Box>
    <Box pt={2}>
      <RecaptchaMessage />
    </Box>
  </Root>
);

export default Footer;
