import { Floorplan, Order, OrderStatus, PaymentStatus } from "../../models";
import {
  Level,
  CreateOrderInput,
  CreateOrderMutation,
  CreateFloorplanOrderInput,
  CreateFloorplanOrderMutation,
} from "../../API";
import * as mutations from "../../graphql/mutations";
import { gqlOperation } from "../../hooks/GraphQLHooks";
import { mapOrder } from "../../types/types";
import { useMutation } from "react-query";

export const useCreateOrder = () => {
  const { mutateAsync: createOrderMutation } = useMutation((input: CreateOrderInput) =>
    gqlOperation<CreateOrderMutation>(mutations.createOrder, {
      input: input,
    })
  );

  const { mutateAsync: createFloorplanOrderMutation } = useMutation((input: CreateFloorplanOrderInput) =>
    gqlOperation<CreateFloorplanOrderMutation>(mutations.createFloorplanOrder, {
      input: input,
    })
  );

  const createOrder = async (floorplan: Floorplan, price_id: Level): Promise<Order> => {
    console.log("Create Order called...", floorplan, price_id);

    try {
      // create Order
      const createOrderInput: CreateOrderInput = {
        user: floorplan.customer.user,
        owner: floorplan.customer.user,
        customer: floorplan.customer.email,
        level: price_id,
        orderStatus: OrderStatus.CONFIRMED,
        paymentStatus: PaymentStatus.PENDING,
      };

      const createdOrder: CreateOrderMutation = await createOrderMutation(createOrderInput);
      console.log("createOrderMutation result: ", createdOrder);

      // create FloorplanOrder
      const createFloorplanOrderInput: CreateFloorplanOrderInput = {
        customer: floorplan.customer.email,
        owner: floorplan.customer.user,
        floorplan_id: floorplan.id,
        order_id: createdOrder.createOrder.id,
      };

      const createdFloorplanOrder: CreateFloorplanOrderMutation = await createFloorplanOrderMutation(
        createFloorplanOrderInput
      );
      console.log("CreateFloorplanOrderMutation result: ", createdFloorplanOrder);

      const newOrder: Order = mapOrder(createdOrder.createOrder);
      console.log("newOrder: ", newOrder);
      return newOrder;
    } catch (err) {
      console.log("createOrder error:", err);
    }
  };

  return { createOrder };
};
