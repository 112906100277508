import React, { ReactElement } from "react";
import FloorplanCard from "./FloorplanCard";
import { Floorplan } from "../../models";

type Props = {
  floorplans: Floorplan[];
  slice: number;
};

const CardGrid = ({ floorplans, slice }: Props): ReactElement => {
  // const gridItems: ListFloorplans = floorplans.listFloorplans.items.slice(
  //   0,
  //   slice
  // );

  const gridItems: Floorplan[] = floorplans.slice(0, slice);

  const getCardData = (floorplan) => {
    return {
      originalImage: floorplan?.originalImage,
      id: floorplan?.id,
      name: floorplan?.name,
      description: floorplan?.description,
      croppedImage: floorplan?.croppedImage,
    };
  };

  return (
    <>
      {gridItems.map(
        (floorplan, i) => !!floorplan && <FloorplanCard key={floorplan.id} content={getCardData(floorplan)} />
      )}
    </>
  );
};

export default CardGrid;
