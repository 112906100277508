import React, { useEffect, useState } from "react";
import { FlrPaper } from "../../components/layout/StyledComponents";
import { Floorplan } from "../../models";
import useCreatePresignedUrl from "../../hooks/exports/useCreatePresignedUrl";
import LightboxImages, { TabProps } from "components/floorplan-detail/LightboxImages";

type ShowcaseImagePanelProps = {
  floorplan: Floorplan;
};

export enum ShowcaseTabLabels {
  SEMANTIC = "Semantic Model",
  POST_PROCESSING = "Post Processing",
  ML = "ML Prediction",
  OCR = "OCR Prediction",
  VIS_2D = "Visualization 2D",
}

const ShowcaseImagePanel: React.FC<ShowcaseImagePanelProps> = ({ floorplan }) => {
  const [images, setImages] = useState([]);
  const { createPresignedURLs } = useCreatePresignedUrl();
  const tabs: TabProps[] = [
    { label: ShowcaseTabLabels.SEMANTIC },
    { label: ShowcaseTabLabels.POST_PROCESSING },
    { label: ShowcaseTabLabels.ML },
    { label: ShowcaseTabLabels.OCR },
    { label: ShowcaseTabLabels.VIS_2D },
  ];

  useEffect(() => {
    const loadPresignedImages = async (keys) => {
      const urls = await createPresignedURLs(keys, "GET");
      setImages(urls);
    };
    const keys = [
      floorplan.internalModelImage?.key,
      floorplan.postMlImage?.key,
      floorplan.mlPredictionImage?.key,
      floorplan.ocrPredictionImage?.key,
      floorplan.visual2DImage?.key,
    ];
    loadPresignedImages(keys);
  }, [createPresignedURLs, floorplan]);

  return <FlrPaper>{images && <LightboxImages images={images} tabs={tabs} />}</FlrPaper>;
};

export default ShowcaseImagePanel;
