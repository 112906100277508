import React, { ReactElement, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import styled from "styled-components";
import { useIdentifyFloorplan } from "../../hooks/floorplan/IdentifyFloorplanHook";
import { useDropzoneStore } from "../../providers/Store";
import { useToastProvider } from "../../providers/ToastProvider";
import { DialogContentBefore } from "../layout/StyledComponents";
import { useCreateFloorplan } from "../../hooks/floorplan/CreateFloorplanHook";
import { useGetCustomers } from "hooks/customer/CustomerCrudHooks";
import usePreprocessImageHook from "hooks/floorplan/PreprocessImageHook";
import { Level, OriginalInputType } from "models";
import { useCustomer } from "providers/CustomerContextProvider";
import { ACCEPTED_FILETYPES } from "../../constants";

type Props = {
  refetch: () => void;
  contentBefore?: ReactElement;
  contentAfter?: ReactElement;
};

const FormContainer = styled.div`
  padding-top: ${(p) => p.theme.spacing(2)}px;
`;

const DropzoneCreateFloorplan = ({ refetch: refetchEntries }: Props): ReactElement => {
  const { showToast } = useToastProvider();
  const { isVisible, closeDropzone } = useDropzoneStore();
  const [submittable, setSubmittable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("Please select a floorplan.");
  const { customers, isLoading } = useGetCustomers();
  const { preprocessImage } = usePreprocessImageHook();
  const [productId, setProductId] = useState(Level.BASIC);
  const customer = useCustomer();
  const [selectedCustomer, setSelectedCustomer] = useState(customer);

  const products = [
    {
      key: 0,
      name: "Basic 2D",
      priceId: Level.BASIC,
    },
    {
      key: 1,
      name: "Basic 2D + Möbliert",
      priceId: Level.BASIC_PLUS,
    },
    {
      key: 2,
      name: "Professional 3D",
      priceId: Level.PROFESSIONAL,
    },
    {
      key: 3,
      name: "Premium",
      priceId: Level.PREMIUM,
    },
  ];

  const inputTypes = [
    {
      key: 0,
      name: "Floorplan",
      type: OriginalInputType.SKETCH,
    },
    {
      key: 1,
      name: "Pointcloud",
      type: OriginalInputType.POINTCLOUD,
    },
  ];

  const successCallback = () => {
    closeDropzone();
    showToast({
      severity: "success",
      content: "Erfolgreich hochgeladen",
    });
    refetchEntries(); // refresh items in Dashboard
  };

  const { createFloorplan, setPreprocessedFile, setFile, handleChange, values, setFieldValue } = useCreateFloorplan({
    successCallback,
  });

  const { containsFloorplan } = useIdentifyFloorplan();

  const handleClose = () => {
    closeDropzone();
  };

  const handleFileChange = async (fileObjects: File[]) => {
    setSubmittable(false);
    if (fileObjects.length === 0) return;

    const file: File = fileObjects[0];
    setStatus("analyzing");
    try {
      const { file: preprocessedImage } = await preprocessImage(file);

      if (values.originalInputType === OriginalInputType.SKETCH) {
        let isValid = await containsFloorplan(preprocessedImage);
        if (isValid === true) {
          setStatus("Grundriss wurde erkannt.");
          setFiles(file, preprocessedImage);
        } else {
          setStatus("Es konnte kein Grundriss erkannt werden, bitte versuchen Sie ein anderes Bild.");
        }
      } else {
        setStatus("Grundriss wurde erkannt.");
        setFiles(file, preprocessedImage);
      }
    } catch (e) {
      console.log("Error during analysis: ", e);
      showToast({
        severity: "error",
        content: "Bei der Analyse des Bildes ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      });
    }
  };

  const setFiles = (file: File, preprocessedImage: File) => {
    setFile(file);
    setPreprocessedFile(preprocessedImage);
    setSubmittable(true);
  };

  // Create Floorplan
  const handleSave = async () => {
    setSubmittable(false); // deactivate button to prevent multiple submits
    setLoading(true);
    createFloorplan(productId, selectedCustomer.identityId); //originalInputType,
  };

  const handleCustomerChange = (event) => {
    const customerId = event.target.value;
    const customer = customers.find((customer) => customer.id === customerId);
    setFieldValue("customerId", customerId);
    setFieldValue("owner", customer.user);
    setSelectedCustomer(customer);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isVisible} maxWidth={"md"} fullWidth>
      <DialogTitle id="customized-dialog-title">Neues Vloor Projekt erstellen</DialogTitle>
      <DialogContent dividers>
        <DialogContentBefore>
          <FormContainer>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  required
                  id="name"
                  label="Name"
                  name="name"
                  value={values?.name ?? ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <TextField
                  id="address"
                  label="Adresse"
                  value={values?.address ?? ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <TextField
                  id="description"
                  name="description"
                  label="Anmerkungen"
                  value={values?.description ?? ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={5}
                />
              </Grid>
              {!isLoading && (
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl>
                    <Select
                      id="customerId"
                      name="customerId"
                      label="Customer"
                      value={values?.customerId}
                      onChange={handleCustomerChange}
                      fullWidth
                    >
                      {customers.map((customer) => {
                        return (
                          <MenuItem key={customer.id} value={customer.id}>
                            {customer.email}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={4}>
                <FormControl>
                  <Select
                    id="productId"
                    name="productId"
                    label="Product"
                    value={productId}
                    onChange={(event) => {
                      setProductId(event.target.value as Level);
                    }}
                    fullWidth
                  >
                    {products.map((product) => {
                      return (
                        <MenuItem key={product.key} value={product.priceId}>
                          {product.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl>
                  <Select
                    id="originalInputType"
                    name="originalInputType"
                    label="InputType"
                    value={values?.originalInputType}
                    onChange={handleChange}
                    fullWidth
                  >
                    {inputTypes.map((type) => {
                      return (
                        <MenuItem key={type.key} value={type.type}>
                          {type.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </FormContainer>
        </DialogContentBefore>
        <Typography gutterBottom variant="h5" component="h2">
          Grundrissplan hochladen
        </Typography>
        <DropzoneArea
          onChange={handleFileChange}
          acceptedFiles={ACCEPTED_FILETYPES}
          showPreviews={false}
          maxFileSize={5000000}
          filesLimit={1}
        />
        {status === "analyzing" ? <Typography>Analysiere...</Typography> : status}
      </DialogContent>
      {status === "analyzing" && <LinearProgress />}
      <DialogActions>
        {loading && <CircularProgress color="primary" />}
        <Button onClick={handleClose} color="secondary">
          Abbrechen
        </Button>
        <Button variant="contained" onClick={handleSave} color="primary" disabled={!submittable}>
          Erstellen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DropzoneCreateFloorplan;
