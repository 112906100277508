import { useMutation } from "react-query";
import { DeleteOrderMutation, DeleteOrderInput } from "../../API";
import * as mutations from "../../graphql/mutations";
import { gqlOperation } from "../GraphQLHooks";
import { Order } from "../../models";

export const useDeleteOrder = () => {
  const { mutateAsync } = useMutation((deleteInput: DeleteOrderInput) => {
    return gqlOperation<DeleteOrderMutation>(mutations.deleteOrder, {
      input: deleteInput,
    });
  });

  const deleteOrder = async (id: string) => {
    try {
      await mutateAsync({ id });
    } catch (e) {
      console.log(`ERROR: ${e}`);
    }
  };

  const deleteOrders = async (orders: Order[]) => {
    try {
      await orders.forEach((order: Order) => {
        const id = order.id;
        mutateAsync({ id });
      });
    } catch (e) {
      console.log(`ERROR: ${e}`);
    }
    console.log("Orders deleted");
  };

  return { deleteOrder, deleteOrders };
};
