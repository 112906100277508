import React from "react";
import { FlrPaper } from "../layout/StyledComponents";
import { Floorplan } from "../../models";
import DialogRescaleFloorplan from "../dashboard/DialogRescaleFloorplan";
import { Edit, Gesture } from "@material-ui/icons";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from "@material-ui/core";

type ScalingInfoPanelProps = {
  floorplan: Floorplan;
};

// User or API scaling input
type ScalingInput = {
  user_total_size: number;
  user_scaling_cm: number;
  user_scaling_selected_px: number;
  api_scaling_factor_cm_per_px: number;
};

const ScalingInfoPanel: React.FC<ScalingInfoPanelProps> = ({ floorplan }) => {
  const scalingInput: ScalingInput = {
    user_total_size: floorplan.user_total_size,
    user_scaling_cm: floorplan.user_scaling_cm,
    user_scaling_selected_px: floorplan.user_scaling_selected_px,
    api_scaling_factor_cm_per_px: floorplan.api_scaling_factor_cm_per_px || 0,
  };

  return (
    <FlrPaper>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2" align="center">
            Scaling Info
          </Typography>
          {scalingInput && (
            <List>
              <ListItem key={"1"}>
                <Tooltip title="Total Size" placement="left">
                  <ListItemAvatar>
                    <Avatar>
                      <AspectRatioIcon />
                    </Avatar>
                  </ListItemAvatar>
                </Tooltip>
                <ListItemText primary={scalingInput.user_total_size + "m²"} />
              </ListItem>

              <ListItem key={"2"}>
                <Tooltip title="Scaling cm" placement="left">
                  <ListItemAvatar>
                    <Avatar>
                      <Edit />
                    </Avatar>
                  </ListItemAvatar>
                </Tooltip>
                <ListItemText primary={scalingInput.user_scaling_cm + "cm"} />
              </ListItem>

              <ListItem key={"3"}>
                <Tooltip title="Scaling selected px" placement="left">
                  <ListItemAvatar>
                    <Avatar>
                      <Gesture />
                    </Avatar>
                  </ListItemAvatar>
                </Tooltip>
                <ListItemText primary={scalingInput.user_scaling_selected_px + "px"} />
              </ListItem>

              <ListItem key={"4"}>
                <Tooltip title="API Scaling factor cm per px" placement="left">
                  <ListItemAvatar>
                    <Avatar>
                      <BorderColorIcon />
                    </Avatar>
                  </ListItemAvatar>
                </Tooltip>
                <ListItemText primary={scalingInput.api_scaling_factor_cm_per_px + "px"} />
              </ListItem>
            </List>
          )}
          <DialogRescaleFloorplan floorplan={floorplan} />
        </Grid>
      </Grid>
    </FlrPaper>
  );
};

export default ScalingInfoPanel;
