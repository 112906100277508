export const getFloorplanOrders = /* GraphQL */ `
  query GetFloorplanOrders($id: ID!) {
    getFloorplan(id: $id) {
      id
      name
      address
      description
      rooms {
        items {
          id
          floorplanId
          name
          room_size_sqm
          room_type
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      orders {
        items {
          id
          floorplan_id
          order_id
          order {
            id
            user
            customer
            amount_total
            level
            stripe_checkout_session_id
            paymentStatus
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;

export const floorplansByDateBasic = /* GraphQL */ `
  query FloorplansByDateBasic($limit: Int, $sortDirection: ModelSortDirection) {
    floorplansByDate(limit: $limit, sortDirection: $sortDirection, type: "Floorplan") {
      items {
        id
        name
        createdAt
        status
        orders {
          items {
            customer
            order {
              level
              orderStatus
              paymentStatus
              id
            }
            id
          }
        }
        customer {
          email
          id
        }
      }
      nextToken
    }
  }
`;

export const floorplansByFloorplanStatusAfterDate = /* GraphQL */ `
  query FloorplansByFloorplanStatusAfterDate(
    $status: FloorplanStatus
    $createdAfter: String
    $sortDirection: ModelSortDirection
  ) {
    floorplansByDate(
      filter: { status: { eq: $status } }
      createdAt: { gt: $createdAfter }
      sortDirection: $sortDirection
      type: "Floorplan"
    ) {
      items {
        id
        name
        createdAt
        acceptedAt
        finishedAt
        status
        orders {
          items {
            customer
            order {
              level
              orderStatus
              id
            }
            id
          }
        }
        customer {
          email
          id
        }
      }
      nextToken
    }
  }
`;

export const floorplansAfterDate = /* GraphQL */ `
  query FloorplansAfterDate($createdAfter: String, $sortDirection: ModelSortDirection) {
    floorplansByDate(createdAt: { gt: $createdAfter }, sortDirection: $sortDirection, type: "Floorplan") {
      items {
        id
        name
        createdAt
        acceptedAt
        finishedAt
        status
        orders {
          items {
            customer
            order {
              level
              orderStatus
              id
            }
            id
          }
        }
        customer {
          email
          id
        }
      }
      nextToken
    }
  }
`;

export const listCustomersBasic = /* GraphQL */ `
  query ListCustomersBasic {
    listCustomers {
      items {
        user
        email
        createdAt
        id
        identityId
        company_taxnumber
        country
        company_city
        company_address_line2
        company
        company_address
        accepted_terms
        accepted_gdpr
        phone_number
        stripe_customer_id
        updatedAt
        zip
      }
    }
  }
`;

export const floorplansByOwnerBasic = /* GraphQL */ `
  query FloorplansByOwnerBasic(
    $owner: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFloorplanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    floorplansByOwner(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        projectId
        createdAt
        updatedAt
        name
        address
        description
        originalImageName
        originalImage {
          bucket
          region
          key
          status
        }
        croppedImage {
          bucket
          region
          key
          status
        }
        total_size
        amount_doors
        amount_rooms
        amount_windows
        total_room_size_m2
        total_room_size_px
        user_total_size
        user_scaling_selected_px
        user_scaling_cm
        status
        rooms {
          items {
            id
            floorplanId
            name
            room_size_sqm
            room_type
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        orders {
          items {
            id
            floorplan_id
            order_id
            customer
            createdAt
            updatedAt
          }
          nextToken
        }
        customerId
        customer {
          id
          user
          identityId
          email
          stripe_customer_id
          phone_number
          company
          company_address
          company_address_line2
          company_city
          company_taxnumber
          country
          zip
          accepted_terms
          accepted_gdpr
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
