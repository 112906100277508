import { IconButton } from "@material-ui/core";
import { PaginationRenderItemParams } from "@material-ui/lab";
import styled from "styled-components";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

type PropTypes = {
  params: PaginationRenderItemParams;
  selected: boolean;
  room: any;
};

const RoomCube = styled.img`
  margin: 5px;
  border-radius: 14px;
  object-fit: cover;
  width: 100px;
  height: 100px;
  cursor: pointer;
  border: 2px solid ${(props) => (props.$selected ? props.theme.palette.primary.main : "transparent")};
`;

export const RoomPagination: React.FC<PropTypes> = ({ params, selected, room }) => {
  switch (params.type) {
    case "page":
      return (
        <RoomCube
          $selected={selected}
          src={room.images[params.page - 1]}
          alt="furnished room"
          onClick={params.onClick}
        />
      );
    case "previous":
      return (
        <IconButton color="primary" onClick={params.onClick} disabled={params.page === 0}>
          <ArrowBackIosIcon />
        </IconButton>
      );
    case "next":
      return (
        <IconButton color="primary" onClick={params.onClick} disabled={params.page === room.images.length + 1}>
          <ArrowForwardIosIcon />
        </IconButton>
      );
    default:
      return <></>;
  }
};
