export type Furniture = {
  id: number;
  title: string;
  link?: string;
  img: string;
  price: number;
  category: FurnitureCategory;
  checked: boolean;
};

export type Position = {
  top: number;
  left: number;
};

export type ExtendedFurniture = {
  furniture: Furniture;
  position: Position;
};

export enum FurnitureCategory {
  COUCH,
  CARPET,
  LIVINGROOMTABLE,
  CHAIR,
  BED,
  BASKET,
  ROUNDDININGTABLE,
  SIDEBOARD,
  CEILINGLIGHT,
  BEDSIDETABLE,
  DININGTABLE,
  LAMP,
}

export type Room = {
  id: number;
  title: string;
  img: string;
  logo?: string;
  furniture?: ExtendedFurniture[];
};
