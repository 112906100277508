import { FloorplanStatus, Level } from "models";
import { useCallback } from "react";

const useKeysForOrderLevelHook = () => {
  const getKeysForOrderLevel = useCallback((floorplan, orderLevel, type) => {
    const keys = [];
    if (
      floorplan.status === FloorplanStatus.IN_PROGRESS ||
      floorplan.status === FloorplanStatus.QA_CREATED ||
      floorplan.status === FloorplanStatus.REWORK ||
      floorplan.status === FloorplanStatus.CREATED
    ) {
      switch (orderLevel) {
        case Level.PROFESSIONAL:
          keys.push(floorplan.croppedImage.key);
          keys.push(floorplan.basicImage[type].key);
          keys.push(floorplan.basicPlusImage[type].key);
          keys.push(floorplan.professionalImageTop[type].key);
          keys.push(floorplan.professionalImageTilted[type].key);
          keys.push(floorplan.professionalImageNE[type].key);
          keys.push(floorplan.professionalImageNW[type].key);
          keys.push(floorplan.professionalImageSE[type].key);
          keys.push(floorplan.professionalImageSW[type].key);
          break;
        case Level.BASIC_PLUS:
          keys.push(floorplan.croppedImage.key);
          keys.push(floorplan.basicImage[type].key);
          keys.push(floorplan.basicPlusImage[type].key);
          break;
        case Level.BASIC:
          keys.push(floorplan.croppedImage.key);
          keys.push(floorplan.basicImage[type].key);
          break;
        case Level.FML:
          keys.push(floorplan.croppedImage.key);
          break;
        default:
          break;
      }
    } else {
      keys.push(floorplan.croppedImage.key);
    }
    return keys;
  }, []);
  return { getKeysForOrderLevel };
};

export default useKeysForOrderLevelHook;
