import React from "react";
import styled from "styled-components";
// import Particles from "react-tsparticles";

import { Box, Grid } from "@material-ui/core";

import Logo from "../../assets/logo_text_right_black_transparent.png";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const MainGrid = styled(Grid)`
  height: 100%;
`;

const LoginGrid = styled(Grid)`
  background: white;
  -webkit-box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
  -moz-box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
  border-radius: 14px;
  padding: 40px 10px 10px 10px;
  margin: 10px;
`;

const LoginLayout = ({ children }) => (
  <Root>
    <MainGrid container justify="center" alignItems="center">
      <Grid item>
        <LoginGrid container direction="column" alignItems="center" justify="center">
          <Grid item>
            <Box display="flex" justifyContent="center">
              <img src={Logo} alt="Logo" height="80" />
            </Box>
          </Grid>
          <Grid item>{children}</Grid>
        </LoginGrid>
      </Grid>
    </MainGrid>
    {/* <Particles
      params={{
        particles: {
          color: {
            value: "#CC0007",
          },
          links: {
            color: "#CC0007",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: "none",
            enable: true,
            outMode: "bounce",
            random: false,
            speed: 6,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              value_area: 2000,
            },
            value: 50,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: "circle",
          },
          size: {
            random: true,
            value: 5,
          },
        },
      }}
    /> */}
  </Root>
);

export default LoginLayout;
