import React from "react";
import { Route } from "react-router-dom";
import Checkout from "../container/checkout/Checkout";
import CheckoutLayout from "../container/layout/CheckoutLayout";
import StateProvider from "container/checkout/context/CheckoutStateContext";

import { useQueryParams } from "utils/UrlUtils";
import { OriginalInputType } from "models";

export const CheckoutRoute = ({ match }) => {
  let query = useQueryParams();
  const type = query.get("type");
  let originalInputType =
    type && type.toUpperCase() === OriginalInputType.POINTCLOUD
      ? OriginalInputType.POINTCLOUD
      : OriginalInputType.SKETCH;

  return (
    <CheckoutLayout>
      <StateProvider>
        <Route path={`${match.path}`} render={({ match }) => <Checkout inputType={originalInputType} />} />
      </StateProvider>
    </CheckoutLayout>
  );
};
