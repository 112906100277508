import React from "react";
import styled from "styled-components";

import Grid from "@material-ui/core/Grid";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const MainGrid = styled(Grid)`
  height: 100%;
`;

const EmptyLayout = ({ children }) => (
  <Root>
    <MainGrid container justify="center" alignItems="center">
      <Grid item>{children}</Grid>
    </MainGrid>
  </Root>
);

export default EmptyLayout;
