import { Redirect, Route } from "react-router-dom";

import IconLink from "components/layout/IconLink";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { ContentHeader, Headline } from "components/layout/StyledComponents";

import FloorplanViewer, { Modes } from "../container/view/FloorplanViewer";

export const ViewRoute = ({ match }) => {
  return (
    <>
      <Route exact path={`${match.path}/:id`} render={(props) => <Redirect to={`${props.match.params.id}/viewer`} />} />
      <Route
        exact
        path={`${match.path}/:id/viewer`}
        render={({ match }) => <FloorplanViewer id={match.params.id} mode={Modes.Viewer} />}
      />
      <Route
        path={`${match.path}/:id/editor`}
        render={({ match }) => <FloorplanViewer id={match.params.id} mode={Modes.Editor} />}
      />
      <Route
        exact
        path={match.url}
        render={() => (
          <ContentHeader>
            <IconLink tooltip="Zurück zum Dashboard" to="/dashboard" icon={<ArrowBackIcon />} />
            <Headline variant="h4" component="h1">
              Please select a floorplan.
            </Headline>
          </ContentHeader>
        )}
      />
    </>
  );
};
