import { useFormik } from "formik";
import { useMutation } from "react-query";
import { UpdateFloorplanMutation } from "../../API";
import * as mutations from "../../graphql/mutations";
import { Floorplan } from "../../models";
import { useToastProvider } from "../../providers/ToastProvider";
import { gqlOperation } from "../GraphQLHooks";

export type FloorplanFormikValues = Partial<Floorplan>;

export const useFloorplanFormik = (id: string, refetch: () => void) => {
  const { showToast } = useToastProvider();
  const { mutateAsync: updateFloorplanAsync } = useMutation((values: FloorplanFormikValues) =>
    gqlOperation<UpdateFloorplanMutation>(mutations.updateFloorplan, {
      input: {
        id: id,
        name: values.name,
        address: values.address,
        description: values.description,
      },
    })
  );

  const formik = useFormik<FloorplanFormikValues>({
    initialValues: { name: "", description: "" },
    onSubmit: async (values) => {
      await updateFloorplanAsync(values);
      refetch();
      showToast({ severity: "success", content: "Erfolgreich gespeichert" });
    },
  });

  return { ...formik, saveFloorplan: formik.submitForm };
};
