import NumberFormat from "react-number-format";

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { value: string } }) => void;
}

// Formats a number input field with the specified format
// Do NOT add type="number" to input field
export const NumberFormatSquareMeters = (props: NumberFormatCustomProps) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      decimalSeparator=","
      decimalScale={2}
      isNumericString={true}
      suffix=" m2"
    />
  );
};

export const NumberFormatCentimeters = (props: NumberFormatCustomProps) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      decimalSeparator=","
      decimalScale={0}
      isNumericString={true}
      suffix=" cm"
    />
  );
};
