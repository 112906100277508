import { createKey, getFileExtension, getNameWithoutExtension } from "utils/KeyUtils";
import { Floorplan } from "models";
import { v4 as uuidv4 } from "uuid";
import { S3_REGION, S3_PRIVACY_LEVEL, S3_PREFIX_FOLDER } from "../constants";
import { S3ObjectInput } from "API";

export const imageToS3Object = (name: string, floorplan: Floorplan) => {
  const key = createKey(
    {
      identityId: floorplan.customer.identityId,
      projectId: floorplan.projectId,
      fileId: uuidv4(),
      privacyLevel: S3_PRIVACY_LEVEL,
      prefixFolder: S3_PREFIX_FOLDER,
    },
    `-${getNameWithoutExtension(name)}`,
    getFileExtension(name),
    "chatMessageImages"
  );
  return toS3Object(key);
};

const bucket = process.env.REACT_APP_FLOORPLAN_UPLOAD_BUCKET;

const toS3Object = (key: string): S3ObjectInput => {
  return {
    bucket: bucket,
    region: S3_REGION,
    key: key,
  };
};
