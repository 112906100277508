import React from "react";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  min-height: ${({ minHeight }) => `${minHeight}`};
`;

export const LoadingAnimationMain = () => {
  return (
    <LoadingContainer minHeight="100vh">
      <CircularProgress />
    </LoadingContainer>
  );
};

export const LoadingAnimation = () => {
  return (
    <LoadingContainer>
      <CircularProgress />
    </LoadingContainer>
  );
};
