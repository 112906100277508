import API, { graphqlOperation, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLOptions } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export const gqlOperation = async <ResultType extends {}, VariablesType extends {} = {}>(
  query: string,
  variables?: VariablesType,
  authMode?: GRAPHQL_AUTH_MODE
) => {
  const options: GraphQLOptions = {
    query: query,
    variables: variables,
    authMode: authMode,
  };
  const { data } = (await API.graphql(options)) as {
    data: ResultType;
  };
  return data;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const gqlSubscription = async <ResultType extends {}, VariablesType extends {} = {}>(
  subscription: string,
  variables?: VariablesType
) => {
  return (await API.graphql(graphqlOperation(subscription, variables))) as Observable<any>;
};
