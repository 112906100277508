import React, { useState, useEffect, useCallback } from "react";
import { API, Auth } from "aws-amplify";
import { Box, Container, Typography } from "@material-ui/core";

import SignIn from "../../components/auth/SignIn";
import SignUp from "../../components/auth/SignUp";
import ConfirmSignUp from "../../components/auth/ConfirmSignUp";
import { ForgotPassword, ConfirmPasswordReset } from "../../components/auth/ForgotPassword";

import { useAuthUIState } from "../../components/auth/context/AuthUIStateContext";
import { AuthUIForm, AuthUIActionTypes, AuthUIState } from "../../components/auth/context/AuthUIStateReducer";
import { useToastProvider } from "providers/ToastProvider";
import { CreateCustomerInput } from "API";

import * as constants from "../../constants";

import { Event } from "../../hooks/analytics/GoogleAnalytics";
import * as analytics from "../../hooks/analytics/GoogleAnalytics";
import { useHistory, useLocation } from "react-router-dom";
import LoginLayout from "container/layout/LoginLayout";

const Authenticator = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const { state, dispatch } = useAuthUIState();
  const { showToast } = useToastProvider();
  const [formType, updateFormType] = useState<AuthUIForm>(state.formState);

  // switch to signup form if register is in path
  useEffect(() => {
    switch (location.pathname) {
      case "/register":
        dispatch({
          type: AuthUIActionTypes.CHANGE_FORM,
          key: state.formState,
          value: AuthUIForm.SIGNUP,
        });
        break;
      case "/resetpassword":
        dispatch({
          type: AuthUIActionTypes.CHANGE_FORM,
          key: state.formState,
          value: AuthUIForm.FORGOTPASSWORD,
        });
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (state.formState) {
      case AuthUIForm.SIGNUP:
        history.push(`/register`);
        break;
      case AuthUIForm.FORGOTPASSWORD:
        history.push(`/resetpassword`);
        break;
      default:
        break;
    }

    updateFormType(state.formState);
  }, [state, history]);

  const changeAuthUIForm = (formType: AuthUIForm, path?: string) => {
    // analytics
    const event: Event = {
      category: "Authenticaton",
      action: "goTo_" + formType,
      label: formType,
    };
    analytics.sendEvent(event);

    // set path
    history.push(path || "/");

    dispatch({
      type: AuthUIActionTypes.CHANGE_FORM,
      key: state.formState,
      value: formType,
    });
  };

  // const resetFormValues = (formType: AuthUIForm) => {
  //   dispatch({
  //     type: AuthUIActionTypes.RESET_FORM_VALUES,
  //     key: null,
  //     value: formType,
  //   });
  // };

  const signUp = async (
    { username, password, formValues }: AuthUIState,
    setSubmitting: (isSubmitting: boolean) => void,
    recaptchaToken: string
  ) => {
    console.log("username:", username);
    console.log("formValues:", formValues);
    console.log("recaptchaToken:", recaptchaToken);

    try {
      const response = await Auth.signUp({
        username: username,
        password: password,
        // @ts-ignore
        validationData: { recaptchaToken },
      });

      showToast({
        severity: "success",
        content: "Wir haben Ihnen eine Email mit einem Code zur Bestätigung der Registrierung geschickt.",
      });

      console.log("SignUp:", response);
      createCustomer(formValues, response.userSub);
      dispatch({
        type: AuthUIActionTypes.SET_USERNAME,
        key: null,
        value: username,
      });

      // analytics event
      const event: Event = {
        category: "SignUp",
        action: "signUp",
        label: "user_" + response.userSub,
      };
      analytics.sendEvent(event);

      changeAuthUIForm(AuthUIForm.CONFIRMSIGNUP);
    } catch (err) {
      console.log("error signing up..", err);
      handleError(err);
      setSubmitting(false);
    }
  };

  const createCustomer = useCallback(async (formValues: CreateCustomerInput, userSub: string): Promise<any> => {
    const payload = {
      body: {
        userSub: userSub,
        ...formValues,
      },
      headers: {
        "x-api-key": process.env.REACT_APP_CUSTOMER_FUNCTIONS_KEY || "",
      },
    };
    try {
      const response = await API.post(constants.CUSTOMER_API_NAME, constants.CUSTOMER_API_ENDPOINT, payload);
      console.log("Response createCustomer: ", response);
      return response;
    } catch (err) {
      console.error(err);
    }
  }, []);

  const resendSignUp = async (username: string) => {
    if (username) {
      // analytics event
      const event: Event = {
        category: "SignUp",
        action: "resendSignUp",
      };
      analytics.sendEvent(event);

      try {
        await Auth.resendSignUp(username);
        console.log("New confirmation code has been sent to:", username);
        const content = "Wir haben einen neuen Verifizierungscode an Sie geschickt: " + username;
        dispatch({
          type: AuthUIActionTypes.SET_USERNAME,
          key: null,
          value: username,
        });
        showToast({
          severity: "success",
          content: content,
        });
      } catch (err) {
        console.log("error resending verificationCode..", err);
        handleError(err);
      }
    } else {
      showToast({
        severity: "warning",
        content: "Bitte geben Sie eine Email Adresse an.",
      });
    }
  };

  const confirmSignUp = async (username: string, verificationCode: string) => {
    // analytics event
    const event: Event = {
      category: "SignUp",
      action: "confirmSignUp",
    };
    analytics.sendEvent(event);

    try {
      await Auth.confirmSignUp(username, verificationCode);
      console.log("confirm sign up success!");
      changeAuthUIForm(AuthUIForm.SIGNIN);
      showToast({
        severity: "success",
        content: "Ihr Account wurde erfolgreich aktiviert.",
      });
    } catch (err) {
      console.log("error signing up..", err);
      handleError(err);
    }
  };

  const resetPassword = async (username: string) => {
    // analytics event
    const event: Event = {
      category: "Password",
      action: "resetPassword",
    };
    analytics.sendEvent(event);

    try {
      await Auth.forgotPassword(username);
      dispatch({
        type: AuthUIActionTypes.SET_USERNAME,
        key: null,
        value: username,
      });
      showToast({
        severity: "success",
        content:
          "Wir haben Ihnen eine Email mit einem Verifizierungscode geschickt. Bitte tragen Sie diesen in das dafür vorgesehene Feld ein und ändern Sie Ihr Passwort.",
      });
      changeAuthUIForm(AuthUIForm.CONFIRMPASSWORD);
    } catch (err) {
      console.log("error resetting password..", err);
      handleError(err);
    }
  };

  const confirmPasswordReset = async (username: string, verificationCode: string, password: string) => {
    // analytics event
    const event: Event = {
      category: "Password",
      action: "confirmPasswordReset",
    };
    analytics.sendEvent(event);

    try {
      await Auth.forgotPasswordSubmit(username, verificationCode, password);
      changeAuthUIForm(AuthUIForm.SIGNIN);
      showToast({
        severity: "success",
        content: "Ihr Passwort wurde erfolgreich zurückgesetzt.",
      });
    } catch (err) {
      console.log("error resetting password..", err);
      handleError(err);
    }
  };

  const signIn = async (username: string, password: string) => {
    // analytics event
    const event: Event = {
      category: "Authentication",
      action: "signIn",
    };
    analytics.sendEvent(event);

    try {
      dispatch({
        type: AuthUIActionTypes.SET_USERNAME,
        key: null,
        value: username,
      });
      const signInResponse = await Auth.signIn({
        username,
        password,
      });
      const sub = signInResponse.attributes.sub;
      API.post(constants.CUSTOMER_API_NAME, constants.CUSTOMER_IDENTITY_ENDPOINT, { body: { sub } });

      history.push(`/`);
      console.log("sign in success!");
    } catch (err) {
      console.log("error signing in..", err);
      handleError(err);
      //resetFormValues(AuthUIForm.SIGNIN);
    }
  };

  const handleError = (err: { message: any; code: any }) => {
    if (err.message && err.code) {
      let message = "Unbekannter Fehler";

      // track exceptions
      analytics.sendException({ description: JSON.stringify(err) });

      switch (err.code) {
        case "UsernameExistsException":
          message = "Ein User mit dieser E-Mail Adresse existiert bereits.";
          break;
        case "NotAuthorizedException":
          message = "Ungültige E-Mail Adresse oder Passwort.";
          break;
        case "LimitExceededException":
          message = "Bitte versuchen Sie es später erneut.";
          break;
        case "CodeMismatchException":
          message = "Ihr Code stimmt nicht mit dem Code überein, den wir Ihnen geschickt haben.";
          break;
        case "UserNotFoundException":
          message = "Ein User mit dieser E-Mail Adresse existiert nicht.";
          break;
        case "InvalidParameterException":
          message = "Ein User mit dieser E-Mail Adresse existiert nicht.";
          break;
        case "CodeDeliveryFailureException;":
          message = "E-Mail mit Verifizierungscode konnte nicht versandt werden, bitte versuchen Sie es nochmal. ";
          break;
        case "UserNotConfirmedException":
          message = "E-Mail Adresse wurde noch nicht bestätigt. Bitte Verifizierungscode eingeben.";
          changeAuthUIForm(AuthUIForm.CONFIRMSIGNUP);
          break;
        default:
          break;
      }
      showToast({
        severity: "error",
        content: message,
      });
    }
  };

  const renderForm = (formType: AuthUIForm) => {
    switch (formType) {
      case AuthUIForm.SIGNUP:
        return <SignUp signUp={signUp} changeForm={changeAuthUIForm} />;
      case AuthUIForm.CONFIRMSIGNUP:
        return (
          <ConfirmSignUp confirmSignUp={confirmSignUp} changeForm={changeAuthUIForm} resendSignUp={resendSignUp} />
        );
      case AuthUIForm.SIGNIN:
        return <SignIn signIn={signIn} changeForm={changeAuthUIForm} />;
      case AuthUIForm.FORGOTPASSWORD:
        return <ForgotPassword resetPassword={resetPassword} changeForm={changeAuthUIForm} />;
      case AuthUIForm.CONFIRMPASSWORD:
        return <ConfirmPasswordReset confirmPasswordReset={confirmPasswordReset} changeForm={changeAuthUIForm} />;
      default:
        return null;
    }
  };

  return (
    <LoginLayout>
      <Container component="main" maxWidth="xs">
        {renderForm(formType)}
        <Box mt={4}>
          <Typography variant={"subtitle2"}>© Vloor GmbH 2021</Typography>
        </Box>
      </Container>
    </LoginLayout>
  );
};

export default Authenticator;
