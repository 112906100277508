import { Avatar, Button } from "@material-ui/core";
import { ChatMessage } from "models";
import { toDateChat } from "../../utils/DateUtils";
import LogoSmall from "../../assets/logo_no_text.png";
import { GetApp } from "@material-ui/icons";
import styled from "styled-components";
import useCreatePresignedUrl from "hooks/exports/useCreatePresignedUrl";
import { useEditorUrlDownload } from "hooks/exports/DownloadImagesHook";

const ChatItemContainer = styled.div`
  display: flex;
  justify-content: ${({ $sentByCustomer }) => ($sentByCustomer ? "flex-end" : "flex-start")};
  margin: 10px 5px;
`;

const ChatBubble = styled.div`
  display: flex;
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 14px;
  background-color: ${(props) =>
    props.$sentByCustomer ? props.theme.palette.primary.main : props.theme.palette.secondary.main};
  min-width: 53%;
  width: auto;
  max-width: 75%;
`;

const MessageDeco = styled.div`
  order: ${({ $sentByCustomer }) => ($sentByCustomer ? 3 : 1)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChatIcon = styled(Avatar)`
  margin: 5px;
`;

const ChatInfo = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 0.7rem;
  color: white;
  align-items: center;
  text-align: center;
  white-space: pre-line;
`;

const MessageContent = styled.div`
  order: 2;
  margin: 5px;
  display: flex;
  flex-direction: column;
  color: white;
  flex: 1;
  gap: 5px;
`;

const ChatMsg = styled.div`
  padding: 5px;
  white-space: pre-line;
`;

const ChatImages = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
  border-top: 1px solid white;
`;

// const ChatImage = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

const DownloadButton = styled(Button)`
  color: white;
`;

type Props = {
  chatMessage: ChatMessage;
};

const ChatItem: React.FC<Props> = ({ chatMessage }) => {
  const { createPresignedURLs } = useCreatePresignedUrl();
  const { startEditorDownload } = useEditorUrlDownload("Anhang");

  const downloadImages = async () => {
    const keys = chatMessage.images.map((s3object) => s3object.key);
    const urls = await createPresignedURLs(keys, "GET");
    startEditorDownload(urls);
  };

  return (
    <ChatItemContainer $sentByCustomer={chatMessage.sentByCustomer}>
      <ChatBubble $sentByCustomer={chatMessage.sentByCustomer}>
        <MessageDeco $sentByCustomer={chatMessage.sentByCustomer}>
          <ChatIcon src={!chatMessage.sentByCustomer ? LogoSmall : ""} />
          <ChatInfo>{toDateChat(chatMessage.createdAt).replace(",", "\n")}</ChatInfo>
        </MessageDeco>
        <MessageContent>
          <ChatMsg> {chatMessage.message} </ChatMsg>
          {chatMessage.images.length > 0 && (
            <ChatImages>
              Anhänge: {chatMessage.images.length} {chatMessage.images.length === 1 ? "Datei" : "Dateien"}
              <DownloadButton onClick={downloadImages}>
                <GetApp />
              </DownloadButton>
            </ChatImages>
          )}
        </MessageContent>
      </ChatBubble>
    </ChatItemContainer>
  );
};

export default ChatItem;
