import React, { useContext } from "react";
import Storage from "@aws-amplify/storage";
import { Auth } from "aws-amplify";
import sanitize from "sanitize-filename";

import * as KeyUtils from "../../utils/KeyUtils";
import * as FloorplanDBUtils from "../../utils/FloorplanDBUtils";

import { useMutation } from "react-query";
import { gqlOperation } from "../GraphQLHooks";
import * as mutations from "../../graphql/mutations";
import { Floorplan, Level, OriginalInputType } from "../../models";
import { CreateFloorplanInput, CreateFloorplanMutation } from "../../API";
import { mapCreateFloorplanMutation } from "../../types/types";

import { CheckoutContext } from "../../providers/CheckoutContextProvider";
import { useCustomer } from "providers/CustomerContextProvider";
import { useDigitizeFloorplan } from "hooks/floorplan/useDigitizeFloorplan";

export const useCreateAndCheckout = () => {
  //const { showToast } = useToastProvider();
  const [uploadProgress, setUploadProgress] = React.useState(0);

  const { digitizeFloorplan } = useDigitizeFloorplan();
  const { checkout } = useContext(CheckoutContext);
  const customer = useCustomer();

  const { mutateAsync: createFloorplanMutation } = useMutation((content: CreateFloorplanInput) =>
    gqlOperation<CreateFloorplanMutation>(mutations.createFloorplan, {
      input: content,
    })
  );

  const createAndCheckout = async (
    newFloorplan: CreateFloorplanInput,
    originalInputType: OriginalInputType,
    originalImageFile: File,
    croppedImageFile: File,
    preprocessedImageFile: File,
    productId: Level,
    reusePaymentMethod: boolean
  ) => {
    try {
      // create floorplan in DB and upload files to S3
      newFloorplan.customerId = customer.id;
      newFloorplan.originalInputType = originalInputType;
      const floorplan: Floorplan = await createFloorplan(
        newFloorplan,
        originalImageFile,
        croppedImageFile,
        preprocessedImageFile
      );
      console.log("createFloorplan result: ", floorplan);

      // trigger digitize floorplan
      digitizeFloorplan(floorplan);

      // redirect to stripe checkout
      checkout(floorplan, productId, reusePaymentMethod);
    } catch (e) {
      console.error(e);
    }
  };

  // create floorplan: upload image to S3 and create floorplan DB entry
  const createFloorplan = async (
    newFloorplan: CreateFloorplanInput,
    originalImageFile: File,
    croppedImageFile: File,
    preprocessedImageFile: File
  ): Promise<Floorplan> => {
    console.log("create floorplan: ", newFloorplan);
    // the id of the current-S3-user (Auth.currentUserInfo()),
    // e.g. eu-central-1:690fbf1c-8866-4d1f-84f7-430e27f9cf8a,
    // is different from the Cognito-User = the owner (Auth.currentAuthenticatedUser())
    // because one owner can own multiple Storages in different regions, e.g. eu-west-1
    const user = await Auth.currentUserInfo();

    const filename = sanitize(originalImageFile?.name).replace(" ", "");
    const content = FloorplanDBUtils.createDBEntryContent(user.id, filename, newFloorplan); // user.id = identityId
    console.log("Filename: ", filename);
    console.log("Content: ", content);

    // upload original, cropped and preprocessed image to S3
    await uploadImage(content.originalImage.key, originalImageFile);
    await uploadImage(content.croppedImage.key, croppedImageFile);
    await uploadImage(content.preprocessedImage.key, preprocessedImageFile);

    // create floorplan db entry
    const result = await createFloorplanMutation(content);
    const floorplan: Floorplan = mapCreateFloorplanMutation(result.createFloorplan);
    return floorplan;
  };

  const uploadImage = async (key: string, file: any) => {
    const user = await Auth.currentAuthenticatedUser(); // auth user.name is the cognito name
    let prog: number = 0;

    // image has to be saved in S3.vault under
    // relative path floorplans/floorplanprojectID/uuid.png
    try {
      const result = await Storage.vault.put(KeyUtils.getPathFromKey(key), file, {
        metadata: {
          owner: user.username,
        },
        progressCallback(progress) {
          prog = (progress.loaded / progress.total) * 100;
          setUploadProgress(prog);
        },
      });
      console.log("Upload Successful! file:", file, result);
    } catch (err) {
      console.error(err);
    }
  };

  return { createAndCheckout, uploadProgress };
};
