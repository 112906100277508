import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  Grid,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Tooltip,
  ListItemIcon,
  ListItemSecondaryAction,
  ListSubheader,
  IconButton,
} from "@material-ui/core";
import { Edit, Share, ChevronRight, Visibility } from "@material-ui/icons";

import { FlrPaper } from "../../components/layout/StyledComponents";
import { Floorplan, Order } from "../../models";
import { Modes } from "container/view/FloorplanViewer";
import { BASE_URL } from "../../constants";

type ExportPanelProps = {
  floorplan: Floorplan;
  order: Order;
};

const ExportPanel: React.FC<ExportPanelProps> = ({ floorplan }) => {
  const history = useHistory();
  const [copiedViewer, setCopiedViewer] = useState(false);
  const [copiedEditor, setCopiedEditor] = useState(false);

  const goToViewer = (id: string) => {
    history.push(`/view/${id}/viewer`);
  };

  const goToEditor = (id: string) => {
    history.push(`/view/${id}/editor`);
  };

  // has to be floorplannerId here to enable shareability without login
  const viewerShareUrl = BASE_URL + "/share/" + floorplan.floorplannerId + "/viewer";
  const editorShareUrl = BASE_URL + "/share/" + floorplan.floorplannerId + "/editor";

  // iframes will be shareable later
  // const viewerIframe = `<iframe src="${viewerShareUrl}"></iframe>`;
  // const editorIframe = `<iframe src="${editorShareUrl}"></iframe>`;

  const handleCopy = (mode: Modes) => {
    switch (mode) {
      case Modes.Viewer:
        setCopiedViewer(true);
        setCopiedEditor(false);
        break;
      case Modes.Editor:
        setCopiedViewer(false);
        setCopiedEditor(true);
        break;
    }
  };

  return (
    <FlrPaper>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="h5" component="h2" align="center">
            3D Viewer und 3D Editor
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <List>
            <ListSubheader>3D Viewer</ListSubheader>

            <ListItem key="viewer" button onClick={() => goToViewer(floorplan.id)}>
              <Tooltip title="3D Viewer" placement="left">
                <ListItemAvatar>
                  <Avatar>
                    <Visibility />
                  </Avatar>
                </ListItemAvatar>
              </Tooltip>
              {floorplan.floorplannerId !== null ? (
                <Typography variant="body2" color="primary">
                  Zum 3D Viewer
                </Typography>
              ) : (
                "No Id set"
              )}
              <ListItemSecondaryAction>
                <ListItemIcon>
                  <ChevronRight />
                </ListItemIcon>
              </ListItemSecondaryAction>
            </ListItem>

            <CopyToClipboard text={viewerShareUrl} onCopy={() => handleCopy(Modes.Viewer)}>
              <ListItem key="viewer_share">
                <ListItemText>
                  <Tooltip title="3D Viewer URL kopieren und Link teilen" placement="left">
                    {floorplan.floorplannerId !== null ? (
                      <TextField
                        id="viewer"
                        label={copiedViewer ? "Viewer kopiert" : "Viewer teilen"}
                        name="viewer_share"
                        value={viewerShareUrl}
                        variant="outlined"
                        focused={copiedViewer}
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    ) : (
                      <>"No Id set"</>
                    )}
                  </Tooltip>
                </ListItemText>

                <ListItemIcon>
                  <Tooltip title="3D Viewer teilen" placement="right">
                    <IconButton edge="end" color={copiedViewer ? "primary" : "secondary"}>
                      <Share />
                    </IconButton>
                  </Tooltip>
                </ListItemIcon>
              </ListItem>
            </CopyToClipboard>

            <ListSubheader>Interaktiver 3D Editor</ListSubheader>
            <ListItem key="editor" button onClick={() => goToEditor(floorplan.id)}>
              <Tooltip title="Interaktiver 3D Editor" placement="left">
                <ListItemAvatar>
                  <Avatar>
                    <Edit />
                  </Avatar>
                </ListItemAvatar>
              </Tooltip>
              {floorplan.floorplannerId !== null ? (
                <Typography variant="body2" color="primary">
                  Zum interaktiven 3D Editor
                </Typography>
              ) : (
                "No Id set"
              )}
              <ListItemSecondaryAction>
                <ListItemIcon>
                  <ChevronRight />
                </ListItemIcon>
              </ListItemSecondaryAction>
            </ListItem>

            <CopyToClipboard text={editorShareUrl} onCopy={() => handleCopy(Modes.Editor)}>
              <ListItem key="editor_share">
                <ListItemText>
                  <Tooltip title="3D Editor URL kopieren und Link teilen" placement="left">
                    {floorplan.floorplannerId !== null ? (
                      <TextField
                        id="editor"
                        label={copiedEditor ? "Editor kopiert" : "Editor teilen"}
                        name="editor_teilen"
                        value={editorShareUrl}
                        variant="outlined"
                        focused={copiedEditor}
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    ) : (
                      <>"No Id set"</>
                    )}
                  </Tooltip>
                </ListItemText>

                <ListItemIcon>
                  <Tooltip title="3D Editor kopieren" placement="right">
                    <IconButton edge="end" color={copiedEditor ? "primary" : "secondary"}>
                      <Share />
                    </IconButton>
                  </Tooltip>
                </ListItemIcon>
              </ListItem>
            </CopyToClipboard>
          </List>
        </Grid>
      </Grid>
    </FlrPaper>
  );
};

export default ExportPanel;
