import styled from "styled-components";

const ActionBlockerOverlay = styled.div`
  height: 60px;
  background-color: white;
  margin-bottom: -60px;
  position: relative;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
`;

const RoomplannerActionBlocker: React.FC = () => {
  return <ActionBlockerOverlay />;
};

export default RoomplannerActionBlocker;
