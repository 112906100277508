import { useMutation } from "react-query";
import { DeleteRoomMutation, DeleteRoomInput } from "../../API";
import * as mutations from "../../graphql/mutations";
import { gqlOperation } from "../GraphQLHooks";
import { Room } from "../../models";

export const useDeleteRoom = () => {
  const { mutateAsync } = useMutation((deleteInput: DeleteRoomInput) => {
    return gqlOperation<DeleteRoomMutation>(mutations.deleteRoom, {
      input: deleteInput,
    });
  });

  const deleteRoom = async (id: string) => {
    try {
      await mutateAsync({ id });
    } catch (e) {
      console.log(`ERROR: ${e}`);
    }
  };

  const deleteRooms = async (rooms: Room[]) => {
    try {
      await rooms.forEach((room: Room) => {
        const id = room.id;
        mutateAsync({ id });
      });
    } catch (e) {
      console.log(`ERROR: ${e}`);
    }
    console.log("Rooms deleted");
  };

  return { deleteRoom, deleteRooms };
};
