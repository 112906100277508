import bedroom from "assets/felixwhg/bedroom.jpeg";
import bedroom2 from "assets/felixwhg/bedroom2.jpeg";
import bedroom3 from "assets/felixwhg/bedroom3.jpeg";

import kitchen from "assets/felixwhg/kitchen.jpeg";
import kitchen2 from "assets/felixwhg/kitchen2.jpeg";
import kitchen3 from "assets/felixwhg/kitchen3.jpeg";

import living from "assets/felixwhg/living.jpeg";
import living2 from "assets/felixwhg/living2.jpeg";
import living3 from "assets/felixwhg/living3.jpeg";

const rooms = [
  { id: 1, title: "Schlafzimmer", images: [bedroom, bedroom2, bedroom3] },
  { id: 2, title: "Wohnzimmer", images: [living, living2, living3] },
  { id: 3, title: "Küche", images: [kitchen, kitchen2, kitchen3] },
];

export default rooms;
