import { Predictions } from "aws-amplify";

type useIdentifyFloorplanType = {
  containsFloorplan: (file: File) => Promise<boolean>;
  containsFloorplanFromBytes: (bytes: string) => Promise<boolean>;
};

const FLOORPLAN_LABEL_MATCHES = ["Plot", "Diagram", "Plan", "Floor Plan", "Paper"];

export const useIdentifyFloorplan = (): useIdentifyFloorplanType => {
  const containsFloorplan = (file: File) => {
    return Predictions.identify({
      labels: {
        source: {
          file,
        },
        type: "LABELS",
      },
    })
      .then((response) => {
        return filterLabels(flattenResponseLabels(response), FLOORPLAN_LABEL_MATCHES) ? true : false;
      })
      .catch((err) => {
        throw new Error(`Error ${err}`);
      });
  };

  const containsFloorplanFromBytes = (bytes: string) => {
    return Predictions.identify({
      labels: {
        source: {
          bytes,
        },
        type: "LABELS",
      },
    })
      .then((response) => {
        return filterLabels(flattenResponseLabels(response), FLOORPLAN_LABEL_MATCHES) ? true : false;
      })
      .catch((err) => {
        throw new Error(`Error ${err}`);
      });
  };

  const filterLabels = (labels: string[], floorplanLabels: string[]) => {
    return labels.some((label) => floorplanLabels.includes(label));
  };

  const flattenResponseLabels = (response) => response.labels.flatMap((label) => label.name);

  return { containsFloorplan, containsFloorplanFromBytes };
};
