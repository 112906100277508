import { Typography } from "@material-ui/core";

const Contact = () => {
  return (
    <>
      <Typography variant="h3" align="center">
        Kontakt aufnehmen
      </Typography>
      <Typography variant="subtitle1" align="center" color="textPrimary" gutterBottom>
        Bei Fragen oder Anliegen freuen wir uns von Ihnen unter folgender E-Mail Adresse zu hören:
      </Typography>
      <Typography align="center">
        <a href="mailto:office@vloor.com">office@vloor.com</a>
      </Typography>
    </>
  );
};

export default Contact;
