import React, { useEffect, useMemo, useState } from "react";

import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import DetailRoomsTable from "../../components/floorplan-detail/DetailRoomsTable";
import IconLink from "../../components/layout/IconLink";
import { ContentHeader, Headline } from "../../components/layout/StyledComponents";
import { useGetFloorplan } from "../../hooks/floorplan/GetFloorplanHooks";

import { FloorplanOrder, Order, Level, FloorplanStatus } from "../../models";

import InfoPanel from "./InfoPanel";
import ProductImagePanel from "./ProductImagePanel";
import EditorPanel from "./EditorPanel";
import ShowcasePanel from "./ShowcasePanel";
import { Skeleton } from "@material-ui/lab";
import { useIsContractor, useIsEditor, useIsShowcase } from "../../providers/AuthContextProvider";
import ShowcaseImagePanel from "./ShowcaseImagePanel";
import ContractorPanel from "./ContractorPanel";
import ExportPanel from "./ExportPanel";
import Chat from "container/communication/Chat";
import ScalingInfoPanel from "../../components/floorplan-detail/ScalingInfoPanel";
import TechnicalInfoPanel from "components/floorplan-detail/TechnicalInfoPanel";
import { OrderStatus } from "models";
import OrderFulfillmentPanel from "./OrderFulfillmentPanel";

type Props = {
  id: string;
};

const FloorplanDetail = ({ id }: Props) => {
  const [paymentStatus, setPaymentStatus] = useState<string>("");
  const [order, setOrder] = useState<Order>();
  const [showExportPanel, setShowExportPanel] = useState(false);

  const { floorplan, error, isLoading, refetch } = useGetFloorplan(id);
  const isEditor = useIsEditor();
  const isShowcase = useIsShowcase();
  const isContractor = useIsContractor();

  useEffect(() => {
    if (!isLoading && !error && floorplan) {
      const orders: FloorplanOrder[] = floorplan?.orders;

      console.log("Orders: ", orders);
      if (orders?.length) {
        const fpOrder: FloorplanOrder = orders[0];
        setPaymentStatus(fpOrder.order.paymentStatus);
        setOrder(fpOrder.order);
      }
    }
  }, [error, floorplan, isLoading]);

  useEffect(() => {
    const show =
      floorplan?.shareable &&
      floorplan?.status === FloorplanStatus.CREATED &&
      (order?.orderStatus === OrderStatus.PROCESSED ||
        order?.orderStatus === OrderStatus.INVOICED ||
        order?.orderStatus === OrderStatus.FINISHED);
    setShowExportPanel(show);
  }, [floorplan, order]);

  // Memoize table for now due to performance-issues with re-render (e.g when input-fields change)
  // Table will not be editable so this should be fine for the future
  const detailMetadataMemoized = useMemo(
    () => floorplan?.rooms && <DetailRoomsTable rooms={floorplan.rooms} />,
    [floorplan]
  );

  return (
    <>
      <ContentHeader>
        <IconLink tooltip="Zurück zum Dashboard" to="/dashboard" icon={<ArrowBackIcon />} />
        <Headline variant="h4" component="h1">
          {isLoading ? <Skeleton /> : floorplan?.name}
        </Headline>
      </ContentHeader>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5} lg={4}>
          <Grid container direction="column" spacing={3}>
            <Grid item xs={12} md={12}>
              {isLoading || !order ? (
                <Skeleton height={400} />
              ) : (
                <InfoPanel floorplan={floorplan} orderLevel={order?.level} refetch={refetch} />
              )}
            </Grid>

            {showExportPanel && (
              <Grid item xs={12} md={12}>
                {isLoading ? <Skeleton height={200} /> : <ExportPanel floorplan={floorplan} order={order} />}
              </Grid>
            )}

            {isShowcase && (
              <Grid item xs={12} md={12}>
                {isLoading ? <Skeleton height={200} /> : <ShowcasePanel floorplan={floorplan} />}
              </Grid>
            )}

            {isContractor && (
              <Grid item xs={12} md={12}>
                {isLoading ? (
                  <Skeleton height={200} />
                ) : (
                  <ContractorPanel floorplan={floorplan} order={order} paymentStatus={paymentStatus} />
                )}
              </Grid>
            )}

            {isEditor && (
              <Grid item xs={12} md={12}>
                {isLoading || !order ? (
                  <Skeleton height={200} />
                ) : (
                  <OrderFulfillmentPanel floorplan={floorplan} order={order} />
                )}
              </Grid>
            )}

            {isEditor && (
              <Grid item xs={12} md={12}>
                {isLoading ? (
                  <Skeleton height={200} />
                ) : (
                  <EditorPanel floorplan={floorplan} order={order} paymentStatus={paymentStatus} />
                )}
              </Grid>
            )}

            {(isEditor || isContractor) && (
              <Grid item xs={12} md={12}>
                {isLoading ? <Skeleton height={200} /> : <ScalingInfoPanel floorplan={floorplan} />}
              </Grid>
            )}

            {isEditor && (
              <Grid item xs={12} md={12}>
                {isLoading ? <Skeleton height={200} /> : <TechnicalInfoPanel floorplan={floorplan} />}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={7} lg={8}>
          <Grid container direction="column" spacing={3}>
            <Grid item xs={12} md={12}>
              {isLoading || !order ? (
                <Skeleton height={400} />
              ) : (
                <ProductImagePanel
                  floorplan={floorplan}
                  orderStatus={order.orderStatus as OrderStatus}
                  orderLevel={order?.level as Level}
                />
              )}
            </Grid>
            {(isContractor || isShowcase) && (
              <Grid item xs={12} md={12}>
                {isLoading ? <Skeleton height={400} /> : <ShowcaseImagePanel floorplan={floorplan} />}
              </Grid>
            )}
            {(isContractor || isShowcase) && (
              <Grid item xs={12} md={12}>
                {isLoading ? <Skeleton height={200} /> : <div>{detailMetadataMemoized}</div>}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Chat floorplan={floorplan} />
    </>
  );
};

export default FloorplanDetail;
