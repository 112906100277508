/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sendOrderMail = /* GraphQL */ `
  query SendOrderMail($template: OrderMailTemplateType, $orderMailInput: String) {
    sendOrderMail(template: $template, orderMailInput: $orderMailInput)
  }
`;
export const digitizeFloorplan = /* GraphQL */ `
  query DigitizeFloorplan($input: RecognitionServiceInput) {
    digitizeFloorplan(input: $input)
  }
`;
export const getPreSignedUrls = /* GraphQL */ `
  query GetPreSignedUrls($input: GetPreSignedUrlsInput) {
    getPreSignedUrls(input: $input) {
      urls
    }
  }
`;
export const signUpNewsletter = /* GraphQL */ `
  query SignUpNewsletter($input: NewsletterSubscriptionInput) {
    signUpNewsletter(input: $input)
  }
`;
export const getFloorplanProject = /* GraphQL */ `
  query GetFloorplanProject($id: ID!) {
    getFloorplanProject(id: $id) {
      id
      name
      address
      description
      size
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listFloorplanProjects = /* GraphQL */ `
  query ListFloorplanProjects($filter: ModelFloorplanProjectFilterInput, $limit: Int, $nextToken: String) {
    listFloorplanProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        address
        description
        size
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFloorplan = /* GraphQL */ `
  query GetFloorplan($id: ID!) {
    getFloorplan(id: $id) {
      id
      type
      owner
      projectId
      createdAt
      updatedAt
      name
      address
      description
      originalImageName
      originalInputType
      shareable
      originalImage {
        bucket
        region
        key
        status
      }
      croppedImage {
        bucket
        region
        key
        status
      }
      preprocessedImage {
        bucket
        region
        key
        status
      }
      visual2DImage {
        bucket
        region
        key
        status
      }
      mlPredictionImage {
        bucket
        region
        key
        status
      }
      ocrPredictionImage {
        bucket
        region
        key
        status
      }
      postMlImage {
        bucket
        region
        key
        status
      }
      internalModelImage {
        bucket
        region
        key
        status
      }
      basicImage {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      basicPlusImage {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageTop {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageTilted {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageNE {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageNW {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageSE {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageSW {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      reactplannerModel {
        bucket
        region
        key
        status
      }
      floorplannerModel {
        bucket
        region
        key
        status
      }
      ifcModel {
        bucket
        region
        key
        status
      }
      reimportedIfcModel {
        bucket
        region
        key
        status
      }
      total_size
      amount_doors
      amount_rooms
      amount_windows
      total_room_size_m2
      total_room_size_px
      user_total_size
      user_scaling_selected_px
      user_scaling_cm
      api_scaling_factor_cm_per_px
      status
      customerId
      floorplannerId
      acceptedAt
      finishedAt
      rooms {
        items {
          id
          floorplanId
          name
          room_size_sqm
          room_type
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      orders {
        items {
          id
          floorplan_id
          order_id
          owner
          customer
          createdAt
          updatedAt
          floorplan {
            id
            type
            owner
            projectId
            createdAt
            updatedAt
            name
            address
            description
            originalImageName
            originalInputType
            shareable
            total_size
            amount_doors
            amount_rooms
            amount_windows
            total_room_size_m2
            total_room_size_px
            user_total_size
            user_scaling_selected_px
            user_scaling_cm
            api_scaling_factor_cm_per_px
            status
            customerId
            floorplannerId
            acceptedAt
            finishedAt
          }
          order {
            id
            user
            customer
            owner
            amount_total
            level
            stripe_checkout_session_id
            paymentStatus
            orderStatus
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      customer {
        id
        user
        identityId
        email
        stripe_customer_id
        phone_number
        company
        company_address
        company_address_line2
        company_city
        company_taxnumber
        country
        zip
        accepted_terms
        accepted_gdpr
        createdAt
        updatedAt
        floorplans {
          items {
            id
            type
            owner
            projectId
            createdAt
            updatedAt
            name
            address
            description
            originalImageName
            originalInputType
            shareable
            total_size
            amount_doors
            amount_rooms
            amount_windows
            total_room_size_m2
            total_room_size_px
            user_total_size
            user_scaling_selected_px
            user_scaling_cm
            api_scaling_factor_cm_per_px
            status
            customerId
            floorplannerId
            acceptedAt
            finishedAt
          }
          nextToken
        }
      }
      chatMessages {
        items {
          id
          floorplanId
          message
          sentByCustomer
          read
          createdAt
          owner
          images {
            bucket
            region
            key
            status
          }
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listFloorplans = /* GraphQL */ `
  query ListFloorplans($filter: ModelFloorplanFilterInput, $limit: Int, $nextToken: String) {
    listFloorplans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        owner
        projectId
        createdAt
        updatedAt
        name
        address
        description
        originalImageName
        originalInputType
        shareable
        originalImage {
          bucket
          region
          key
          status
        }
        croppedImage {
          bucket
          region
          key
          status
        }
        preprocessedImage {
          bucket
          region
          key
          status
        }
        visual2DImage {
          bucket
          region
          key
          status
        }
        mlPredictionImage {
          bucket
          region
          key
          status
        }
        ocrPredictionImage {
          bucket
          region
          key
          status
        }
        postMlImage {
          bucket
          region
          key
          status
        }
        internalModelImage {
          bucket
          region
          key
          status
        }
        basicImage {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        basicPlusImage {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageTop {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageTilted {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageNE {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageNW {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageSE {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageSW {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        reactplannerModel {
          bucket
          region
          key
          status
        }
        floorplannerModel {
          bucket
          region
          key
          status
        }
        ifcModel {
          bucket
          region
          key
          status
        }
        reimportedIfcModel {
          bucket
          region
          key
          status
        }
        total_size
        amount_doors
        amount_rooms
        amount_windows
        total_room_size_m2
        total_room_size_px
        user_total_size
        user_scaling_selected_px
        user_scaling_cm
        api_scaling_factor_cm_per_px
        status
        customerId
        floorplannerId
        acceptedAt
        finishedAt
        rooms {
          items {
            id
            floorplanId
            name
            room_size_sqm
            room_type
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        orders {
          items {
            id
            floorplan_id
            order_id
            owner
            customer
            createdAt
            updatedAt
          }
          nextToken
        }
        customer {
          id
          user
          identityId
          email
          stripe_customer_id
          phone_number
          company
          company_address
          company_address_line2
          company_city
          company_taxnumber
          country
          zip
          accepted_terms
          accepted_gdpr
          createdAt
          updatedAt
          floorplans {
            nextToken
          }
        }
        chatMessages {
          items {
            id
            floorplanId
            message
            sentByCustomer
            read
            createdAt
            owner
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const floorplansByDate = /* GraphQL */ `
  query FloorplansByDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFloorplanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    floorplansByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        owner
        projectId
        createdAt
        updatedAt
        name
        address
        description
        originalImageName
        originalInputType
        shareable
        originalImage {
          bucket
          region
          key
          status
        }
        croppedImage {
          bucket
          region
          key
          status
        }
        preprocessedImage {
          bucket
          region
          key
          status
        }
        visual2DImage {
          bucket
          region
          key
          status
        }
        mlPredictionImage {
          bucket
          region
          key
          status
        }
        ocrPredictionImage {
          bucket
          region
          key
          status
        }
        postMlImage {
          bucket
          region
          key
          status
        }
        internalModelImage {
          bucket
          region
          key
          status
        }
        basicImage {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        basicPlusImage {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageTop {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageTilted {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageNE {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageNW {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageSE {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageSW {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        reactplannerModel {
          bucket
          region
          key
          status
        }
        floorplannerModel {
          bucket
          region
          key
          status
        }
        ifcModel {
          bucket
          region
          key
          status
        }
        reimportedIfcModel {
          bucket
          region
          key
          status
        }
        total_size
        amount_doors
        amount_rooms
        amount_windows
        total_room_size_m2
        total_room_size_px
        user_total_size
        user_scaling_selected_px
        user_scaling_cm
        api_scaling_factor_cm_per_px
        status
        customerId
        floorplannerId
        acceptedAt
        finishedAt
        rooms {
          items {
            id
            floorplanId
            name
            room_size_sqm
            room_type
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        orders {
          items {
            id
            floorplan_id
            order_id
            owner
            customer
            createdAt
            updatedAt
          }
          nextToken
        }
        customer {
          id
          user
          identityId
          email
          stripe_customer_id
          phone_number
          company
          company_address
          company_address_line2
          company_city
          company_taxnumber
          country
          zip
          accepted_terms
          accepted_gdpr
          createdAt
          updatedAt
          floorplans {
            nextToken
          }
        }
        chatMessages {
          items {
            id
            floorplanId
            message
            sentByCustomer
            read
            createdAt
            owner
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const floorplansByOwner = /* GraphQL */ `
  query FloorplansByOwner(
    $owner: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFloorplanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    floorplansByOwner(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        owner
        projectId
        createdAt
        updatedAt
        name
        address
        description
        originalImageName
        originalInputType
        shareable
        originalImage {
          bucket
          region
          key
          status
        }
        croppedImage {
          bucket
          region
          key
          status
        }
        preprocessedImage {
          bucket
          region
          key
          status
        }
        visual2DImage {
          bucket
          region
          key
          status
        }
        mlPredictionImage {
          bucket
          region
          key
          status
        }
        ocrPredictionImage {
          bucket
          region
          key
          status
        }
        postMlImage {
          bucket
          region
          key
          status
        }
        internalModelImage {
          bucket
          region
          key
          status
        }
        basicImage {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        basicPlusImage {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageTop {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageTilted {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageNE {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageNW {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageSE {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageSW {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        reactplannerModel {
          bucket
          region
          key
          status
        }
        floorplannerModel {
          bucket
          region
          key
          status
        }
        ifcModel {
          bucket
          region
          key
          status
        }
        reimportedIfcModel {
          bucket
          region
          key
          status
        }
        total_size
        amount_doors
        amount_rooms
        amount_windows
        total_room_size_m2
        total_room_size_px
        user_total_size
        user_scaling_selected_px
        user_scaling_cm
        api_scaling_factor_cm_per_px
        status
        customerId
        floorplannerId
        acceptedAt
        finishedAt
        rooms {
          items {
            id
            floorplanId
            name
            room_size_sqm
            room_type
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        orders {
          items {
            id
            floorplan_id
            order_id
            owner
            customer
            createdAt
            updatedAt
          }
          nextToken
        }
        customer {
          id
          user
          identityId
          email
          stripe_customer_id
          phone_number
          company
          company_address
          company_address_line2
          company_city
          company_taxnumber
          country
          zip
          accepted_terms
          accepted_gdpr
          createdAt
          updatedAt
          floorplans {
            nextToken
          }
        }
        chatMessages {
          items {
            id
            floorplanId
            message
            sentByCustomer
            read
            createdAt
            owner
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const floorplansByFloorplannerId = /* GraphQL */ `
  query FloorplansByFloorplannerId(
    $floorplannerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFloorplanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    floorplansByFloorplannerId(
      floorplannerId: $floorplannerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        owner
        projectId
        createdAt
        updatedAt
        name
        address
        description
        originalImageName
        originalInputType
        shareable
        originalImage {
          bucket
          region
          key
          status
        }
        croppedImage {
          bucket
          region
          key
          status
        }
        preprocessedImage {
          bucket
          region
          key
          status
        }
        visual2DImage {
          bucket
          region
          key
          status
        }
        mlPredictionImage {
          bucket
          region
          key
          status
        }
        ocrPredictionImage {
          bucket
          region
          key
          status
        }
        postMlImage {
          bucket
          region
          key
          status
        }
        internalModelImage {
          bucket
          region
          key
          status
        }
        basicImage {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        basicPlusImage {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageTop {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageTilted {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageNE {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageNW {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageSE {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageSW {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        reactplannerModel {
          bucket
          region
          key
          status
        }
        floorplannerModel {
          bucket
          region
          key
          status
        }
        ifcModel {
          bucket
          region
          key
          status
        }
        reimportedIfcModel {
          bucket
          region
          key
          status
        }
        total_size
        amount_doors
        amount_rooms
        amount_windows
        total_room_size_m2
        total_room_size_px
        user_total_size
        user_scaling_selected_px
        user_scaling_cm
        api_scaling_factor_cm_per_px
        status
        customerId
        floorplannerId
        acceptedAt
        finishedAt
        rooms {
          items {
            id
            floorplanId
            name
            room_size_sqm
            room_type
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        orders {
          items {
            id
            floorplan_id
            order_id
            owner
            customer
            createdAt
            updatedAt
          }
          nextToken
        }
        customer {
          id
          user
          identityId
          email
          stripe_customer_id
          phone_number
          company
          company_address
          company_address_line2
          company_city
          company_taxnumber
          country
          zip
          accepted_terms
          accepted_gdpr
          createdAt
          updatedAt
          floorplans {
            nextToken
          }
        }
        chatMessages {
          items {
            id
            floorplanId
            message
            sentByCustomer
            read
            createdAt
            owner
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRoom = /* GraphQL */ `
  query GetRoom($id: ID!) {
    getRoom(id: $id) {
      id
      floorplanId
      name
      room_size_sqm
      room_type
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listRooms = /* GraphQL */ `
  query ListRooms($filter: ModelRoomFilterInput, $limit: Int, $nextToken: String) {
    listRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        floorplanId
        name
        room_size_sqm
        room_type
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFloorplanOrder = /* GraphQL */ `
  query GetFloorplanOrder($id: ID!) {
    getFloorplanOrder(id: $id) {
      id
      floorplan_id
      order_id
      owner
      customer
      createdAt
      updatedAt
      floorplan {
        id
        type
        owner
        projectId
        createdAt
        updatedAt
        name
        address
        description
        originalImageName
        originalInputType
        shareable
        originalImage {
          bucket
          region
          key
          status
        }
        croppedImage {
          bucket
          region
          key
          status
        }
        preprocessedImage {
          bucket
          region
          key
          status
        }
        visual2DImage {
          bucket
          region
          key
          status
        }
        mlPredictionImage {
          bucket
          region
          key
          status
        }
        ocrPredictionImage {
          bucket
          region
          key
          status
        }
        postMlImage {
          bucket
          region
          key
          status
        }
        internalModelImage {
          bucket
          region
          key
          status
        }
        basicImage {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        basicPlusImage {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageTop {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageTilted {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageNE {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageNW {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageSE {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageSW {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        reactplannerModel {
          bucket
          region
          key
          status
        }
        floorplannerModel {
          bucket
          region
          key
          status
        }
        ifcModel {
          bucket
          region
          key
          status
        }
        reimportedIfcModel {
          bucket
          region
          key
          status
        }
        total_size
        amount_doors
        amount_rooms
        amount_windows
        total_room_size_m2
        total_room_size_px
        user_total_size
        user_scaling_selected_px
        user_scaling_cm
        api_scaling_factor_cm_per_px
        status
        customerId
        floorplannerId
        acceptedAt
        finishedAt
        rooms {
          items {
            id
            floorplanId
            name
            room_size_sqm
            room_type
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        orders {
          items {
            id
            floorplan_id
            order_id
            owner
            customer
            createdAt
            updatedAt
          }
          nextToken
        }
        customer {
          id
          user
          identityId
          email
          stripe_customer_id
          phone_number
          company
          company_address
          company_address_line2
          company_city
          company_taxnumber
          country
          zip
          accepted_terms
          accepted_gdpr
          createdAt
          updatedAt
          floorplans {
            nextToken
          }
        }
        chatMessages {
          items {
            id
            floorplanId
            message
            sentByCustomer
            read
            createdAt
            owner
            updatedAt
          }
          nextToken
        }
      }
      order {
        id
        user
        customer
        owner
        amount_total
        level
        stripe_checkout_session_id
        paymentStatus
        orderStatus
        createdAt
        updatedAt
        floorplans {
          items {
            id
            floorplan_id
            order_id
            owner
            customer
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listFloorplanOrders = /* GraphQL */ `
  query ListFloorplanOrders($filter: ModelFloorplanOrderFilterInput, $limit: Int, $nextToken: String) {
    listFloorplanOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        floorplan_id
        order_id
        owner
        customer
        createdAt
        updatedAt
        floorplan {
          id
          type
          owner
          projectId
          createdAt
          updatedAt
          name
          address
          description
          originalImageName
          originalInputType
          shareable
          originalImage {
            bucket
            region
            key
            status
          }
          croppedImage {
            bucket
            region
            key
            status
          }
          preprocessedImage {
            bucket
            region
            key
            status
          }
          visual2DImage {
            bucket
            region
            key
            status
          }
          mlPredictionImage {
            bucket
            region
            key
            status
          }
          ocrPredictionImage {
            bucket
            region
            key
            status
          }
          postMlImage {
            bucket
            region
            key
            status
          }
          internalModelImage {
            bucket
            region
            key
            status
          }
          reactplannerModel {
            bucket
            region
            key
            status
          }
          floorplannerModel {
            bucket
            region
            key
            status
          }
          ifcModel {
            bucket
            region
            key
            status
          }
          reimportedIfcModel {
            bucket
            region
            key
            status
          }
          total_size
          amount_doors
          amount_rooms
          amount_windows
          total_room_size_m2
          total_room_size_px
          user_total_size
          user_scaling_selected_px
          user_scaling_cm
          api_scaling_factor_cm_per_px
          status
          customerId
          floorplannerId
          acceptedAt
          finishedAt
          rooms {
            nextToken
          }
          orders {
            nextToken
          }
          customer {
            id
            user
            identityId
            email
            stripe_customer_id
            phone_number
            company
            company_address
            company_address_line2
            company_city
            company_taxnumber
            country
            zip
            accepted_terms
            accepted_gdpr
            createdAt
            updatedAt
          }
          chatMessages {
            nextToken
          }
        }
        order {
          id
          user
          customer
          owner
          amount_total
          level
          stripe_checkout_session_id
          paymentStatus
          orderStatus
          createdAt
          updatedAt
          floorplans {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders($filter: ModelOrderFilterInput, $limit: Int, $nextToken: String) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        customer
        owner
        amount_total
        level
        stripe_checkout_session_id
        paymentStatus
        orderStatus
        createdAt
        updatedAt
        floorplans {
          items {
            id
            floorplan_id
            order_id
            owner
            customer
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      user
      customer
      owner
      amount_total
      level
      stripe_checkout_session_id
      paymentStatus
      orderStatus
      createdAt
      updatedAt
      floorplans {
        items {
          id
          floorplan_id
          order_id
          owner
          customer
          createdAt
          updatedAt
          floorplan {
            id
            type
            owner
            projectId
            createdAt
            updatedAt
            name
            address
            description
            originalImageName
            originalInputType
            shareable
            total_size
            amount_doors
            amount_rooms
            amount_windows
            total_room_size_m2
            total_room_size_px
            user_total_size
            user_scaling_selected_px
            user_scaling_cm
            api_scaling_factor_cm_per_px
            status
            customerId
            floorplannerId
            acceptedAt
            finishedAt
          }
          order {
            id
            user
            customer
            owner
            amount_total
            level
            stripe_checkout_session_id
            paymentStatus
            orderStatus
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const getOrderByCheckoutSession = /* GraphQL */ `
  query GetOrderByCheckoutSession(
    $stripe_checkout_session_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderByCheckoutSession(
      stripe_checkout_session_id: $stripe_checkout_session_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        customer
        owner
        amount_total
        level
        stripe_checkout_session_id
        paymentStatus
        orderStatus
        createdAt
        updatedAt
        floorplans {
          items {
            id
            floorplan_id
            order_id
            owner
            customer
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers($filter: ModelCustomerFilterInput, $limit: Int, $nextToken: String) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        identityId
        email
        stripe_customer_id
        phone_number
        company
        company_address
        company_address_line2
        company_city
        company_taxnumber
        country
        zip
        accepted_terms
        accepted_gdpr
        createdAt
        updatedAt
        floorplans {
          items {
            id
            type
            owner
            projectId
            createdAt
            updatedAt
            name
            address
            description
            originalImageName
            originalInputType
            shareable
            total_size
            amount_doors
            amount_rooms
            amount_windows
            total_room_size_m2
            total_room_size_px
            user_total_size
            user_scaling_selected_px
            user_scaling_cm
            api_scaling_factor_cm_per_px
            status
            customerId
            floorplannerId
            acceptedAt
            finishedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      user
      identityId
      email
      stripe_customer_id
      phone_number
      company
      company_address
      company_address_line2
      company_city
      company_taxnumber
      country
      zip
      accepted_terms
      accepted_gdpr
      createdAt
      updatedAt
      floorplans {
        items {
          id
          type
          owner
          projectId
          createdAt
          updatedAt
          name
          address
          description
          originalImageName
          originalInputType
          shareable
          originalImage {
            bucket
            region
            key
            status
          }
          croppedImage {
            bucket
            region
            key
            status
          }
          preprocessedImage {
            bucket
            region
            key
            status
          }
          visual2DImage {
            bucket
            region
            key
            status
          }
          mlPredictionImage {
            bucket
            region
            key
            status
          }
          ocrPredictionImage {
            bucket
            region
            key
            status
          }
          postMlImage {
            bucket
            region
            key
            status
          }
          internalModelImage {
            bucket
            region
            key
            status
          }
          reactplannerModel {
            bucket
            region
            key
            status
          }
          floorplannerModel {
            bucket
            region
            key
            status
          }
          ifcModel {
            bucket
            region
            key
            status
          }
          reimportedIfcModel {
            bucket
            region
            key
            status
          }
          total_size
          amount_doors
          amount_rooms
          amount_windows
          total_room_size_m2
          total_room_size_px
          user_total_size
          user_scaling_selected_px
          user_scaling_cm
          api_scaling_factor_cm_per_px
          status
          customerId
          floorplannerId
          acceptedAt
          finishedAt
          rooms {
            nextToken
          }
          orders {
            nextToken
          }
          customer {
            id
            user
            identityId
            email
            stripe_customer_id
            phone_number
            company
            company_address
            company_address_line2
            company_city
            company_taxnumber
            country
            zip
            accepted_terms
            accepted_gdpr
            createdAt
            updatedAt
          }
          chatMessages {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const getCustomerByUsername = /* GraphQL */ `
  query GetCustomerByUsername(
    $user: String
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCustomerByUsername(
      user: $user
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        identityId
        email
        stripe_customer_id
        phone_number
        company
        company_address
        company_address_line2
        company_city
        company_taxnumber
        country
        zip
        accepted_terms
        accepted_gdpr
        createdAt
        updatedAt
        floorplans {
          items {
            id
            type
            owner
            projectId
            createdAt
            updatedAt
            name
            address
            description
            originalImageName
            originalInputType
            shareable
            total_size
            amount_doors
            amount_rooms
            amount_windows
            total_room_size_m2
            total_room_size_px
            user_total_size
            user_scaling_selected_px
            user_scaling_cm
            api_scaling_factor_cm_per_px
            status
            customerId
            floorplannerId
            acceptedAt
            finishedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCustomerByEmail = /* GraphQL */ `
  query GetCustomerByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCustomerByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        identityId
        email
        stripe_customer_id
        phone_number
        company
        company_address
        company_address_line2
        company_city
        company_taxnumber
        country
        zip
        accepted_terms
        accepted_gdpr
        createdAt
        updatedAt
        floorplans {
          items {
            id
            type
            owner
            projectId
            createdAt
            updatedAt
            name
            address
            description
            originalImageName
            originalInputType
            shareable
            total_size
            amount_doors
            amount_rooms
            amount_windows
            total_room_size_m2
            total_room_size_px
            user_total_size
            user_scaling_selected_px
            user_scaling_cm
            api_scaling_factor_cm_per_px
            status
            customerId
            floorplannerId
            acceptedAt
            finishedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSettings = /* GraphQL */ `
  query GetSettings($id: ID!) {
    getSettings(id: $id) {
      id
      theme
      displayName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSettingss = /* GraphQL */ `
  query ListSettingss($filter: ModelSettingsFilterInput, $limit: Int, $nextToken: String) {
    listSettingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        theme
        displayName
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getChatMessage = /* GraphQL */ `
  query GetChatMessage($id: ID!) {
    getChatMessage(id: $id) {
      id
      floorplanId
      message
      sentByCustomer
      read
      createdAt
      owner
      images {
        bucket
        region
        key
        status
      }
      updatedAt
    }
  }
`;
export const listChatMessages = /* GraphQL */ `
  query ListChatMessages($filter: ModelChatMessageFilterInput, $limit: Int, $nextToken: String) {
    listChatMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        floorplanId
        message
        sentByCustomer
        read
        createdAt
        owner
        images {
          bucket
          region
          key
          status
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const chatMessagesByFloorplanId = /* GraphQL */ `
  query ChatMessagesByFloorplanId(
    $floorplanId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatMessagesByFloorplanId(
      floorplanId: $floorplanId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        floorplanId
        message
        sentByCustomer
        read
        createdAt
        owner
        images {
          bucket
          region
          key
          status
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      date
      stripePaymentIntent
      paymentStatus
      amount
      user
      createdAt
      updatedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments($filter: ModelPaymentFilterInput, $limit: Int, $nextToken: String) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        stripePaymentIntent
        paymentStatus
        amount
        user
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const preprocessImageLambda = /* GraphQL */ `
  query PreprocessImageLambda($image: String) {
    preprocessImageLambda(image: $image)
  }
`;
export const callRecognitionService = /* GraphQL */ `
  query CallRecognitionService($input: RecognitionServiceInput) {
    callRecognitionService(input: $input)
  }
`;
