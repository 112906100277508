import { Paper } from "@material-ui/core";
import IframeResizer from "iframe-resizer-react";

type IFrameProps = {
  link: string;
};

const IFrame: React.FC<IFrameProps> = ({ link }) => {
  const style = {
    width: "1px",
    minWidth: "100%",
    height: "90vh",
    borderRadius: "14px",
  };

  return (
    <>
      {link && (
        <Paper>
          <IframeResizer log src={link} style={style} heightCalculationMethod="lowestElement" frameBorder="0" />
        </Paper>
      )}
    </>
  );
};

export default IFrame;
