import React from "react";
import { API } from "aws-amplify";
import { Customer, Floorplan, Order, Level } from "../models";
import * as constants from "../constants";

import { useHistory } from "react-router-dom";

import { useStripe } from "@stripe/react-stripe-js";
import { useToastProvider } from "./ToastProvider";

import { useCreateOrder } from "hooks/checkout/useCreateOrder";
import { sendOrderConfirmation } from "../hooks/mails/MailServiceHooks";

// Config Checkout
const apiConfig: ApiConfig = {
  apiName: constants.STRIPE_API_NAME,
  apiEndpoint: constants.API_CHECKOUT_ENDPOINT,
};

const checkoutConfig: StripeCheckoutPageConfig = {
  success_url: constants.CHECKOUT_SUCCESS_URL,
  cancel_url: constants.CHECKOUT_CANCEL_URL,
  billing_address_collection: "required",
};

type CheckoutType = {
  checkout: (arg0: Floorplan, arg1: string, arg2?: boolean) => void;
  reducer?: [any, React.Dispatch<any>];
};

const CheckoutContext = React.createContext<CheckoutType | undefined>(undefined);

const CheckoutProvider = ({ children }) => {
  const { showToast } = useToastProvider();
  const { createOrder } = useCreateOrder();
  const stripe = useStripe();
  const history = useHistory();

  const checkStripe = () => {
    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      showToast({
        severity: "error",
        content: "Stripe was not loaded",
      });
      return;
    }
  };

  const checkout = async (floorplan: Floorplan, price_id: Level, reusePaymentMethod: boolean) => {
    checkStripe();

    // create a new Order
    const newOrder = await createOrder(floorplan, price_id);

    // create Stripe checkout session
    const body = createStripeConfigBody(newOrder, floorplan.customer, floorplan.id, price_id, reusePaymentMethod);
    const response = await API.post(apiConfig.apiName, apiConfig.apiEndpoint, {
      body,
    }).catch((err) => {
      console.log("Error when trying to call API:", err);
    });

    if (response) {
      console.log("stripeCheckout response: ", response);

      // send Confirmation mail
      await sendOrderConfirmation(floorplan.customer, newOrder, floorplan);
      // send new Order mail to Contractors
      // await sendNewOrder(floorplan.customer, newOrder, floorplan);

      // redirect to stripe checkout page
      console.log("Stripe API response", response);
      const session = await response?.session;
      if (!reusePaymentMethod) {
        // Redirect to Stripe deactivated for now
        // stripe
        //   .redirectToCheckout({
        //     sessionId: session?.id,
        //   })
        //   .then((result) => console.log(result.error.message));

        history.push("/dashboard/success?session_id=" + session?.id);
      } else {
        history.push("/dashboard");
      }
    } else {
      showToast({
        severity: "error",
        content: "Stripe Checkout could not be reached. Please try again.",
      });
    }
  };

  return <CheckoutContext.Provider value={{ checkout }}>{children}</CheckoutContext.Provider>;
};
export { CheckoutContext, CheckoutProvider };

const createStripeConfigBody = (
  order: Order,
  customer: Customer,
  floorplan_id: string,
  price_id: string,
  reusePaymentMethod: boolean
) => {
  const body = {
    order_id: order.id,
    price_id,
    success_url: checkoutConfig?.success_url,
    cancel_url: checkoutConfig?.cancel_url,
    billing_address_collection: checkoutConfig?.billing_address_collection,

    user: customer?.user,
    customer: customer?.stripe_customer_id,
    customer_email: customer?.email,

    floorplan_id,
    reusePaymentMethod,
  };
  return body;
};

type ApiConfig = {
  apiName: string;
  apiEndpoint: string;
};

type StripeCheckoutPageConfig = {
  billing_address_collection: string;
  success_url: string; // Where to redirect if success
  cancel_url: string; // Where to go if payment canceled
};
