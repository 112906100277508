import { Route } from "react-router-dom";

import IconLink from "components/layout/IconLink";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { ContentHeader, Headline } from "components/layout/StyledComponents";

import { Modes } from "../container/view/FloorplanViewer";
import FloorplanSharer from "container/share/FloorplanSharer";

export const ShareRoute = ({ match }) => {
  return (
    <>
      <Route
        exact
        path={`${match.path}/:floorplanner_id/viewer`}
        render={({ match }) => <FloorplanSharer floorplannerId={match.params.floorplanner_id} mode={Modes.Viewer} />}
      />
      <Route
        exact
        path={`${match.path}/:floorplanner_id/editor`}
        render={({ match }) => <FloorplanSharer floorplannerId={match.params.floorplanner_id} mode={Modes.Editor} />}
      />
      <Route
        exact
        path={match.url}
        render={() => (
          <ContentHeader>
            <IconLink tooltip="Zurück zum Dashboard" to="/dashboard" icon={<ArrowBackIcon />} />
            <Headline variant="h4" component="h1">
              Please select a floorplan.
            </Headline>
          </ContentHeader>
        )}
      />
    </>
  );
};
