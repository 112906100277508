import React, { useState } from "react";
import { Auth } from "aws-amplify";
import Storage from "@aws-amplify/storage";

import { useMutation } from "react-query";
import { UpdateFloorplanInput, UpdateFloorplanMutation } from "../../API";
import { gqlOperation } from "../GraphQLHooks";
import * as mutations from "../../graphql/mutations";

import * as KeyUtils from "../../utils/KeyUtils";

type Props = {
  imageKey: string;
  successCallback: () => void;
};
type useReplaceImageType = {
  replaceImage: () => Promise<void>;
  setFile: React.Dispatch<React.SetStateAction<File>>;
  uploadProgress: number;
};

export const useUpdateFloorplan = () => {
  const { mutateAsync: updateFloorplan } = useMutation((content: UpdateFloorplanInput) =>
    gqlOperation<UpdateFloorplanMutation>(mutations.updateFloorplan, {
      input: content,
    })
  );

  return { updateFloorplan };
};

export const useReplaceImage = ({ imageKey, successCallback }: Props): useReplaceImageType => {
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [file, setFile] = useState<File>(null);

  const replaceImage = async () => {
    const user = await Auth.currentAuthenticatedUser();
    let prog: number = 0;

    Storage.vault
      .put(KeyUtils.getPathFromKey(imageKey), file, {
        metadata: {
          owner: user.username,
        },
        progressCallback(progress) {
          prog = (progress.loaded / progress.total) * 100;
          console.log(prog + "%");
          setUploadProgress(prog);
        },
      })
      .then((result) => {
        successCallback();
        console.log("Success!");
      })
      .catch((err) => console.error(err));
  };

  return { replaceImage, setFile, uploadProgress };
};
