import React, { createContext, useContext, useReducer } from "react";
import { reducer, initialState, AIPreviewState } from "./AIPreviewReducer";

type AIPreviewContextType = {
  state: AIPreviewState;
  dispatch: React.Dispatch<any>;
};

const AIPreviewStateContext = createContext<AIPreviewContextType>(undefined);

const AIPreviewStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <AIPreviewStateContext.Provider value={{ state, dispatch }}>{children}</AIPreviewStateContext.Provider>;
};

export default AIPreviewStateProvider;

export const useAIPreviewState = () => {
  const context = useContext<AIPreviewContextType>(AIPreviewStateContext);
  if (context === undefined) {
    throw new Error("`useAIPreviewState` hook must be used within a `AIPreviewStateProvider` component");
  }
  return context;
};
