import React, { useState } from "react";
import { Route } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Box, Typography } from "@material-ui/core";

import useBillingPortal from "hooks/customer/BillingPageHook";
import { useToastProvider } from "providers/ToastProvider";

const useStyles = makeStyles((theme) => ({
  subtext: {
    margin: theme.spacing(1, 0, 1),
  },
  textButton: {
    textTransform: "none",
  },
}));

type BillingPortalButtonProps = {
  buttonText?: String;
};

const BillingPortalButton = ({ buttonText }: BillingPortalButtonProps) => {
  const classes = useStyles();
  const { showToast } = useToastProvider();
  const [loading, setLoading] = useState(false);
  const [billingPortalUrl, setBillingPortalUrl] = useState<string>("");
  const { getBillingPortalUrl } = useBillingPortal();

  const handleClick = async () => {
    setLoading(true);
    try {
      const url: string = await getBillingPortalUrl();

      // redirect to billing portal
      if (url) {
        window.location.href = url;
        setBillingPortalUrl(url);
      }
    } catch (error) {
      console.log(error);
      showToast({
        severity: "error",
        content: "Stripe konnte nicht erreicht werden, bitte versuchen Sie es später noch einmal.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {billingPortalUrl ? (
        <Route
          path="/billing"
          component={() => {
            window.location.href = billingPortalUrl;
            return null;
          }}
        />
      ) : (
        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="start">
          <Typography variant={"body1"} className={classes.subtext}>
            <Button
              className={classes.textButton}
              onClick={handleClick}
              disabled={loading}
              variant="text"
              color="primary"
            >
              {buttonText || "Manage Subscription"}
            </Button>
          </Typography>
          <Box marginRight={2} />
          {loading ? <CircularProgress color="secondary" /> : null}
        </Box>
      )}
    </>
  );
};

export default BillingPortalButton;
