import { Route } from "react-router-dom";
import AIPreview from "container/ai-preview/AIPreview";
import AIPreviewStateProvider from "container/ai-preview/context/AIPreviewContext";

export const AIPreviewRoute = ({ match }) => {
  return (
    <>
      <AIPreviewStateProvider>
        <Route path={match.url} render={() => <AIPreview />} />
      </AIPreviewStateProvider>
    </>
  );
};
