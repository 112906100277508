import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { UpdateFloorplanInput } from "../../API";

import { useToastProvider } from "../../providers/ToastProvider";
import { DialogContentBefore } from "../layout/StyledComponents";

import { Floorplan } from "models";
import CanvasScaling from "components/scaling/CanvasScaling";
import useCreatePresignedUrl from "hooks/exports/useCreatePresignedUrl";
import { useUpdateFloorplan } from "hooks/floorplan/UpdateFloorplanHooks";
import { useDigitizeFloorplan } from "../../hooks/floorplan/useDigitizeFloorplan";

type Props = {
  floorplan: Floorplan;
};

const DialogRescaleFloorplan: React.FC<Props> = ({ floorplan }) => {
  const { showSuccessToast, showErrorToast } = useToastProvider();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { createPresignedURLs } = useCreatePresignedUrl();
  const [image, setImage] = useState();
  const [scalingPx, setScalingPx] = useState(floorplan.user_scaling_selected_px);
  const [scalingCm, setScalingCm] = useState(floorplan.user_scaling_cm);
  const { updateFloorplan } = useUpdateFloorplan();
  const { digitizeFloorplan } = useDigitizeFloorplan();

  const handleClose = () => {
    setIsOpen(false);
  };

  const onScale = (value) => {
    setScalingPx(value);
  };

  useEffect(() => {
    setScalingPx(0);
    setScalingCm(0);
  }, [isOpen]);

  const onRescale = async () => {
    const updatedFloorplan: UpdateFloorplanInput = {
      id: floorplan.id,
      user_scaling_cm: scalingCm,
      user_scaling_selected_px: scalingPx,
    };
    try {
      await updateFloorplan(updatedFloorplan);
      showSuccessToast("Successfully updated Floorplan");
      await digitize();
      handleClose();
    } catch (error) {
      showErrorToast("Failed to update Floorplan");
    }
  };

  const digitize = async () => {
    try {
      setIsLoading(true);
      console.log("Floorplan: ", floorplan);
      showSuccessToast("The floor plan will be digitized with the new scaling factor. This can take a few minutes.");
      await digitizeFloorplan(floorplan);
      setIsLoading(false);
    } catch (err) {
      showErrorToast("Failed to digitize floor plan with new scaling factor");
    }
  };

  useEffect(() => {
    const setImageCanvas = async () => {
      const url = await createPresignedURLs([floorplan.croppedImage.key], "GET");
      setImage(url);
    };
    setImageCanvas();
  }, [floorplan, createPresignedURLs]);

  return (
    <>
      <Dialog
        fullScreen
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        maxWidth={"md"}
        fullWidth
      >
        <DialogTitle id="customized-dialog-title">Rescale Floorplan</DialogTitle>
        <DialogContent dividers>
          <DialogContentBefore>
            <CanvasScaling image={image} onScale={onScale} />
          </DialogContentBefore>
          <Grid container justify="center">
            <TextField
              required
              id="user_scaling_cm"
              label="Known length (cm)"
              name="user_scaling_cm"
              variant="outlined"
              inputProps={{ type: "number" }}
              value={scalingCm}
              onChange={(e) => setScalingCm(e.target.value as any)}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          {isLoading && <CircularProgress color="primary" />}
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={onRescale} variant="contained" color="primary">
            Rescale
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container justify="center">
        <Button
          onClick={() => {
            setIsOpen(true);
          }}
          color="primary"
          variant="contained"
        >
          Rescale Floorplan
        </Button>
      </Grid>
    </>
  );
};

export default DialogRescaleFloorplan;
