import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import { Typography, Button, makeStyles } from "@material-ui/core";
import Storage from "@aws-amplify/storage";
import Skeleton from "@material-ui/lab/Skeleton";
import { useHistory } from "react-router-dom";
import * as KeyUtils from "../../utils/KeyUtils";
import { S3Object } from "../../models";
import { useIsEditor } from "../../providers/AuthContextProvider";

type Props = {
  content: {
    originalImage: S3Object;
    id: string;
    name: string;
    description: string;
    croppedImage: S3Object;
  };
};

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 3,
  },
  cardActions: {
    flexGrow: 1,
    alignItems: "flex-end",
  },
}));

const FloorplanCard = ({ content }: Props) => {
  const classes = useStyles();
  const [image, setImage] = useState("");
  const history = useHistory();
  const isEditor = useIsEditor();

  const showDetail = (id: string) => {
    return history.push(`/detail/${id}`);
  };

  useEffect(() => {
    Storage.vault
      .get(KeyUtils.getPathFromKey(content.croppedImage.key))
      .then((result) => setImage(String(result)))
      .catch((err) => console.log(err));
  }, [content]);

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card className={classes.card} elevation={3}>
        <CardActionArea onClick={() => showDetail(content.id)}>
          {image ? (
            <CardMedia className={classes.cardMedia} image={image} title="Image title" />
          ) : (
            <Skeleton animation="wave" variant="rect" className={classes.cardMedia} />
          )}

          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h5" component="h2">
              {content.name}
            </Typography>
            <Typography>{content.description}</Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.cardActions}>
          <Button variant="contained" size="small" color="primary" onClick={() => showDetail(content.id)}>
            Details
          </Button>
          {isEditor && (
            <Link to={`editor/${content.id}`}>
              <Button size="small" color="secondary">
                Editor
              </Button>
            </Link>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
};

export default FloorplanCard;
