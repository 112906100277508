import { useContext, useEffect } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { FlrPaper } from "components/layout/StyledComponents";
import { useHistory } from "react-router-dom";

import rooms from "./mockData/rooms";
import { useQueryParams } from "utils/UrlUtils";
import { FurnitureActionTypes, FurnitureConfigContext } from "providers/FurnitureConfigContextProvider";
import BackLink from "components/links/BackLink";
import RoomImage from "./RoomImage";
//import VurnishedLogo from "../../assets/vurnished/vurnished_logo.png";

import styled from "styled-components";

const RoomCube = styled.img`
  margin: 5px;
  border-radius: 14px;
  width: 100%;
  border: 2px solid ${(props) => (props.$selected ? props.theme.palette.primary.main : "transparent")};
  cursor: pointer;
`;

const VurnishedOverview: React.FC = () => {
  const history = useHistory();
  const query = useQueryParams();
  const select = query.get("selected");
  const id = query.get("id");

  const [state, dispatch] = useContext(FurnitureConfigContext);

  useEffect(() => {
    //case fresh visit on page
    if (state.room === undefined) {
      const selectedRoom = rooms[parseInt(select) || 1];
      dispatch({ type: FurnitureActionTypes.SET_ROOM, room: selectedRoom });
    }
    if (state.editorLink === undefined && id !== null) {
      dispatch({ type: FurnitureActionTypes.SET_EDITOR_LINK, editorLink: `/share/${id}/editor` });
    }
  }, [state.room, state.editorLink, select, dispatch, id]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={2}>
        {state.editorLink !== undefined && <BackLink to={state.editorLink} tooltip={"Zurück zum Editor"} />}
      </Grid>
      <Grid item xs={8} style={{ alignSelf: "center" }}>
        {/* <img src={VurnishedLogo} alt="Logo" height="60" /> */}
        <Typography variant="h4" align="center">
          Willkommen Zuhause!
        </Typography>
        <Typography variant="h5" align="center">
          Mit Einrichtungsvorschlägen von Vurnished.
        </Typography>
      </Grid>

      <Grid item xs={12} lg={9}>
        <RoomImage img={state.room?.img} />
      </Grid>

      <Grid item xs={12} lg={3}>
        <FlrPaper>
          <Grid container spacing={3} direction="row" alignItems="center">
            {Object.values(rooms).map((room) => {
              return (
                <Grid item xs={4} lg={12}>
                  <RoomCube
                    src={room.img}
                    alt="furnished room"
                    $selected={state.room?.id === room.id}
                    onClick={() => dispatch({ type: FurnitureActionTypes.SET_ROOM, room: room })}
                  />
                </Grid>
              );
            })}
          </Grid>
        </FlrPaper>
      </Grid>

      <Grid item xs={12}>
        <Grid container justify="center">
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => history.push(`/vurnished/${state.room?.id}/detail`)}
          >
            Einrichtung ansehen
          </Button>
        </Grid>
      </Grid>

      {/* <Grid item xs={12}>
        <Grid container spacing={3} direction="column" alignItems="center">
          <Grid item xs={12} component={FlrPaper}>
            <Grid container spacing={3} alignItems="center" direction="column" wrap="nowrap">
              <Grid item xs={12}>
                <RoomImage img={state.room?.img} />
              </Grid>
              <Grid item xs={12}>
                {state.room && (
                  <Pagination
                    style={{ paddingBottom: "10px" }}
                    count={Object.keys(rooms).length}
                    color="primary"
                    page={state.room.id}
                    size="large"
                    onChange={(event, value) => {
                      dispatch({ type: FurnitureActionTypes.SET_ROOM, room: rooms[value] });
                    }}
                    shape="rounded"
                    renderItem={(item) => <RoomPagination params={item} selected={item.page === state.room.id} />}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={() => history.push(`/vurnished/${state.room?.id}`)}>
              Einrichtung ansehen
            </Button>
          </Grid>
        </Grid>
      </Grid> */}
    </Grid>
  );
};

export default VurnishedOverview;
