import React from "react";
import { useGetFloorplansByOwner } from "../../hooks/floorplan/GetFloorplanHooks";
import { useUser } from "../../providers/AuthContextProvider";
import CardGrid from "../../components/dashboard/CardGrid";

const GridView: React.FC<{}> = () => {
  const { userAttributes } = useUser();
  const { floorplans, isLoading } = useGetFloorplansByOwner(userAttributes?.sub);

  return <>{!isLoading && <CardGrid floorplans={floorplans} slice={100} />}</>;
};

export default GridView;
