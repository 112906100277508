import { Floorplan } from "../../models";

import {
  CallRecognitionServiceQuery,
  CallRecognitionServiceQueryVariables,
  RecognitionServiceEventType,
  RecognitionServiceInput,
} from "../../API";
import * as queries from "../../graphql/queries";
import { gqlOperation } from "../GraphQLHooks";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Event Structure example:
// {
//   "typeName": "Query",
//   "fieldName": "callRecognitionService",
// "arguments": {
//   "input":{
//     "image": "Base64 image string"
//     "floorplan_id": "000fd113-d23c-4380-9a3d-eeee88481f5e"
//     "event_type": "RECOGNITION" // FLOORPLANNER_API_CALL, FLOORPLANNER_REIMPORT, PREVIEW
//   }
// },
//   ...
// }

export type AIPreviewResult = {
  imageUri: string; // base64 URL string
  file: File;
};

const callRecognitionService = async (
  params: CallRecognitionServiceQueryVariables,
  authMode?: GRAPHQL_AUTH_MODE
): Promise<CallRecognitionServiceQuery> => {
  let response = {};
  const auth = authMode || GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS;
  try {
    response = await gqlOperation<CallRecognitionServiceQuery>(queries.callRecognitionService, params, auth);
    console.log("callRecognitionService result: ", response);
  } catch (err) {
    console.log("callRecognitionService error:", err);
  }
  return response;
};

export const useReimportFloorplan = () => {
  const reimportFloorplan = async (floorplan: Floorplan): Promise<any> => {
    console.log("Reimport floorplan called...", floorplan.id);

    const input: RecognitionServiceInput = {
      floorplan_id: floorplan.id,
      event_type: RecognitionServiceEventType.FLOORPLANNER_REIMPORT,
    };

    const params: CallRecognitionServiceQueryVariables = {
      input: input,
    };

    callRecognitionService(params);
  };

  return { reimportFloorplan };
};

export const useCreateAIPreview = () => {
  const createAIPreview = async (image: string): Promise<AIPreviewResult> => {
    console.log("Create floorplan AI preview called...");

    const input: RecognitionServiceInput = {
      image: image,
      event_type: RecognitionServiceEventType.PREVIEW,
    };

    const params: CallRecognitionServiceQueryVariables = {
      input: input,
    };

    const response = await callRecognitionService(params, GRAPHQL_AUTH_MODE.AWS_IAM);
    const result: AIPreviewResult = JSON.parse(response.callRecognitionService);

    return result;
  };

  return { createAIPreview };
};
