import IconLink from "components/layout/IconLink";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

type PropTypes = {
  tooltip: string;
  to: string;
};
const BackLink: React.FC<PropTypes> = ({ tooltip, to }) => {
  return <IconLink tooltip={tooltip} to={to} icon={<ArrowBackIcon />} />;
};
export default BackLink;
