import { Snackbar } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import React from "react";
import { useToastProvider } from "../../providers/ToastProvider";

type Props = {
  toast: {
    severity: Color;
    content: string;
  };
};

const Toast = ({ toast }: Props) => {
  const { isVisible, closeToast } = useToastProvider();

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    closeToast();
  };

  return (
    <Snackbar open={isVisible} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={toast.severity}>
        {toast.content}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
