/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createFloorplanProject = /* GraphQL */ `
  mutation CreateFloorplanProject(
    $input: CreateFloorplanProjectInput!
    $condition: ModelFloorplanProjectConditionInput
  ) {
    createFloorplanProject(input: $input, condition: $condition) {
      id
      name
      address
      description
      size
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateFloorplanProject = /* GraphQL */ `
  mutation UpdateFloorplanProject(
    $input: UpdateFloorplanProjectInput!
    $condition: ModelFloorplanProjectConditionInput
  ) {
    updateFloorplanProject(input: $input, condition: $condition) {
      id
      name
      address
      description
      size
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteFloorplanProject = /* GraphQL */ `
  mutation DeleteFloorplanProject(
    $input: DeleteFloorplanProjectInput!
    $condition: ModelFloorplanProjectConditionInput
  ) {
    deleteFloorplanProject(input: $input, condition: $condition) {
      id
      name
      address
      description
      size
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createFloorplan = /* GraphQL */ `
  mutation CreateFloorplan($input: CreateFloorplanInput!, $condition: ModelFloorplanConditionInput) {
    createFloorplan(input: $input, condition: $condition) {
      id
      type
      owner
      projectId
      createdAt
      updatedAt
      name
      address
      description
      originalImageName
      originalInputType
      shareable
      originalImage {
        bucket
        region
        key
        status
      }
      croppedImage {
        bucket
        region
        key
        status
      }
      preprocessedImage {
        bucket
        region
        key
        status
      }
      visual2DImage {
        bucket
        region
        key
        status
      }
      mlPredictionImage {
        bucket
        region
        key
        status
      }
      ocrPredictionImage {
        bucket
        region
        key
        status
      }
      postMlImage {
        bucket
        region
        key
        status
      }
      internalModelImage {
        bucket
        region
        key
        status
      }
      basicImage {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      basicPlusImage {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageTop {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageTilted {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageNE {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageNW {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageSE {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageSW {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      reactplannerModel {
        bucket
        region
        key
        status
      }
      floorplannerModel {
        bucket
        region
        key
        status
      }
      ifcModel {
        bucket
        region
        key
        status
      }
      reimportedIfcModel {
        bucket
        region
        key
        status
      }
      total_size
      amount_doors
      amount_rooms
      amount_windows
      total_room_size_m2
      total_room_size_px
      user_total_size
      user_scaling_selected_px
      user_scaling_cm
      api_scaling_factor_cm_per_px
      status
      customerId
      floorplannerId
      acceptedAt
      finishedAt
      rooms {
        items {
          id
          floorplanId
          name
          room_size_sqm
          room_type
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      orders {
        items {
          id
          floorplan_id
          order_id
          owner
          customer
          createdAt
          updatedAt
          floorplan {
            id
            type
            owner
            projectId
            createdAt
            updatedAt
            name
            address
            description
            originalImageName
            originalInputType
            shareable
            total_size
            amount_doors
            amount_rooms
            amount_windows
            total_room_size_m2
            total_room_size_px
            user_total_size
            user_scaling_selected_px
            user_scaling_cm
            api_scaling_factor_cm_per_px
            status
            customerId
            floorplannerId
            acceptedAt
            finishedAt
          }
          order {
            id
            user
            customer
            owner
            amount_total
            level
            stripe_checkout_session_id
            paymentStatus
            orderStatus
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      customer {
        id
        user
        identityId
        email
        stripe_customer_id
        phone_number
        company
        company_address
        company_address_line2
        company_city
        company_taxnumber
        country
        zip
        accepted_terms
        accepted_gdpr
        createdAt
        updatedAt
        floorplans {
          items {
            id
            type
            owner
            projectId
            createdAt
            updatedAt
            name
            address
            description
            originalImageName
            originalInputType
            shareable
            total_size
            amount_doors
            amount_rooms
            amount_windows
            total_room_size_m2
            total_room_size_px
            user_total_size
            user_scaling_selected_px
            user_scaling_cm
            api_scaling_factor_cm_per_px
            status
            customerId
            floorplannerId
            acceptedAt
            finishedAt
          }
          nextToken
        }
      }
      chatMessages {
        items {
          id
          floorplanId
          message
          sentByCustomer
          read
          createdAt
          owner
          images {
            bucket
            region
            key
            status
          }
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateFloorplan = /* GraphQL */ `
  mutation UpdateFloorplan($input: UpdateFloorplanInput!, $condition: ModelFloorplanConditionInput) {
    updateFloorplan(input: $input, condition: $condition) {
      id
      type
      owner
      projectId
      createdAt
      updatedAt
      name
      address
      description
      originalImageName
      originalInputType
      shareable
      originalImage {
        bucket
        region
        key
        status
      }
      croppedImage {
        bucket
        region
        key
        status
      }
      preprocessedImage {
        bucket
        region
        key
        status
      }
      visual2DImage {
        bucket
        region
        key
        status
      }
      mlPredictionImage {
        bucket
        region
        key
        status
      }
      ocrPredictionImage {
        bucket
        region
        key
        status
      }
      postMlImage {
        bucket
        region
        key
        status
      }
      internalModelImage {
        bucket
        region
        key
        status
      }
      basicImage {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      basicPlusImage {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageTop {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageTilted {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageNE {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageNW {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageSE {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageSW {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      reactplannerModel {
        bucket
        region
        key
        status
      }
      floorplannerModel {
        bucket
        region
        key
        status
      }
      ifcModel {
        bucket
        region
        key
        status
      }
      reimportedIfcModel {
        bucket
        region
        key
        status
      }
      total_size
      amount_doors
      amount_rooms
      amount_windows
      total_room_size_m2
      total_room_size_px
      user_total_size
      user_scaling_selected_px
      user_scaling_cm
      api_scaling_factor_cm_per_px
      status
      customerId
      floorplannerId
      acceptedAt
      finishedAt
      rooms {
        items {
          id
          floorplanId
          name
          room_size_sqm
          room_type
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      orders {
        items {
          id
          floorplan_id
          order_id
          owner
          customer
          createdAt
          updatedAt
          floorplan {
            id
            type
            owner
            projectId
            createdAt
            updatedAt
            name
            address
            description
            originalImageName
            originalInputType
            shareable
            total_size
            amount_doors
            amount_rooms
            amount_windows
            total_room_size_m2
            total_room_size_px
            user_total_size
            user_scaling_selected_px
            user_scaling_cm
            api_scaling_factor_cm_per_px
            status
            customerId
            floorplannerId
            acceptedAt
            finishedAt
          }
          order {
            id
            user
            customer
            owner
            amount_total
            level
            stripe_checkout_session_id
            paymentStatus
            orderStatus
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      customer {
        id
        user
        identityId
        email
        stripe_customer_id
        phone_number
        company
        company_address
        company_address_line2
        company_city
        company_taxnumber
        country
        zip
        accepted_terms
        accepted_gdpr
        createdAt
        updatedAt
        floorplans {
          items {
            id
            type
            owner
            projectId
            createdAt
            updatedAt
            name
            address
            description
            originalImageName
            originalInputType
            shareable
            total_size
            amount_doors
            amount_rooms
            amount_windows
            total_room_size_m2
            total_room_size_px
            user_total_size
            user_scaling_selected_px
            user_scaling_cm
            api_scaling_factor_cm_per_px
            status
            customerId
            floorplannerId
            acceptedAt
            finishedAt
          }
          nextToken
        }
      }
      chatMessages {
        items {
          id
          floorplanId
          message
          sentByCustomer
          read
          createdAt
          owner
          images {
            bucket
            region
            key
            status
          }
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteFloorplan = /* GraphQL */ `
  mutation DeleteFloorplan($input: DeleteFloorplanInput!, $condition: ModelFloorplanConditionInput) {
    deleteFloorplan(input: $input, condition: $condition) {
      id
      type
      owner
      projectId
      createdAt
      updatedAt
      name
      address
      description
      originalImageName
      originalInputType
      shareable
      originalImage {
        bucket
        region
        key
        status
      }
      croppedImage {
        bucket
        region
        key
        status
      }
      preprocessedImage {
        bucket
        region
        key
        status
      }
      visual2DImage {
        bucket
        region
        key
        status
      }
      mlPredictionImage {
        bucket
        region
        key
        status
      }
      ocrPredictionImage {
        bucket
        region
        key
        status
      }
      postMlImage {
        bucket
        region
        key
        status
      }
      internalModelImage {
        bucket
        region
        key
        status
      }
      basicImage {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      basicPlusImage {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageTop {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageTilted {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageNE {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageNW {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageSE {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageSW {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      reactplannerModel {
        bucket
        region
        key
        status
      }
      floorplannerModel {
        bucket
        region
        key
        status
      }
      ifcModel {
        bucket
        region
        key
        status
      }
      reimportedIfcModel {
        bucket
        region
        key
        status
      }
      total_size
      amount_doors
      amount_rooms
      amount_windows
      total_room_size_m2
      total_room_size_px
      user_total_size
      user_scaling_selected_px
      user_scaling_cm
      api_scaling_factor_cm_per_px
      status
      customerId
      floorplannerId
      acceptedAt
      finishedAt
      rooms {
        items {
          id
          floorplanId
          name
          room_size_sqm
          room_type
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      orders {
        items {
          id
          floorplan_id
          order_id
          owner
          customer
          createdAt
          updatedAt
          floorplan {
            id
            type
            owner
            projectId
            createdAt
            updatedAt
            name
            address
            description
            originalImageName
            originalInputType
            shareable
            total_size
            amount_doors
            amount_rooms
            amount_windows
            total_room_size_m2
            total_room_size_px
            user_total_size
            user_scaling_selected_px
            user_scaling_cm
            api_scaling_factor_cm_per_px
            status
            customerId
            floorplannerId
            acceptedAt
            finishedAt
          }
          order {
            id
            user
            customer
            owner
            amount_total
            level
            stripe_checkout_session_id
            paymentStatus
            orderStatus
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      customer {
        id
        user
        identityId
        email
        stripe_customer_id
        phone_number
        company
        company_address
        company_address_line2
        company_city
        company_taxnumber
        country
        zip
        accepted_terms
        accepted_gdpr
        createdAt
        updatedAt
        floorplans {
          items {
            id
            type
            owner
            projectId
            createdAt
            updatedAt
            name
            address
            description
            originalImageName
            originalInputType
            shareable
            total_size
            amount_doors
            amount_rooms
            amount_windows
            total_room_size_m2
            total_room_size_px
            user_total_size
            user_scaling_selected_px
            user_scaling_cm
            api_scaling_factor_cm_per_px
            status
            customerId
            floorplannerId
            acceptedAt
            finishedAt
          }
          nextToken
        }
      }
      chatMessages {
        items {
          id
          floorplanId
          message
          sentByCustomer
          read
          createdAt
          owner
          images {
            bucket
            region
            key
            status
          }
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createRoom = /* GraphQL */ `
  mutation CreateRoom($input: CreateRoomInput!, $condition: ModelRoomConditionInput) {
    createRoom(input: $input, condition: $condition) {
      id
      floorplanId
      name
      room_size_sqm
      room_type
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateRoom = /* GraphQL */ `
  mutation UpdateRoom($input: UpdateRoomInput!, $condition: ModelRoomConditionInput) {
    updateRoom(input: $input, condition: $condition) {
      id
      floorplanId
      name
      room_size_sqm
      room_type
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteRoom = /* GraphQL */ `
  mutation DeleteRoom($input: DeleteRoomInput!, $condition: ModelRoomConditionInput) {
    deleteRoom(input: $input, condition: $condition) {
      id
      floorplanId
      name
      room_size_sqm
      room_type
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createFloorplanOrder = /* GraphQL */ `
  mutation CreateFloorplanOrder($input: CreateFloorplanOrderInput!, $condition: ModelFloorplanOrderConditionInput) {
    createFloorplanOrder(input: $input, condition: $condition) {
      id
      floorplan_id
      order_id
      owner
      customer
      createdAt
      updatedAt
      floorplan {
        id
        type
        owner
        projectId
        createdAt
        updatedAt
        name
        address
        description
        originalImageName
        originalInputType
        shareable
        originalImage {
          bucket
          region
          key
          status
        }
        croppedImage {
          bucket
          region
          key
          status
        }
        preprocessedImage {
          bucket
          region
          key
          status
        }
        visual2DImage {
          bucket
          region
          key
          status
        }
        mlPredictionImage {
          bucket
          region
          key
          status
        }
        ocrPredictionImage {
          bucket
          region
          key
          status
        }
        postMlImage {
          bucket
          region
          key
          status
        }
        internalModelImage {
          bucket
          region
          key
          status
        }
        basicImage {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        basicPlusImage {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageTop {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageTilted {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageNE {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageNW {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageSE {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageSW {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        reactplannerModel {
          bucket
          region
          key
          status
        }
        floorplannerModel {
          bucket
          region
          key
          status
        }
        ifcModel {
          bucket
          region
          key
          status
        }
        reimportedIfcModel {
          bucket
          region
          key
          status
        }
        total_size
        amount_doors
        amount_rooms
        amount_windows
        total_room_size_m2
        total_room_size_px
        user_total_size
        user_scaling_selected_px
        user_scaling_cm
        api_scaling_factor_cm_per_px
        status
        customerId
        floorplannerId
        acceptedAt
        finishedAt
        rooms {
          items {
            id
            floorplanId
            name
            room_size_sqm
            room_type
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        orders {
          items {
            id
            floorplan_id
            order_id
            owner
            customer
            createdAt
            updatedAt
          }
          nextToken
        }
        customer {
          id
          user
          identityId
          email
          stripe_customer_id
          phone_number
          company
          company_address
          company_address_line2
          company_city
          company_taxnumber
          country
          zip
          accepted_terms
          accepted_gdpr
          createdAt
          updatedAt
          floorplans {
            nextToken
          }
        }
        chatMessages {
          items {
            id
            floorplanId
            message
            sentByCustomer
            read
            createdAt
            owner
            updatedAt
          }
          nextToken
        }
      }
      order {
        id
        user
        customer
        owner
        amount_total
        level
        stripe_checkout_session_id
        paymentStatus
        orderStatus
        createdAt
        updatedAt
        floorplans {
          items {
            id
            floorplan_id
            order_id
            owner
            customer
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateFloorplanOrder = /* GraphQL */ `
  mutation UpdateFloorplanOrder($input: UpdateFloorplanOrderInput!, $condition: ModelFloorplanOrderConditionInput) {
    updateFloorplanOrder(input: $input, condition: $condition) {
      id
      floorplan_id
      order_id
      owner
      customer
      createdAt
      updatedAt
      floorplan {
        id
        type
        owner
        projectId
        createdAt
        updatedAt
        name
        address
        description
        originalImageName
        originalInputType
        shareable
        originalImage {
          bucket
          region
          key
          status
        }
        croppedImage {
          bucket
          region
          key
          status
        }
        preprocessedImage {
          bucket
          region
          key
          status
        }
        visual2DImage {
          bucket
          region
          key
          status
        }
        mlPredictionImage {
          bucket
          region
          key
          status
        }
        ocrPredictionImage {
          bucket
          region
          key
          status
        }
        postMlImage {
          bucket
          region
          key
          status
        }
        internalModelImage {
          bucket
          region
          key
          status
        }
        basicImage {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        basicPlusImage {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageTop {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageTilted {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageNE {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageNW {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageSE {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageSW {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        reactplannerModel {
          bucket
          region
          key
          status
        }
        floorplannerModel {
          bucket
          region
          key
          status
        }
        ifcModel {
          bucket
          region
          key
          status
        }
        reimportedIfcModel {
          bucket
          region
          key
          status
        }
        total_size
        amount_doors
        amount_rooms
        amount_windows
        total_room_size_m2
        total_room_size_px
        user_total_size
        user_scaling_selected_px
        user_scaling_cm
        api_scaling_factor_cm_per_px
        status
        customerId
        floorplannerId
        acceptedAt
        finishedAt
        rooms {
          items {
            id
            floorplanId
            name
            room_size_sqm
            room_type
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        orders {
          items {
            id
            floorplan_id
            order_id
            owner
            customer
            createdAt
            updatedAt
          }
          nextToken
        }
        customer {
          id
          user
          identityId
          email
          stripe_customer_id
          phone_number
          company
          company_address
          company_address_line2
          company_city
          company_taxnumber
          country
          zip
          accepted_terms
          accepted_gdpr
          createdAt
          updatedAt
          floorplans {
            nextToken
          }
        }
        chatMessages {
          items {
            id
            floorplanId
            message
            sentByCustomer
            read
            createdAt
            owner
            updatedAt
          }
          nextToken
        }
      }
      order {
        id
        user
        customer
        owner
        amount_total
        level
        stripe_checkout_session_id
        paymentStatus
        orderStatus
        createdAt
        updatedAt
        floorplans {
          items {
            id
            floorplan_id
            order_id
            owner
            customer
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteFloorplanOrder = /* GraphQL */ `
  mutation DeleteFloorplanOrder($input: DeleteFloorplanOrderInput!, $condition: ModelFloorplanOrderConditionInput) {
    deleteFloorplanOrder(input: $input, condition: $condition) {
      id
      floorplan_id
      order_id
      owner
      customer
      createdAt
      updatedAt
      floorplan {
        id
        type
        owner
        projectId
        createdAt
        updatedAt
        name
        address
        description
        originalImageName
        originalInputType
        shareable
        originalImage {
          bucket
          region
          key
          status
        }
        croppedImage {
          bucket
          region
          key
          status
        }
        preprocessedImage {
          bucket
          region
          key
          status
        }
        visual2DImage {
          bucket
          region
          key
          status
        }
        mlPredictionImage {
          bucket
          region
          key
          status
        }
        ocrPredictionImage {
          bucket
          region
          key
          status
        }
        postMlImage {
          bucket
          region
          key
          status
        }
        internalModelImage {
          bucket
          region
          key
          status
        }
        basicImage {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        basicPlusImage {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageTop {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageTilted {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageNE {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageNW {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageSE {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        professionalImageSW {
          png {
            bucket
            region
            key
            status
          }
          jpeg {
            bucket
            region
            key
            status
          }
          pdf {
            bucket
            region
            key
            status
          }
        }
        reactplannerModel {
          bucket
          region
          key
          status
        }
        floorplannerModel {
          bucket
          region
          key
          status
        }
        ifcModel {
          bucket
          region
          key
          status
        }
        reimportedIfcModel {
          bucket
          region
          key
          status
        }
        total_size
        amount_doors
        amount_rooms
        amount_windows
        total_room_size_m2
        total_room_size_px
        user_total_size
        user_scaling_selected_px
        user_scaling_cm
        api_scaling_factor_cm_per_px
        status
        customerId
        floorplannerId
        acceptedAt
        finishedAt
        rooms {
          items {
            id
            floorplanId
            name
            room_size_sqm
            room_type
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        orders {
          items {
            id
            floorplan_id
            order_id
            owner
            customer
            createdAt
            updatedAt
          }
          nextToken
        }
        customer {
          id
          user
          identityId
          email
          stripe_customer_id
          phone_number
          company
          company_address
          company_address_line2
          company_city
          company_taxnumber
          country
          zip
          accepted_terms
          accepted_gdpr
          createdAt
          updatedAt
          floorplans {
            nextToken
          }
        }
        chatMessages {
          items {
            id
            floorplanId
            message
            sentByCustomer
            read
            createdAt
            owner
            updatedAt
          }
          nextToken
        }
      }
      order {
        id
        user
        customer
        owner
        amount_total
        level
        stripe_checkout_session_id
        paymentStatus
        orderStatus
        createdAt
        updatedAt
        floorplans {
          items {
            id
            floorplan_id
            order_id
            owner
            customer
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder($input: CreateOrderInput!, $condition: ModelOrderConditionInput) {
    createOrder(input: $input, condition: $condition) {
      id
      user
      customer
      owner
      amount_total
      level
      stripe_checkout_session_id
      paymentStatus
      orderStatus
      createdAt
      updatedAt
      floorplans {
        items {
          id
          floorplan_id
          order_id
          owner
          customer
          createdAt
          updatedAt
          floorplan {
            id
            type
            owner
            projectId
            createdAt
            updatedAt
            name
            address
            description
            originalImageName
            originalInputType
            shareable
            total_size
            amount_doors
            amount_rooms
            amount_windows
            total_room_size_m2
            total_room_size_px
            user_total_size
            user_scaling_selected_px
            user_scaling_cm
            api_scaling_factor_cm_per_px
            status
            customerId
            floorplannerId
            acceptedAt
            finishedAt
          }
          order {
            id
            user
            customer
            owner
            amount_total
            level
            stripe_checkout_session_id
            paymentStatus
            orderStatus
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder($input: UpdateOrderInput!, $condition: ModelOrderConditionInput) {
    updateOrder(input: $input, condition: $condition) {
      id
      user
      customer
      owner
      amount_total
      level
      stripe_checkout_session_id
      paymentStatus
      orderStatus
      createdAt
      updatedAt
      floorplans {
        items {
          id
          floorplan_id
          order_id
          owner
          customer
          createdAt
          updatedAt
          floorplan {
            id
            type
            owner
            projectId
            createdAt
            updatedAt
            name
            address
            description
            originalImageName
            originalInputType
            shareable
            total_size
            amount_doors
            amount_rooms
            amount_windows
            total_room_size_m2
            total_room_size_px
            user_total_size
            user_scaling_selected_px
            user_scaling_cm
            api_scaling_factor_cm_per_px
            status
            customerId
            floorplannerId
            acceptedAt
            finishedAt
          }
          order {
            id
            user
            customer
            owner
            amount_total
            level
            stripe_checkout_session_id
            paymentStatus
            orderStatus
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder($input: DeleteOrderInput!, $condition: ModelOrderConditionInput) {
    deleteOrder(input: $input, condition: $condition) {
      id
      user
      customer
      owner
      amount_total
      level
      stripe_checkout_session_id
      paymentStatus
      orderStatus
      createdAt
      updatedAt
      floorplans {
        items {
          id
          floorplan_id
          order_id
          owner
          customer
          createdAt
          updatedAt
          floorplan {
            id
            type
            owner
            projectId
            createdAt
            updatedAt
            name
            address
            description
            originalImageName
            originalInputType
            shareable
            total_size
            amount_doors
            amount_rooms
            amount_windows
            total_room_size_m2
            total_room_size_px
            user_total_size
            user_scaling_selected_px
            user_scaling_cm
            api_scaling_factor_cm_per_px
            status
            customerId
            floorplannerId
            acceptedAt
            finishedAt
          }
          order {
            id
            user
            customer
            owner
            amount_total
            level
            stripe_checkout_session_id
            paymentStatus
            orderStatus
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer($input: CreateCustomerInput!, $condition: ModelCustomerConditionInput) {
    createCustomer(input: $input, condition: $condition) {
      id
      user
      identityId
      email
      stripe_customer_id
      phone_number
      company
      company_address
      company_address_line2
      company_city
      company_taxnumber
      country
      zip
      accepted_terms
      accepted_gdpr
      createdAt
      updatedAt
      floorplans {
        items {
          id
          type
          owner
          projectId
          createdAt
          updatedAt
          name
          address
          description
          originalImageName
          originalInputType
          shareable
          originalImage {
            bucket
            region
            key
            status
          }
          croppedImage {
            bucket
            region
            key
            status
          }
          preprocessedImage {
            bucket
            region
            key
            status
          }
          visual2DImage {
            bucket
            region
            key
            status
          }
          mlPredictionImage {
            bucket
            region
            key
            status
          }
          ocrPredictionImage {
            bucket
            region
            key
            status
          }
          postMlImage {
            bucket
            region
            key
            status
          }
          internalModelImage {
            bucket
            region
            key
            status
          }
          reactplannerModel {
            bucket
            region
            key
            status
          }
          floorplannerModel {
            bucket
            region
            key
            status
          }
          ifcModel {
            bucket
            region
            key
            status
          }
          reimportedIfcModel {
            bucket
            region
            key
            status
          }
          total_size
          amount_doors
          amount_rooms
          amount_windows
          total_room_size_m2
          total_room_size_px
          user_total_size
          user_scaling_selected_px
          user_scaling_cm
          api_scaling_factor_cm_per_px
          status
          customerId
          floorplannerId
          acceptedAt
          finishedAt
          rooms {
            nextToken
          }
          orders {
            nextToken
          }
          customer {
            id
            user
            identityId
            email
            stripe_customer_id
            phone_number
            company
            company_address
            company_address_line2
            company_city
            company_taxnumber
            country
            zip
            accepted_terms
            accepted_gdpr
            createdAt
            updatedAt
          }
          chatMessages {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer($input: UpdateCustomerInput!, $condition: ModelCustomerConditionInput) {
    updateCustomer(input: $input, condition: $condition) {
      id
      user
      identityId
      email
      stripe_customer_id
      phone_number
      company
      company_address
      company_address_line2
      company_city
      company_taxnumber
      country
      zip
      accepted_terms
      accepted_gdpr
      createdAt
      updatedAt
      floorplans {
        items {
          id
          type
          owner
          projectId
          createdAt
          updatedAt
          name
          address
          description
          originalImageName
          originalInputType
          shareable
          originalImage {
            bucket
            region
            key
            status
          }
          croppedImage {
            bucket
            region
            key
            status
          }
          preprocessedImage {
            bucket
            region
            key
            status
          }
          visual2DImage {
            bucket
            region
            key
            status
          }
          mlPredictionImage {
            bucket
            region
            key
            status
          }
          ocrPredictionImage {
            bucket
            region
            key
            status
          }
          postMlImage {
            bucket
            region
            key
            status
          }
          internalModelImage {
            bucket
            region
            key
            status
          }
          reactplannerModel {
            bucket
            region
            key
            status
          }
          floorplannerModel {
            bucket
            region
            key
            status
          }
          ifcModel {
            bucket
            region
            key
            status
          }
          reimportedIfcModel {
            bucket
            region
            key
            status
          }
          total_size
          amount_doors
          amount_rooms
          amount_windows
          total_room_size_m2
          total_room_size_px
          user_total_size
          user_scaling_selected_px
          user_scaling_cm
          api_scaling_factor_cm_per_px
          status
          customerId
          floorplannerId
          acceptedAt
          finishedAt
          rooms {
            nextToken
          }
          orders {
            nextToken
          }
          customer {
            id
            user
            identityId
            email
            stripe_customer_id
            phone_number
            company
            company_address
            company_address_line2
            company_city
            company_taxnumber
            country
            zip
            accepted_terms
            accepted_gdpr
            createdAt
            updatedAt
          }
          chatMessages {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer($input: DeleteCustomerInput!, $condition: ModelCustomerConditionInput) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      user
      identityId
      email
      stripe_customer_id
      phone_number
      company
      company_address
      company_address_line2
      company_city
      company_taxnumber
      country
      zip
      accepted_terms
      accepted_gdpr
      createdAt
      updatedAt
      floorplans {
        items {
          id
          type
          owner
          projectId
          createdAt
          updatedAt
          name
          address
          description
          originalImageName
          originalInputType
          shareable
          originalImage {
            bucket
            region
            key
            status
          }
          croppedImage {
            bucket
            region
            key
            status
          }
          preprocessedImage {
            bucket
            region
            key
            status
          }
          visual2DImage {
            bucket
            region
            key
            status
          }
          mlPredictionImage {
            bucket
            region
            key
            status
          }
          ocrPredictionImage {
            bucket
            region
            key
            status
          }
          postMlImage {
            bucket
            region
            key
            status
          }
          internalModelImage {
            bucket
            region
            key
            status
          }
          reactplannerModel {
            bucket
            region
            key
            status
          }
          floorplannerModel {
            bucket
            region
            key
            status
          }
          ifcModel {
            bucket
            region
            key
            status
          }
          reimportedIfcModel {
            bucket
            region
            key
            status
          }
          total_size
          amount_doors
          amount_rooms
          amount_windows
          total_room_size_m2
          total_room_size_px
          user_total_size
          user_scaling_selected_px
          user_scaling_cm
          api_scaling_factor_cm_per_px
          status
          customerId
          floorplannerId
          acceptedAt
          finishedAt
          rooms {
            nextToken
          }
          orders {
            nextToken
          }
          customer {
            id
            user
            identityId
            email
            stripe_customer_id
            phone_number
            company
            company_address
            company_address_line2
            company_city
            company_taxnumber
            country
            zip
            accepted_terms
            accepted_gdpr
            createdAt
            updatedAt
          }
          chatMessages {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createSettings = /* GraphQL */ `
  mutation CreateSettings($input: CreateSettingsInput!, $condition: ModelSettingsConditionInput) {
    createSettings(input: $input, condition: $condition) {
      id
      theme
      displayName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSettings = /* GraphQL */ `
  mutation UpdateSettings($input: UpdateSettingsInput!, $condition: ModelSettingsConditionInput) {
    updateSettings(input: $input, condition: $condition) {
      id
      theme
      displayName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSettings = /* GraphQL */ `
  mutation DeleteSettings($input: DeleteSettingsInput!, $condition: ModelSettingsConditionInput) {
    deleteSettings(input: $input, condition: $condition) {
      id
      theme
      displayName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createChatMessage = /* GraphQL */ `
  mutation CreateChatMessage($input: CreateChatMessageInput!, $condition: ModelChatMessageConditionInput) {
    createChatMessage(input: $input, condition: $condition) {
      id
      floorplanId
      message
      sentByCustomer
      read
      createdAt
      owner
      images {
        bucket
        region
        key
        status
      }
      updatedAt
    }
  }
`;
export const updateChatMessage = /* GraphQL */ `
  mutation UpdateChatMessage($input: UpdateChatMessageInput!, $condition: ModelChatMessageConditionInput) {
    updateChatMessage(input: $input, condition: $condition) {
      id
      floorplanId
      message
      sentByCustomer
      read
      createdAt
      owner
      images {
        bucket
        region
        key
        status
      }
      updatedAt
    }
  }
`;
export const deleteChatMessage = /* GraphQL */ `
  mutation DeleteChatMessage($input: DeleteChatMessageInput!, $condition: ModelChatMessageConditionInput) {
    deleteChatMessage(input: $input, condition: $condition) {
      id
      floorplanId
      message
      sentByCustomer
      read
      createdAt
      owner
      images {
        bucket
        region
        key
        status
      }
      updatedAt
    }
  }
`;
