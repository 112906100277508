import { UpdateOrderInput, UpdateOrderMutation } from "API";
import { gqlOperation } from "hooks/GraphQLHooks";
import { useMutation } from "react-query";
import * as mutations from "../../graphql/mutations";

export const useUpdateOrder = () => {
  const { mutateAsync: updateOrder } = useMutation((content: UpdateOrderInput) =>
    gqlOperation<UpdateOrderMutation>(mutations.updateOrder, {
      input: content,
    })
  );

  return { updateOrder };
};
