// import ActionMap from "../../utils/ActionMap";

import { CreateCustomerInput } from "../../../API";

// export type CheckoutActions = ActionMap<ActionPayload>[keyof ActionMap<
//   ActionPayload
// >];

export enum AuthUIForm {
  SIGNUP = "SIGNUP",
  CONFIRMSIGNUP = "CONFIRMSIGNUP",
  SIGNIN = "SIGNIN",
  FORGOTPASSWORD = "FORGOTPASSWORD",
  CONFIRMPASSWORD = "CONFIRMPASSWORD",
  CHANGEPASSWORD = "CHANGEPASSWORD",
  SIGNOUT = "SIGNOUT",
}

export enum AuthUIActionTypes {
  EDIT_FORM_VALUE = "EDIT_FORM_VALUE",
  RESET_FORM_VALUES = "RESET",
  CHANGE_FORM = "CHANGE_FORM",
  SET_USERNAME = "SET_USERNAME",
}

export type AuthUIState = {
  formState?: AuthUIForm;
  username: string;
  password?: string;
  verificationCode?: string;
  formValues?: CreateCustomerInput;
};

const initialState: AuthUIState = {
  formState: AuthUIForm.SIGNIN,
  username: "",
  password: "",
  verificationCode: "",
  formValues: {
    user: "",
    email: "",
    phone_number: "",
    company: "",
    company_address: "",
    company_address_line2: "",
    company_city: "",
    company_taxnumber: "",
    country: "",
    zip: "",
    accepted_terms: false,
    accepted_gdpr: false,
  },
};

const reducer = (state: AuthUIState, action: { type: any; key: string; value: any }) => {
  console.log("state:", state);
  switch (action.type) {
    case AuthUIActionTypes.EDIT_FORM_VALUE:
      state.formValues[action.key.toLowerCase()] = action.value;
      return { ...state };
    case AuthUIActionTypes.RESET_FORM_VALUES:
      return {
        formState: action.value,
        ...initialState,
      };
    case AuthUIActionTypes.SET_USERNAME:
      state.username = action.value;
      return { ...state };
    // e.g. action.key = formState, value = AuthUIForm.SIGNIN
    case AuthUIActionTypes.CHANGE_FORM:
      return { ...state, formState: action.value };
    default:
  }
  return state;
};

export { initialState, reducer };
