import React, { useState, useEffect } from "react";
import { useCustomer } from "providers/CustomerContextProvider";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  Tooltip,
  ListItemText,
  Divider,
  ListSubheader,
  makeStyles,
  CircularProgress,
  ListItemSecondaryAction,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { ContentHeader, FlrPaper, Headline } from "../components/layout/StyledComponents";
import { MailOutline } from "@material-ui/icons";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import BusinessIcon from "@material-ui/icons/Business";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PhoneIcon from "@material-ui/icons/Phone";
import SaveIcon from "@material-ui/icons/Save";
import IconLink from "components/layout/IconLink";

import BillingPortalButton from "components/customer/BillingPortalButton";
import ChangePassword from "components/auth/ChangePassword";

import { Formik, Field, Form, FormikHelpers } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { UpdateCustomerInput } from "API";
import { useUpdateCustomer } from "hooks/customer/CustomerCrudHooks";
import { useToastProvider } from "providers/ToastProvider";

//import useSettingsCRUD from "../hooks/customer/SettingsCrudHooks";
//import { Settings } from "../models";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

interface FormValues {
  phone_number: string;
  company: string;
  company_address: string;
  company_address_line2: string;
  company_city: string;
  company_taxnumber: string;
  country: string;
  zip: string;
}

const SettingsPage = () => {
  const classes = useStyles();
  const [editable, setEditable] = useState(false);
  const customer = useCustomer();
  const { updateCustomer } = useUpdateCustomer();
  const { showToast } = useToastProvider();

  // TODO fix warning uncontrolled to controlled values
  const [initialValues, setInitialValues] = useState<FormValues>({
    phone_number: "phone_number",
    company: "company",
    company_address: "company_address",
    company_address_line2: "company_address_line2",
    company_city: "company_city",
    company_taxnumber: "company_taxnumber",
    country: "country",
    zip: "zip",
  });

  //const { data, handleChange, handleSubmit } = useSettingsCRUD();

  useEffect(() => {
    const customerValues: FormValues = {
      phone_number: customer?.phone_number,
      company: customer?.company,
      company_address: customer?.company_address,
      company_address_line2: customer?.company_address_line2,
      company_city: customer?.company_city,
      company_taxnumber: customer?.company_taxnumber,
      country: customer?.country,
      zip: customer?.zip,
    };

    setInitialValues(customerValues);
  }, [customer]);

  const handleUpdateCustomer = async (values: FormValues) => {
    const updatedCustomer: UpdateCustomerInput = {
      id: customer.id,
      phone_number: values.phone_number,
      company: values.company,
      company_address: values.company_address,
      company_address_line2: values.company_address_line2,
      company_city: values.company_city,
      company_taxnumber: values.company_taxnumber,
      country: values.country,
      zip: values.zip,
    };
    const res = await updateCustomer(updatedCustomer);
    if (res) {
      showToast({
        severity: "success",
        content: "Ihre Kundendaten wurden erfolgreich aktualisiert",
      });
    }
  };

  return (
    <>
      <ContentHeader>
        <IconLink tooltip="Zurück zum Dashboard" to="/dashboard" icon={<ArrowBackIcon />} />
        <Headline variant="h4" component="h1">
          Kundenprofil
        </Headline>
      </ContentHeader>
      <Grid container spacing={3} direction="column" justify="center" alignItems="center">
        {customer ? (
          <Grid item xs={12}>
            <FlrPaper>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object({
                  company: Yup.string().required("Bitte geben Sie den Namen ihrer Firma an."),
                  company_address: Yup.string().required("Bitte geben Sie die Adresse ihres Firmensitzes an."),
                  company_address_line2: Yup.string().optional(),
                  company_city: Yup.string().required("Bitte geben Sie die Stadt ihres Firmensitzes an."),
                  company_taxnumber: Yup.string().required("Bitte geben Sie UID Nummer ihrer Firma an."),
                  zip: Yup.string().required("Bitte geben Sie Ihre PLZ an"),
                })}
                onSubmit={async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
                  console.log("UpdatedValues:", values);
                  handleUpdateCustomer(values);
                  setEditable(false);
                }}
              >
                {() => (
                  <Form className={classes.form}>
                    <List>
                      <ListSubheader>Account Email</ListSubheader>
                      <ListItem key="email">
                        <Tooltip title="Email" placement="left">
                          <ListItemAvatar>
                            <Avatar>
                              <MailOutline />
                            </Avatar>
                          </ListItemAvatar>
                        </Tooltip>
                        <ListItemText primary={customer?.email} />
                      </ListItem>
                      <ListSubheader>Rechnungsinformation</ListSubheader>
                      <ListItem key="company">
                        <Tooltip title="Firma" placement="left">
                          <ListItemAvatar>
                            <Avatar>
                              <BusinessIcon />
                            </Avatar>
                          </ListItemAvatar>
                        </Tooltip>
                        <Field
                          required
                          component={TextField}
                          variant="standard"
                          fullWidth
                          id="company"
                          name="company"
                          label="Firma"
                          disabled={!editable}
                        />
                      </ListItem>
                      <ListItem key="company_addresse">
                        <Tooltip title="Adresse" placement="left">
                          <ListItemAvatar>
                            <Avatar>
                              <LocationOnIcon />
                            </Avatar>
                          </ListItemAvatar>
                        </Tooltip>
                        <Field
                          required
                          component={TextField}
                          variant="standard"
                          id="company_address"
                          name="company_address"
                          label="Adresse"
                          disabled={!editable}
                        />
                        <ListItemSecondaryAction>
                          <Field
                            required
                            component={TextField}
                            variant="standard"
                            id="company_address_line2"
                            name="company_address_line2"
                            label="Adresszusatz"
                            disabled={!editable}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem key="city">
                        <Tooltip title="PLZ / Stadt" placement="left">
                          <ListItemAvatar>
                            <Avatar>
                              <LocationCityIcon />
                            </Avatar>
                          </ListItemAvatar>
                        </Tooltip>
                        <Field
                          required
                          component={TextField}
                          variant="standard"
                          id="zip"
                          name="zip"
                          label="ZIP"
                          disabled={!editable}
                        />
                        <ListItemSecondaryAction>
                          <Field
                            required
                            component={TextField}
                            variant="standard"
                            id="company_city"
                            name="company_city"
                            label="Stadt"
                            disabled={!editable}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem key="taxnumber">
                        <Tooltip title="UID Steuernummer" placement="left">
                          <ListItemAvatar>
                            <Avatar>
                              <AccountBalanceIcon />
                            </Avatar>
                          </ListItemAvatar>
                        </Tooltip>
                        <Field
                          required
                          component={TextField}
                          variant="standard"
                          fullWidth
                          id="company_taxnumber"
                          name="company_taxnumber"
                          label="UID"
                          disabled={!editable}
                        />
                      </ListItem>
                      <ListItem key="phone">
                        <Tooltip title="Telefon" placement="left">
                          <ListItemAvatar>
                            <Avatar>
                              <PhoneIcon />
                            </Avatar>
                          </ListItemAvatar>
                        </Tooltip>
                        <Field
                          required
                          component={TextField}
                          variant="standard"
                          fullWidth
                          id="phone_number"
                          name="phone_number"
                          label="Telefon"
                          disabled={!editable}
                        />
                      </ListItem>
                      <ListItem key="save" alignItems="center">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={editable}
                              onChange={() => setEditable(!editable)}
                              name="edit"
                              color="primary"
                            />
                          }
                          label="Informationen aktualisieren"
                        />
                        <ListItemSecondaryAction>
                          <Button
                            disabled={!editable}
                            type="submit"
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon />}
                          >
                            Speichern
                          </Button>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />

                      <ListSubheader>Zahlungsmethoden & Rechnungen</ListSubheader>
                      <ListItem key="stripeBillingPage" button>
                        <Tooltip title="Zahlungsmethoden und Rechnungen" placement="left">
                          <ListItemAvatar>
                            <Avatar>
                              <CreditCardIcon />
                            </Avatar>
                          </ListItemAvatar>
                        </Tooltip>
                        <BillingPortalButton buttonText="Zahlungsmethoden und Rechnungen verwalten" />
                        <ArrowForwardIosIcon />
                      </ListItem>
                    </List>
                  </Form>
                )}
              </Formik>
            </FlrPaper>
            <Divider />
          </Grid>
        ) : (
          <CircularProgress color="secondary" />
        )}

        <Grid item xs={12}>
          <FlrPaper>
            <ChangePassword />
          </FlrPaper>
        </Grid>
      </Grid>

      {/* <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                id="theme"
                label="Theme"
                variant="outlined"
                defaultValue={data?.listSettingss?.items[0].theme}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="displayName"
                label="Display Name"
                variant="outlined"
                defaultValue={data?.listSettingss?.items[0].displayName}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={() => handleSubmit()}>
                Save
              </Button>
            </Grid>
          </Grid>
        </form> */}
    </>
  );
};

export default SettingsPage;
