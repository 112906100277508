import { API } from "aws-amplify";
import { STRIPE_API_NAME, API_PAYMENT_METHODS_ENDPOINT } from "../../constants";
import { useCustomer } from "providers/CustomerContextProvider";
import { useEffect, useState } from "react";

const usePreviousPayments = () => {
  const customer = useCustomer();
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    const getPaymentMethods = async (stripe_id) => {
      const body = {
        customer: stripe_id,
      };
      const response = await API.post(STRIPE_API_NAME, API_PAYMENT_METHODS_ENDPOINT, {
        body,
      });
      setPaymentMethods(response);
    };
    getPaymentMethods(customer.stripe_customer_id);
  }, [customer]);

  return { paymentMethods };
};

export default usePreviousPayments;
