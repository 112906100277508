import React from "react";
import { API, Auth } from "aws-amplify";
import { Button, Grid } from "@material-ui/core";
import { Floorplan, Level, Customer } from "../../models";
import Title from "../../components/layout/Title";
import { FlrPaper } from "../../components/layout/StyledComponents";
import { useToastProvider } from "../../providers/ToastProvider";
import { useGetFloorplans } from "../../hooks/floorplan/GetFloorplanHooks";
import { useDigitizeFloorplan } from "hooks/floorplan/useDigitizeFloorplan";
import { useCreateOrder } from "hooks/checkout/useCreateOrder";
import { ADMIN_API_NAME, MIME_TYPE } from "../../constants";

const customer: Customer = {
  id: "ebc57056-3211-491d-834d-fdfe18d801fa",
  zip: "1030",
  accepted_terms: true,
  accepted_gdpr: true,
  user: "ebc57056-3211-491d-834d-fdfe18d801fa",
  email: "peter@vloor.com",
  country: "Österreich",
  stripe_customer_id: "cus_JD4VSJ9CkjrIGQ",
  company_taxnumber: "ATU76472425",
  company: "Vloor GmbH",
  company_city: "Wien",
  phone_number: "+431234567",
  company_address_line2: "Tür 17",
  company_address: "Seidlgasse 21",
};

const floorplan: Floorplan = {
  id: "000fd113-d23c-4380-9a3d-eeee88481f5e",
  name: "DeveloperPanel Test",
  customer: customer,
};

const DeveloperPanel: React.FC<{}> = () => {
  const { showToast } = useToastProvider();
  const { refetch } = useGetFloorplans();

  const { digitizeFloorplan } = useDigitizeFloorplan();
  const { createOrder } = useCreateOrder();

  // list Editor users
  let paginationNextToken: any;
  async function listEditors(limit: number) {
    let apiName = ADMIN_API_NAME;
    let path = "/listUsersInGroup";
    let myInit = {
      queryStringParameters: {
        groupname: "Editors",
        limit: limit,
        token: paginationNextToken,
      },
      headers: {
        "Content-Type": MIME_TYPE.JSON,
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const { NextToken, ...rest } = await API.get(apiName, path, myInit);
    paginationNextToken = NextToken;
    console.log(rest);

    return rest;
  }

  const handleToast = () => {
    showToast({ severity: "success", content: "Erfolgreicher Toast" });
  };

  const testDigitize = async () => {
    const result = await digitizeFloorplan(floorplan);
    console.log("Digitization result:", result);
  };

  const testCreateOrder = async () => {
    const result = await createOrder(floorplan, Level.BASIC);
    console.log("CreateOrder result:", result);
  };

  return (
    <Grid item xs={12}>
      <FlrPaper elevation={3}>
        <Title>Developer Panel</Title>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Button color="primary" variant="contained" onClick={() => refetch()}>
              Refetch Editor entries
            </Button>
            <Button onClick={handleToast}>ShowToast</Button>
            <Button onClick={() => listEditors(10)}>List Editors</Button>
            <Button color="primary" variant="contained" onClick={() => testDigitize()}>
              Test Digitize Floorplan
            </Button>
            <Button color="primary" variant="contained" onClick={() => testCreateOrder()}>
              Test createOrder
            </Button>
          </Grid>
        </Grid>
      </FlrPaper>
    </Grid>
  );
};

export default DeveloperPanel;
