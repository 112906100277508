import React, { ReactElement, useMemo, forwardRef } from "react";

import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { IconButton, Tooltip } from "@material-ui/core";

type Props = {
  icon: ReactElement;
  to: string;
  tooltip: string;
  color?: "primary" | "secondary";
};

const IconLink = ({ icon, to, tooltip, color }: Props) => {
  const renderLink = useMemo(
    () =>
      forwardRef<any, Omit<RouterLinkProps, "to">>((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to]
  );

  return (
    <Tooltip title={tooltip}>
      <IconButton color={color} component={renderLink}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};

export default IconLink;
