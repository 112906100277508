// returns a slice of key, as used when already in the private vault of a user
// e.g.:
// Key = https://webapp-floorplan-upload-bucket172331-dev.s3.eu-central-1.amazonaws.com/private/eu-central-1/690fbf1c-8866-4d1f-84f7-430e27f9cf8a/floorplans/b5d38822-9f19-4715-92df-ff089402e1de/7d862002-34c6-442c-9d0c-cdcc161efaa6.png
// Path =  floorplans/b5d38822-9f19-4715-92df-ff089402e1de/7d862002-34c6-442c-9d0c-cdcc161efaa6.png
// usage: Storage.vault.get(getPathFromKey(Key))
export const getPathFromKey = (key: String) => {
  return key.slice(key.indexOf("floorplans"));
};

export interface KeyProps {
  // ids
  identityId: string;
  projectId: string;
  fileId: string;
  projectSubFolder?: string;
  // key
  privacyLevel: string;
  prefixFolder: string;
  postfix?: string;
  fileExtension?: string;
}

export const createKey = (props: KeyProps, postfix?: string, extension?: string, projectSubFolder?: string) => {
  props.postfix = postfix ? postfix : "";
  props.fileExtension = extension ? extension : "";
  props.projectSubFolder = projectSubFolder ? projectSubFolder + "/" : "";
  return (
    props.privacyLevel +
    "/" +
    props.identityId +
    "/" +
    props.prefixFolder +
    "/" +
    props.projectId +
    "/" +
    props.projectSubFolder +
    props.fileId +
    props.postfix +
    "." +
    props.fileExtension
  );
};

export const createFilenameWithPostfix = (file: File, postfix: string, newExtension?: string): string => {
  const filename = getFileNameWithoutExtension(file);
  const extension = newExtension ? newExtension : getFileExtension(file.name);

  return filename + postfix + "." + extension;
};

export const getFileNameWithoutExtension = (file: File): string => {
  return file.name.substring(0, file.name.lastIndexOf("."));
};

export const getNameWithoutExtension = (name: string): string => {
  return name.substring(0, name.lastIndexOf("."));
};

export const getFileExtension = (filename: string) => {
  return filename.substring(filename.lastIndexOf(".") + 1, filename.length) || filename;
};

export const getRegion = (userid: string) => {
  return userid.substring(0, userid.indexOf(":")) || "";
};
