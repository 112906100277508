import { useMutation, useQuery } from "react-query";
import {
  CreateCustomerInput,
  CreateCustomerMutation,
  UpdateCustomerInput,
  UpdateCustomerMutation,
  ListCustomersQuery,
  GetCustomerQuery,
  GetCustomerByEmailQuery,
  GetCustomerByUsernameQuery,
} from "../../API";
import { Customer } from "../../models";

import { gqlOperation } from "../GraphQLHooks";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { mapGetCustomerQuery, mapListCustomersQuery } from "../../types/types";

import * as advanced from "../../graphql/advanced_queries";

export const useGetCustomerByUsername = (username: string) => {
  const { data, error, isLoading, refetch } = useQuery<Customer | undefined>(
    `getCustomerByUsername-${username}`,
    async () => {
      const data = await gqlOperation<GetCustomerByUsernameQuery>(queries.getCustomerByUsername, {
        user: username,
      });
      console.log("getCustomerByUsername query fired");
      const { floorplans, ...customer } = data.getCustomerByUsername.items[0];
      return customer;
    }
  );

  //console.log("getCustomerByUsername result:", data);
  return { customer: data, error, isLoading, refetch };
};

export const useGetCustomerByEmail = (email: string) => {
  const { data, error, isLoading, refetch } = useQuery<Customer | undefined>(
    `getCustomerByEmail-${email}`,
    async () => {
      const data = await gqlOperation<GetCustomerByEmailQuery>(queries.getCustomerByEmail, {
        email: email,
      });
      console.log("getCustomerByEmail query fired");
      const { floorplans, ...customer } = data.getCustomerByEmail.items[0];
      return customer;
    }
  );

  console.log("useGetCustomerByEmail result:", data);
  return { customer: data, error, isLoading, refetch };
};

export const useGetCustomer = (id: string) => {
  const { data, error, isLoading, refetch } = useQuery<Customer | undefined>(`getCustomer-${id}`, async () => {
    const data = await gqlOperation<GetCustomerQuery>(queries.getCustomer, {
      id: id,
    });
    console.log("useGetCustomer query fired");
    return mapGetCustomerQuery(data.getCustomer);
  });

  console.log("useGetCustomer result:", data);
  return { customer: data, error, isLoading, refetch };
};

export const useGetCustomers = () => {
  const { data, error, isLoading, refetch } = useQuery<Customer[] | undefined>(
    "listCustomersBasic",
    async () => {
      const data = await gqlOperation<ListCustomersQuery>(advanced.listCustomersBasic);

      console.log("listCustomers query fired");
      return mapListCustomersQuery(data.listCustomers);
    },
    {
      refetchInterval: false,
      // If set to `false`, the query will not refetch on window focus.
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  console.log("listCustomers query result:", data);
  return { customers: data, error, isLoading, refetch };
};

export const useCreateCustomer = () => {
  const { mutateAsync: createCustomer } = useMutation((customer: CreateCustomerInput) =>
    gqlOperation<CreateCustomerMutation>(mutations.createCustomer, {
      input: customer,
    })
  );

  return { createCustomer };
};

export const useUpdateCustomer = () => {
  const { mutateAsync: updateCustomer } = useMutation((customer: UpdateCustomerInput) =>
    gqlOperation<UpdateCustomerMutation>(mutations.updateCustomer, {
      input: customer,
    })
  );

  return { updateCustomer };
};
