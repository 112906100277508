import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Stepper, Step, StepLabel, Button, Box, Grid } from "@material-ui/core";
import StepperIcons from "../../components/checkout/StepperIcons";
import StepConnector from "../../components/checkout/StepConnector";
import CheckoutUpload from "./CheckoutUpload";
import CheckoutCrop from "./CheckoutCrop";
import CheckoutOverview from "./CheckoutOverview";
import CheckoutFinalization from "./CheckoutFinalization";
import CheckoutScaling from "./CheckoutScaling";
import { OriginalInputType } from "models";

import { Event } from "../../hooks/analytics/GoogleAnalytics";
import * as analytics from "../../hooks/analytics/GoogleAnalytics";

// OVERALL STYLE
const style = makeStyles((theme) => ({
  mainBox: {
    position: "relative",
    marginTop: "-8px",
    padding: "10px 20px 20px 20px",
    borderBottomRightRadius: "14px",
    borderBottomLeftRadius: "14px",
    background: theme.palette.background.paper,
  },
  stepper: {
    height: "calc(10vh - 40px)",
    minHeight: "55px",
    background: theme.palette.common.black,
    borderTopLeftRadius: "14px",
    borderTopRightRadius: "14px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
}));

type Props = {
  inputType?: OriginalInputType;
};

const checkoutSteps = ["CheckoutUpload", "CheckoutCrop", "CheckoutScaling", "CheckoutOverview", "CheckoutFinalization"];

const CheckoutSteps = ({ inputType }: Props) => {
  const classes = style();
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();

  // analytics
  useEffect(() => {
    const event: Event = {
      category: "Checkout",
      action: "checkoutStep_" + checkoutSteps[activeStep],
      label: checkoutSteps[activeStep],
    };
    analytics.sendEvent(event);
  }, [activeStep]);

  const StepContent = ({ step }) => {
    switch (step) {
      case 0:
        return <CheckoutUpload inputType={inputType} />;
      case 1:
        return <CheckoutCrop />;
      case 2:
        return <CheckoutScaling />;
      case 3:
        return <CheckoutOverview />;
      case 4:
        return <CheckoutFinalization />;
      default:
        return <></>;
    }
  };

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  return (
    <>
      <Stepper alternativeLabel className={classes.stepper} connector={<StepConnector />} activeStep={activeStep}>
        {/* Change the number of loops here based on StepContent */}
        {[1, 2, 3, 4].map((e) => (
          <Step key={e}>
            <StepLabel StepIconComponent={StepperIcons} />
          </Step>
        ))}
      </Stepper>
      <Box className={classes.mainBox}>
        <form
          autoComplete="off"
          className={classes.form}
          onSubmit={(e) => {
            e.preventDefault();
            handleNext();
          }}
        >
          <Grid container spacing={3}>
            <StepContent step={activeStep} />

            <Grid container item justify={activeStep === 0 ? "space-between" : "flex-end"}>
              {activeStep === 0 && (
                <Button
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  Abbrechen
                </Button>
              )}
              {activeStep !== 0 && activeStep !== 4 && <Button onClick={handleBack}>Zurück</Button>}
              {activeStep !== 4 && (
                <Button variant="contained" color="primary" type="submit">
                  {activeStep !== 3 ? "Speichern & Weiter" : "Zahlungspflichtig bestellen"}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CheckoutSteps;
