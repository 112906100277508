import React from "react";
import { Radio, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import { useIsShowcase } from "providers/AuthContextProvider";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: "8px",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 3,
    display: "flex",
    alignItems: "center",
  },
}));

const ProductCard = ({ product, selected, handleSelect }) => {
  const classes = useStyles();
  const isShowcase = useIsShowcase(); // hide price for showcases

  return (
    <Card className={classes.card} elevation={3}>
      <CardActionArea onClick={() => handleSelect(product.key)}>
        <CardContent className={classes.cardContent}>
          <Grid container direction={"column"}>
            <Typography variant="h5" component="h2">
              {product.name}
            </Typography>
            <Grid container alignItems={"center"}>
              <Typography variant={"body2"}>{product.description}</Typography>
            </Grid>
            <Grid container alignItems={"center"}>
              <Radio required checked={selected === product} color={"primary"} />
              {!isShowcase && <Typography variant="h5">{product.price}€</Typography>}
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ProductCard;
