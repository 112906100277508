import React, { useState } from "react";
import { Checkbox, FormControlLabel, Grid, RadioGroup, Link } from "@material-ui/core";
import { Level } from "../../models";
import ProductCard from "components/checkout/ProductCard";
import { useStateValue } from "./context/CheckoutStateContext";
import { CheckoutActionTypes } from "./context/CheckoutReducer";
import usePreviousPayments from "hooks/checkout/PreviousPaymentsHook";

// TODO: Get prices for products from stripe
// Note: prices in stripe test and live environment can differ
const products = [
  {
    key: 0,
    name: "Basic 2D",
    description: "2D Grundriss (schwarz/weiß)",
    priceId: Level.BASIC,
    price: "9,95",
  },
  {
    key: 1,
    name: "Basic 2D Plus",
    description: "2D Grundriss mit Möblierung (schwarz/weiß/farbig)",
    priceId: Level.BASIC_PLUS,
    price: "14,95",
  },
  {
    key: 2,
    name: "Professional 3D",
    description: "3D Grundriss möbliert mit 5 Perspektivansichten",
    priceId: Level.PROFESSIONAL,
    price: "24,95",
  },
];

const CheckoutProducts = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = useStateValue();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const { paymentMethods } = usePreviousPayments();
  const [checkPreviousPayment, setCheckPreviousPayment] = useState(false);

  const termsLink = (
    <div>
      <p>
        hiermit stimme ich den <br />
        <Link
          href={`https://vloor-logo-bucket.s3.eu-central-1.amazonaws.com/terms_and_conditions/AGB+Draft_HK_210308_clean.docx.pdf`}
          target="_blank"
        >
          Allgemeinen Geschäftsbedingungen
        </Link>
        <br />
        zu
      </p>
    </div>
  );

  const handleSelect = (key) => {
    setSelectedProduct(products[key]);
    dispatch({
      type: CheckoutActionTypes.editProductId,
      key: "product_id",
      value: products[key].priceId,
    });
  };

  const handleAcceptTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptedTerms(event.target.checked);
    dispatch({
      type: CheckoutActionTypes.acceptedTerms,
      key: "accepted_terms",
      value: acceptedTerms,
    });
  };

  return (
    <RadioGroup>
      <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
        {products.map((product, index) => {
          return (
            <Grid container xs={6} key={product.key}>
              <ProductCard key={product.key} product={product} selected={selectedProduct} handleSelect={handleSelect} />
            </Grid>
          );
        })}

        <Grid container xs={8}>
          <FormControlLabel
            control={
              <Checkbox
                required
                checked={acceptedTerms}
                onChange={handleAcceptTerms}
                name="checkedTerms"
                color="primary"
              />
            }
            label={termsLink}
          />
          {paymentMethods.length > 0 && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkPreviousPayment}
                  onChange={() => {
                    setCheckPreviousPayment(!checkPreviousPayment);
                    dispatch({
                      type: CheckoutActionTypes.reusePaymentMethod,
                      key: "paymentMethod",
                      value: !checkPreviousPayment,
                    });
                  }}
                  name="previousPayment"
                  color="primary"
                />
              }
              label={`Karte mit Endung ${paymentMethods[0].last4} wiederverwenden`}
            />
          )}
        </Grid>
      </Grid>
    </RadioGroup>
  );
};

export default CheckoutProducts;
