import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import AuthContextProvider from "./providers/AuthContextProvider";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import Amplify from "aws-amplify";
import config from "./aws-exports";
import { AmazonAIPredictionsProvider } from "@aws-amplify/predictions";
import { floorplannerAPI, FLOORPLANNER_API_NAME } from "./constants";

// added handling of different redirect urls for social login providers based on:
// https://github.com/aws-amplify/amplify-js/issues/5127
// and https://github.com/arelaxtest/amplify-auth-demo/blob/master/src/index.js
var urlsSignIn = config.oauth.redirectSignIn.split(",");
var urlsSignOut = config.oauth.redirectSignOut.split(",");
const oauth = {
  domain: config.oauth.domain,
  scope: config.oauth.scope,
  redirectSignIn: config.oauth.redirectSignIn,
  redirectSignOut: config.oauth.redirectSignOut,
  responseType: config.oauth.responseType,
};
var hasLocalhost = (hostname) =>
  Boolean(hostname.match(/localhost/) || hostname.match(/127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/));
var hasHostname = (hostname) => Boolean(hostname.includes(window.location.hostname));
var isLocalhost = hasLocalhost(window.location.hostname);
if (isLocalhost) {
  urlsSignIn.forEach((e) => {
    if (hasLocalhost(e)) {
      oauth.redirectSignIn = e;
    }
  });
  urlsSignOut.forEach((e) => {
    if (hasLocalhost(e)) {
      oauth.redirectSignOut = e;
    }
  });
} else {
  urlsSignIn.forEach((e) => {
    if (hasHostname(e)) {
      oauth.redirectSignIn = e;
    }
  });
  urlsSignOut.forEach((e) => {
    if (hasHostname(e)) {
      oauth.redirectSignOut = e;
    }
  });
}

var configUpdate = config;
configUpdate.oauth = oauth;

const environment = process.env.REACT_APP_ENV || "dev";

configUpdate.aws_cloud_logic_custom.push({
  name: FLOORPLANNER_API_NAME,
  endpoint: floorplannerAPI[environment],
  region: "eu-central-1",
});

Amplify.configure(configUpdate);

Amplify.addPluggable(new AmazonAIPredictionsProvider());

// deactivate logging in prod environment
if (environment === "prod") {
  console.log = function () {};
}

ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY} language="de-AT">
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </GoogleReCaptchaProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
