import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider, useQueryErrorResetBoundary } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Button, CssBaseline, MuiThemeProvider, NoSsr, StylesProvider } from "@material-ui/core";
import { ErrorBoundary } from "react-error-boundary";
import { ThemeProvider } from "styled-components";
import { LoadingAnimationMain } from "./components/layout/LoadingAnimations";
import Contact from "./container/Contact";
import Dashboard from "./container/dashboard/Dashboard";
import EmptyLayout from "container/layout/EmptyLayout";
import { useAuthState } from "./providers/AuthContextProvider";
import { ToastProvider } from "./providers/ToastProvider";
import { DetailRoute } from "./routes/DetailRoute";
import { CheckoutRoute } from "./routes/CheckoutRoute";
import { vloorTheme } from "./Theme";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CheckoutProvider } from "./providers/CheckoutContextProvider";
import Authenticator from "container/auth/Authenticator";
import AuthUIStateProvider from "components/auth/context/AuthUIStateContext";
import CustomerContextProvider from "providers/CustomerContextProvider";
import { useAnalytics, usePageViewTracking } from "./hooks/analytics/GoogleAnalytics";
import SettingsPage from "./container/SettingsPage";

import { ViewRoute } from "./routes/ViewRoute";
import { ShareRoute } from "routes/ShareRoute";
import { EstateRoute } from "routes/EstateRoute";
import { NotFoundRoute } from "routes/NotFoundRoute";
import { StoreProvider } from "providers/Store";
import { VurnishedRoute } from "routes/VurnishedRoute";
import WideLayout from "container/layout/WideLayout";
import Layout from "container/layout/Layout";

import { Helmet } from "react-helmet";
import { MsClarityScript } from "./scripts/MsClarityScript";
import { AIPreviewRoute } from "routes/AIPreviewRoute";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_TOKEN);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: 60000,
      suspense: true,
    },
  },
});

const showCaptchaBadge = (show: boolean) => {
  const badge = document.getElementsByClassName("grecaptcha-badge")[0];
  if (badge && badge instanceof HTMLElement) {
    badge.style.visibility = show ? "visible" : "hidden";
  }
};

// common service-providers & wrappers we need, regardless of logged-in or not
export const CommonProviders = ({ children }) => (
  <React.StrictMode>
    <BrowserRouter>
      <StylesProvider injectFirst>
        <NoSsr>
          <MuiThemeProvider theme={vloorTheme}>
            <ThemeProvider theme={vloorTheme}>
              <CssBaseline />
              <Elements stripe={stripePromise}>
                <ToastProvider>{children}</ToastProvider>
              </Elements>
            </ThemeProvider>
          </MuiThemeProvider>
        </NoSsr>
      </StylesProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// extracted Routes component to useGoogleAnalytics on page views
const Routes = () => {
  const location = useLocation();
  usePageViewTracking(location);
  showCaptchaBadge(false);

  // redirect from "/" to "/dashboard" does not work correctly, see https://floorplan.atlassian.net/browse/FA-733
  return (
    <Switch>
      <Route path="/checkout" component={CheckoutRoute} />
      <Route exact={true} path="/">
        <Redirect to="/dashboard" />
      </Route>
      <Route path={["/dashboard", "/settings", "/detail", "/view", "/contact"]}>
        <Layout>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/detail" component={DetailRoute} />
          <Route path="/contact" component={Contact} />
          <Route path="/settings" component={SettingsPage} />
          <Route path="/view" component={ViewRoute} />
        </Layout>
      </Route>
      <Route path={["/vurnished", "/share", "/estate", "/ai"]}>
        <WideLayout>
          <Route path="/share" component={ShareRoute} />
          <Route path="/vurnished" component={VurnishedRoute} />
          <Route path="/estate" component={EstateRoute} />
          <Route path="/ai" component={AIPreviewRoute} />
        </WideLayout>
      </Route>

      <Route component={NotFoundRoute} />
    </Switch>
  );
};

const PublicRoutes = () => {
  const location = useLocation();
  usePageViewTracking(location);
  showCaptchaBadge(true);

  // two seperate matches for "/" and "/login" needed, redirect causes wrong behaviour
  return (
    <Switch>
      <Route exact={true} path="/" component={Authenticator} />
      <Route exact={true} path="/login" component={Authenticator} />
      <Route path="/register" component={Authenticator} />
      <Route path="/resetpassword" component={Authenticator} />
      <Route path={["/vurnished", "/share", "/estate", "/ai"]}>
        <WideLayout>
          <Route path="/share" component={ShareRoute} />
          <Route path="/vurnished" component={VurnishedRoute} />
          <Route path="/estate" component={EstateRoute} />
          <Route path="/ai" component={AIPreviewRoute} />
        </WideLayout>
      </Route>
      <Route component={NotFoundRoute} />
    </Switch>
  );
};

const App = () => {
  const { reset } = useQueryErrorResetBoundary();
  const auth = useAuthState();

  // init GoogleAnalytics
  useAnalytics();

  // Invalidate cache on logout/login
  useEffect(() => {
    if (!auth.user) {
      const invalidate = async () => {
        await queryClient.clear();
      };
      invalidate();
      console.log("cache invalidated");
    }
  }, [auth.user]);

  // TODO: Refine Error-Msg
  const ErrorMessage = ({ resetErrorBoundary }) => (
    <EmptyLayout>
      Ups! There was an error!
      <Button color="primary" variant="contained" onClick={() => resetErrorBoundary()}>
        Try again
      </Button>
    </EmptyLayout>
  );

  return (
    <CommonProviders>
      <Helmet>
        <script type="text/javascript">{MsClarityScript}</script>
      </Helmet>
      {!auth.loading &&
        (auth.user ? (
          <QueryClientProvider client={queryClient}>
            <ErrorBoundary onReset={reset} fallbackRender={ErrorMessage}>
              <Suspense fallback={<LoadingAnimationMain />}>
                <CustomerContextProvider>
                  <CheckoutProvider>
                    <StoreProvider>
                      <Routes />
                    </StoreProvider>
                  </CheckoutProvider>
                </CustomerContextProvider>
              </Suspense>
            </ErrorBoundary>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        ) : (
          <AuthUIStateProvider>
            <PublicRoutes />
          </AuthUIStateProvider>
        ))}
    </CommonProviders>
  );
};

export default App;
