import { Grid, TextField, Typography } from "@material-ui/core";
import styled from "styled-components";
import React, { useState, useEffect } from "react";

import { useStateValue } from "./context/CheckoutStateContext";
import { CheckoutActionTypes } from "./context/CheckoutReducer";
import { NumberFormatCentimeters } from "../../components/util/NumberFormatUtil";
import CanvasScaling from "components/scaling/CanvasScaling";

const FormContainer = styled.div`
  padding-top: ${(p) => p.theme.spacing(8)}px;
`;

const CheckoutScaling = () => {
  const [croppedImage, setCroppedImage] = useState<string>();
  const [{ formValues, croppedImageFile, originalImageFile }, dispatch] = useStateValue();

  const onScale = (value) => {
    dispatch({
      type: CheckoutActionTypes.editFormValue,
      key: "user_scaling_selected_px",
      value: value,
    });
    console.log("dispatched the distance: ", value);
  };

  // load cropped image file
  useEffect(() => {
    console.log("CroppedImageFile: ", croppedImageFile);
    if (croppedImageFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setCroppedImage(reader.result as any);
      };
      reader.readAsDataURL(croppedImageFile);
    }
  }, [croppedImageFile, originalImageFile]);

  return (
    <>
      <Grid item xs={12} md={12}>
        <FormContainer>
          <Grid container justify="center" alignItems="center" spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2" align="center">
                Bitte markieren Sie eine bekannte Länge im Bild. (z.B.: Türbreite)
              </Typography>
              <Typography align="center">
                Dafür bitte zwei Punkte auswählen und die Breite in Zentimeter eintragen
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {croppedImage ? (
                <CanvasScaling image={croppedImage} onScale={onScale} />
              ) : (
                <Typography gutterBottom variant="h5" component="h2">
                  Kein Grundriss hochgeladen, bitte gehen Sie zurück zu Schritt 1.
                </Typography>
              )}
            </Grid>

            <Grid item>
              <TextField
                required
                id="user_scaling_cm"
                label="Bekannte Länge (cm)"
                name="user_scaling_cm"
                value={formValues?.user_scaling_cm ?? ""}
                onChange={(e) =>
                  dispatch({
                    type: CheckoutActionTypes.editFormValue,
                    key: "user_scaling_cm",
                    value: e.target.value,
                  })
                }
                InputProps={{
                  inputComponent: NumberFormatCentimeters as any,
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </FormContainer>
      </Grid>
    </>
  );
};

export default CheckoutScaling;
