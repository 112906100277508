import React, { createContext, useReducer, useContext } from "react";
import { createPortal } from "react-dom";
import { Color } from "@material-ui/lab";
import Toast from "../components/layout/Toast";
import ActionMap from "../utils/ActionMap";

export type ToastActions = ActionMap<ActionPayload>[keyof ActionMap<ActionPayload>];

type ToastState = {
  isVisible: boolean;
  severity: Color;
  content: string;
};

export enum Types {
  showToast = "SHOW_TOAST",
  closeToast = "CLOSE_TOAST",
}

type ActionPayload = {
  [Types.showToast]: {
    severity: Color;
    content: string;
  };
  [Types.closeToast]: {
    isVisible: boolean;
  };
};

const initialState: ToastState = {
  isVisible: false,
  severity: "info",
  content: "",
};

export const ToastContext = createContext<{
  toast: ToastState;
  dispatch: React.Dispatch<any>;
}>({ toast: initialState, dispatch: () => null });

const toastReducer = (state: ToastState, action: ToastActions) => {
  switch (action.type) {
    case Types.showToast:
      return {
        isVisible: true,
        severity: action.payload.severity,
        content: action.payload.content,
      };
    case Types.closeToast:
      return {
        ...state,
        isVisible: false,
      };
    default:
      return state;
  }
};

export const ToastProvider = (props) => {
  const [toast, dispatch] = useReducer(toastReducer, initialState);
  return (
    <ToastContext.Provider value={{ toast, dispatch }}>
      {props.children}

      {createPortal(<Toast toast={toast} />, document.body)}
    </ToastContext.Provider>
  );
};

export const useToastContext = () => {
  return useContext(ToastContext);
};

export const useToastProvider = () => {
  const { toast, dispatch } = useToastContext();
  return {
    isVisible: toast.isVisible,
    showToast: (toast) => {
      dispatch({ type: Types.showToast, payload: toast });
    },
    showSuccessToast: (message: string) => {
      dispatch({ type: Types.showToast, payload: { severity: "success", content: message } });
    },
    showErrorToast: (message: string) => {
      dispatch({ type: Types.showToast, payload: { severity: "error", content: message } });
    },
    showInfoToast: (message: string) => {
      dispatch({ type: Types.showToast, payload: { severity: "info", content: message } });
    },
    closeToast: () => {
      dispatch({ type: Types.closeToast });
    },
  };
};
