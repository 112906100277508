import React, { useContext } from "react";
import { Grid, IconButton } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { furnituresWithCategory } from "./mockData/furniture";
import { Furniture } from "./mockData/types";
import { FurnitureConfigContext, FurnitureActionTypes } from "providers/FurnitureConfigContextProvider";
import ProductImage from "./ProductImage";

type PropTypes = {
  swapping: boolean;
  onFinished: Function;
  furniture: Furniture;
};

const FurnitureSwapper: React.FC<PropTypes> = ({ swapping, onFinished, furniture }) => {
  const similarFurniture = furnituresWithCategory(furniture.category);
  const [, dispatch] = useContext(FurnitureConfigContext);

  const dispatchFurnitureSwap = (fn: Furniture) => {
    dispatch({ type: FurnitureActionTypes.SWAP_FURNITURE, oldFurniture: furniture, newFurniture: fn });
  };

  return (
    <Grid item xs={12} style={{ display: swapping ? "block" : "none" }}>
      <Grid container spacing={3} justify="space-between" alignItems="center">
        <Grid item xs={2}>
          <IconButton aria-label="exchange" color="primary" onClick={() => onFinished()}>
            <CheckIcon />
          </IconButton>
        </Grid>
        {similarFurniture &&
          similarFurniture.map((fn: Furniture) => (
            <Grid item xs={3} key={fn.id}>
              <ProductImage img={fn.img} selected={fn.id === furniture.id} onClick={() => dispatchFurnitureSwap(fn)} />
            </Grid>
          ))}
        <Grid item xs={1} />
      </Grid>
    </Grid>
  );
};

export default FurnitureSwapper;
