import React from "react";
import { Container } from "@material-ui/core";
import EditorFloorplanList from "../../components/dashboard/EditorFloorplanList";
import { ContentHeader, FlrPaper, Headline } from "../../components/layout/StyledComponents";
import { useGetFloorplans } from "../../hooks/floorplan/GetFloorplanHooks";
// import { getLastMonthDateString } from "utils/DateUtils";

const EditorListView: React.FC<{}> = () => {
  // TODO find out why not all new floor plans are shown with this query
  // const now = useRef(new Date());
  // const lastMonth = getLastMonthDateString(now.current);
  // const { isLoading, floorplans, refetch } = useGetFloorplansAfterDate(lastMonth);

  const { isLoading, floorplans, refetch } = useGetFloorplans(100);

  return (
    <Container maxWidth={false}>
      <ContentHeader>
        <Headline variant="h4" component="h1">
          Editor Dashboard
        </Headline>
      </ContentHeader>
      <FlrPaper elevation={3}>
        {!isLoading && <EditorFloorplanList floorplans={floorplans} refetchEntries={refetch} />}
      </FlrPaper>
    </Container>
  );
};

export default EditorListView;
