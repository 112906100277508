import React, { useEffect, useState, useContext } from "react";
import { Hub, Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import { CognitoUserSession, CognitoIdToken, CognitoAccessToken } from "amazon-cognito-identity-js";

/*
 * Custom attributes type defined according to the attributes used in this app
 * see: https://github.com/aws-amplify/amplify-js/issues/4927
 */
export interface UserAttributes {
  sub: string;
  email: string;
  email_verified: string;
  phone_number: string;
  phone_number_verified: string;
  name: string;
  updated_at: string;
  "custom:bytesQuota": string;
  "custom:bytesUsed": string;
}

// adapted Provider from: https://itnext.io/creating-reusable-abstractions-with-amplify-and-react-hooks-97784c8b5c2a
// also look at: https://gist.github.com/bodokaiser/a6377f5cecf6344cd131dce97694a2ad
interface AuthProps {
  children: React.ReactNode;
}

export interface AuthState {
  state: string;
  loading: boolean;
  user: CognitoUser;
}
const defaultState: AuthState = { state: "signOut", user: undefined, loading: false };

export const AuthContext = React.createContext<AuthState>(defaultState);

const AuthContextProvider = ({ children }: AuthProps) => {
  const auth = useAuth();

  // Make sure to not force a re-render on the components that are reading these values,
  // unless the `user` (inside auth) value has changed. This is an optimisation that is mostly needed in cases
  // where the parent of the current component re-renders and thus the current component is forced
  // to re-render as well. If it does, we want to make sure to give the `AuthContext.Provider` the
  // same value as long as the user data is the same. If you have multiple other "controller"
  // components or Providers above this component, then this will be a performance booster.
  const memoAuth = React.useMemo(() => auth, [auth]);

  return <AuthContext.Provider value={memoAuth}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;

export const useAuth = (): AuthState => {
  const [auth, setAuth] = useState<AuthState>(defaultState);

  useEffect(() => {
    setAuth({ state: "signOut", user: null, loading: true });
    Auth.currentAuthenticatedUser()
      .then((user: CognitoUser) => {
        setAuth({ state: "signIn", user, loading: false });
      })
      .catch((err) => {
        console.log("No signed in user.", err);
        setAuth({ state: "signOut", user: null, loading: false });
      });
  }, []);

  useEffect(() => {
    Hub.listen("auth", ({ payload }) => {
      const { event, data } = payload;
      switch (event) {
        case "signIn":
          console.log("signIn");
          return setAuth({ state: event, user: data, loading: false });
        case "signOut":
          console.log("signOut");
          return setAuth({ state: event, user: null, loading: false });
      }
    });
  }, []);

  return auth;
};

// We also create a simple custom hook to read these values from. We want our React components
// to know as little as possible on how everything is handled, so we are not only abstracting them from
// the fact that we are using React's context, but we also skip some imports.
export const useAuthState = () => {
  const auth = useContext(AuthContext);
  if (auth === undefined) {
    throw new Error("`useAuthState` hook must be used within a `AuthContextProvider` component");
  }
  return auth;
};

export const useUser = () => {
  const user: CognitoUser = useAuthState().user;
  if (!user)
    return {
      user: undefined,
      userAttributes: undefined,
      userSession: undefined,
      userIdToken: undefined,
      userAccessToken: undefined,
      userGroups: undefined,
    };

  // See https://github.com/aws-amplify/amplify-js/issues/4927
  // @ts-ignore
  const { attributes } = user;
  const userAttributes: UserAttributes = attributes;
  const userSession: CognitoUserSession = user.getSignInUserSession();
  user.setSignInUserSession(userSession);
  const userIdToken: CognitoIdToken = userSession.getIdToken();
  const userAccessToken: CognitoAccessToken = userSession.getAccessToken();
  const userGroups = userAccessToken.payload["cognito:groups"];

  return {
    user,
    userAttributes,
    userSession,
    userIdToken,
    userAccessToken,
    userGroups,
  };
};

// This may be bad practice. maybe extract it to seperate util file
export const useIsEditor = () => {
  const { userGroups } = useUser();
  return userGroups?.includes("Editors");
};

export const useIsDeveloper = () => {
  const { userGroups } = useUser();
  return userGroups?.includes("Developers");
};

export const useIsShowcase = () => {
  const { userGroups } = useUser();
  return userGroups?.includes("Showcase");
};

export const useIsContractor = () => {
  const { userGroups } = useUser();
  return userGroups?.includes("Contractors");
};

export const useIsProfessional = () => {
  const { userGroups } = useUser();
  return userGroups?.includes("Professionals");
};
