// import ActionMap from "../../utils/ActionMap";

import { OriginalInputType } from "models";
import { Level } from "models";
import { CreateFloorplanInput } from "../../../API";

// export type CheckoutActions = ActionMap<ActionPayload>[keyof ActionMap<
//   ActionPayload
// >];

export enum CheckoutActionTypes {
  setOriginalInputType = "SET_ORIGINAL_INPUT_TYPE",
  editFormValue = "EDIT_FORM_VALUE",
  editOriginalImageFile = "EDIT_ORIGINAL_IMAGE",
  editCroppedImageFile = "EDIT_CROPPED_IMAGE",
  editPreprocessedImageFile = "EDIT_PREPROCESSED_IMAGE",
  editProductId = "EDIT_PRODUCT_ID",
  acceptedTerms = "ACCEPTED_TERMS",
  reusePaymentMethod = "REUSE_PAYMENT",
  resetCheckoutContext = "RESET_CHECKOUT_CONTEXT",
}

export type CheckoutState = {
  formValues: CreateFloorplanInput;
  originalImageFile: File;
  croppedImageFile: File;
  preprocessedImageFile: File;
  productId: Level;
  acceptedTerms: boolean;
  reusePaymentMethod: boolean;
  originalInputType: OriginalInputType;
};

const initialState: CheckoutState = {
  formValues: {
    name: "",
    address: "",
    description: "",
    user_total_size: 0,
    user_scaling_selected_px: 0,
    user_scaling_cm: 0,
  },
  originalImageFile: null,
  preprocessedImageFile: null,
  croppedImageFile: null,
  productId: null,
  acceptedTerms: false,
  reusePaymentMethod: false,
  originalInputType: OriginalInputType.SKETCH,
};

const reducer = (state: CheckoutState, action) => {
  switch (action.type) {
    case CheckoutActionTypes.editFormValue:
      state.formValues[action.key.toLowerCase()] = action.value;
      return { ...state };

    case CheckoutActionTypes.editOriginalImageFile:
      state.originalImageFile = action.value;
      return { ...state };

    case CheckoutActionTypes.editCroppedImageFile:
      state.croppedImageFile = action.value;
      return { ...state };

    case CheckoutActionTypes.editPreprocessedImageFile:
      state.preprocessedImageFile = action.value;
      return { ...state };

    case CheckoutActionTypes.reusePaymentMethod:
      state.reusePaymentMethod = action.value;
      return { ...state };

    case CheckoutActionTypes.editProductId:
      state.productId = action.value;
      return { ...state };

    case CheckoutActionTypes.acceptedTerms:
      state.acceptedTerms = action.value;
      return { ...state };

    case CheckoutActionTypes.setOriginalInputType:
      state.originalInputType = action.value;
      return { ...state };

    case CheckoutActionTypes.resetCheckoutContext:
      state.formValues.name = "";
      state.formValues.address = "";
      state.formValues.description = "";
      state.formValues.user_total_size = 0;
      state.formValues.user_scaling_selected_px = 0;
      state.formValues.user_scaling_cm = 0;
      state.originalImageFile = null;
      state.preprocessedImageFile = null;
      state.croppedImageFile = null;
      state.productId = null;
      state.acceptedTerms = false;
      state.reusePaymentMethod = false;
      state.originalInputType = OriginalInputType.SKETCH;
      return { ...state };

    default:
      return state;
  }
};

export { initialState, reducer };
