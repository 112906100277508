import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { FloorplanFormikValues } from "../../hooks/floorplan/FloorplanFormikHook";
import styled from "styled-components";

type Props = {
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: FloorplanFormikValues;
};
const EditForm = styled.form`
  padding-top: ${(p) => p.theme.spacing(2)}px;
`;
const DetailInfoGrid = styled(Grid)`
  margin-bottom: ${(p) => p.theme.spacing(4)}px;
`;

const DetailInfo = ({ handleChange, values }: Props) => {
  return (
    <DetailInfoGrid item xs={12}>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Typography variant="h5" component="h2" align="center">
            Details
          </Typography>
        </Grid>
      </Grid>

      <EditForm>
        <Grid container direction="column" spacing={4}>
          <Grid item xs={12}>
            <TextField
              required
              id="name"
              label="Name"
              name="name"
              value={values.name || ""}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="address"
              name="address"
              label="Adresse"
              value={values.address || ""}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="description"
              name="description"
              label="Anmerkungen"
              value={values.description || ""}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </EditForm>
    </DetailInfoGrid>
  );
};

export default DetailInfo;
