import { Button, Grid } from "@material-ui/core";
import React, { useState, useCallback, useEffect } from "react";
import { ZoomIn, ZoomOut, ArrowDropUp, ArrowDropDown, ArrowLeft, ArrowRight } from "@material-ui/icons";

type Props = {
  image: any;
  onScale: (value) => void;
};

const CanvasScaling: React.FC<Props> = ({ image, onScale }) => {
  const [isFirstClick, setFirstClick] = useState(true);
  const [pointA, setPointA] = useState({ x: 0, y: 0 });

  const [zoomFactor, setZoomFactor] = useState(1);
  const [zoomed, setZoomed] = useState(false);
  const [canvasX, setCanvasX] = useState(0);
  const [canvasY, setCanvasY] = useState(0);

  const [viewFactor, setViewFactor] = useState(1);

  const getDistance = (A, B) => {
    var xDiff: number = A.x - B.x;
    var yDiff: number = A.y - B.y;

    return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
  };

  const scaledDistance = (distance) => {
    return distance / viewFactor;
  };

  const drawImageOnCanvas = useCallback(
    (img, canvas, ctx) => {
      canvas.width = img.width * viewFactor;
      //set width of canvas to image width times factor of height scaling
      ctx.drawImage(
        img,
        canvasX,
        canvasY,
        img.width / zoomFactor,
        img.height / zoomFactor,
        0,
        0,
        canvas.width, // * window.devicePixelRatio,
        canvas.height //* window.devicePixelRatio
      );
    },
    [canvasX, canvasY, viewFactor, zoomFactor]
  );

  // handle mouse movement after first click
  const mouseMove = (event) => {
    if (!isFirstClick) {
      const x = event.nativeEvent.offsetX;
      const y = event.nativeEvent.offsetY;
      const img = new Image();
      img.src = image;
      img.onload = () => {
        var canvas = document.getElementById("croppedCanvas") as HTMLCanvasElement;
        var ctx = canvas.getContext("2d");
        ctx.imageSmoothingEnabled = false;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.beginPath();
        drawImageOnCanvas(img, canvas, ctx);
        ctx.lineWidth = 4;
        ctx.strokeStyle = "#CC0007";
        ctx.moveTo(pointA.x, pointA.y);
        ctx.lineTo(x, y);
        ctx.stroke();
      };
    }
  };

  // handle scaling click
  const handleClick = (event) => {
    const x = event.nativeEvent.offsetX;
    const y = event.nativeEvent.offsetY;
    if (isFirstClick) {
      setPointA({ x, y });
      setFirstClick(false);
    } else {
      setFirstClick(true);
      const distance = getDistance(pointA, { x, y });
      const scalingSelectedPixel = Math.trunc(scaledDistance(distance / zoomFactor));
      onScale(scalingSelectedPixel);
    }
  };

  // set croppedImage to canvas croppedCanvas
  useEffect(() => {
    if (image) {
      const img = new Image();
      img.src = image;
      img.onload = () => {
        var canvas = document.getElementById("croppedCanvas") as HTMLCanvasElement;
        var ctx = canvas.getContext("2d");
        ctx.imageSmoothingEnabled = false;

        const factor = canvas.height / img.height;
        setViewFactor(factor);
        drawImageOnCanvas(img, canvas, ctx);
      };
    }
  }, [image, zoomFactor, canvasX, canvasY, drawImageOnCanvas]);

  return (
    <Grid container justify={"center"}>
      <canvas
        id="croppedCanvas"
        height="600"
        style={{ border: "1px solid black", cursor: "crosshair" }}
        onClick={handleClick}
        onMouseMove={mouseMove}
      />
      <Grid item style={{ position: "absolute", left: 33 }}>
        <Grid container justify="center">
          <Button
            variant="contained"
            disabled={!zoomed}
            color="secondary"
            onClick={() => {
              setCanvasY(canvasY - 50);
            }}
          >
            <ArrowDropUp />
          </Button>
        </Grid>
        <Grid container justify="center">
          <Button
            variant="contained"
            disabled={!zoomed}
            color="secondary"
            onClick={() => {
              setCanvasX(canvasX - 50);
            }}
          >
            <ArrowLeft />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setZoomFactor(zoomed ? 1 : 2);
              setZoomed(!zoomed);
              setCanvasX(0);
              setCanvasY(0);
            }}
          >
            {zoomed ? <ZoomOut /> : <ZoomIn />}
          </Button>
          <Button
            variant="contained"
            disabled={!zoomed}
            color="secondary"
            onClick={() => {
              setCanvasX(canvasX + 50);
            }}
          >
            <ArrowRight />
          </Button>
        </Grid>
        <Grid container justify="center">
          <Button
            variant="contained"
            size="small"
            disabled={!zoomed}
            color="secondary"
            onClick={() => {
              setCanvasY(canvasY + 50);
            }}
          >
            <ArrowDropDown />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CanvasScaling;
