import { Customer, Order, Floorplan } from "models";
//import { API } from "aws-amplify";
//import * as constants from "../../constants";
import { OrderMailTemplateType, SendOrderMailQuery } from "../../API";
import {} from "../../API";
import * as queries from "../../graphql/queries";
import { gqlOperation } from "../../hooks/GraphQLHooks";

// REST API call, has been replaced by GraphQL API call
// const sendMail = async (template: OrderMailTemplateType, payload) => {
//   try {
//     console.log("Sending mail via mailService...");
//     const response = await API.post(constants.MAIL_API_NAME, constants.MAIL_API_ENDPOINT, payload);
//     console.log("Response mailService: ", response);
//     return response;
//   } catch (err) {
//     console.error(err);
//   }
// };

const sendMailQuery = async (template: OrderMailTemplateType, payload) => {
  try {
    console.log("Sending mail via sendOrderMail query...");
    const orderMailInput = JSON.stringify(payload);
    const response = await gqlOperation<SendOrderMailQuery>(queries.sendOrderMail, {
      template,
      orderMailInput,
    });

    console.log("sendOrderMail response: ", response);
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const sendOrderConfirmation = async (customer: Customer, order: Order, floorplan: Floorplan) => {
  const payload = {
    body: {
      order: order,
      customer: customer,
      floorplan_id: floorplan.id,
      floorplan_name: floorplan.name,
    },
  };
  console.log("sendOrderConfirmation payload: ", payload);
  return await sendMailQuery(OrderMailTemplateType.ORDER_CONFIRMATION, payload);
};

export const sendOrderDeclined = async (customer: Customer, order: Order, floorplan: Floorplan) => {
  const payload = {
    body: {
      order: order,
      customer: customer,
      floorplan_id: floorplan.id,
      floorplan_name: floorplan.name,
    },
  };
  console.log("sendOrderDeclined payload: ", payload);
  return await sendMailQuery(OrderMailTemplateType.ORDER_DECLINED, payload);
};

export const sendQANotify = async (
  customer: Customer,
  order: Order,
  floorplan: Floorplan,
  contractor: string,
  message: string
) => {
  const payload = {
    body: {
      order: order,
      customer: customer,
      floorplan_id: floorplan.id,
      floorplan_name: floorplan.name,
      contractor: contractor,
      message,
    },
  };
  console.log("sendQANotify payload: ", payload);
  return await sendMailQuery(OrderMailTemplateType.ORDER_QA_NOTIFY, payload);
};

export const sendOrderProcessed = async (
  customer: Customer,
  order: Order,
  floorplan: Floorplan,
  downloadLinks?: string[]
) => {
  const payload = {
    body: {
      order: order,
      customer: customer,
      floorplan_id: floorplan.id,
      floorplan_name: floorplan.name,
      basic_link: downloadLinks?.[1],
      basic_plus_link: downloadLinks?.[2],
      professional_link: downloadLinks?.[3],
    },
  };

  console.log("sendOrderProcessing payload: ", payload);
  return await sendMailQuery(OrderMailTemplateType.ORDER_PROCESSED, payload);
};

export const sendNewOrder = async (customer: Customer, order: Order, floorplan: Floorplan) => {
  const payload = {
    body: {
      order: order,
      customer: customer,
      floorplan_id: floorplan.id,
      floorplan_name: floorplan.name,
    },
  };
  console.log("sendNewOrder payload: ", payload);
  return await sendMailQuery(OrderMailTemplateType.ORDER_NEW, payload);
};

export const sendChangeRequest = async (customer: Customer, order: Order, floorplan: Floorplan, message: string) => {
  const payload = {
    body: {
      order,
      customer,
      floorplan_id: floorplan.id,
      message,
    },
  };
  console.log("sendChangeRequest payload: ", payload);
  return await sendMailQuery(OrderMailTemplateType.ORDER_CHANGE_REQUEST, payload);
};

export const sendChangeResponse = async (customer: Customer, order: Order, floorplan: Floorplan, message: string) => {
  const payload = {
    body: {
      order,
      customer,
      floorplan_id: floorplan.id,
      floorplan_name: floorplan.name,
      message,
    },
  };
  console.log("sendChangeResponse payload: ", payload);
  return await sendMailQuery(OrderMailTemplateType.ORDER_CHANGE_RESPONSE, payload);
};
