/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Grid, Typography } from "@material-ui/core";
import { Modes } from "container/view/FloorplanViewer";
import IFrame from "container/view/IFrame";
import styled from "styled-components";
import { ROOMPLANNER_FLOORPLANNER_BASE_URL } from "../../constants";
import { VIEWER_FLOORPLANNER_BASE_URL } from "../../constants";
import RoomplannerActionBlocker from "container/view/RoomplannerActionBlocker";
import MuiRouterLink from "components/links/MuiRouterLink";

import rooms from "../vurnished/mockData/rooms";
import { FlrPaper } from "components/layout/StyledComponents";

type Props = {
  floorplannerId: string;
  mode: Modes;
};

const VurnishedAd = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 300px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  font-size: 16px;
  border-radius: 14px;
  margin: 10px;
  gap: 5px;
`;

const RoomCubeGridItem = styled(Grid)`
  &:hover {
    * > img {
      border: 2px solid ${(props) => props.theme.palette.primary.main};
    }
  }
`;

const RoomCube = styled.img`
  margin: 5px;
  border-radius: 14px;
  width: 100%;
  border: 2px solid transparent;
`;

const FloorplanSharer: React.FC<Props> = ({ floorplannerId, mode }) => {
  return mode === "roomplanner" ? (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12} lg={9} component={Box} alignSelf="flex-start">
        <Grid container>
          <Grid item xs={12} md={9}>
            <RoomplannerActionBlocker />
          </Grid>
          <Grid item xs={12}>
            <IFrame link={`${ROOMPLANNER_FLOORPLANNER_BASE_URL}${floorplannerId}`} key={mode} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} lg={3}>
        <FlrPaper>
          <Grid container spacing={3} direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                Einrichtungsvorschläge
              </Typography>
            </Grid>

            <RoomCubeGridItem item xs={4} lg={12}>
              <MuiRouterLink to={`/vurnished/?selected=1&id=${floorplannerId}`}>
                <RoomCube src={rooms[1].img} alt="furnished room" />
                <Typography align="center">Zum Wohnzimmer</Typography>
              </MuiRouterLink>
            </RoomCubeGridItem>
            <RoomCubeGridItem item xs={4} lg={12}>
              <MuiRouterLink to={`/vurnished?selected=2&id=${floorplannerId}`}>
                <RoomCube src={rooms[2].img} alt="furnished room" />
                <Typography align="center">Zum Schlafzimmer</Typography>
              </MuiRouterLink>
            </RoomCubeGridItem>
            <RoomCubeGridItem item xs={4} lg={12}>
              <MuiRouterLink to={`/vurnished?selected=3&id=${floorplannerId}`}>
                <RoomCube src={rooms[3].img} alt="furnished room" />
                <Typography align="center">Zum Esszimmer</Typography>
              </MuiRouterLink>
            </RoomCubeGridItem>
          </Grid>
        </FlrPaper>
      </Grid>
    </Grid>
  ) : (
    <IFrame link={`${VIEWER_FLOORPLANNER_BASE_URL}${floorplannerId}/${mode}`} key={mode} />
  );
};

export default FloorplanSharer;
