import { Container, Fab, Paper, Typography } from "@material-ui/core";
import styled from "styled-components";

export const Content = styled(Container)`
  padding-top: ${(p) => p.theme.spacing(4)}px;
  padding-bottom: ${(p) => p.theme.spacing(4)}px;
`;

export const FlrPaper = styled(Paper)`
  padding: ${(p) => p.theme.spacing(2)}px;
  display: flex;
  overflow: auto;
  flex-direction: column;
`;

export const Headline = styled(Typography)`
  margin-bottom: 0;
  flex-grow: 1;
  text-align: center;
`;

export const DialogContentBefore = styled.div`
  margin-bottom: ${(p) => p.theme.spacing(4)}px;
`;

export const FlrFab = styled(Fab)`
  position: absolute;
  z-index: 999;
  bottom: ${(p) => p.theme.spacing(4)}px;
  right: ${(p) => p.theme.spacing(4)}px;
`;

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing(4)}px;
`;
