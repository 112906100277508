import {
  GetFloorplanQuery,
  ListFloorplansQuery,
  ListRoomsQuery,
  //ListOrdersQuery,
  CreateFloorplanMutation,
  GetCustomerQuery,
  ListCustomersQuery,
  GetOrderQuery,
  GetFloorplanOrdersQuery,
} from "../API";
import { Floorplan, Room, Order, FloorplanOrder, Customer } from "../models";

/*
===== This file containes mapping functions from the QueryResult types to the model types =====
e.g.:  GetFloorplanQuery -> Floorplan, GetCustomerQuery -> Customer
*/

// ====== Floorplan mapping functions =======
export type ListFloorplans = Omit<Exclude<ListFloorplansQuery["listFloorplans"]["items"], null>, "__typename">;

// Removed the GraphQlResult because we use react-query
// see https://dev.to/rmuhlfeldner/how-to-use-an-aws-amplify-graphql-api-with-a-react-typescript-frontend-2g79

function mapGetFloorplanQuery(getFloorplanQuery: GetFloorplanQuery["getFloorplan"]): Floorplan | undefined {
  const floorplan = getFloorplanQuery;
  return mapFloorplan(floorplan);
}

function mapListFloorplansQuery(listFloorplansQuery: ListFloorplansQuery["listFloorplans"]): Floorplan[] {
  return listFloorplansQuery?.items?.map((floorplan) => mapFloorplan(floorplan)) || [];
}

function mapCreateFloorplanMutation(
  createFloorplanMutation: CreateFloorplanMutation["createFloorplan"]
): Floorplan | undefined {
  const floorplan = createFloorplanMutation;
  return mapFloorplan(floorplan);
}

function mapFloorplan(floorplan): Floorplan | undefined {
  if (floorplan) {
    return {
      ...floorplan,
      rooms: floorplan?.rooms?.items,
      orders: floorplan?.orders?.items,
    } as Floorplan;
  } else {
    return undefined;
  }
}

function mapListRoomsQuery(listRoomsQuery: ListRoomsQuery): Room[] {
  return (
    listRoomsQuery.listRooms?.items?.map(
      (room) =>
        ({
          id: room?.id,
          floorplanId: room?.floorplanId,
          room_type: room?.room_type,
          room_size_sqm: room?.room_size_sqm,
        } as Room)
    ) || []
  );
}

// function mapListOrdersQuery(listOrdersQuery: ListOrdersQuery): Order[] {
//   return (
//     listOrdersQuery.listOrders?.items?.map(
//       (order) =>
//         ({
//           ...order,
//           //floorplans: order?.floorplans.items,
//         } as Order)
//     ) || []
//   );
// }

// ====== Customer mapping functions =======
function mapGetCustomerQuery(getCustomerQuery: GetCustomerQuery["getCustomer"]): Customer | undefined {
  const customer = getCustomerQuery;
  return mapCustomer(customer);
}

function mapListCustomersQuery(listCustomersQuery: ListCustomersQuery["listCustomers"]): Customer[] {
  return listCustomersQuery?.items?.map((customer) => mapCustomer(customer)) || [];
}

function mapCustomer(customer): Customer | undefined {
  if (customer) {
    return {
      ...customer,
    } as Customer;
  } else {
    return undefined;
  }
}

// ====== Order mapping functions =======
function mapGetOrderQuery(getOrderQuery: GetOrderQuery["getOrder"]): Order | undefined {
  const order = getOrderQuery;
  return mapOrder(order);
}

function mapOrder(order): Order | undefined {
  if (order) {
    return {
      ...order,
    } as Order;
  } else {
    return undefined;
  }
}

function mapGetFloorplanOrderQuery(
  getFloorplanOrdersQuery: GetFloorplanOrdersQuery["getFloorplan"]
): FloorplanOrder | undefined {
  const floorplanOrder = getFloorplanOrdersQuery;
  return mapFloorplanOrder(floorplanOrder);
}

function mapFloorplanOrder(floorplanOrder): FloorplanOrder | undefined {
  if (floorplanOrder) {
    return {
      ...floorplanOrder,
      orders: floorplanOrder?.orders?.items,
    } as FloorplanOrder;
  } else {
    return undefined;
  }
}

export {
  mapGetFloorplanQuery,
  mapListFloorplansQuery,
  mapCreateFloorplanMutation,
  mapListRoomsQuery,
  mapGetCustomerQuery,
  mapListCustomersQuery,
  mapCustomer,
  mapGetOrderQuery,
  //mapListOrdersQuery,
  mapGetFloorplanOrderQuery,
  mapFloorplanOrder,
  mapOrder,
};
