import { DEFAULT_LOCALE } from "../constants";

const options: Intl.DateTimeFormatOptions = {
  weekday: "short",
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

const optionsShort: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

const optionsChat: Intl.DateTimeFormatOptions = {
  day: "2-digit",
  month: "short",
  hour: "numeric",
  minute: "numeric",
};

const optionsChatToday: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "numeric",
};

export const toLocaleDate = (dateString: string): string => {
  return new Date(dateString).toLocaleDateString(DEFAULT_LOCALE, options);
};

export const toLocaleDateShort = (dateString: string): string => {
  return new Date(dateString).toLocaleDateString(DEFAULT_LOCALE, optionsShort);
};

export const toDateChat = (dateString: string): string => {
  const newDate = new Date(dateString);
  const today = new Date();
  if (newDate.toDateString() === today.toDateString()) {
    return newDate.toLocaleTimeString(DEFAULT_LOCALE, optionsChatToday);
  } else return newDate.toLocaleDateString(DEFAULT_LOCALE, optionsChat);
};

export const getLastMonthDateString = (currentDate: Date) => {
  let lastMonth = currentDate;
  lastMonth.setDate(1);
  lastMonth.setMonth(currentDate.getMonth() - 1);
  return lastMonth.toISOString().split("T")[0] + "T";
};
