import React, { useState, useEffect, useRef } from "react";
import { Button, Grid, Typography, LinearProgress } from "@material-ui/core";
import styled from "styled-components";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import { useStateValue } from "./context/CheckoutStateContext";
import { CheckoutActionTypes } from "./context/CheckoutReducer";

import { MIME_TYPE } from "../../constants";

const FormContainer = styled.div`
  padding-top: ${(p: { theme: { spacing: (arg0: number) => any } }) => p.theme.spacing(8)}px;
`;

// load src and convert to a File instance object
// work for any type of src, not only image src.
// return a promise that resolves with a File instance
const srcToFile = async (src: string, fileName: string, mimeType: string): Promise<File> => {
  return await fetch(src)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], fileName, { type: mimeType });
    });
};

const CheckoutCrop = () => {
  const cropperRef = useRef<HTMLImageElement>(null);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<string>();
  const [{ croppedImageFile, preprocessedImageFile }, dispatch] = useStateValue();

  const readFile = (file: Blob) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(file);
  };

  const dispatchCropped = (value: File) => {
    dispatch({
      type: CheckoutActionTypes.editCroppedImageFile,
      key: "cropped",
      value: value,
    });
  };

  const resetToOriginal = () => {
    if (preprocessedImageFile) {
      readFile(preprocessedImageFile);
      dispatchCropped(preprocessedImageFile);
    }
  };

  // if there is a cropped image, load it into the cropper
  // otherwise use the preprocessed image as fallback
  // the cropped image will be set to preprocessed and is the one we continue working with
  useEffect(() => {
    if (croppedImageFile) {
      console.log("CroppedImageFile because you go back: ", croppedImageFile);
      readFile(croppedImageFile);
    } else if (preprocessedImageFile) {
      console.log("PreprocessedImageFile: ", preprocessedImageFile);
      readFile(preprocessedImageFile);
      dispatchCropped(preprocessedImageFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCrop = async () => {
    setLoading(true);
    console.log("gettingCroppedImage");

    try {
      const imageElement: any = cropperRef?.current;
      const cropper: any = imageElement?.cropper;

      const cropped = await srcToFile(
        cropper.getCroppedCanvas().toDataURL(MIME_TYPE.JPEG),
        "croppedImage",
        MIME_TYPE.JPEG
      );
      readFile(cropped);
      dispatchCropped(cropped);
      setLoading(false);
    } catch (err) {
      console.log("Cropping error: ", err);
    }
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        <FormContainer>
          <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2" align="center">
                Grundriss im Bild zuschneiden
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container justify={"center"} alignItems="center" direction={"column"}>
                {image ? (
                  <Cropper
                    style={{ height: 600, width: "100%" }}
                    preview=".img-preview"
                    src={image}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    zoomable={false}
                    movable={false}
                    responsive={true}
                    cropend={() => {
                      onCrop();
                    }}
                    autoCropArea={1}
                    scalable={false}
                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                    ref={cropperRef}
                  />
                ) : (
                  <Typography variant="h5" component="h2">
                    Kein Grundriss hochgeladen, bitte gehen Sie zurück zu Schritt 1.
                  </Typography>
                )}
                {loading ? <LinearProgress style={{ width: "50%" }} /> : <div style={{ height: "4px" }}></div>}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container justify={"center"}>
                <Button onClick={resetToOriginal} color="secondary">
                  Zurücksetzen
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </FormContainer>
      </Grid>
    </>
  );
};

export default CheckoutCrop;
