import { FLOORPLANNER_API_NAME, FLOORPLANNER_PROJECT_ENDPOINT_BASE } from "../../constants";
import { API } from "aws-amplify";
import { useCallback, useState } from "react";
enum ExportOrientation {
  "PORTRAIT" = "Portrait",
  "LANDSCAPE" = "Landscape",
}
const useImportImagesHook = () => {
  const [orientation, setOrientation] = useState(ExportOrientation.LANDSCAPE);
  const changeOrientation = useCallback(() => {
    setOrientation(
      orientation === ExportOrientation.LANDSCAPE ? ExportOrientation.PORTRAIT : ExportOrientation.LANDSCAPE
    );
  }, [orientation]);
  const importImages = async (orderLevel, floorplannerId) => {
    const path = `${FLOORPLANNER_PROJECT_ENDPOINT_BASE}/${floorplannerId}/export`;
    try {
      await API.get(FLOORPLANNER_API_NAME, path, {
        queryStringParameters: {
          orderLevel: orderLevel,
          exportOrientation: orientation,
        },
      });
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 422:
            throw Error("Die Warteschleife für Renderings ist derzeit voll.");
          default:
            throw Error("Import fehlgeschlagen");
        }
      } else {
        throw Error("Netzwerk Fehler");
      }
    }
  };

  return { importImages, orientation, changeOrientation, ExportOrientation };
};

export default useImportImagesHook;
