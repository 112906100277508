import React from "react";
import { useGetFloorplansByOwner } from "../../hooks/floorplan/GetFloorplanHooks";
import { useUser } from "../../providers/AuthContextProvider";
import CardGrid from "../../components/dashboard/CardGrid";
import { Grid } from "@material-ui/core";
import { FlrPaper } from "../../components/layout/StyledComponents";
import FloorplanList from "../../components/dashboard/FloorplanList";

type Props = {
  slice?: number;
};

const ListView: React.FC<Props> = ({ slice = 100 }) => {
  const { userAttributes } = useUser();
  const { floorplans, isLoading } = useGetFloorplansByOwner(userAttributes?.sub);

  return (
    <>
      {!isLoading && <CardGrid floorplans={floorplans} slice={slice} />}
      <Grid item xs={12}>
        <FlrPaper elevation={3}>{!isLoading && <FloorplanList floorplans={floorplans} />}</FlrPaper>
      </Grid>
    </>
  );
};

export default ListView;
