import { GetCustomerByUsernameQuery } from "API";
import { gqlOperation } from "hooks/GraphQLHooks";
import React, { useContext, useEffect, useState } from "react";
import { useAuthState } from "./AuthContextProvider";
import * as queries from "graphql/queries";
import { Customer } from "models";

export const CustomerContext = React.createContext({});

type Props = {
  children: React.ReactNode;
};

const CustomerContextProvider = ({ children }: Props) => {
  const authState = useAuthState();
  const [customer, setCustomer] = useState<Customer | undefined>(null);

  useEffect(() => {
    if (authState.user !== undefined) {
      const user = authState.user;
      const getCustomerByUsername = async () => {
        const customerQueryResult = await gqlOperation<GetCustomerByUsernameQuery>(queries.getCustomerByUsername, {
          user: user.getUsername(),
        });
        if (customerQueryResult.getCustomerByUsername.items) {
          const customerResult = customerQueryResult.getCustomerByUsername.items[0];
          const { __typename, floorplans, ...customer } = customerResult;
          setCustomer(customer);
          console.log("Current customer: ", customer);
        }
      };
      getCustomerByUsername();
    }
  }, [authState]);

  return <CustomerContext.Provider value={customer}>{children}</CustomerContext.Provider>;
};

export const useCustomer = (): Customer => {
  const customer = useContext(CustomerContext) as Customer;
  if (customer === undefined) {
    throw new Error("`useCustomer` must be within a `CustomerContextProvider`");
  }
  return customer;
};

export default CustomerContextProvider;
