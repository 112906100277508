import { saveAs } from "file-saver";
import JSZip from "jszip";
import Storage from "@aws-amplify/storage";
import * as KeyUtils from "../../utils/KeyUtils";
import { useToastProvider } from "../../providers/ToastProvider";

type Image = {
  url: string;
  filename: string;
};

export const useDownloadImages = (images: Image[]) => {
  const startDownload = async () => {
    const folder = new JSZip().folder("collection");

    await Promise.all(
      images.map(async (image: Image) => {
        const blob = await fetch(image.url, {
          method: "GET",
          mode: "cors",
          cache: "no-cache",
          headers: {
            Origin: window.location.origin,
          },
        }).then((response) => response.blob());

        const imageFile = new File([blob], image.filename);
        folder.file(image.filename, imageFile);
      })
    );

    folder.generateAsync({ type: "blob" }).then((content) => saveAs(content, "files"));
  };

  return { startDownload };
};

export const useCustomerImageDownload = (floorplan, directoryName) => {
  const { showSuccessToast } = useToastProvider();

  const startDownload = async (keys) => {
    showSuccessToast("Ihr Download beginnt in Kürze.");
    const folder = new JSZip().folder("collection");
    for (const fileType in keys) {
      const fileFolder = folder.folder(fileType);
      await Promise.all(
        keys[fileType].map(async (key) => {
          console.log("dl");
          const img = (await Storage.vault.get(KeyUtils.getPathFromKey(key))) as string;
          const blob = await fetch(img).then((response) => response.blob());
          const originalName = floorplan.originalImageName.substring(0, floorplan.originalImageName.lastIndexOf("."));
          const name = originalName + "-" + key.substring(key.lastIndexOf("-") + 1);
          const imageFile = new File([blob], name);
          fileFolder.file(name, imageFile);
        })
      );
    }
    folder.generateAsync({ type: "blob" }).then((content) => saveAs(content, directoryName));
  };
  return { startDownload };
};

export const useEditorUrlDownload = (filename: string) => {
  const { showSuccessToast } = useToastProvider();

  const downloadImagesToFolder = async (urls, folder) => {
    await Promise.all(
      urls.map(async (url) => {
        const blob = await fetch(url).then((response) => response.blob());
        let name = url.substring(url.lastIndexOf("/") + 1);
        name = name.substring(0, name.lastIndexOf("?"));
        name = name.substring(name.lastIndexOf("/") + 1);
        const imageFile = new File([blob], name);
        folder.file(name, imageFile);
      })
    );
  };

  const startEditorDownload = async (urls) => {
    showSuccessToast("Ihr Download beginnt in Kürze.");
    const folder = new JSZip().folder("collection");
    await downloadImagesToFolder(urls, folder);
    folder.generateAsync({ type: "blob" }).then((content) => saveAs(content, filename));
  };

  const startEditorDownloadMultiple = async (urls) => {
    showSuccessToast("Ihr Download beginnt in Kürze.");
    const folder = new JSZip().folder("collection");
    for (const fileType in urls) {
      const fileFolder = folder.folder(fileType);
      await downloadImagesToFolder(urls[fileType], fileFolder);
    }

    folder.generateAsync({ type: "blob" }).then((content) => saveAs(content, filename));
  };
  return { startEditorDownload, startEditorDownloadMultiple };
};
