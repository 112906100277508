import { Route } from "react-router-dom";

import IconLink from "components/layout/IconLink";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { ContentHeader, Headline } from "components/layout/StyledComponents";

import { Modes } from "../container/view/FloorplanViewer";
import FloorplanEstateEditor from "container/estate/FloorplanEstateEditor";
import FloorplanEstateImages from "container/estate/FloorplanEstateImages";
import FloorplanEstateMatterport from "container/estate/FloorplanEstateMatterport";

export const EstateRoute = ({ match }) => {
  return (
    <>
      <Route
        exact
        path={`${match.path}/:floorplanner_id/editor`}
        render={({ match }) => (
          <FloorplanEstateEditor floorplannerId={match.params.floorplanner_id} mode={Modes.Editor} />
        )}
      />
      <Route
        path={`${match.path}/:floorplanner_id/images`}
        render={({ match }) => <FloorplanEstateImages floorplannerId={match.params.floorplanner_id} />}
      />
      <Route
        path={`${match.path}/:floorplanner_id/matterport`}
        render={({ match }) => <FloorplanEstateMatterport floorplannerId={match.params.floorplanner_id} />}
      />
      <Route
        exact
        path={match.url}
        render={() => (
          <ContentHeader>
            <IconLink tooltip="Zurück zum Dashboard" to="/dashboard" icon={<ArrowBackIcon />} />
            <Headline variant="h4" component="h1">
              Please select a floorplan.
            </Headline>
          </ContentHeader>
        )}
      />
    </>
  );
};
