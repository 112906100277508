import { gqlOperation, gqlSubscription } from "../GraphQLHooks";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import * as subscriptions from "../../graphql/subscriptions";

import { useMutation, useQuery } from "react-query";
import {
  ChatMessagesByFloorplanIdQuery,
  CreateChatMessageInput,
  CreateChatMessageMutation,
  ModelSortDirection,
  OnChatMessageByFloorplanIdSubscription,
  OnChatMessageByFloorplanIdSubscriptionVariables,
} from "API";
import { ChatMessage } from "models";

export const useCreateChatMessage = () => {
  const { mutateAsync: createChatMessage } = useMutation((message: CreateChatMessageInput) =>
    gqlOperation<CreateChatMessageMutation>(mutations.createChatMessage, {
      input: message,
    })
  );

  return { createChatMessage };
};

export const useGetChatMessagesHook = (floorplanId: string, limit?: number, nextToken?: string) => {
  const { data, error, isLoading, refetch } = useQuery<ChatMessage[]>(`getChatMessages-${floorplanId}`, async () => {
    const data = await gqlOperation<ChatMessagesByFloorplanIdQuery>(queries.chatMessagesByFloorplanId, {
      floorplanId: floorplanId,
      sortDirection: ModelSortDirection.DESC,
      limit,
      nextToken,
    });
    console.log(data);
    return data.chatMessagesByFloorplanId.items;
  });

  return { chatMessages: data, error, isLoading, refetch };
};

export const subscribeChatMessagesHook = async (floorplanId: string, callback?: (props) => void) => {
  const subscription = await gqlSubscription<
    OnChatMessageByFloorplanIdSubscription,
    OnChatMessageByFloorplanIdSubscriptionVariables
  >(subscriptions.onChatMessageByFloorplanId, {
    floorplanId,
  });
  return subscription;
};
