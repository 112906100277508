import { Icons } from "material-table";
import { SvgIcon } from "@material-ui/core";
import React, { forwardRef, Ref } from "react";
import { default as AddBox } from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import Replay from "@material-ui/icons/Replay";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
export type IconSet = Record<keyof Icons, typeof SvgIcon>;

// List of icons for table
const iconComponentByTableIconType: IconSet = {
  Add: AddBox,
  Check,
  Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage,
  LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search,
  SortArrow: ArrowDownward,
  ThirdStateCheck: Remove,
  ViewColumn,
  Retry: Replay,
};

// Create icons for material-table
// https://github.com/mbrn/material-table/issues/51#issuecomment-615882861
const getTableIcons = () => {
  return Object.entries(iconComponentByTableIconType).reduce(
    (currentTableIcons: Icons, [tableIconType, IconComponent]) => {
      currentTableIcons[
        tableIconType as keyof Icons
      ] = forwardRef((props, ref: Ref<SVGSVGElement>) => (
        <IconComponent {...props} ref={ref} />
      ));
      return currentTableIcons;
    },
    {}
  );
};

export default getTableIcons;
