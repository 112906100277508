import {
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { FlrPaper } from "components/layout/StyledComponents";
import { useContext, useEffect, useState } from "react";
import VurnishedLogo from "../../assets/vurnished/vurnished_logo.png";

import FurnitureCardPrice from "./FurnitureCardPrice";
import CheckIcon from "@material-ui/icons/Check";
import rooms from "./mockData/rooms";
import BackLink from "components/links/BackLink";
import RoomImage from "./RoomImage";
import { FurnitureConfigContext, FurnitureActionTypes } from "providers/FurnitureConfigContextProvider";
import MuiRouterLink from "components/links/MuiRouterLink";
import styled from "styled-components";

type PropTypes = {
  id: string;
};

const LogoDisplay = styled.img`
  width: 200px;
`;

const VurnishedPrice: React.FC<PropTypes> = ({ id }) => {
  const [state, dispatch] = useContext(FurnitureConfigContext);

  // const [style, setStyle] = useState("Modern");
  const [duration, setDuration] = useState(12);

  useEffect(() => {
    if (state.room === undefined) {
      dispatch({ type: FurnitureActionTypes.SET_ROOM, room: rooms[id] });
    }
  });

  const totalPrice = state.furniture.reduce(
    (previousValue, currentValue) => (currentValue.checked ? previousValue + currentValue.price : previousValue),
    0
  );

  let monthlyPrice: number;
  switch (duration) {
    case 12:
      monthlyPrice = (totalPrice / duration) * 0.5;
      break;
    case 24:
      monthlyPrice = (totalPrice / duration) * 0.6;
      break;
    case 36:
      monthlyPrice = (totalPrice / duration) * 0.7;
      break;
    case 48:
      monthlyPrice = (totalPrice / duration) * 0.8;
      break;
    default:
      monthlyPrice = (totalPrice / duration) * 0.5;
      break;
  }
  monthlyPrice = Math.ceil(monthlyPrice);

  return (
    <Grid container spacing={3}>
      <Grid item xs={4} sm={7} md={8}>
        <BackLink tooltip="Zur Produktübersicht" to={`/vurnished`} />
      </Grid>
      <Grid item xs={8} sm={5} md={4}>
        {state.room && (
          <Select
            value={state.room.id}
            onChange={(event) => {
              dispatch({ type: FurnitureActionTypes.SET_ROOM, room: rooms[event.target.value as number] });
            }}
            label="Raum"
          >
            {Object.keys(rooms).map((key) => (
              <MenuItem value={rooms[key].id}>
                <Typography variant="h5">{rooms[key].title}</Typography>
              </MenuItem>
            ))}
          </Select>
        )}
      </Grid>

      <Grid item xs={12} lg={8}>
        <RoomImage img={state.room?.img} />
        {state.room?.logo && (
          <Grid container spacing={3} justify="center" alignItems="center" style={{ marginTop: "12px" }}>
            <Grid item component={Typography} variant="h6">
              Ausstattung von:
            </Grid>
            <Grid item component={LogoDisplay} src={state.room?.logo} />
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} lg={4}>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} md={6} lg={12}>
            <FlrPaper style={{ height: "100%" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <ListItemText primary={`Einrichtungsstil:`} />
                      <ListItemSecondaryAction>
                        Modern
                        {/* <Select value={style} onChange={(event) => setStyle(event.target.value as string)}>
                          {["Modern", "Klassisch", "Industriell"].map((style) => (
                            <MenuItem value={style}>{style}</MenuItem>
                          ))}
                        </Select> */}
                      </ListItemSecondaryAction>
                    </ListItem>

                    <Divider />

                    {state.furniture.map((fn) => (
                      <ListItem>
                        <FurnitureCardPrice furniture={fn} />
                      </ListItem>
                    ))}

                    <Divider />

                    <ListItem>
                      <Grid container style={{ margin: "10px 0" }}>
                        <Grid item xs={8}>
                          <Typography variant="h6">Gesamtkosten</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h5" align="center">
                            {`${totalPrice.toString().replace(".", ",")} €`}
                          </Typography>
                          <MuiRouterLink to={`/vurnished/${id}/rent`}>{">"} Auswahl jetzt kaufen</MuiRouterLink>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </FlrPaper>
          </Grid>

          <Grid item xs={12} md={6} lg={12}>
            <FlrPaper style={{ height: "100%" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <Grid container justify="center">
                        <Grid item>
                          <img src={VurnishedLogo} alt="Logo" height="60" />
                        </Grid>
                        <Grid item xs={9}>
                          <Typography align="center" variant="h6">
                            Vurnished bietet Ihnen den vollen Service in der Rent-to-Own Option:
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {["Möbelkauf", "Abgestimmtes Interior Design", "Lieferung und Installation", "Versicherung"].map(
                      (title) => (
                        <>
                          <ListItem>
                            <Grid container justify="space-between">
                              <Grid item>
                                <Typography>{title}</Typography>
                              </Grid>
                              <Grid>
                                <CheckIcon color="primary" />
                              </Grid>
                            </Grid>
                          </ListItem>
                          <Divider />
                        </>
                      )
                    )}
                    <ListItem>
                      <Grid container justify="space-between">
                        <Grid item>
                          <Typography>Laufzeit</Typography>
                        </Grid>
                        <Grid>
                          <Select
                            value={duration}
                            onChange={(event) => {
                              setDuration(event.target.value as number);
                            }}
                          >
                            {["12 Monate", "24 Monate", "36 Monate", "48 Monate"].map((duration) => (
                              <MenuItem value={parseInt(duration.split(" ")[0])}>{duration}</MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      </Grid>
                    </ListItem>
                    <ListItem>
                      <Grid container justify="space-between" style={{ margin: "10px 0" }}>
                        <Grid item>
                          <Typography variant="h5">Rent-to-Own</Typography>
                        </Grid>
                        <Grid item style={{ alignSelf: "center" }}>
                          <Typography>pro Monat</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h5">{`${monthlyPrice} €`}</Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                    <ListItem>
                      <Grid container justify="flex-end">
                        <MuiRouterLink to={`/vurnished/${id}/rent`}>
                          <Button color="primary" variant="contained">
                            Jetzt einfach Abo bestellen!
                          </Button>
                        </MuiRouterLink>
                      </Grid>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </FlrPaper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VurnishedPrice;
