import { FLOORPLANNER_API_NAME, FLOORPLANNER_FLOORPLAN_ENDPOINT_BASE } from "../../constants";
import { API } from "aws-amplify";

const useUpdateDesignHook = () => {
  const updateFloorplannerDesign = async (floorplanId) => {
    const path = `${FLOORPLANNER_FLOORPLAN_ENDPOINT_BASE}/${floorplanId}/update`;
    try {
      return await API.get(FLOORPLANNER_API_NAME, path, {});
    } catch (error) {
      if (error.response) {
        throw Error(error.response.data);
      } else {
        throw Error("Netzwerk Fehler");
      }
    }
  };

  return { updateFloorplannerDesign };
};

export default useUpdateDesignHook;
