import React, { useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel, Grid, Link, Typography } from "@material-ui/core";
import { CloudDownload, Save } from "@material-ui/icons";
import { FlrPaper } from "../../components/layout/StyledComponents";
import DetailInfo from "../../components/floorplan-detail/DetailInfo";
import { useFloorplanFormik } from "../../hooks/floorplan/FloorplanFormikHook";
import { Floorplan, FloorplanStatus } from "../../models";
import { useCustomerImageDownload, useEditorUrlDownload } from "../../hooks/exports/DownloadImagesHook";
import useCreatePresignedUrl from "../../hooks/exports/useCreatePresignedUrl";
import { useIsEditor } from "../../providers/AuthContextProvider";
import useKeysForOrderLevelHook from "../../hooks/exports/useKeysForOrderLevelHook";

import { Event } from "../../hooks/analytics/GoogleAnalytics";
import * as analytics from "../../hooks/analytics/GoogleAnalytics";
import { FILE_TYPE } from "../../constants";

type InfoPanelProps = {
  floorplan: Floorplan;
  refetch: () => void;
  orderLevel: string;
};

const InfoPanel: React.FC<InfoPanelProps> = ({ floorplan, refetch, orderLevel }) => {
  const { getKeysForOrderLevel } = useKeysForOrderLevelHook();
  const [fileTypePng, setFileTypePng] = useState(true);
  const [fileTypeJpeg, setFileTypeJpeg] = useState(false);
  const [fileTypePdf, setFileTypePdf] = useState(true);

  const fileName = `VloorExport.zip`;
  const { startDownload } = useCustomerImageDownload(floorplan, fileName);
  const { startEditorDownloadMultiple } = useEditorUrlDownload(floorplan.id);
  const { values: formikValues, handleChange, setValues, saveFloorplan } = useFloorplanFormik(floorplan.id, refetch);
  const { createPresignedURLs } = useCreatePresignedUrl();
  const isEditor = useIsEditor();

  // send GA event and start download
  const handleDownload = async () => {
    const fileTypes = (fileTypePng ? "png_" : "") + (fileTypeJpeg ? "jpeg_" : "") + (fileTypePdf ? "pdf" : "");

    const event: Event = {
      category: "Detailspage",
      action: "download_images",
      label: "download_" + fileTypes,
    };
    analytics.sendEvent(event);

    await startImageDownload();
  };

  const startImageDownload = async () => {
    let keys = {};
    if (fileTypePng) {
      const imagesToDownloadKeysPng = getKeysForOrderLevel(floorplan, orderLevel, FILE_TYPE.PNG);
      keys["png"] = imagesToDownloadKeysPng;
    }
    if (fileTypeJpeg) {
      const imagesToDownloadKeysJpeg = getKeysForOrderLevel(floorplan, orderLevel, FILE_TYPE.JPEG);
      keys["jpeg"] = imagesToDownloadKeysJpeg;
    }
    if (fileTypePdf) {
      const imagesToDownloadKeysPdf = getKeysForOrderLevel(floorplan, orderLevel, FILE_TYPE.PDF);
      keys["pdf"] = imagesToDownloadKeysPdf;
    }
    if (isEditor) {
      const typeUrls = {};
      for (const fileType in keys) {
        const urls = await createPresignedURLs(keys[fileType], "GET");
        typeUrls[fileType] = urls;
      }
      startEditorDownloadMultiple(typeUrls);
    } else {
      startDownload(keys);
    }
  };

  useEffect(() => {
    if (floorplan) {
      setValues({
        name: floorplan.name,
        address: floorplan.address,
        description: floorplan.description,
      });
    }
  }, [floorplan, setValues]);

  return (
    <FlrPaper>
      <DetailInfo handleChange={handleChange} values={formikValues} />
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12} md={12}>
          <Grid container justify={"space-around"}>
            <Button variant="contained" color="primary" onClick={saveFloorplan} startIcon={<Save />}>
              Speichern
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container direction="row" justify={"space-around"} alignItems="center">
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    checked={fileTypePng}
                    disabled={floorplan.status !== FloorplanStatus.CREATED}
                    onChange={() => {
                      setFileTypePng(!fileTypePng);
                    }}
                    color="primary"
                  />
                }
                label="PNG"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    disabled={floorplan.status !== FloorplanStatus.CREATED}
                    checked={fileTypeJpeg}
                    onChange={() => {
                      setFileTypeJpeg(!fileTypeJpeg);
                    }}
                    color="primary"
                  />
                }
                label="JPEG"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    disabled={floorplan.status !== FloorplanStatus.CREATED}
                    checked={fileTypePdf}
                    onChange={() => {
                      setFileTypePdf(!fileTypePdf);
                    }}
                    color="primary"
                  />
                }
                label="PDF"
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDownload}
                disabled={
                  floorplan.status !== FloorplanStatus.CREATED || (!fileTypePdf && !fileTypePng && !fileTypeJpeg)
                }
                startIcon={<CloudDownload />}
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {floorplan.status !== FloorplanStatus.CREATED && (
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle2">Ihr Grundrissplan wird soeben digitalisiert.</Typography>
            <Typography variant="subtitle2">
              Wir melden uns in Kürze bei Ihnen, sobald die Digitalisierung abgeschlossen ist.
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <Typography variant={"subtitle2"}>
            <Link href={`mailto:office@vloor.com?subject=Feedback zu Projekt ${floorplan.id}`}>
              Feedback oder Änderungswünsche?
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </FlrPaper>
  );
};

export default InfoPanel;
