import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import styled from "styled-components";

const AlertButton = styled(Button)`
  color: ${(p) => p.theme.palette.getContrastText(red[500])};
  background-color: ${(p) => p.theme.palette.error.main};
  &:hover {
    background-color: ${(p) => p.theme.palette.error.dark};
  }
`;

const DeleteConfirmationDialog = ({ isOpen, setOpen, confirmCallback }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    confirmCallback();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Floorplan Projekt löschen
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography gutterBottom>
            Sind Sie sicher, dass sie das Floorplan Projekt löschen möchten?
          </Typography>
          <Alert severity="warning">
            <AlertTitle>Achtung!</AlertTitle>
            Das Projekt wird dadurch unwiederruflich gelöscht. <br />
            Dieser Vorgang kann nicht rückgängig gemacht werden!
          </Alert>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Abbrechen
        </Button>
        <AlertButton
          variant="contained"
          onClick={handleConfirm}
          color="primary"
          autoFocus
        >
          Löschen
        </AlertButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
