import { API } from "aws-amplify";
import { API_CUSTOMER_PORTAL_ENDPOINT, BILLING_PORTAL_RETURN_URL, STRIPE_API_NAME } from "../../constants";
import { useCustomer } from "providers/CustomerContextProvider";
import { Customer } from "models";
import { useToastProvider } from "providers/ToastProvider";
import { useStripe } from "@stripe/react-stripe-js";

// Config Billing Portal
const billingPortalConfig: BillingPortalConfig = {
  return_url: BILLING_PORTAL_RETURN_URL,
};

const billingConfig: ApiConfig = {
  apiName: STRIPE_API_NAME,
  apiEndpoint: API_CUSTOMER_PORTAL_ENDPOINT,
};

const useBillingPortal = () => {
  const { showToast } = useToastProvider();
  const customer = useCustomer();
  const stripe = useStripe();

  const checkStripe = () => {
    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      showToast({
        severity: "error",
        content: "Stripe was not loaded",
      });
      return;
    }
  };

  //call API to create Stripe Billing Portal session
  const getBillingPortalUrl = async () => {
    checkStripe();
    try {
      const body = createBillingPortalConfig(customer);
      const response = await API.post(billingConfig.apiName, billingConfig.apiEndpoint, {
        body,
      });
      console.log("BillingPortalUrl:", response);
      return response.url;
    } catch (err) {
      console.log("BillingPortalUrl: Error when trying to call API:", err);
      throw new Error(`Error when trying to call Stripe API`);
    }
  };

  return { getBillingPortalUrl };
};

export default useBillingPortal;

const createBillingPortalConfig = (customer: Customer) => {
  const body = {
    customer: customer?.stripe_customer_id,
    return_url: billingPortalConfig?.return_url,
  };
  return body;
};

type ApiConfig = {
  apiName: string;
  apiEndpoint: string;
};

type BillingPortalConfig = {
  return_url: string; // Where to go if return
};
