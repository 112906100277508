import React, { useEffect } from "react";
import { Grid, Typography, LinearProgress } from "@material-ui/core";
import styled from "styled-components";

import { useStateValue } from "./context/CheckoutStateContext";
import { useCreateAndCheckout } from "../../hooks/checkout/CreateAndCheckoutHook";
import { CheckoutActionTypes } from "./context/CheckoutReducer";

const Container = styled.div`
  padding-top: ${(p) => p.theme.spacing(8)}px;
`;

const CheckoutFinalization = () => {
  const [
    {
      formValues,
      originalImageFile,
      originalInputType,
      croppedImageFile,
      preprocessedImageFile,
      productId,
      reusePaymentMethod,
    },
    dispatch,
  ] = useStateValue();
  const { createAndCheckout, uploadProgress } = useCreateAndCheckout();

  useEffect(() => {
    const goToCheckout = async () => {
      await createAndCheckout(
        formValues,
        originalInputType,
        originalImageFile,
        croppedImageFile,
        preprocessedImageFile,
        productId,
        reusePaymentMethod
      );
      dispatch({
        type: CheckoutActionTypes.resetCheckoutContext,
      });
    };
    goToCheckout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid item xs={12} md={12}>
        <Container>
          <Typography variant="h5" component="h2" align="center">
            Vielen Dank für Ihre Bestellung. Sie werden benachrichtigt alsbald Ihr Grundriss fertig visualisiert ist.
          </Typography>
          <LinearProgress variant="determinate" value={uploadProgress} />
        </Container>
      </Grid>
    </>
  );
};

export default CheckoutFinalization;
