import React, { createContext, useContext, useReducer } from "react";
import { reducer, initialState, AuthUIState } from "./AuthUIStateReducer";

type AuthUIStateType = {
  //useReducer: (state: AuthUIState, action: any) => AuthUIState;
  state: AuthUIState;
  dispatch: React.Dispatch<any>;
};

const AuthUIStateContext = createContext<AuthUIStateType>(undefined);

// useReducer provides state=form values and dispatch() function to
const AuthUIStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <AuthUIStateContext.Provider value={{ state, dispatch }}>{children}</AuthUIStateContext.Provider>;
};

export default AuthUIStateProvider;

export const useAuthUIState = () => {
  const context = useContext(AuthUIStateContext);
  if (context === undefined) {
    throw new Error("`useAuthUIState` hook must be used within a `AuthUIStateProvider` component");
  }
  return context;
};
