import React from "react";
import { makeStyles, Grid, Card, Typography } from "@material-ui/core";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

type Props = {
  cardTitle?: string;
  addFloorplan: () => void;
};
const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  cardActionArea: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  add: {
    width: "40%",
    height: "40%",
    opacity: "0.8",
  },
}));

function AddCard({ addFloorplan, cardTitle }: Props) {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card className={classes.card} elevation={3} onClick={() => addFloorplan()}>
        <CardActionArea className={classes.cardActionArea}>
          <AddCircleOutlineIcon color="primary" className={classes.add} />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2" align="center">
              {cardTitle || "Neues Projekt erstellen"}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}

export default AddCard;
