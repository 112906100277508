import { createMuiTheme } from "@material-ui/core/styles";

export const vloorTheme = createMuiTheme({
  palette: {
    common: { black: "#000", white: "#fff" },
    background: {
      default: "#F2F5FB",
    },
    primary: {
      main: "#CC0007", //red-600
      contrastText: "#F6F7F8", //white
    },
    secondary: {
      main: "#666C6E", //grey-700
      contrastText: "#F6F7F8", //white
    },
    text: {
      primary: "#424647", //grey-800
    },
    action: {
      hover: "#E50008", //red-550
    },
    // tertiary: {
    //   main: "#FFFFFF", //true_white
    //   contastText: "#F2F5FB"
    // },

    //     linkColor: "#666C6E", //grey-300

    //     logoAccent: "#121A30", // black_logo
  },
  mixins: {
    toolbar: {
      minHeight: 84,
    },
  },
  overrides: {
    MuiToolbar: {
      regular: {
        minHeight: "32px",
        "@media (min-width: 600px)": {
          minHeight: "84px",
        },
      },
    },
    MuiButton: {
      contained: {
        "&:hover": {
          backgroundColor: "#E50008 !important",
        },
        "&:active": {
          backgroundColor: "#E50008 !important",
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "14px",
      },
    },
    MuiTableRow: {
      hover: {
        "&:hover": {
          backgroundColor: "#F2F5FB !important",
          "& > td:first-child": {
            color: "#CC0007 !important",
            textDecoration: "underline",
          },
        },
        // "&:hover": {
        //   backgroundColor: "#CC0007 !important",
        //   color: "white !important",
        // },
      },
    },
    MuiListItem: {
      root: {
        "&:hover": {
          backgroundColor: "#F2F5FB !important",
        },
      },
    },
  },
});

const theme = createMuiTheme({
  palette: {
    common: { black: "#000", white: "#fff" },
    background: { paper: "#fff", default: "#fafafa" },
    primary: {
      light: "rgba(150, 255, 255, 1)",
      main: "rgba(85, 189, 202, 1)",
      dark: "rgba(63, 140, 150, 1)",
      contrastText: "#fff",
    },
    secondary: {
      light: "rgba(244, 174, 139, 1)",
      main: "rgba(242, 125, 66, 1)",
      dark: "rgba(217, 111, 59, 1)",
      contrastText: "#fff",
    },
    error: {
      light: "rgba(238, 76, 124, 1)",
      main: "rgba(154, 23, 80, 1)",
      dark: "rgba(93, 0, 30, 1)",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(51, 51, 51, 1)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
});

export const lively = createMuiTheme({
  palette: {
    common: { black: "#000", white: "#fff" },
    background: { paper: "#fff", default: "#EFEFEF" },
    primary: {
      light: "rgba(159, 237, 215, 1)",
      main: "rgba(2, 102, 112, 1)",
      dark: "rgba(2, 80, 87, 1)",
      contrastText: "#fff",
    },
    secondary: {
      light: "rgba(254, 249, 199, 1)",
      main: "rgba(252, 225, 129, 1)",
      dark: "rgba(227, 203, 116, 1)",
      contrastText: "rgba(74, 74, 74, 1)",
    },
    error: {
      light: "rgba(238, 76, 124, 1)",
      main: "rgba(154, 23, 80, 1)",
      dark: "rgba(93, 0, 30, 1)",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(74, 74, 74, 1)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
});

export const snipcart = createMuiTheme({
  palette: {
    common: { black: "#000", white: "rgba(255, 255, 255, 1)" },
    background: {
      paper: "rgba(255, 255, 255, 1)",
      default: "rgba(250, 250, 250, 1)",
    },
    primary: {
      light: "rgba(146, 157, 158, 1)",
      main: "rgba(127, 134, 136, 1)",
      dark: "rgba(102, 108, 110, 1)",
      contrastText: "#fff",
    },
    secondary: {
      light: "rgba(255, 238, 107, 1)",
      main: "rgba(243, 227, 103, 1)",
      dark: "rgba(217, 202, 91, 1)",
      contrastText: "rgba(74, 74, 74, 1)",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(0, 0, 0, 1)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
});

// adapted from website
export const website = createMuiTheme({
  palette: {
    common: { black: "rgba(36, 38, 38, 1)", white: "rgba(246, 247, 248, 1)" }, // grey-900, white
    background: {
      paper: "rgba(246, 247, 248, 1)", // white
      default: "rgba(172, 184, 185, 1)", // grey
    },
    primary: {
      light: "rgba(243, 227, 103, 1)", //yellow-300
      main: "rgba(243, 227, 103, 1)", //yellow-300
      dark: "rgba(255, 229, 33, 1)", //yellow-400
      contrastText: "rgba(70, 70, 70, 1)", //grey-800
    },
    secondary: {
      light: "rgba(172, 184, 185, 1)", //grey-300
      main: "rgba(146, 157, 158, 1)", //grey-500
      dark: "rgba(127, 134, 136, 1)", //grey-600
      contrastText: "rgba(70, 70, 70, 1)", //grey-800
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "rgba(70, 70, 70, 1)", //grey-800
    },
    text: {
      primary: "rgba(70, 70, 70, 1)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
});

// adapted from website
export const styleguide = createMuiTheme({
  palette: {
    common: { black: "rgba(36, 38, 38, 1)", white: "rgba(246, 247, 248, 1)" }, // grey-900, white
    background: {
      paper: "rgba(246, 247, 248, 1)", // white
      default: "rgba(36, 38, 38, 1)", // grey
      //dark: "rgba(36, 38, 38, 1)", // black
    },
    primary: {
      light: "rgba(179, 179, 179, 1)", //red-light
      main: "rgba(255, 102, 107, 1)", //"rgba(153, 153, 153, 1)", //red-primary
      dark: "rgba(229, 0, 8, 1)", // "rgba(128, 128, 128, 1)", //red-dark
      contrastText: "rgba(102, 102, 102, 1)", //dark-red
    },
    secondary: {
      light: "rgba(255, 153, 156, 1)", //grey-300
      main: "rgba(255, 102, 107, 1)", //grey-500
      dark: "rgba(229, 0, 8, 1)", //grey-600
      contrastText: "rgba(70, 70, 70, 1)", //grey-800
    },
    error: {
      light: "#e57373",
      main: "rgba(204, 0, 7, 1)",
      dark: "#d32f2f",
      contrastText: "rgba(70, 70, 70, 1)", //grey-800
    },
    text: {
      primary: "#0f1a30", //"rgba(70, 70, 70, 1)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
});

export default theme;
