import { v4 as uuidv4 } from "uuid";
import { createKey, getFileExtension } from "../utils/KeyUtils";
import { CreateFloorplanInput, FloorplanStatus, S3ObjectInput } from "../API";
import { FILE_TYPE, S3_REGION, S3_PRIVACY_LEVEL, S3_PREFIX_FOLDER, FLOORPLAN_TYPE } from "../constants";

const bucket = process.env.REACT_APP_FLOORPLAN_UPLOAD_BUCKET;

export const createDBEntryContent = (
  identityId: string,
  fileName: string,
  floorplan: CreateFloorplanInput
): CreateFloorplanInput => {
  const projectUUID = uuidv4();
  const fileAndDBEntryUUID = uuidv4();

  const keyprops = {
    identityId: identityId,
    projectId: projectUUID,
    fileId: fileAndDBEntryUUID, // generate a unique id as fileId
    privacyLevel: S3_PRIVACY_LEVEL,
    prefixFolder: S3_PREFIX_FOLDER,
    fileExtension: getFileExtension(fileName),
  };

  const createExportFiles = (filepostfix: string) => {
    return {
      png: toS3Object(createKey(keyprops, filepostfix, FILE_TYPE.PNG, "productImages")),
      jpeg: toS3Object(createKey(keyprops, filepostfix, FILE_TYPE.JPEG, "productImages")),
      pdf: toS3Object(createKey(keyprops, filepostfix, FILE_TYPE.PDF, "productImages")),
    };
  };

  floorplan.id = fileAndDBEntryUUID;
  floorplan.type = FLOORPLAN_TYPE;
  floorplan.projectId = projectUUID;
  floorplan.status = FloorplanStatus.INITIALIZED;
  floorplan.originalImageName = fileName;

  // images
  floorplan.originalImage = toS3Object(createKey(keyprops, "-original", getFileExtension(fileName)));
  floorplan.preprocessedImage = toS3Object(createKey(keyprops, "-preprocessed", FILE_TYPE.JPEG));
  floorplan.croppedImage = toS3Object(createKey(keyprops, "-cropped", FILE_TYPE.JPEG));

  floorplan.visual2DImage = toS3Object(createKey(keyprops, "-visualization_2d", FILE_TYPE.SVG));
  floorplan.mlPredictionImage = toS3Object(createKey(keyprops, "-visualization_ml_prediction", FILE_TYPE.PNG));
  floorplan.ocrPredictionImage = toS3Object(createKey(keyprops, "-visualization_ocr_prediction", FILE_TYPE.PNG));
  floorplan.postMlImage = toS3Object(createKey(keyprops, "-visualization_postml", FILE_TYPE.PNG));
  floorplan.internalModelImage = toS3Object(createKey(keyprops, "-visualization_internal_model", FILE_TYPE.PNG));

  floorplan.basicImage = createExportFiles("-basic");
  floorplan.basicPlusImage = createExportFiles("-basicPlus");
  floorplan.professionalImageTop = createExportFiles("-professionalImageTop");
  floorplan.professionalImageTilted = createExportFiles("-professionalImageTilted");
  floorplan.professionalImageNE = createExportFiles("-professionalImageNE");
  floorplan.professionalImageNW = createExportFiles("-professionalImageNW");
  floorplan.professionalImageSE = createExportFiles("-professionalImageSE");
  floorplan.professionalImageSW = createExportFiles("-professionalImageSW");

  // model for editor/viewer
  floorplan.reactplannerModel = toS3Object(createKey(keyprops, "-reactplanner_model", FILE_TYPE.JSON));
  floorplan.floorplannerModel = toS3Object(createKey(keyprops, "-floorplanner_model", FILE_TYPE.FML));
  floorplan.ifcModel = toS3Object(createKey(keyprops, "-cad", FILE_TYPE.IFC));
  floorplan.reimportedIfcModel = toS3Object(createKey(keyprops, "-cad-reimport", FILE_TYPE.IFC));

  return floorplan;
};

const toS3Object = (key: string): S3ObjectInput => {
  return createS3Object(bucket, S3_REGION, key);
};

const createS3Object = (bucket: string, region: string, key: string, status?: FloorplanStatus): S3ObjectInput => {
  return {
    bucket: bucket,
    region: region,
    key: key,
    status: status ? status : FloorplanStatus.INITIALIZED,
  };
};
