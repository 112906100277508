import { LoadingAnimation } from "components/layout/LoadingAnimations";
import { Suspense } from "react";
import styled from "styled-components";
import { useQueryParams } from "utils/UrlUtils";
import Footer from "../../components/layout/Footer";
import { Header } from "../../components/layout/Header";
import { Content } from "../../components/layout/StyledComponents";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
const AppBarSpacer = styled.div`
  ${(p) => p.theme.mixins.toolbar}
`;
const Main = styled.main`
  flex-grow: 1;
  overflow: auto;
`;

// preventing backspace from going back in history
const handleBackspace = () => {
  window.onkeydown = (ev) => {
    if (ev.keyCode === 8 && ev.target === document.body) ev.preventDefault();
  };
};

type LayoutProps = {
  children?: JSX.Element[] | JSX.Element;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
};

const Layout = ({ children, maxWidth }: LayoutProps) => {
  let query = useQueryParams();
  let view = query.get("view");
  maxWidth = view === "editor" ? "xl" : "lg";

  return (
    <Root>
      <Header />
      <AppBarSpacer />
      <Suspense fallback={<LoadingAnimation />}>
        <Main onClick={() => handleBackspace()}>
          <Content maxWidth={maxWidth}>{children}</Content>
          <Footer />
        </Main>
      </Suspense>
    </Root>
  );
};

export default Layout;
