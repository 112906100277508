import React, { useEffect, useState } from "react";
import { Grid, LinearProgress, TextField, Typography } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import styled from "styled-components";
import { useIdentifyFloorplan } from "../../hooks/floorplan/IdentifyFloorplanHook";

import { useStateValue } from "./context/CheckoutStateContext";
import { CheckoutActionTypes } from "./context/CheckoutReducer";
import { NumberFormatSquareMeters } from "../../components/util/NumberFormatUtil";
import usePreprocessImageHook from "hooks/floorplan/PreprocessImageHook";

import { ACCEPTED_FILETYPES } from "../../constants";

import { Event } from "../../hooks/analytics/GoogleAnalytics";
import * as analytics from "../../hooks/analytics/GoogleAnalytics";
import { OriginalInputType } from "models";

const FormContainer = styled.div`
  padding-top: ${(p) => p.theme.spacing(8)}px;
`;

const InfoContainer = styled.div`
  margin-top: 6px;
`;

const DisclaimerContainer = styled.div`
  margin-top: 6px;
`;

const DISCLAIMERTEXT =
  "* Bitte beachten Sie das wir derzeit nur Pläne von einzelnen Wohneinheiten unter 200m2 automatisch digitalisieren können, keine Geschoßpläne, oder Dachgeschoßwohnungen. Wir arbeiten stetig daran auch diese Pläne für Sie digitalisieren zu können und informieren Sie alsbald. Maximale Dateigröße 5MB.";
const FLOORPLAN_ADD = "Bitte fügen Sie einen Grundriss hinzu. *";
const FLOORPLAN_RECOGNISED = "Grundriss wurde erkannt.";
const FLOORPLAN_NOT_DETECTED = "Es konnte kein Grundriss erkannt werden. Bitte versuchen Sie ein anderes Bild.";
const ANALYSIS_ERROR = "Bei der Analyse des Bildes ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.";
const FILE_TO_LARGE = "Das gewählte Bild ist zu groß, maximale Dateigröße 5MB. Bitte versuchen Sie ein anderes Bild.";

type Props = {
  inputType?: OriginalInputType;
};

const CheckoutFloorplanUpload = ({ inputType }: Props) => {
  const [status, setStatus] = useState(FLOORPLAN_ADD);
  const [fileInputValue, setFileInputvalue] = useState("");
  const [{ formValues, originalImageFile, originalInputType }, dispatch] = useStateValue();
  const initialFile = originalImageFile ? [originalImageFile] : [];

  const { containsFloorplan } = useIdentifyFloorplan();
  const { preprocessImage } = usePreprocessImageHook();

  // OriginalInput Type has to be set on first step in checkout
  useEffect(() => {
    if (inputType) {
      dispatch({
        type: CheckoutActionTypes.setOriginalInputType,
        key: "originalInputType",
        value: inputType,
      });
    }
    console.log("OriginalInputType:", originalInputType);
  }, [dispatch, inputType, originalInputType]);

  const handleFileDelete = () => {
    setStatus(FLOORPLAN_ADD);
    setFileInputvalue("");
    // set original, cropped and preprocessed image to null
    dispatchImageFiles(null, null);
  };

  const handleFileChange = async (fileObjects: File[]) => {
    if (fileObjects.length === 0) return;
    const newFile: File = fileObjects[0];

    // analytics
    const event: Event = {
      category: "Checkout",
      action: "imageUpload",
      label: "",
    };
    analytics.sendEvent(event);

    setFileInputvalue(newFile.name);
    console.log("NewFile: ", newFile);

    switch (originalInputType) {
      case OriginalInputType.SKETCH:
        handleSketch(newFile);
        break;
      case OriginalInputType.POINTCLOUD:
        handlePointCloud(newFile);
        break;
    }
  };

  const handleSketch = async (newFile: File) => {
    if (originalImageFile === null || newFile !== originalImageFile) {
      setStatus("analyzing");
      try {
        const { file: preprocessedImage } = await preprocessImage(newFile);
        const isValid = await containsFloorplan(preprocessedImage);
        if (isValid === true) {
          setStatus(FLOORPLAN_RECOGNISED);
          dispatchImageFiles(newFile, preprocessedImage);
        } else {
          setStatus(FLOORPLAN_NOT_DETECTED);
          setFileInputvalue("");
        }
      } catch (e) {
        console.log("Error during analysis: ", e);
        setStatus(ANALYSIS_ERROR);
        setFileInputvalue("");
      }
    } else {
      setStatus(FLOORPLAN_RECOGNISED);
      setFileInputvalue(newFile.name);
    }
  };

  const handlePointCloud = async (newFile: File) => {
    if (originalImageFile === null || newFile !== originalImageFile) {
      setStatus("analyzing");
      try {
        const { file: preprocessedImage } = await preprocessImage(newFile);
        if (preprocessedImage) {
          setStatus(FLOORPLAN_RECOGNISED);
          dispatchImageFiles(newFile, preprocessedImage);
        }
      } catch (e) {
        console.log("Error during preprocessing: ", e);
        setStatus(ANALYSIS_ERROR);
        setFileInputvalue("");
      }
    } else {
      setStatus(FLOORPLAN_RECOGNISED);
      setFileInputvalue(newFile.name);
    }
  };

  const handleFileRejected = (files: File[], event: any) => {
    setStatus(FILE_TO_LARGE);
  };

  const dispatchImageFiles = (newFile: File, preprocessedImage: File) => {
    // set original image file to new file
    dispatch({
      type: CheckoutActionTypes.editOriginalImageFile,
      key: "original",
      value: newFile,
    });
    // reset cropped file
    dispatch({
      type: CheckoutActionTypes.editCroppedImageFile,
      key: "original",
      value: null,
    });
    // set preprocessedImage
    dispatch({
      type: CheckoutActionTypes.editPreprocessedImageFile,
      key: "preprocessed",
      value: preprocessedImage,
    });
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        <FormContainer>
          <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2" align="center">
                Grundrissplan hochladen
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <DropzoneArea
                onChange={handleFileChange}
                onDelete={handleFileDelete}
                onDropRejected={handleFileRejected}
                acceptedFiles={ACCEPTED_FILETYPES}
                showPreviews={false}
                maxFileSize={5000000}
                filesLimit={1}
                dropzoneText={"Klicken oder Datei hinzufügen"}
                initialFiles={initialFile}
                showAlerts={false}
              />
              <input name="file" id="file" required={true} defaultValue={fileInputValue} style={{ opacity: 0 }} />
              <InfoContainer>
                {status === "analyzing" ? (
                  <>
                    <Typography>Analysiere...</Typography>
                    <LinearProgress />
                  </>
                ) : (
                  <>
                    <Typography>{status}</Typography>
                    <div style={{ height: "4px" }} />
                  </>
                )}
              </InfoContainer>
              <DisclaimerContainer>
                <Typography style={{ fontSize: 12 }}>{DISCLAIMERTEXT}</Typography>
              </DisclaimerContainer>
            </Grid>
          </Grid>
        </FormContainer>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormContainer>
          <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2" align="center">
                Projekt Details
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id="name"
                label="Name"
                name="name"
                value={formValues?.name ?? ""}
                onChange={(e) =>
                  dispatch({
                    type: CheckoutActionTypes.editFormValue,
                    key: "name",
                    value: e.target.value,
                  })
                }
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="user_total_size"
                label="Gesamtgröße der Wohneinheit (m2)"
                value={formValues?.user_total_size ?? ""}
                onChange={(e) =>
                  dispatch({
                    type: CheckoutActionTypes.editFormValue,
                    key: "user_total_size",
                    value: e.target.value,
                  })
                }
                InputProps={{
                  inputComponent: NumberFormatSquareMeters as any,
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="address"
                label="Adresse"
                value={formValues?.address ?? ""}
                onChange={(e) =>
                  dispatch({
                    type: CheckoutActionTypes.editFormValue,
                    key: "address",
                    value: e.target.value,
                  })
                }
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="description"
                name="description"
                label="Anmerkungen"
                value={formValues?.description ?? ""}
                onChange={(e) =>
                  dispatch({
                    type: CheckoutActionTypes.editFormValue,
                    key: "description",
                    value: e.target.value,
                  })
                }
                variant="outlined"
                fullWidth
                multiline
                rows={5}
              />
            </Grid>
          </Grid>
        </FormContainer>
      </Grid>
    </>
  );
};

export default CheckoutFloorplanUpload;
