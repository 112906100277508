import MaterialTable, { Column } from "material-table";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import getTableIcons from "../../utils/TableIcons";
import { toLocaleDate } from "../../utils/DateUtils";

import { Floorplan } from "../../models";
// import { FloorplanStatus } from "../../models";
// import { useUpdateFloorplan } from "../../hooks/UpdateFloorplanHooks";
// import { UpdateFloorplanInput, UpdateFloorplanMutation } from "../../API";

type Props = {
  floorplans: Floorplan[];
};

type TableColumns = Array<Column<RowData>>;
type TableData = RowData[];
type RowData = {
  id: string;
  name: string;
  address?: string;
  status?: string;
  level?: string;
  orderStatus?: string;
  createdAt: string;
  updatedAt: string;
};

const createTableData = (floorplans: Floorplan[]): TableData => {
  console.log("FLOORPLANS:", floorplans);
  return floorplans.map((floorplan) => {
    let data: RowData = {
      id: floorplan?.id,
      name: floorplan?.name,
      address: floorplan?.address,
      status: floorplan?.status,
      createdAt: toLocaleDate(floorplan?.createdAt),
      updatedAt: toLocaleDate(floorplan?.updatedAt),
    };

    const floorplanOrders = floorplan?.orders;
    if (floorplanOrders.length > 0) {
      data.level = floorplanOrders[0].order?.level;
      data.orderStatus = floorplanOrders[0].order?.orderStatus;
    }

    return data;
  });
};

// const floorplanToRowData = (input: UpdateFloorplanMutation): RowData => {
//   const row: RowData = {
//     id: input.updateFloorplan.id,
//     name: input.updateFloorplan.name,
//     address: input.updateFloorplan.address,
//     createdAt: input.updateFloorplan.createdAt,
//     updatedAt: input.updateFloorplan.updatedAt,
//   };
//   return row;
// };

const FloorplanList: React.FC<Props> = ({ floorplans }: Props) => {
  const history = useHistory();
  const tableIcons = getTableIcons();
  const [data] = useState<TableData>(() => createTableData(floorplans));
  const [columns] = useState<TableColumns>([
    // { title: "ID", field: "id" },
    { title: "Name", field: "name", editable: "never" },
    { title: "Adresse", field: "address", editable: "never" },
    // { title: "Produkt", field: "level", editable: "never" }, // TODO fix query and mapping to display this value
    // { title: "Order Status", field: "orderStatus", editable: "never" }, // TODO fix query and mapping to display this value
    { title: "Erstellt am", field: "createdAt", editable: "never" },
    { title: "Letzte Änderung am", field: "updatedAt", editable: "never" },
  ]);

  const viewDetails = (id: string) => {
    return history.push(`/detail/${id}`);
  };

  //const { updateFloorplan } = useUpdateFloorplan();

  /*const handleCellUpdate = async (newValue, rowData, resolve, reject): Promise<RowData> => {
    try {
      const updatedFP: UpdateFloorplanInput = {
        id: rowData.id,
        name: newValue,
      };
      const result: UpdateFloorplanMutation = await updateFloorplan(updatedFP);
      return floorplanToRowData(result);
    } catch (err) {
      console.error(err);
      reject(err);
    }
  };*/

  return (
    <MaterialTable
      components={{
        Container: (p) => p.children,
      }}
      title="Projekte"
      columns={columns}
      data={data}
      icons={tableIcons}
      localization={{
        header: {
          actions: "Aktionen",
        },
        toolbar: {
          searchTooltip: "Suche",
          searchPlaceholder: "Suche",
        },
        body: {
          emptyDataSourceMessage: "Keine Projekte vorhanden",
        },
        pagination: {
          labelRowsSelect: "Zeilen",
          labelDisplayedRows: "{from}-{to} von {count}",
          firstAriaLabel: "Erste Seite",
          firstTooltip: "Erste Seite",
          previousAriaLabel: "Vorherige Seite",
          previousTooltip: "Vorherige Seite",
          nextAriaLabel: "Nächste Seite",
          nextTooltip: "Nächste Seite",
          lastAriaLabel: "Letzte Seite",
          lastTooltip: "Letzte Seite",
        },
      }}
      onRowClick={(event, rowData) => viewDetails(rowData.id)}
      /*
      cellEditable={{
        onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
          return new Promise((resolve, reject) => {
            console.log("newValue: " + newValue);
            console.log("rowData", rowData);

            const dataUpdate = [...data];
            const index = dataUpdate.indexOf(rowData);
            dataUpdate[index].name = newValue;
            setData([...dataUpdate]);

            handleCellUpdate(newValue, rowData, resolve, reject)
              .then((result) => {
                resolve();
              })
              .catch((err) => {
                dataUpdate[index] = rowData;
                setData([...dataUpdate]);
              });
          });
        },
      }}
      */
    />
  );
};

export default FloorplanList;
