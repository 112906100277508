import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Floorplan } from "models";
import { useEffect, useState } from "react";
import useCreateProjectHook from "hooks/floorplannerApi/createProjectHook";
import { useToastProvider } from "../../providers/ToastProvider";

type PropTypes = {
  floorplan: Floorplan;
  onSuccess: Function;
  disabled: boolean;
};

const CFPButton: React.FC<PropTypes> = ({ floorplan: floorplanProp, onSuccess, disabled }) => {
  const [floorplan, setFloorplan] = useState(floorplanProp);
  const { createFloorplannerProject } = useCreateProjectHook();
  const { showSuccessToast, showErrorToast } = useToastProvider();

  useEffect(() => {
    setFloorplan(floorplan);
  }, [floorplan]);

  const handleCreateProject = async () => {
    try {
      const response = await createFloorplannerProject(floorplan.id);
      setFloorplan({ floorplannerId: response.floorplannedId, ...floorplan });
      onSuccess(response.floorplannerId);
      showSuccessToast("Floorplanner Projekt erfolgreich erstellt");
    } catch (error) {
      showErrorToast(`Floorplanner Projekt erstellen hat leider nicht geklappt: ${error.message}`);
    }
  };

  return (
    <Button disabled={disabled} variant="contained" color="primary" onClick={handleCreateProject} startIcon={<Add />}>
      Create Floorplanner Project
    </Button>
  );
};

export default CFPButton;
