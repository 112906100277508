import React from "react";
import Link from "@material-ui/core/Link";
import Skeleton from "@material-ui/lab/Skeleton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Fade, Grid, Tooltip, Typography } from "@material-ui/core";
import {
  ReactCompareSlider,
  ReactCompareSliderHandle,
  ReactCompareSliderImage,
  styleFitContainer,
} from "react-compare-slider";
import { ContentHeader, FlrPaper, Headline } from "components/layout/StyledComponents";
import FloorplanDropzone from "container/ai-preview/FloorplanDropzone";
import { useAIPreviewState } from "./context/AIPreviewContext";
import theme from "Theme";
import { useUser } from "providers/AuthContextProvider";
import IconLink from "components/layout/IconLink";

const reactCompareSliderImageStyle = {
  ...styleFitContainer({
    objectFit: "contain",
    objectPosition: "center",
    height: 800,
  }),
};

const AIPreview = () => {
  const { state } = useAIPreviewState();
  const { originalImageFile, preprocessingResult, aiPreviewResult, loadingPreview } = state;
  const { user } = useUser();

  return (
    <>
      <ContentHeader>
        <Tooltip title={user ? "Zum Dashboard" : "Zurück zu Website"}>
          {user ? (
            <IconLink tooltip="Zurück zum Dashboard" to="/dashboard" icon={<ArrowBackIcon />} />
          ) : (
            <Link href={"https://www.vloor.com/ai"} color="secondary">
              <ArrowBackIcon />
            </Link>
          )}
        </Tooltip>

        <Headline variant="h4" component="h1">
          Digitalisieren Sie jetzt ihren 2D Grundriss mit der Vloor AI
        </Headline>
      </ContentHeader>

      <Grid
        container
        direction="row"
        justify="center"
        style={{ alignItems: loadingPreview ? "center" : "flex-start" }}
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={originalImageFile ? 4 : 5}
          style={{
            transition: theme.transitions.create("all", {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }}
        >
          <FlrPaper elevation={3} style={{ height: "100%" }}>
            <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
              <Grid item>
                <Typography variant="h5" component="h2" align="center">
                  Grundrissplan hochladen
                </Typography>
              </Grid>
              <Grid item>
                <FloorplanDropzone />
              </Grid>
            </Grid>
          </FlrPaper>
        </Grid>

        <Fade in={originalImageFile != null} timeout={{ appear: 1000, enter: 1000, exit: 1000 }}>
          <Grid item xs={12} md={8}>
            {preprocessingResult && aiPreviewResult ? (
              <Fade in={!loadingPreview} timeout={{ enter: 1000, exit: 1000 }}>
                <FlrPaper elevation={3}>
                  <ReactCompareSlider
                    boundsPadding={80}
                    handle={<ReactCompareSliderHandle style={{ color: "#CC0007" }} />}
                    itemOne={
                      <ReactCompareSliderImage
                        alt="Image one"
                        src={preprocessingResult.imageUri}
                        style={reactCompareSliderImageStyle}
                      />
                    }
                    itemTwo={
                      <ReactCompareSliderImage
                        alt="Image two"
                        src={aiPreviewResult.imageUri}
                        style={reactCompareSliderImageStyle}
                      />
                    }
                    position={50}
                    style={{
                      flexGrow: 1,
                      width: "100%",
                    }}
                  />
                </FlrPaper>
              </Fade>
            ) : (
              <Fade in={loadingPreview} timeout={{ appear: 1000, enter: 1000, exit: 1000 }}>
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={12}>
                    <Typography variant="h4" align="center">
                      Vorschau wird erstellt...
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton variant="rect" animation="wave" height={100} width="100%" />
                  </Grid>
                </Grid>
              </Fade>
            )}
          </Grid>
        </Fade>
      </Grid>
    </>
  );
};

export default AIPreview;
