import { useDeleteRoom } from "./DeleteRoomHooks";
import { Floorplan } from "../../models";

import {
  DigitizeFloorplanQuery,
  DigitizeFloorplanQueryVariables,
  RecognitionServiceEventType,
  RecognitionServiceInput,
} from "../../API";
import * as queries from "../../graphql/queries";
import { gqlOperation } from "../GraphQLHooks";

// Event Structure example:
// {
//   "typeName": "Query",
//   "fieldName": "digitizeFloorplan",
//   "arguments": {
//     "floorplan_id": "000fd113-d23c-4380-9a3d-eeee88481f5e"
//   },
//   ...
// }

// TODO refactor to get integrated into RecognitionServiceHooks
export const useDigitizeFloorplan = () => {
  const { deleteRooms } = useDeleteRoom();

  const digitizeFloorplan = async (floorplan: Floorplan): Promise<any> => {
    console.log("Digitize floorplan called...", floorplan.id);

    deleteRooms(floorplan.rooms);

    const input: RecognitionServiceInput = {
      floorplan_id: floorplan.id,
      event_type: RecognitionServiceEventType.RECOGNITION,
    };

    const params: DigitizeFloorplanQueryVariables = {
      input: input,
    };

    try {
      const data = await gqlOperation<DigitizeFloorplanQuery>(queries.digitizeFloorplan, params);
      console.log("digitizeFloorplan result: ", data);
    } catch (err) {
      console.log("digitizeFloorplan error:", err);
    }
  };

  return { digitizeFloorplan };
};
