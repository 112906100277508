import React, { createContext, useContext, useReducer } from "react";
import ActionMap from "../utils/ActionMap";

export type GlobalActions = ActionMap<ActionPayload>[keyof ActionMap<ActionPayload>];

type GlobalState = {
  modalVisible: boolean;
  dropzoneVisible: boolean;
};

export enum Types {
  openDropzone = "OPEN_DROPZONE",
  closeDropzone = "CLOSE_DROPZONE",
  setDropzone = "SET_DROPZONE",
}

type ActionPayload = {
  [Types.openDropzone]: {
    dropzoneVisible: boolean;
  };
  [Types.closeDropzone]: {
    dropzoneVisible: boolean;
  };
  [Types.setDropzone]: {
    dropzoneVisible: boolean;
  };
};

const initialState: GlobalState = {
  modalVisible: false,
  dropzoneVisible: false,
};

export const StoreContext = createContext<{
  store: GlobalState;
  dispatch: React.Dispatch<any>;
}>({ store: initialState, dispatch: () => null });

const reducer = (state: GlobalState, action: GlobalActions) => {
  switch (action.type) {
    case Types.openDropzone:
      return {
        ...state,
        dropzoneVisible: true,
      };
    case Types.closeDropzone:
      return {
        ...state,
        dropzoneVisible: false,
      };
    case Types.setDropzone:
      return {
        ...state,
        dropzoneVisible: action.payload.dropzoneVisible,
      };
    default:
      return state;
  }
};

export const StoreProvider = ({ children }) => {
  const [store, dispatch] = useReducer(reducer, initialState);

  return <StoreContext.Provider value={{ store, dispatch }}>{children}</StoreContext.Provider>;
};

export const useStore = () => useContext(StoreContext);

export const useDropzoneStore = () => {
  const { store, dispatch } = useStore();
  return {
    isVisible: store.dropzoneVisible,
    showDropzone: () => {
      dispatch({ type: Types.openDropzone });
    },
    closeDropzone: () => {
      dispatch({ type: Types.closeDropzone });
    },
    setDropzone: (visible: boolean) => {
      dispatch({ type: Types.closeDropzone, payload: visible });
    },
  };
};
