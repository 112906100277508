import Footer from "components/layout/Footer";
import { ReducedHeader } from "components/layout/Header";
import { Content } from "components/layout/StyledComponents";
import styled from "styled-components";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const AppBarSpacer = styled.div`
  ${(p) => p.theme.mixins.toolbar}
`;

const MainGrid = styled.main`
  flex-grow: 1;
  overflow: auto;
`;

const WideLayout = ({ children }) => (
  <Root>
    <ReducedHeader />
    <AppBarSpacer />
    <MainGrid container spacing={2}>
      <Content maxWidth="xl">{children}</Content>
      <Footer />
    </MainGrid>
  </Root>
);

export default WideLayout;
