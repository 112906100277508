import Storage from "@aws-amplify/storage";
import * as KeyUtils from "../../utils/KeyUtils";
import { S3Object } from "models";
import { useState } from "react";

const useUploadImage = () => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);

  const uploadImage = async (s3Object: S3Object, file: File) => {
    setLoading(true);
    try {
      const res = await Storage.vault.put(KeyUtils.getPathFromKey(s3Object.key), file, {
        progressCallback(progress) {
          setUploadProgress((progress.loaded / progress.total) * 100);
        },
      });
      console.log("Upload Successful! file:", file, res);
      setLoading(false);
      return res;
    } catch (err) {
      setError(err);
      setLoading(false);
      console.error(err);
    }
  };

  return { uploadProgress, error, loading, uploadImage };
};

export { useUploadImage };
