import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { Button, ButtonGroup, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Share } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";

import { useGetFloorplan } from "../../hooks/floorplan/GetFloorplanHooks";
import { BASE_URL, ROOMPLANNER_FLOORPLANNER_BASE_URL, VIEWER_FLOORPLANNER_BASE_URL } from "../../constants";
import IFrame from "./IFrame";
import RoomplannerActionBlocker from "./RoomplannerActionBlocker";
import BackLink from "components/links/BackLink";

type Props = {
  id: string;
  mode: Modes;
};

export enum Modes {
  Editor = "roomplanner",
  Viewer = "viewer",
}

const FloorplanViewer = ({ id, mode }: Props) => {
  // get Floorplan data from API
  const { floorplan, isLoading } = useGetFloorplan(id);
  const [currentMode, setCurrentMode] = useState<Modes>(mode);
  const [copied, setCopied] = useState(false);

  const backLink = "/detail/" + id;

  const floorplannerLink =
    currentMode === "roomplanner"
      ? `${ROOMPLANNER_FLOORPLANNER_BASE_URL}${floorplan.floorplannerId}`
      : `${VIEWER_FLOORPLANNER_BASE_URL}${floorplan.floorplannerId}/${mode}`;

  // has to be floorplannerId here to enable shareability without login
  const shareUrl =
    BASE_URL + "/share/" + floorplan.floorplannerId + (currentMode === Modes.Viewer ? "/viewer" : "/editor");

  const handleModeSwitch = (mode: Modes) => {
    setCurrentMode(mode);
    setCopied(false);
  };

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={3}>
        <BackLink tooltip="Zurück zur Detailseite" to={backLink} />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4" component="h1" align="center">
          {isLoading ? <Skeleton /> : floorplan?.name}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Grid container justify="flex-end">
          <Grid item>
            <ButtonGroup variant="contained" color="primary">
              <Button
                onClick={() => handleModeSwitch(Modes.Viewer)}
                color={currentMode === Modes.Viewer ? "primary" : "secondary"}
              >
                Viewer
              </Button>
              <Button
                onClick={() => handleModeSwitch(Modes.Editor)}
                color={currentMode === Modes.Editor ? "primary" : "secondary"}
              >
                Editor
              </Button>
            </ButtonGroup>

            <Tooltip title="Link kopieren und teilen" placement="right">
              <CopyToClipboard text={shareUrl} onCopy={() => setCopied(true)}>
                <IconButton edge="end" color={copied ? "primary" : "secondary"}>
                  <Share />
                </IconButton>
              </CopyToClipboard>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      {!isLoading && (
        <Grid item xs={12}>
          <Grid container>
            {currentMode === Modes.Editor && (
              <Grid item xs={12} md={9}>
                <RoomplannerActionBlocker />
              </Grid>
            )}
            <Grid item xs={12} alignItems="center">
              <IFrame link={floorplannerLink} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default FloorplanViewer;
