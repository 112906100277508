import { Checkbox, Grid, Typography } from "@material-ui/core";
import { useContext, useState } from "react";
import { Furniture } from "./mockData/types";
import FurnitureSwapper from "./FurnitureSwapper";
import { FurnitureActionTypes, FurnitureConfigContext } from "providers/FurnitureConfigContextProvider";

type FPPropTypes = {
  furniture: Furniture;
};

const FurnitureCardPrice: React.FC<FPPropTypes> = ({ furniture }: FPPropTypes) => {
  const [swapping, setSwapping] = useState(false);
  const [, dispatch] = useContext(FurnitureConfigContext);

  return (
    <Grid container spacing={3} justify="space-between" alignItems="center">
      <Grid item xs={12} style={{ display: swapping ? "none" : "block" }}>
        <Grid container spacing={3} justify="space-between" alignItems="center">
          <Grid item xs={2}>
            <img alt="furniture" src={furniture.img} style={{ width: "100%" }} />
            {/* <IconButton
              aria-label="exchange"
              color="primary"
              onClick={() => {
                setSwapping(true);
              }}
            >
              <AutorenewIcon />
            </IconButton> */}
          </Grid>
          <Grid item xs={6}>
            <Typography>{furniture.title}</Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography>{furniture.price.toString().replace(".", ",")}€</Typography>
          </Grid>
          <Grid item xs={2}>
            <Checkbox
              color="primary"
              checked={furniture.checked}
              onChange={() =>
                dispatch({ type: FurnitureActionTypes.CHECK_FURNITURE, furniture, checked: !furniture.checked })
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <FurnitureSwapper
        swapping={swapping}
        furniture={furniture}
        onFinished={() => {
          setSwapping(false);
        }}
      />
    </Grid>
  );
};

export default FurnitureCardPrice;
