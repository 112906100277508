import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import styled from "styled-components";

import { useStateValue } from "./context/CheckoutStateContext";
import CheckoutProducts from "./CheckoutProducts";

const Container = styled.div`
  padding-top: ${(p) => p.theme.spacing(8)}px;
`;

const ImageContainer = styled.div`
  & > img {
    max-width: 100%;
  }
  margin-top: 8px;
`;

const CheckoutOverview = () => {
  const [croppedImage, setCroppedImage] = useState("");

  const [{ formValues, croppedImageFile }] = useStateValue();

  // load image
  useEffect(() => {
    console.log("CroppedImage: ", croppedImageFile);
    if (croppedImageFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setCroppedImage(reader.result as any);
      };
      reader.readAsDataURL(croppedImageFile);
    }
  }, [croppedImageFile]);

  return (
    <>
      <Grid item xs={12} md={6}>
        <Container>
          <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" component="h2" align="center">
                Übersicht
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" component="h5">
                Name: {formValues.name}
              </Typography>

              <Typography variant="h5" component="h2">
                Gesamtgröße: {formValues.user_total_size}m²
              </Typography>

              {formValues.addess && (
                <Typography variant="h5" component="h2">
                  Adresse: {formValues.address}
                </Typography>
              )}
              {formValues.description && (
                <Typography variant="h5" component="h2">
                  Anmerkungen: {formValues.description}
                </Typography>
              )}
              {/* <Typography variant="h5" component="h2">
                Breite: {formValues.user_scaling_cm}cm
              </Typography> */}
              <ImageContainer>
                <img src={croppedImage} alt="Cropped" />
              </ImageContainer>
            </Grid>
          </Grid>
        </Container>
      </Grid>

      <Grid item xs={12} md={6}>
        <Container>
          <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" component="h2" align="center">
                Bitte wählen Sie ein Produkt Paket aus.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <CheckoutProducts />
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </>
  );
};

export default CheckoutOverview;
