import React, { useEffect, useState } from "react";
import { FlrPaper } from "../../components/layout/StyledComponents";
import { Floorplan, FloorplanStatus, Level } from "../../models";
import * as KeyUtils from "../../utils/KeyUtils";
import Storage from "@aws-amplify/storage";
import { useIsContractor, useIsEditor } from "../../providers/AuthContextProvider";
import useCreatePresignedUrl from "../../hooks/exports/useCreatePresignedUrl";
import useKeysForOrderLevelHook from "../../hooks/exports/useKeysForOrderLevelHook";
import LightboxImages, { TabProps } from "components/floorplan-detail/LightboxImages";
import { FILE_TYPE } from "../../constants";
import { OrderStatus } from "models";

type ProductImagePanelProps = {
  floorplan: Floorplan;
  orderStatus: OrderStatus;
  orderLevel: Level;
};

export enum TabLabels {
  ORIGINAL = "Original",
  TAB_2D = "2D",
  TAB_2D_PLUS = "2D Möbliert",
  TAB_3D = "3D",
}

const ProductImagePanel: React.FC<ProductImagePanelProps> = ({ floorplan, orderStatus, orderLevel }) => {
  const [images, setImages] = useState([]);
  const isEditor = useIsEditor();
  const isContractor = useIsContractor();
  const { createPresignedURLs } = useCreatePresignedUrl();
  const { getKeysForOrderLevel } = useKeysForOrderLevelHook();

  const tabs: TabProps[] = [
    { label: TabLabels.ORIGINAL, selected: false, disabled: false },
    { label: TabLabels.TAB_2D, selected: false, disabled: true },
    { label: TabLabels.TAB_2D_PLUS, selected: false, disabled: true },
    { label: TabLabels.TAB_3D, selected: false, disabled: true },
    // { label: "VR", disabled: true },
  ];

  useEffect(() => {
    const loadImages = async (keys) => {
      try {
        const imageUrls = [];
        await Promise.all(
          keys.map(async (key) => {
            const imageUrl = await Storage.vault.get(KeyUtils.getPathFromKey(key));
            imageUrls.push(imageUrl);
          })
        );
        setImages(imageUrls);
      } catch (error) {
        console.log(error);
      }
    };

    const loadPresignedImages = async (keys) => {
      const urls = await createPresignedURLs(keys, "GET");
      setImages(urls);
    };

    const keys = getKeysForOrderLevel(floorplan, orderLevel, FILE_TYPE.PNG);
    if (isEditor) {
      loadPresignedImages(keys);
    } else {
      loadImages(keys);
    }
  }, [floorplan, isEditor, orderLevel, createPresignedURLs, getKeysForOrderLevel]);

  console.log(floorplan);

  const setTabStatii = (orderLevel: Level, floorplan: Floorplan, tabs: TabProps[]): TabProps[] => {
    let neededStatii: FloorplanStatus[];
    if (isEditor || isContractor) {
      neededStatii = [FloorplanStatus.QA_CREATED, FloorplanStatus.CREATED, FloorplanStatus.REWORK];
    } else if (orderStatus !== OrderStatus.CONFIRMED && orderStatus !== OrderStatus.DECLINED) {
      neededStatii = [FloorplanStatus.CREATED];
    }
    if (neededStatii && neededStatii.filter((nS) => nS === floorplan.status).length > 0) {
      tabs.map((tab) => {
        switch (tab.label) {
          case TabLabels.TAB_2D:
            tab.disabled = orderLevel === Level.FML;
            tab.selected = orderLevel === Level.BASIC;
            break;
          case TabLabels.TAB_2D_PLUS:
            tab.disabled = orderLevel === Level.FML || orderLevel === Level.BASIC;
            tab.selected = orderLevel === Level.BASIC_PLUS;
            break;
          case TabLabels.TAB_3D:
            tab.disabled = orderLevel === Level.FML || orderLevel === Level.BASIC || orderLevel === Level.BASIC_PLUS;
            tab.selected = orderLevel === Level.PROFESSIONAL;
            break;
        }
        return tab;
      });
    }

    return tabs;
  };

  return (
    <FlrPaper>{images && <LightboxImages images={images} tabs={setTabStatii(orderLevel, floorplan, tabs)} />}</FlrPaper>
  );
};

export default ProductImagePanel;
