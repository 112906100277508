import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Container, Grid, Link, Typography } from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";

import { Formik, Field, Form, FormikHelpers, FieldProps } from "formik";
import * as Yup from "yup";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import { useAuthUIState } from "./context/AuthUIStateContext";
import { AuthUIForm, AuthUIState } from "./context/AuthUIStateReducer";
import { GDPR_LINK, PASSWORD_REGEX, TERMS_LINK } from "../../constants";
import { CountrySelect } from "./CountrySelect";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
  subtext: {
    margin: theme.spacing(1, 0, 1),
  },
  textButton: {
    textTransform: "none",
  },
}));

const termsLink = (
  <div>
    <Typography variant="body1">
      Hiermit akzeptiere ich die{" "}
      <Link href={TERMS_LINK} target="_blank">
        AGB
      </Link>
    </Typography>
  </div>
);

const gdprLink = (
  <Typography variant="body1">
    Hiermit akzeptiere ich die{" "}
    <Link href={GDPR_LINK} target="_blank">
      Datenschutzerklärung
    </Link>
  </Typography>
);

interface FormValues {
  email: string;
  password: string;
  phone_number: string;
  company: string;
  company_address: string;
  company_address_line2: string;
  company_city: string;
  company_taxnumber: string;
  country: string;
  zip: string;
  accepted_terms: boolean;
  accepted_gdpr: boolean;
}

interface SignUpProps {
  signUp: (state: AuthUIState, setSubmitting: (isSubmitting: boolean) => void, recaptchaToken: string) => void;
  changeForm: (formType: AuthUIForm) => void;
}

const SignUp = ({ signUp, changeForm }: SignUpProps) => {
  const classes = useStyles();
  const { state } = useAuthUIState();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const initialValues: FormValues = {
    email: state.username,
    password: state.password,
    phone_number: state.formValues.phone_number,
    company: state.formValues.company,
    company_address: state.formValues.company_address,
    company_address_line2: state.formValues.company_address_line2,
    company_city: state.formValues.company_city,
    company_taxnumber: state.formValues.company_taxnumber,
    country: state.formValues.country,
    zip: state.formValues.zip,
    accepted_terms: false,
    accepted_gdpr: false,
  };

  const handleReCaptcha = useCallback(async () => {
    const token = await executeRecaptcha("signup_page");
    return token;
  }, [executeRecaptcha]);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Neuen Account erstellen
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            email: Yup.string().email("Ungültige Email Adresse").required("Bitte geben Sie ihre Email Adresse an."),
            password: Yup.string()
              .required("Bitte geben Sie ihr Passwort an.")
              .matches(PASSWORD_REGEX, "Mind. 8 Zeichen, 1 Großbuchstabe, 1 Kleinbuchstabe und 1 Zahl"),
            company: Yup.string().required("Bitte geben Sie den Namen ihrer Firma an."),
            company_address: Yup.string().required("Bitte geben Sie die Adresse ihres Firmensitzes an."),
            company_address_line2: Yup.string().optional(),
            company_city: Yup.string().required("Bitte geben Sie die Stadt ihres Firmensitzes an."),
            company_taxnumber: Yup.string().required("Bitte geben Sie UID Nummer ihrer Firma an."),
            zip: Yup.string().required("Bitte geben Sie Ihre PLZ an"),
            // country: Yup.string().required("Bitte geben Sie das Land ihres Firmensitzes an."), // not working with yup validation
            // phone_number: Yup.string().matches(PHONE_REGEX, "Bitte geben Sie eine gültige Telefonnummer an."),
            accepted_terms: Yup.boolean().required("Pflichtfeld").oneOf([true], "Sie müssen den AGB zustimmen."),
            accepted_gdpr: Yup.boolean()
              .required("Pflichtfeld")
              .oneOf([true], "Sie müssen der Datenschutzerklärung zustimmen."),
          })}
          onSubmit={async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
            console.log("submit");
            // delete password from formValues
            const password = values.password;
            values.password = "";

            // execute recaptcha and get token
            const recaptchaToken = await handleReCaptcha();
            console.log("recaptchaToken:", recaptchaToken);

            signUp(
              {
                username: values.email,
                password: password,
                formValues: { user: values.email, ...values },
              },
              setSubmitting,
              recaptchaToken
            );
          }}
        >
          {({ handleChange, values, setFieldValue }) => (
            <Form className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    required
                    component={TextField}
                    variant="outlined"
                    fullWidth
                    id="email"
                    name="email"
                    label="Email Adresse"
                    autoComplete="email"
                    placeholder="example@email.com"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    required
                    component={TextField}
                    variant="outlined"
                    fullWidth
                    id="password"
                    name="password"
                    label="Passwort"
                    type="password"
                    autoComplete="current-password"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant={"h6"} align="center">
                    Firmendaten
                  </Typography>
                  <Typography variant="subtitle2" align="center">
                    Ihre Firmendaten werden für die Rechnungslegung benötigt.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    required
                    component={TextField}
                    variant="outlined"
                    fullWidth
                    id="company"
                    name="company"
                    label="Firma"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    required
                    component={TextField}
                    variant="outlined"
                    fullWidth
                    id="company_address"
                    name="company_address"
                    label="Firmenadresse"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    variant="outlined"
                    fullWidth
                    id="company_address_line2"
                    name="company_address_line2"
                    label="Firmenadresse Zusatz"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    required
                    component={TextField}
                    variant="outlined"
                    fullWidth
                    id="company_city"
                    name="company_city"
                    label="Stadt"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field required component={TextField} variant="outlined" fullWidth id="zip" name="zip" label="PLZ" />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    required
                    component={TextField}
                    variant="outlined"
                    fullWidth
                    id="company_taxnumber"
                    name="company_taxnumber"
                    label="UID"
                  />
                </Grid>
                <Grid item xs={12}>
                  <CountrySelect
                    onChange={(e: object, value: any) => {
                      setFieldValue("country", value !== null && value.label ? value.label : "");
                    }}
                  />

                  {/* <Field
                    required
                    component={CountrySelect}
                    variant="outlined"
                    fullWidth
                    id="country"
                    name="country"
                    label="Land"
                  /> */}
                </Grid>

                <Grid item xs={12}>
                  <Typography variant={"h6"} align="center">
                    Kontaktdaten
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field id="phone_number" name="phone_number" component={PhoneField} />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    component={CheckboxWithLabel}
                    required
                    type="checkbox"
                    id="accepted_terms"
                    name="accepted_terms"
                    color="primary"
                    Label={{ label: termsLink }}
                  />
                  <Field
                    component={CheckboxWithLabel}
                    required
                    type="checkbox"
                    id="accepted_gdpr"
                    name="accepted_gdpr"
                    color="primary"
                    Label={{ label: gdprLink }}
                  />
                </Grid>
              </Grid>
              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                Registrieren
              </Button>
            </Form>
          )}
        </Formik>
        <Grid container justify="flex-end">
          <Grid item>
            <Typography variant={"body2"} className={classes.subtext}>
              Bereits registriert?
              <Button className={classes.textButton} color="primary" onClick={() => changeForm(AuthUIForm.SIGNIN)}>
                Zum Login
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

const PhoneField = (props: FieldProps) => {
  //console.log("props: ", props);
  return (
    <MuiPhoneNumber
      variant="outlined"
      fullWidth
      id="phone_number"
      name="phone_number"
      label="Telefonnummer"
      defaultCountry={"at"}
      onChange={(e: any) => props.form.setFieldValue("phone_number", e)}
    />
  );
};

export default SignUp;
