import { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, MenuItem, Select, Typography } from "@material-ui/core";
import { FlrPaper } from "components/layout/StyledComponents";

import { useQueryParams } from "utils/UrlUtils";
import BackLink from "components/links/BackLink";
//import VurnishedLogo from "../../assets/vurnished/vurnished_logo.png";

import rooms from "./rooms";
import { Pagination } from "@material-ui/lab";
import { RoomPagination } from "container/vurnished/RoomPagination";
import Lightbox from "react-image-lightbox";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import MuiRouterLink from "components/links/MuiRouterLink";

type Props = {
  floorplannerId: string;
};

const FloorplanEstateImages: React.FC<Props> = ({ floorplannerId }) => {
  const query = useQueryParams();
  const select = query.get("selected");

  const [room, setRoom] = useState(select === null ? rooms[1] : rooms[parseInt(select)]);
  const [selectedImage, setSelectedImage] = useState(room.images[0]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  useEffect(() => {
    setSelectedImage(room.images[0]);
  }, [room]);

  useEffect(() => {
    setPhotoIndex(room.images.indexOf(selectedImage));
  }, [selectedImage, room.images]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={2} sm={4} md={3}>
        <BackLink to={`/estate/${floorplannerId}/editor`} tooltip={"Zurück zum Editor"} />
      </Grid>

      <Grid
        item
        xs={2}
        sm={3}
        md={6}
        component={Box}
        style={{ justifyContent: "center", display: "flex", alignItems: "center" }}
      >
        <Typography variant="h4" align="center">
          Phantastische 2-Zimmer Wohnung
        </Typography>
      </Grid>

      <Grid item xs={8} sm={5} md={3}>
        {/* <img src={VurnishedLogo} alt="Logo" height="60" /> */}

        <Select
          value={room.id}
          onChange={(event) => {
            setRoom(rooms[(event.target.value as number) - 1]);
          }}
          label="Raum"
        >
          {rooms.map((room) => (
            <MenuItem key={room.id} value={room.id}>
              <Typography variant="h5">{room.title}</Typography>
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={3} direction="column" alignItems="center">
          <Grid item xs={12}>
            <Grid container spacing={3} alignItems="center" direction="column" wrap="nowrap">
              <Grid item xs={12}>
                <FlrPaper style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ position: "relative", display: "flex", justifyContent: "center" }}>
                    <img
                      style={{
                        width: "auto",
                        maxHeight: "669px",
                        borderRadius: "14px",
                        cursor: "pointer",
                        position: "relative",
                      }}
                      alt="roomImage"
                      src={selectedImage}
                      onClick={() => {
                        setLightboxOpen(true);
                      }}
                    />
                    <IconButton
                      style={{ color: "white", position: "absolute", bottom: 0, left: 0 }}
                      onClick={() => setLightboxOpen(true)}
                    >
                      <ZoomInIcon />
                    </IconButton>
                  </div>

                  {room && (
                    <Pagination
                      style={{ padding: "10px 0", justifyContent: "center", display: "flex" }}
                      count={Object.keys(rooms).length}
                      color="primary"
                      page={room.images.indexOf(selectedImage) + 1}
                      size="large"
                      onChange={(event, value) => {
                        setSelectedImage(room.images[value - 1]);
                      }}
                      shape="rounded"
                      renderItem={(item) => (
                        <RoomPagination
                          params={item}
                          selected={item.page - 1 === room.images.indexOf(selectedImage)}
                          room={room}
                        />
                      )}
                    />
                  )}
                </FlrPaper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MuiRouterLink to={`/estate/${floorplannerId}/matterport`}>
              <Button variant="contained" color="primary">
                Zur Matterport Tour
              </Button>
            </MuiRouterLink>
          </Grid>
        </Grid>
      </Grid>

      {lightboxOpen && (
        <Lightbox
          mainSrc={room.images[photoIndex]}
          nextSrc={room.images[(photoIndex + 1) % room.images.length]}
          prevSrc={room.images[(photoIndex + room.images.length - 1) % room.images.length]}
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + room.images.length - 1) % room.images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % room.images.length)}
          reactModalStyle={{
            overlay: {
              zIndex: 1300,
            },
          }}
        />
      )}
    </Grid>
  );
};

export default FloorplanEstateImages;
