import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  LinearProgress,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import useCreatePresignedUrl from "../../hooks/exports/useCreatePresignedUrl";
import { DropzoneArea } from "material-ui-dropzone";
import { useToastProvider } from "../../providers/ToastProvider";
import React, { useState } from "react";
import { Floorplan } from "../../models";
import { getMimeType } from "../../utils/FileTypeUtil";
import { FILE_TYPE, ACCEPTED_FILETYPES } from "../../constants";

type DialogProps = {
  open: boolean;
  onClose: () => void;
  floorplan: Floorplan;
};

const EditorUploadImagesDialog: React.FC<DialogProps> = ({ open, onClose, floorplan }) => {
  const [fileBasic, setFileBasic] = useState(null);
  const [fileBasicPlus, setFileBasicPlus] = useState(null);
  const [filesProfessional, setFilesProfessional] = useState(null);
  const [loading, setLoading] = useState(false);
  const { createPresignedURLs } = useCreatePresignedUrl();
  const { showToast } = useToastProvider();
  const [fileType, setFileType] = useState<FILE_TYPE>(FILE_TYPE.PNG);

  const uploadImages = async () => {
    setLoading(true);

    try {
      var keysToUploadBasic = [floorplan?.basicImage[fileType].key];
      var keysToUploadBasicPlus = [floorplan?.basicPlusImage[fileType].key];
      var keysToUpload3D = [
        floorplan.professionalImageTop[fileType].key,
        floorplan.professionalImageTilted[fileType].key,
        floorplan.professionalImageNE[fileType].key,
        floorplan.professionalImageNW[fileType].key,
        floorplan.professionalImageSE[fileType].key,
        floorplan.professionalImageSW[fileType].key,
      ];

      const uploadFile = async (url, file) => {
        const fetchConfig = {
          method: "PUT",
          headers: {
            "Content-Type": getMimeType(fileType),
          },
          body: file,
        };
        const result = await fetch(url, fetchConfig);
        if (result.status === 200) {
          counter--;
          if (counter === 0) {
            setLoading(false);
          }
          showToast({
            severity: "success",
            content: `${file.name} wurde hochgeladen`,
          });
        } else {
          showToast({
            severity: "danger",
            content: `${file.name} Fehler beim hochladen`,
          });
        }
      };

      var counter = 0;
      if (fileBasic.length > 0) {
        counter++;
        const urlBasic = await createPresignedURLs(keysToUploadBasic, "PUT");
        uploadFile(urlBasic[0], fileBasic[0]);
      }
      if (fileBasicPlus.length > 0) {
        counter++;
        const urlBasicPlus = await createPresignedURLs(keysToUploadBasicPlus, "PUT");
        uploadFile(urlBasicPlus[0], fileBasicPlus[0]);
      }
      if (filesProfessional.length === 6) {
        const urlsProfessional = await createPresignedURLs(keysToUpload3D, "PUT");
        for (let index = 0; index < filesProfessional.length; index++) {
          counter++;
          const file = filesProfessional[index];
          const url = urlsProfessional[index];
          uploadFile(url, file);
        }
      }
      if (counter === 0) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"lg"} fullWidth>
      <DialogTitle>Produktbilder hochladen</DialogTitle>
      <DialogContent>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={12} md={6}>
            <Typography>2D Basic</Typography>
            <DropzoneArea
              onChange={(fileObjects: File[]) => {
                setFileBasic(fileObjects);
              }}
              acceptedFiles={ACCEPTED_FILETYPES}
              showPreviews={false}
              maxFileSize={50000000}
              filesLimit={1}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>2D Basic + Möbel</Typography>
            <DropzoneArea
              onChange={(fileObjects: File[]) => {
                setFileBasicPlus(fileObjects);
              }}
              acceptedFiles={ACCEPTED_FILETYPES}
              showPreviews={false}
              maxFileSize={50000000}
              filesLimit={1}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography>3D, Tilted, NE, NW, SE, SW</Typography>
            <DropzoneArea
              onChange={(fileObjects: File[]) => {
                setFilesProfessional(fileObjects);
              }}
              acceptedFiles={ACCEPTED_FILETYPES}
              showPreviews={false}
              maxFileSize={50000000}
              filesLimit={6}
            />
            {loading && <LinearProgress />}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <RadioGroup>
          <Grid container direction="row" justify="flex-end" alignItems="center">
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    checked={fileType === FILE_TYPE.PNG}
                    onChange={() => {
                      setFileType(FILE_TYPE.PNG);
                    }}
                    color="primary"
                  />
                }
                label="PNG"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    checked={fileType === FILE_TYPE.JPEG}
                    onChange={() => {
                      setFileType(FILE_TYPE.JPEG);
                    }}
                    color="primary"
                  />
                }
                label="JPEG"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    checked={fileType === FILE_TYPE.PDF}
                    onChange={() => {
                      setFileType(FILE_TYPE.PDF);
                    }}
                    color="primary"
                  />
                }
                label="PDF"
              />
            </Grid>
          </Grid>
        </RadioGroup>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={uploadImages}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditorUploadImagesDialog;
