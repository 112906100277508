import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import { Formik, Field, Form, FormikHelpers } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";

import { useAuthUIState } from "./context/AuthUIStateContext";
import { AuthUIForm } from "./context/AuthUIStateReducer";
// import FederatedSignIn from "./FederatedSignIn";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0, 0),
  },
  subtext: {
    margin: theme.spacing(1, 0, 1),
  },
  textButton: {
    textTransform: "none",
  },
}));

interface Values {
  email: string;
  password: string;
}

interface SignInProps {
  signIn: (username: string, password: string) => void;
  changeForm: (formType: AuthUIForm) => void;
}

const SignIn = ({ signIn, changeForm }: SignInProps) => {
  const classes = useStyles();
  const { state } = useAuthUIState();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography variant="h5">Anmeldung</Typography>
        {/* <FederatedSignIn /> */}
        <Formik
          initialValues={{
            email: state.username,
            password: "",
          }}
          validationSchema={Yup.object({
            email: Yup.string().email("Ungültige Email Adresse").required("Bitte geben Sie ihre Email Adresse an."),
            password: Yup.string().required("Bitte geben Sie ihr Passwort an."),
          })}
          onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
            signIn(values.email, values.password);
            setSubmitting(false);
          }}
        >
          <Form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  required
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  id="email"
                  name="email"
                  label="Email Adresse"
                  autoComplete="email"
                  placeholder="example@email.com"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  required
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  id="password"
                  name="password"
                  label="Passwort"
                  type="password"
                  autoComplete="current-password"
                />
                <Typography variant={"body2"} className={classes.subtext}>
                  Passwort vergessen?
                  <Button
                    className={classes.textButton}
                    color="primary"
                    onClick={() => changeForm(AuthUIForm.FORGOTPASSWORD)}
                  >
                    Zurücksetzen
                  </Button>
                </Typography>
              </Grid>
            </Grid>
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Einloggen
            </Button>
          </Form>
        </Formik>
        <Grid container justify="flex-end">
          <Grid item>
            <Typography variant={"body2"} className={classes.subtext}>
              Noch nicht registriert?
              <Button className={classes.textButton} color="primary" onClick={() => changeForm(AuthUIForm.SIGNUP)}>
                Registrieren
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default SignIn;
