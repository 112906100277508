import { Link as RouterLink } from "react-router-dom";
import { Link } from "@material-ui/core";

type Props = {
  to: string;
  target?: string;
};

const MuiRouterLink: React.FC<Props> = ({ to, target, children }) => {
  return (
    <Link component={RouterLink} to={to} target={target}>
      {children}
    </Link>
  );
};

export default MuiRouterLink;
