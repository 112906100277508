import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Collapse,
  FormControlLabel,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Radio,
  RadioGroup,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  Edit,
  Done,
  DoneAll,
  ImportExport,
  Mail,
  Sync,
  Brush,
  ShoppingCart,
  ArrowDropDown,
  ArrowDropUp,
} from "@material-ui/icons";

import { FlrPaper } from "components/layout/StyledComponents";
import { Floorplan, FloorplanStatus, Order } from "models";
import { OrderStatus, UpdateFloorplanInput, UpdateOrderInput } from "API";

import { useUpdateFloorplan } from "hooks/floorplan/UpdateFloorplanHooks";
import useImportImagesHook from "hooks/floorplannerApi/ImportImagesHook";
import { useToastProvider } from "providers/ToastProvider";
import { sendOrderProcessed } from "hooks/mails/MailServiceHooks";
import { useUpdateOrder } from "hooks/order/UpdateOrderHooks";
import useCreatePresignedUrl from "hooks/exports/useCreatePresignedUrl";
import useKeysForOrderLevelHook from "hooks/exports/useKeysForOrderLevelHook";
import { FILE_TYPE } from "../../constants";

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    maxWidth: 380,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

type OrderFulfillmentPanelProps = {
  floorplan: Floorplan;
  order: Order;
};

const OrderFulfillmentPanel: React.FC<OrderFulfillmentPanelProps> = ({
  floorplan,
  order,
}: OrderFulfillmentPanelProps) => {
  const [floorplanStatus, setFloorplanStatus] = useState(floorplan.status);
  const [orderStatus, setOrderStatus] = useState(order?.orderStatus);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [floorplannerId, setFloorplannerId] = useState(floorplan.floorplannerId);
  const [open, setOpen] = useState(false);

  const [orderLevel, setOrderLevel] = useState(order?.level);

  const { showSuccessToast, showErrorToast } = useToastProvider();
  const { importImages, orientation, changeOrientation, ExportOrientation } = useImportImagesHook();
  const { updateFloorplan } = useUpdateFloorplan();
  const { updateOrder } = useUpdateOrder();
  const { createPresignedURLs } = useCreatePresignedUrl();
  const { getKeysForOrderLevel } = useKeysForOrderLevelHook();

  useEffect(() => {
    setOrderLevel(order?.level);
    setOrderStatus(order?.orderStatus);
  }, [order]);

  const handleImport = async () => {
    try {
      await importImages(orderLevel, floorplannerId);
      showSuccessToast("Successfully imported images from Floorplanner!");
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  const handleReviewApproved = async () => {
    let updatedFloorplan: UpdateFloorplanInput = {
      id: floorplan.id,
      status: FloorplanStatus.CREATED,
    };

    const res = await updateFloorplan(updatedFloorplan);
    console.log("FloorplanStatus: ", res.updateFloorplan.status);
    setFloorplanStatus(res.updateFloorplan.status);

    showSuccessToast("Review approved. Floor plan status was set to CREATED! You can now finish the order.");
  };

  const handleOrderFulfilled = async () => {
    const updatedOrder: UpdateOrderInput = {
      id: order?.id,
      orderStatus: OrderStatus.PROCESSED,
    };
    const res = await updateOrder(updatedOrder);
    setOrderStatus(res.updateOrder.orderStatus);

    // get presigned PDF urls to link to in order processed mail
    const expiresIn = 432000; // 5 days
    const keys = getKeysForOrderLevel(floorplan, order?.level, FILE_TYPE.PDF);
    const urls: string[] = await createPresignedURLs(keys, "GET", expiresIn);

    await sendOrderProcessed(floorplan.customer, order, floorplan, urls);
    showSuccessToast("Order finished. A mail was sent to the customer!");
  };

  return (
    <FlrPaper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2" align="center">
            Order Fulfillment Panel
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <List>
            <ListSubheader key={1}>1. Review project in Floorplanner</ListSubheader>
            {orderLevel && (
              <ListItem key="OFPorderlevel1">
                <HtmlTooltip
                  placement="left"
                  title={
                    <>
                      <Typography color="inherit">Order Levels:</Typography>
                      <b>Basic: </b> basic furnishing, toilet, kitchen, bathroom <br />
                      <b>Basic Plus: </b> fully furnished <br />
                      <b>Professional: </b> fully furnished for 3D model <br />
                      <b>Premium: </b> staged for HD rendering <br />
                    </>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <ShoppingCart />
                    </Avatar>
                  </ListItemAvatar>
                </HtmlTooltip>
                <ListItemText>{orderLevel}</ListItemText>
              </ListItem>
            )}
            <ListItem key="OFPfloorplannerLink2">
              <HtmlTooltip title="Floorplanner Link" placement="left">
                <ListItemAvatar>
                  <Avatar>
                    <Edit />
                  </Avatar>
                </ListItemAvatar>
              </HtmlTooltip>
              {floorplannerId !== null && floorplannerId !== "" ? (
                <Link
                  href={`https://floorplanner.com/projects/${floorplannerId}/editor`}
                  target="_blank"
                  color="primary"
                >
                  Link to Floorplanner
                </Link>
              ) : (
                "No Id set"
              )}
            </ListItem>

            <ListSubheader key={2}>2. Approve Review</ListSubheader>
            <ListItem key="OFPreimport3">
              <HtmlTooltip
                placement="left"
                title={
                  <>
                    <Typography color="inherit">
                      Reimport images from Floorplanner if changes had to be made during review.
                    </Typography>
                  </>
                }
              >
                {/* Added span to prevent MaterialUI warning for disabled Button */}
                <span>
                  <Button
                    disabled={floorplannerId === null}
                    variant="contained"
                    color="primary"
                    onClick={handleImport}
                    startIcon={<ImportExport />}
                  >
                    Reimport Floorplanner Images
                  </Button>
                  <RadioGroup row onChange={changeOrientation}>
                    {Object.values(ExportOrientation).map((exportOrientation) => (
                      <FormControlLabel
                        key={exportOrientation}
                        control={<Radio checked={orientation === exportOrientation} color="primary" />}
                        label={exportOrientation}
                      />
                    ))}
                  </RadioGroup>
                </span>
              </HtmlTooltip>
            </ListItem>
            <ListItem key="OFPapprove4">
              <HtmlTooltip
                placement="left"
                title={
                  <>
                    <Typography color="inherit">Approve Review:</Typography>
                    Please approve the review request from the contractor and then you can finish the order. This will
                    set the FloorplanStatus to Created. The user cannot see the results yet. To be able to approve
                    FloorplanStatus needs to be set to QA_CREATED.
                  </>
                }
              >
                {/* Added span to prevent MaterialUI warning for disabled Button */}
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Done />}
                    onClick={handleReviewApproved}
                    disabled={floorplannerId === null || floorplanStatus !== FloorplanStatus.QA_CREATED}
                  >
                    Approve Floor Plan
                  </Button>
                </span>
              </HtmlTooltip>
            </ListItem>

            <ListSubheader key={3}>3. Finish Order</ListSubheader>
            {orderStatus && (
              <>
                <ListItem key="OFPMailStatus5">
                  <Tooltip title="Mail sent to Customer" placement="top">
                    <ListItemAvatar>
                      <Avatar>
                        <Mail />
                      </Avatar>
                    </ListItemAvatar>
                  </Tooltip>
                  <Typography>
                    {orderStatus === OrderStatus.PROCESSED
                      ? "Mail already sent to customer!"
                      : "No Mail has been sent."}
                  </Typography>
                </ListItem>
                <ListItem key="OFPfinish6">
                  {/* Added span to prevent MaterialUI warning for disabled Button */}
                  <HtmlTooltip
                    placement="left"
                    title={
                      <>
                        <Typography color="inherit">Finish Order:</Typography>
                        To finish order, please approve the review request. This will set the OrderStatus to PROCESSED
                        and send an email notification to the customer. The customer can then see the results.
                      </>
                    }
                  >
                    <span>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<DoneAll />}
                        onClick={handleOrderFulfilled}
                        disabled={floorplanStatus !== FloorplanStatus.CREATED || orderStatus === OrderStatus.PROCESSED}
                      >
                        Finish Order
                      </Button>
                    </span>
                  </HtmlTooltip>
                </ListItem>
              </>
            )}

            <ListItem
              key="OFPshowMore"
              style={{ display: "flex", justifyContent: "flex-end" }}
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open ? (
                <>
                  <Link key="less">Show less</Link>
                  <ArrowDropUp />
                </>
              ) : (
                <>
                  <Link key="more">Show more</Link>
                  <ArrowDropDown />
                </>
              )}
            </ListItem>
            <Collapse key="OFPcollapse7" in={open} timeout="auto" unmountOnExit>
              <ListSubheader key={4}>Project Status:</ListSubheader>
              {floorplanStatus && (
                <ListItem key="OFPfloorplanStatus8">
                  <HtmlTooltip
                    placement="top"
                    title={
                      <>
                        <Typography color="inherit">Floorplan Status:</Typography>
                        <ul>
                          <li> INITIALIZED: Initial status </li>
                          <li> GENERATED: Generated by ML </li>
                          <li> IN_PROGRESS: Contractor is working on floor plan.</li>
                          <li> REJECTED: Contractor has rejected the floor plan.</li>
                          <li> QA_CREATED: Contractor has finished working on floor plan, please review. </li>
                          <li> REWORK: Floor plan needs to be reworked. </li>
                          <li> CREATED: Floor plan has been reviewed and is ready for the customer </li>
                        </ul>
                      </>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Brush />
                      </Avatar>
                    </ListItemAvatar>
                  </HtmlTooltip>
                  <ListItemText>{floorplanStatus}</ListItemText>
                </ListItem>
              )}
              {orderStatus && (
                <ListItem key="OFPorderStatus9">
                  <HtmlTooltip
                    placement="top"
                    title={
                      <>
                        <Typography color="inherit">Order Status:</Typography>
                        <ul>
                          <li> CONFIRMED: Order was confirmed/accepted by Vloor. </li>
                          <li> DECLINED: Order had to be declined by Vloor. </li>
                          <li> PROCESSED: Order has been successfully processed. Customer can see the results! </li>
                          <li> INVOICED: An invoice has been sent to the customer. </li>
                          <li> FINISHED: Invoice has been paid by the customer. </li>
                        </ul>
                      </>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Sync />
                      </Avatar>
                    </ListItemAvatar>
                  </HtmlTooltip>
                  <ListItemText>{orderStatus}</ListItemText>
                </ListItem>
              )}
            </Collapse>
          </List>
        </Grid>
      </Grid>
    </FlrPaper>
  );
};

export default OrderFulfillmentPanel;
