import { Grid } from "@material-ui/core";
import ChatItem from "container/communication/ChatItem";
import { useGetChatMessagesHook, subscribeChatMessagesHook } from "hooks/communication/ChatMessageHooks";
import { useEffect, useState } from "react";

import styled from "styled-components";
import Observable from "zen-observable-ts";

const ChatContainer = styled(Grid)`
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
`;

type Props = {
  floorplanId: string;
};

//TODO show if connected to live chat currently or if there was issue
//reconnect if subscription errors

const ChatView: React.FC<Props> = ({ floorplanId }) => {
  const [allChatMessages, setAllChatMessages] = useState([]);
  const [subscription, setSubscription] = useState<Observable<any>>();
  const { chatMessages, error, isLoading } = useGetChatMessagesHook(floorplanId);

  useEffect(() => {
    if (!isLoading) setAllChatMessages(chatMessages);
  }, [isLoading, chatMessages]);

  useEffect(() => {
    const subscribe = async () => {
      const sub = await subscribeChatMessagesHook(floorplanId);
      setSubscription(sub);
      sub.subscribe({
        next: ({ value }) => {
          setAllChatMessages((allChatMessages) => [value.data.onChatMessageByFloorplanId, ...allChatMessages]);
          console.log(value);
        },
      });
    };
    subscribe();
    return () => {
      if (subscription !== undefined) {
        try {
          const sub = subscription as any;
          sub.unsubscribe();
          setSubscription(null);
        } catch (error) {
          console.error(error, "woops failed to unsub");
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floorplanId]);

  return (
    <ChatContainer item xs={12}>
      <>
        <div>{error ? "❌ Fehler beim Laden, bitte versuchen Sie die Seite zu aktualisieren!" : ""}</div>
        {allChatMessages.map((chatMessage) => (
          <ChatItem key={chatMessage.id} chatMessage={chatMessage}></ChatItem>
        ))}
      </>
    </ChatContainer>
  );
};

export default ChatView;
