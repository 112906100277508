// import ActionMap from "../../utils/ActionMap";

import { PreprocessingResult } from "hooks/floorplan/PreprocessImageHook";
import { AIPreviewResult } from "hooks/floorplan/RecognitionServiceHooks";
import { OriginalInputType } from "models";

type AIPreviewInput = {
  name: string;
  email: string;
};

export enum AIPreviewActionTypes {
  setOriginalInputType = "SET_ORIGINAL_INPUT_TYPE",
  editFormValue = "EDIT_FORM_VALUE",
  editOriginalImageFile = "EDIT_ORIGINAL_IMAGE",
  resetAIPreviewContext = "RESET_AIPREVIEW_CONTEXT",

  editPreprocessingResult = "EDIT_PREPROCESSING_RESULT",
  editAIPreviewResult = "EDIT_AIPREVIEW_RESULT",
}

export type AIPreviewState = {
  formValues: AIPreviewInput;
  originalImageFile: File;
  originalInputType: OriginalInputType;

  preprocessingResult: PreprocessingResult;
  aiPreviewResult: AIPreviewResult;

  loadingPreview: boolean;
};

const initialState: AIPreviewState = {
  formValues: {
    name: "",
    email: "",
  },
  originalImageFile: null,
  originalInputType: OriginalInputType.SKETCH,

  preprocessingResult: null,
  aiPreviewResult: null,

  loadingPreview: false,
};

const reducer = (state: AIPreviewState, action) => {
  switch (action.type) {
    case AIPreviewActionTypes.editFormValue:
      state.formValues[action.key.toLowerCase()] = action.value;
      return { ...state };

    case AIPreviewActionTypes.editOriginalImageFile:
      state.originalImageFile = action.value;
      state.loadingPreview = true;
      return { ...state };

    case AIPreviewActionTypes.setOriginalInputType:
      state.originalInputType = action.value;
      return { ...state };

    case AIPreviewActionTypes.editPreprocessingResult:
      state.preprocessingResult = action.value;
      return { ...state };

    case AIPreviewActionTypes.editAIPreviewResult:
      state.aiPreviewResult = action.value;
      state.loadingPreview = false;
      return { ...state };

    case AIPreviewActionTypes.resetAIPreviewContext:
      state.formValues.name = "";
      state.formValues.email = "";
      state.originalImageFile = null;
      state.originalInputType = OriginalInputType.SKETCH;
      state.preprocessingResult = null;
      state.aiPreviewResult = null;
      state.loadingPreview = false;
      return { ...state };

    default:
      return state;
  }
};

export { initialState, reducer };
