import React from "react";
import CheckoutSteps from "./CheckoutSteps";
import { OriginalInputType } from "models";

type Props = {
  inputType?: OriginalInputType;
};

const Checkout = ({ inputType }: Props) => {
  return (
    <>
      <CheckoutSteps inputType={inputType} />
    </>
  );
};

export default Checkout;
