import styled from "styled-components";

const ProductImg = styled.img`
  padding: 2px;
  width: 100%;
  height: 100%;
  background-color: white;
  border: ${(props) => `2px solid ${props.$selected ? props.theme.palette.primary.main : "transparent"}`};
  border-radius: 14px;
  &:hover {
    cursor: pointer;
    outline: 0;
  }
`;

type PIPropTypes = {
  img: string;
  selected?: boolean;
  onClick?: any;
};

const ProductImage: React.FC<PIPropTypes> = ({ img, selected, onClick }) => {
  return <ProductImg $selected={selected} src={img} onClick={onClick} />;
};

export default ProductImage;
