import MaterialTable, { Column } from "material-table";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import getTableIcons from "../../utils/TableIcons";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
//import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";
import { toLocaleDateShort } from "../../utils/DateUtils";

import { Floorplan, FloorplanOrder, Order, FloorplanStatus, OrderStatus, PaymentStatus, Level } from "../../models";
import { useUpdateFloorplan } from "../../hooks/floorplan/UpdateFloorplanHooks";
import { useUpdateOrder } from "../../hooks/order/UpdateOrderHooks";

import { UpdateFloorplanInput, UpdateFloorplanMutation, UpdateOrderInput, UpdateOrderMutation } from "../../API";
import { useToastProvider } from "providers/ToastProvider";

type Props = {
  floorplans: Floorplan[];
  pageSize?: number;
  setPageSize?: (pageSize: number) => void;
  pageSizeOptions?: number[];
  refetchEntries: () => void;
};

type TableColumns = Array<Column<RowData>>;
type TableData = RowData[];
type RowData = {
  id: string;
  name: string;
  owner: string;
  createdAt: string;
  status?: Status;
  references?: References;
  customerEmail?: string;
  tableData?: any;
};

type Status = {
  floorplanStatus?: FloorplanStatus;
  level?: Level;
  orderStatus?: OrderStatus;
  paymentStatus?: PaymentStatus;
};

type References = {
  floorplanOrderId?: string;
  orderId?: string;
  customerId?: string;
};

const createTableData = (floorplans: Floorplan[]): TableData => {
  return floorplans.map((fp) => {
    let floorplan: RowData = {
      id: fp.id,
      name: fp.name,
      owner: fp.owner,
      createdAt: fp.createdAt,
      customerEmail: fp.customer?.email,
    };

    const floorplanOrders = fp?.orders;

    let references: References = {
      orderId: "string",
      customerId: fp?.customer?.id,
    };

    let stati: Status[];
    if (floorplanOrders.length > 0) {
      stati = floorplanOrders
        .filter((e) => e.order != null)
        .map((fpo: FloorplanOrder): Status => {
          const order: Order = fpo?.order;
          const status: Status = {
            floorplanStatus: FloorplanStatus[fp?.status],
            level: Level[order?.level],
            orderStatus: OrderStatus[order?.orderStatus],
            paymentStatus: PaymentStatus[order?.paymentStatus],
          };

          return status;
        });

      references.floorplanOrderId = floorplanOrders[0].id;
      references.orderId = floorplanOrders[0].order?.id;
    }

    floorplan.status = stati ? stati[0] : {};
    floorplan.references = references;

    return floorplan;
  });
};

const rowDataToFloorplan = (newData: RowData): UpdateFloorplanInput => {
  const input: UpdateFloorplanInput = {
    id: newData.id,
    status: newData.status.floorplanStatus,
  };
  return input;
};

const rowDataToOrder = (newData: RowData): UpdateOrderInput => {
  const input: UpdateOrderInput = {
    id: newData.references.orderId,
    orderStatus: newData.status.orderStatus,
    level: newData.status.level,
  };
  return input;
};

const toRowData = (floorplan: UpdateFloorplanMutation, order: UpdateOrderMutation): RowData => {
  const status: Status = {
    floorplanStatus: floorplan.updateFloorplan.status,
    level: order.updateOrder.level,
    orderStatus: order.updateOrder.orderStatus,
    paymentStatus: order.updateOrder.paymentStatus,
  };

  const row: RowData = {
    id: floorplan.updateFloorplan.id,
    name: floorplan.updateFloorplan.name,
    owner: floorplan.updateFloorplan.owner,
    createdAt: floorplan.updateFloorplan.createdAt,
    customerEmail: floorplan.updateFloorplan.customer?.email,
    status: status,
  };
  return row;
};

const EditorFloorplanList: React.FC<Props> = ({ floorplans, refetchEntries }: Props) => {
  const history = useHistory();
  const tableIcons = getTableIcons();
  const [data, setData] = useState<TableData>(() => createTableData(floorplans));
  const [columns] = useState<TableColumns>([
    { title: "Name", field: "name", editable: "never" },
    { title: "Kunden Email", field: "customerEmail", editable: "never" },
    { title: "Floorplanstatus", field: "status.floorplanStatus", editable: "onUpdate", lookup: FloorplanStatus },
    { title: "Level", field: "status.level", editable: "onUpdate", lookup: Level },
    { title: "OrderStatus", field: "status.orderStatus", editable: "onUpdate", lookup: OrderStatus },
    {
      title: "Erstellt am",
      field: "createdAt",
      editable: "never",
      defaultSort: "desc",
      render: (rowData) => toLocaleDateShort(rowData.createdAt),
    },
    { title: "ID", field: "id", editable: "never" },
  ]);
  const { updateFloorplan } = useUpdateFloorplan();
  const { updateOrder } = useUpdateOrder();
  const { showToast } = useToastProvider();

  const viewContent = (id: string) => {
    return history.push(`/detail/${id}`);
  };

  // const viewEditor = (id: string) => {
  //   return history.push(`/editor/${id}`);
  // };

  const handleRowUpdate = async (newData: RowData, resolve, reject): Promise<RowData> => {
    try {
      const result: UpdateFloorplanMutation = await updateFloorplan(rowDataToFloorplan(newData));
      const order: UpdateOrderMutation = await updateOrder(rowDataToOrder(newData));

      toastAndRefetch();
      return toRowData(result, order);
    } catch (err) {
      console.error(err);
      reject(err);
    }
  };

  const toastAndRefetch = () => {
    showToast({
      severity: "success",
      content: "Erfolgreich upgedated",
    });
    refetchEntries(); // refresh items in Dashboard
  };

  return (
    <MaterialTable
      components={{
        Container: (p) => p.children,
      }}
      title="Floorplans"
      columns={columns}
      data={data}
      icons={tableIcons}
      //onChangeRowsPerPage={(pageSize) => setPageSize(pageSize)}
      options={{
        paging: true,
        pageSize: 50, // make initial page size
        emptyRowsWhenPaging: true, //to make page size fix in case of less data rows
        pageSizeOptions: [10, 20, 30, 40, 50], // rows selection options
      }}
      actions={[
        {
          icon: ChevronRightIcon,
          tooltip: "Details",
          onClick: (_event, rowData: RowData) => {
            viewContent(rowData.id);
          },
        },
        // {
        //   icon: DeveloperBoardIcon,
        //   tooltip: "Editor",
        //   onClick: (_event, rowData: RowData) => {
        //     viewEditor(rowData.id);
        //   },
        // },
      ]}
      localization={{
        header: {
          actions: "Aktionen",
        },
      }}
      editable={{
        onRowUpdate: (newData, oldData) => {
          return new Promise((resolve, reject) => {
            handleRowUpdate(newData, resolve, reject).then((result) => {
              const dataUpdate = [...data];
              const index = dataUpdate.indexOf(oldData);
              dataUpdate[index] = result;
              console.log(dataUpdate);
              setData([...dataUpdate]);
              resolve(result);
            });
          });
        },
      }}
    />
  );
};

export default EditorFloorplanList;
