import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { DeleteFloorplanInput, DeleteFloorplanMutation } from "../../API";
import * as mutations from "../../graphql/mutations";
import { useToastProvider } from "../../providers/ToastProvider";
import { gqlOperation } from "../GraphQLHooks";

export const useDeleteFloorplan = () => {
  const { showToast } = useToastProvider();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutateAsync: deleteFloorplanAsync } = useMutation((deleteInput: DeleteFloorplanInput) => {
    return gqlOperation<DeleteFloorplanMutation>(mutations.deleteFloorplan, {
      input: deleteInput,
    });
  });

  const deleteFloorplan = async (id: string) => {
    try {
      await deleteFloorplanAsync({ id });
      // grab list-all query from cache & refetch
      await queryClient.refetchQueries(["getFloorplans"]);
    } catch (e) {
      console.log(`ERROR: ${e}`);
    }

    showToast({ severity: "success", content: "Erfolgreich gelöscht" });
    return history.push(`/`); // return to Dashboard
  };
  return { deleteFloorplan };
};
