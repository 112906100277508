import { AppBar, Toolbar } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const FlrAppBar = styled(AppBar)`
  .MuiToolbar-root {
    justify-content: flex-end;
  }
  padding-bottom: ${(p) => p.theme.spacing(2)}px;
`;

export const ContentBar = ({ children }) => (
  <FlrAppBar position="relative" color="transparent" elevation={0}>
    <Toolbar variant="dense" disableGutters>
      {children}
    </Toolbar>
  </FlrAppBar>
);
