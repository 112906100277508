import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { ACCEPTED_FILETYPES } from "../../constants";
import { DropzoneArea } from "material-ui-dropzone";
import React, { ReactElement, useState } from "react";
import { useReplaceImage } from "../../hooks/floorplan/UpdateFloorplanHooks";
import { useDropzoneStore } from "../../providers/Store";
import { useToastProvider } from "../../providers/ToastProvider";
import { DialogContentBefore } from "../layout/StyledComponents";

type Props = {
  imageKey: string;
  setImageKey: React.Dispatch<React.SetStateAction<string>>;
};

const DropzoneReplaceImage = ({ imageKey, setImageKey }: Props): ReactElement => {
  const { showToast } = useToastProvider();
  const { isVisible, closeDropzone } = useDropzoneStore();
  const [submittable, setSubmittable] = useState(false);
  const successCallback = () => {
    closeDropzone();
    showToast({
      severity: "success",
      content: "Grundrissplan erfolgreich ersetzt",
    });
    setImageKey(""); // refresh items in Dashboard
  };

  const { replaceImage, setFile, uploadProgress } = useReplaceImage({
    imageKey,
    successCallback,
  });

  const handleClose = () => {
    uploadProgress === 0 && closeDropzone();
    // only allow closing when there's no upload in progress
  };

  const handleChange = (fileObjects: File[]) => {
    // activate submit when we have a file
    if (fileObjects.length > 0) {
      setFile(fileObjects[0]);
      setSubmittable(true);
    } else {
      // disable submit when no file
      setSubmittable(false);
    }
  };

  const handleSave = () => {
    setSubmittable(false); // deactivate button to prevent multiple submits
    replaceImage().then();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isVisible} maxWidth={"md"} fullWidth>
      <DialogTitle id="customized-dialog-title">Originalbild ersetzen</DialogTitle>
      <DialogContent dividers>
        <DialogContentBefore>
          <Alert severity="warning">
            <AlertTitle>Achtung</AlertTitle>
            Wenn Sie das Originalbild ersetzen, wird auch der digitalisierte Plan gelöscht. <br />
            Die Digitalisierung muss anschließend neu durchgeführt werden.
            <br />
            <strong>Diese Operation ist nicht umkehrbar!</strong>
          </Alert>
        </DialogContentBefore>
        <DropzoneArea
          onChange={handleChange}
          acceptedFiles={ACCEPTED_FILETYPES}
          showPreviews={false}
          maxFileSize={5000000}
          filesLimit={1}
        />
      </DialogContent>
      <LinearProgress variant="determinate" value={uploadProgress} />
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Abbrechen
        </Button>
        <Button onClick={handleSave} color="primary" disabled={!submittable}>
          Ersetzen & speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DropzoneReplaceImage;
