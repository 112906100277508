import React, { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import HomeIcon from "@material-ui/icons/Home";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { useUser } from "../../providers/AuthContextProvider";
import clsx from "clsx";
import SignOut from "../auth/SignOut";
import IconLink from "./IconLink";
import PersonIcon from "@material-ui/icons/Person";

import Logo from "../../assets/logo_text_right_black_transparent.png";
//import { Button } from "@material-ui/core";

import { Event } from "../../hooks/analytics/GoogleAnalytics";
import * as analytics from "../../hooks/analytics/GoogleAnalytics";

// import Drawer from "@material-ui/core/Drawer";
// import Divider from "@material-ui/core/Divider";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
// import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";
// import MailIcon from "@material-ui/icons/Mail";
// import SettingsIcon from "@material-ui/icons/Settings";

// import InboxIcon from "@material-ui/icons/MoveToInbox";

// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "white",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 0,
  },
  nav: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  userLoggedIn: {
    marginRight: theme.spacing(4),
  },
  navbarText: {
    marginRight: theme.spacing(4),
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  link: {
    margin: theme.spacing(1, 1.5),
  },
  logoPad: {
    margin: "10px",
  },
}));

export const Header: React.FC = (): ReactElement => {
  const classes = useStyles();
  const [open] = useState(false);
  const location = useLocation();
  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };
  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const { userAttributes } = useUser();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState("");

  useEffect(() => {
    try {
      setError(null);
      setLoading(true);

      setUsername(userAttributes?.email);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [userAttributes]);

  // const primaryListItems = () => {
  //   ["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
  //     <ListItem button key={text}>
  //       <ListItemIcon>
  //         {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
  //       </ListItemIcon>
  //       <ListItemText primary={text} />
  //     </ListItem>
  //   ));
  // };

  // const secondaryListItems = () => {
  //   ["All mail", "Trash", "Spam"].map((text, index) => (
  //     <ListItem button key={text}>
  //       <ListItemIcon>
  //         {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
  //       </ListItemIcon>
  //       <ListItemText primary={text} />
  //     </ListItem>
  //   ));
  // };

  return (
    <>
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} color="default">
        <Toolbar className={classes.toolbar}>
          {/* <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}>
            <MenuIcon />
          </IconButton> */}
          <Link to={`/dashboard`} className={classes.logoPad}>
            <img src={Logo} alt="Logo" height="60" />
          </Link>
          {/* <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
            <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <nav className={classes.nav}>
            <IconLink
              to="/dashboard"
              tooltip="Dashboard"
              icon={<HomeIcon />}
              color={location.pathname === "/dashboard" ? "primary" : "secondary"}
            />
            {/* <Link
              variant="button"
              color="textPrimary"
              component={RouterLink}
              className={classes.link}
              to="/"
            >
              Dashboard
            </Link> */}
          </nav>
          <Typography component="p" color="inherit" noWrap className={classes.userLoggedIn}>
            {error && `Whoops...${error}`}
            {loading ? "Loading..." : username}
          </Typography>
          <IconLink tooltip="Profil" to="/settings" icon={<PersonIcon />} />

          <IconLink
            to="/contact"
            tooltip="Kontakt"
            icon={<HelpOutlineIcon />}
            color={location.pathname === "/contact" ? "primary" : "secondary"}
          />
          <SignOut />
        </Toolbar>
      </AppBar>
      {/* <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}>
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{primaryListItems}</List>
        <Divider />
        <List>{secondaryListItems}</List>
      </Drawer> */}
    </>
  );
};

export const ReducedHeader: React.FC = (): ReactElement => {
  const classes = useStyles();
  const [open] = useState(false);

  // analytics
  const event: Event = {
    category: "Order_Request",
    action: "order_from_shared",
    label: "Hier bestellen!",
  };
  analytics.sendEvent(event);

  return (
    <>
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} color="default">
        <Toolbar className={classes.toolbar}>
          <Link to={`/`} className={classes.logoPad}>
            <img src={Logo} alt="Logo" height="60" />
          </Link>

          <nav className={classes.nav}></nav>

          {/* Removed for the first customers */}
          {/* <Typography component="p" color="inherit" noWrap className={classes.navbarText}>
            Sie haben ebenfalls Grundrisse zu digitalisieren?
          </Typography>
          <Link to={`/`}>
            <Button color="primary" variant="contained">
              Hier bestellen!
            </Button>
          </Link> */}
        </Toolbar>
      </AppBar>
    </>
  );
};
