import { Box, Button, Grid, Link, Typography } from "@material-ui/core";
import { NewsletterSubscriptionInput, SignUpNewsletterQuery, SignUpNewsletterQueryVariables } from "API";
import { FlrPaper } from "components/layout/StyledComponents";
import BackLink from "components/links/BackLink";
import { Formik, Field, Form, FormikHelpers } from "formik";
import { TextField } from "formik-material-ui";
import { gqlOperation } from "hooks/GraphQLHooks";
import { useToastProvider } from "providers/ToastProvider";
import VurnishedLogo from "../../assets/vurnished/vurnished_logo.png";
import * as queries from "../../graphql/queries";

type PropTypes = {
  id: string;
};

const VurnishedFinished: React.FC<PropTypes> = ({ id }) => {
  const { showSuccessToast, showErrorToast } = useToastProvider();

  const signUp = async (input: NewsletterSubscriptionInput) => {
    console.log(input);
    const variables: SignUpNewsletterQueryVariables = {
      input: input,
    };

    try {
      console.log("Signing up to newsletter...");
      const response = await gqlOperation<SignUpNewsletterQuery>(queries.signUpNewsletter, variables);
      showSuccessToast("Erfolgreich zum Newsletter angemeldet! 😊");
      console.log("sendOrderMail response: ", response);
      return response;
    } catch (err) {
      showErrorToast("Es ist ein Fehler aufgetreten 😯");
      console.error(err);
    }
  };

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <BackLink tooltip="Zurück zur Preisliste" to={`/vurnished/${id}/detail`}></BackLink>
      </Grid>
      <Grid item xs={12} md={8} lg={6} xl={4}>
        <FlrPaper style={{ gap: "10px" }}>
          <Typography variant="h5" align="center">
            Interesse geweckt?
          </Typography>
          <Typography variant="body1" align="center">
            Wir arbeiten gerade an der Entwicklung unseres Interior Design Services:
          </Typography>
          <Box display="flex" justifyContent="center">
            <Link href="https://vurnished.com" target="_blank">
              <img src={VurnishedLogo} alt="Logo" height="60" />
            </Link>
          </Box>
          <Link href="https://vurnished.com" target="_blank">
            <Typography variant="subtitle1" align="center">
              Zur Website
            </Typography>
          </Link>
          <Typography variant="body1" align="center" style={{ marginTop: "20px" }}>
            Melden Sie sich gerne für unseren Newsletter an, um informiert zu werden sobald dieser Service verfügbar
            ist:
          </Typography>
          <Formik
            enableReinitialize
            initialValues={{
              email: "",
            }}
            onSubmit={(
              values: NewsletterSubscriptionInput,
              { setSubmitting, resetForm }: FormikHelpers<NewsletterSubscriptionInput>
            ) => {
              signUp(values);
              setSubmitting(false);
              resetForm();
            }}
          >
            <Form>
              <Grid container spacing={3} direction="row" justify="center" alignItems="center">
                <Grid item xs={8}>
                  <Field fullWidth component={TextField} id="email" name="email" label="Email" type="email" />
                </Grid>
                <Grid item xs={5}>
                  <Button variant="contained" color="primary" fullWidth type="submit">
                    Anmelden
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </FlrPaper>
      </Grid>
    </Grid>
  );
};

export default VurnishedFinished;
