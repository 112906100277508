import { useMutation } from "react-query";
import { DeleteFloorplanOrderMutation, DeleteFloorplanOrderInput } from "../../API";
import * as mutations from "../../graphql/mutations";
import { gqlOperation } from "../GraphQLHooks";
import { FloorplanOrder } from "../../models";

export const useDeleteFloorplanOrder = () => {
  const { mutateAsync } = useMutation((deleteInput: DeleteFloorplanOrderInput) => {
    return gqlOperation<DeleteFloorplanOrderMutation>(mutations.deleteFloorplanOrder, {
      input: deleteInput,
    });
  });

  const deleteFloorplanOrder = async (id: string) => {
    try {
      await mutateAsync({ id });
    } catch (e) {
      console.log(`ERROR: ${e}`);
    }
  };

  const deleteFloorplanOrders = async (forders: FloorplanOrder[]) => {
    try {
      await forders.forEach((forder: FloorplanOrder) => {
        const id = forder.id;
        mutateAsync({ id });
      });
    } catch (e) {
      console.log(`ERROR: ${e}`);
    }
    console.log("FloorplanOrders deleted");
  };

  return { deleteFloorplanOrder, deleteFloorplanOrders };
};
