import React, { useRef, useState } from "react";
import { Container } from "@material-ui/core";
import ContractorFloorplanList from "../../components/dashboard/ContractorFloorplanList";
import { ContentHeader, FlrPaper, Headline } from "../../components/layout/StyledComponents";
import { useGetFloorplansByStatusAfterDate } from "../../hooks/floorplan/GetFloorplanHooks";
import { Floorplan, FloorplanStatus } from "models";
import styled from "styled-components";
import { getLastMonthDateString } from "utils/DateUtils";

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(p) => p.theme.spacing(2)}px;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const ContractorListView: React.FC<{}> = () => {
  const now = useRef(new Date());
  const lastMonth = getLastMonthDateString(now.current);

  const { floorplans: floorplansInReview } = useGetFloorplansByStatusAfterDate(FloorplanStatus.QA_CREATED, lastMonth);
  const { floorplans: acceptedfloorplans } = useGetFloorplansByStatusAfterDate(FloorplanStatus.IN_PROGRESS, lastMonth);
  const { floorplans: rejectedfloorplans } = useGetFloorplansByStatusAfterDate(FloorplanStatus.REJECTED, lastMonth);
  const { floorplans: generatedfloorplans } = useGetFloorplansByStatusAfterDate(FloorplanStatus.GENERATED, lastMonth);
  const {
    isLoading,
    floorplans: initializedFloorplans,
    refetch,
  } = useGetFloorplansByStatusAfterDate(FloorplanStatus.INITIALIZED, lastMonth);

  const [floorplans] = useState<Floorplan[]>([
    ...acceptedfloorplans,
    ...initializedFloorplans,
    ...generatedfloorplans,
    ...rejectedfloorplans,
    ...floorplansInReview,
  ]);

  //console.log("Floorplans:", floorplans);

  return (
    <StyledContainer maxWidth={false}>
      <ContentHeader>
        <Headline variant="h4" component="h1">
          Contractor Dashboard
        </Headline>
      </ContentHeader>
      <FlrPaper elevation={3}>
        {!isLoading && <ContractorFloorplanList floorplans={floorplans} refetchEntries={refetch} />}
      </FlrPaper>
    </StyledContainer>
  );
};

export default ContractorListView;
