export const schema = {
  models: {
    PreprocessImageResult: {
      name: "PreprocessImageResult",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        imageUri: {
          name: "imageUri",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "PreprocessImageResults",
      attributes: [
        {
          type: "model",
          properties: {
            queries: null,
            mutations: null,
            subscriptions: null,
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                provider: "iam",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    RecognitionServiceResult: {
      name: "RecognitionServiceResult",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        imageUri: {
          name: "imageUri",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "RecognitionServiceResults",
      attributes: [
        {
          type: "model",
          properties: {
            queries: null,
            mutations: null,
            subscriptions: null,
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                provider: "iam",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    ChatMessage: {
      name: "ChatMessage",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        floorplanId: {
          name: "floorplanId",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        message: {
          name: "message",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        sentByCustomer: {
          name: "sentByCustomer",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
        read: {
          name: "read",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        images: {
          name: "images",
          isArray: true,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
      },
      syncable: true,
      pluralName: "ChatMessages",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byFloorplan",
            fields: ["floorplanId", "createdAt"],
            queryField: "chatMessagesByFloorplanId",
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                identityClaim: "cognito:username",
                operations: ["create", "update", "delete", "read"],
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Editors", "Contractors"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    FloorplanProject: {
      name: "FloorplanProject",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        address: {
          name: "address",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        size: {
          name: "size",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "FloorplanProjects",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                identityClaim: "cognito:username",
                operations: ["create", "update", "delete", "read"],
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Editors", "Contractors"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Floorplan: {
      name: "Floorplan",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        projectId: {
          name: "projectId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        address: {
          name: "address",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        originalImageName: {
          name: "originalImageName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        originalInputType: {
          name: "originalInputType",
          isArray: false,
          type: {
            enum: "OriginalInputType",
          },
          isRequired: false,
          attributes: [],
        },
        shareable: {
          name: "shareable",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        originalImage: {
          name: "originalImage",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
        croppedImage: {
          name: "croppedImage",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
        preprocessedImage: {
          name: "preprocessedImage",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
        visual2DImage: {
          name: "visual2DImage",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
        mlPredictionImage: {
          name: "mlPredictionImage",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
        ocrPredictionImage: {
          name: "ocrPredictionImage",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
        postMlImage: {
          name: "postMlImage",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
        internalModelImage: {
          name: "internalModelImage",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
        basicImage: {
          name: "basicImage",
          isArray: false,
          type: {
            nonModel: "ExportFiles",
          },
          isRequired: false,
          attributes: [],
        },
        basicPlusImage: {
          name: "basicPlusImage",
          isArray: false,
          type: {
            nonModel: "ExportFiles",
          },
          isRequired: false,
          attributes: [],
        },
        professionalImageTop: {
          name: "professionalImageTop",
          isArray: false,
          type: {
            nonModel: "ExportFiles",
          },
          isRequired: false,
          attributes: [],
        },
        professionalImageTilted: {
          name: "professionalImageTilted",
          isArray: false,
          type: {
            nonModel: "ExportFiles",
          },
          isRequired: false,
          attributes: [],
        },
        professionalImageNE: {
          name: "professionalImageNE",
          isArray: false,
          type: {
            nonModel: "ExportFiles",
          },
          isRequired: false,
          attributes: [],
        },
        professionalImageNW: {
          name: "professionalImageNW",
          isArray: false,
          type: {
            nonModel: "ExportFiles",
          },
          isRequired: false,
          attributes: [],
        },
        professionalImageSE: {
          name: "professionalImageSE",
          isArray: false,
          type: {
            nonModel: "ExportFiles",
          },
          isRequired: false,
          attributes: [],
        },
        professionalImageSW: {
          name: "professionalImageSW",
          isArray: false,
          type: {
            nonModel: "ExportFiles",
          },
          isRequired: false,
          attributes: [],
        },
        reactplannerModel: {
          name: "reactplannerModel",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
        floorplannerModel: {
          name: "floorplannerModel",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
        ifcModel: {
          name: "ifcModel",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
        reimportedIfcModel: {
          name: "reimportedIfcModel",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
        total_size: {
          name: "total_size",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        amount_doors: {
          name: "amount_doors",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        amount_rooms: {
          name: "amount_rooms",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        amount_windows: {
          name: "amount_windows",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        total_room_size_m2: {
          name: "total_room_size_m2",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        total_room_size_px: {
          name: "total_room_size_px",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        user_total_size: {
          name: "user_total_size",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        user_scaling_selected_px: {
          name: "user_scaling_selected_px",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        user_scaling_cm: {
          name: "user_scaling_cm",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        api_scaling_factor_cm_per_px: {
          name: "api_scaling_factor_cm_per_px",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "FloorplanStatus",
          },
          isRequired: false,
          attributes: [],
        },
        rooms: {
          name: "rooms",
          isArray: true,
          type: {
            model: "Room",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "floorplanId",
          },
        },
        orders: {
          name: "orders",
          isArray: true,
          type: {
            model: "FloorplanOrder",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "floorplan",
          },
        },
        customer: {
          name: "customer",
          isArray: false,
          type: {
            model: "Customer",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "customerId",
          },
        },
        floorplannerId: {
          name: "floorplannerId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        chatMessages: {
          name: "chatMessages",
          isArray: true,
          type: {
            model: "ChatMessage",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "floorplanId",
          },
        },
        acceptedAt: {
          name: "acceptedAt",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        finishedAt: {
          name: "finishedAt",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "Floorplans",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byDate",
            fields: ["type", "createdAt"],
            queryField: "floorplansByDate",
          },
        },
        {
          type: "key",
          properties: {
            name: "byOwner",
            fields: ["owner", "createdAt"],
            queryField: "floorplansByOwner",
          },
        },
        {
          type: "key",
          properties: {
            name: "byFloorplannerId",
            fields: ["floorplannerId", "createdAt"],
            queryField: "floorplansByFloorplannerId",
          },
        },
        {
          type: "key",
          properties: {
            name: "byCustomer",
            fields: ["customerId", "createdAt"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                identityClaim: "cognito:username",
                operations: ["create", "update", "delete", "read"],
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Editors", "Contractors"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Room: {
      name: "Room",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        floorplanId: {
          name: "floorplanId",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        room_size_sqm: {
          name: "room_size_sqm",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        room_type: {
          name: "room_type",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "Rooms",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byFloorplanId",
            fields: ["floorplanId", "room_type"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                identityClaim: "cognito:username",
                operations: ["create", "update", "delete", "read"],
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Editors", "Contractors"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    FloorplanOrder: {
      name: "FloorplanOrder",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        customer: {
          name: "customer",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        floorplan: {
          name: "floorplan",
          isArray: false,
          type: {
            model: "Floorplan",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "floorplan_id",
          },
        },
        order: {
          name: "order",
          isArray: false,
          type: {
            model: "Order",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "order_id",
          },
        },
      },
      syncable: true,
      pluralName: "FloorplanOrders",
      attributes: [
        {
          type: "model",
          properties: {
            subscriptions: null,
          },
        },
        {
          type: "key",
          properties: {
            name: "byFloorplan",
            fields: ["floorplan_id", "order_id"],
          },
        },
        {
          type: "key",
          properties: {
            name: "byOrder",
            fields: ["order_id", "floorplan_id"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                identityClaim: "cognito:username",
                operations: ["create", "update", "delete", "read"],
              },
              {
                provider: "userPools",
                ownerField: "customer",
                allow: "owner",
                identityClaim: "email",
                operations: ["create", "update", "delete", "read"],
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Editors", "Contractors"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Order: {
      name: "Order",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        user: {
          name: "user",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        customer: {
          name: "customer",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        amount_total: {
          name: "amount_total",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        level: {
          name: "level",
          isArray: false,
          type: {
            enum: "Level",
          },
          isRequired: false,
          attributes: [],
        },
        stripe_checkout_session_id: {
          name: "stripe_checkout_session_id",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        paymentStatus: {
          name: "paymentStatus",
          isArray: false,
          type: {
            enum: "PaymentStatus",
          },
          isRequired: false,
          attributes: [],
        },
        orderStatus: {
          name: "orderStatus",
          isArray: false,
          type: {
            enum: "OrderStatus",
          },
          isRequired: false,
          attributes: [],
        },
        floorplans: {
          name: "floorplans",
          isArray: true,
          type: {
            model: "FloorplanOrder",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "order",
          },
        },
      },
      syncable: true,
      pluralName: "Orders",
      attributes: [
        {
          type: "model",
          properties: {
            subscriptions: null,
          },
        },
        {
          type: "key",
          properties: {
            name: "byUser",
            fields: ["user"],
          },
        },
        {
          type: "key",
          properties: {
            name: "byCheckoutSession",
            fields: ["stripe_checkout_session_id"],
            queryField: "getOrderByCheckoutSession",
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                operations: ["create", "update", "delete", "read"],
                identityClaim: "cognito:username",
              },
              {
                provider: "userPools",
                ownerField: "customer",
                allow: "owner",
                identityClaim: "email",
                operations: ["create", "update", "delete", "read"],
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Editors", "Contractors"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Customer: {
      name: "Customer",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        user: {
          name: "user",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        identityId: {
          name: "identityId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        email: {
          name: "email",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        stripe_customer_id: {
          name: "stripe_customer_id",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        phone_number: {
          name: "phone_number",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        company: {
          name: "company",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        company_address: {
          name: "company_address",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        company_address_line2: {
          name: "company_address_line2",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        company_city: {
          name: "company_city",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        company_taxnumber: {
          name: "company_taxnumber",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        country: {
          name: "country",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        zip: {
          name: "zip",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        accepted_terms: {
          name: "accepted_terms",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        accepted_gdpr: {
          name: "accepted_gdpr",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        floorplans: {
          name: "floorplans",
          isArray: true,
          type: {
            model: "Floorplan",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "customer",
          },
        },
      },
      syncable: true,
      pluralName: "Customers",
      attributes: [
        {
          type: "model",
          properties: {
            subscriptions: null,
          },
        },
        {
          type: "key",
          properties: {
            name: "byUser",
            fields: ["user"],
            queryField: "getCustomerByUsername",
          },
        },
        {
          type: "key",
          properties: {
            name: "byEmail",
            fields: ["email"],
            queryField: "getCustomerByEmail",
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "user",
                allow: "owner",
                operations: ["create", "update", "read"],
                identityClaim: "cognito:username",
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Editors", "Contractors"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Settings: {
      name: "Settings",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        theme: {
          name: "theme",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        displayName: {
          name: "displayName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "Settings",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                operations: ["create", "update", "delete", "read"],
                identityClaim: "cognito:username",
              },
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["Editors"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Payment: {
      name: "Payment",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        date: {
          name: "date",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
        stripePaymentIntent: {
          name: "stripePaymentIntent",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        paymentStatus: {
          name: "paymentStatus",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        amount: {
          name: "amount",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        user: {
          name: "user",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "Payments",
      attributes: [
        {
          type: "model",
          properties: {
            mutations: null,
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                identityClaim: "cognito:username",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
  },
  enums: {
    OrderMailTemplateType: {
      name: "OrderMailTemplateType",
      values: [
        "ORDER_NEW",
        "ORDER_PLACED",
        "ORDER_CONFIRMATION",
        "ORDER_DECLINED",
        "ORDER_QA_NOTIFY",
        "ORDER_PROCESSED",
        "ORDER_CHANGE_REQUEST",
        "ORDER_CHANGE_RESPONSE",
      ],
    },
    RecognitionServiceEventType: {
      name: "RecognitionServiceEventType",
      values: ["PREVIEW", "RECOGNITION", "FLOORPLANNER_API_CALL", "FLOORPLANNER_REIMPORT"],
    },
    OriginalInputType: {
      name: "OriginalInputType",
      values: ["SKETCH", "POINTCLOUD"],
    },
    FloorplanStatus: {
      name: "FloorplanStatus",
      values: ["INITIALIZED", "GENERATED", "IN_PROGRESS", "REJECTED", "QA_CREATED", "REWORK", "CREATED"],
    },
    Level: {
      name: "Level",
      values: ["FML", "BASIC", "BASIC_PLUS", "PROFESSIONAL", "PREMIUM"],
    },
    PaymentStatus: {
      name: "PaymentStatus",
      values: ["PENDING", "PAID", "FAILED", "NO_PAYMENT_REQUIRED"],
    },
    OrderStatus: {
      name: "OrderStatus",
      values: ["CONFIRMED", "DECLINED", "PROCESSED", "INVOICED", "FINISHED"],
    },
  },
  nonModels: {
    GetPreSignedUrlsResult: {
      name: "GetPreSignedUrlsResult",
      fields: {
        urls: {
          name: "urls",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
      },
    },
    S3Object: {
      name: "S3Object",
      fields: {
        bucket: {
          name: "bucket",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        region: {
          name: "region",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        key: {
          name: "key",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
    },
    ExportFiles: {
      name: "ExportFiles",
      fields: {
        png: {
          name: "png",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
        jpeg: {
          name: "jpeg",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
        pdf: {
          name: "pdf",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
      },
    },
  },
  version: "9d0b8e5979536290ad2920241513237f",
};
