import React, { ReactElement, useState, useEffect, useMemo } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import ListIcon from "@material-ui/icons/List";
import ViewCompactIcon from "@material-ui/icons/ViewCompact";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import AddCard from "../../components/dashboard/AddCard";
import DropzoneCreateFloorplan from "../../components/dashboard/DropzoneCreateFloorplan";
import { ContentBar } from "../../components/layout/ContentBar";
import { FlrFab } from "../../components/layout/StyledComponents";
import { useDropzoneStore } from "../../providers/Store";
import { useIsEditor, useIsDeveloper, useIsProfessional, useIsContractor } from "../../providers/AuthContextProvider";
// import ProfileHeader from "../../components/dashboard/ProfileHeader";
import EditorListView from "./EditorListView";
import GridView from "./GridView";
import ListView from "./ListView";
import DeveloperPanel from "./DeveloperPanel";
import { useGetFloorplans } from "../../hooks/floorplan/GetFloorplanHooks";
import { useToastProvider } from "../../providers/ToastProvider";
import ContractorListView from "./ContractorListView";
import { useQueryParams } from "utils/UrlUtils";

export enum Views {
  EDITOR = "editor",
  CONTRACTOR = "contractor",
  LIST = "list",
  GRID = "grid",
}

type Props = {
  initialView?: Views;
};

const Dashboard = ({ initialView }: Props): ReactElement => {
  const { showDropzone } = useDropzoneStore();
  const isEditor = useIsEditor();
  const isProfessionals = useIsProfessional();
  const isContractor = useIsContractor();
  const isDeveloper = useIsDeveloper();
  const { refetch } = useGetFloorplans();
  const { showToast } = useToastProvider();

  const query = useQueryParams();
  const setInitialView = (): Views => {
    const view = query.get("view");
    switch (view) {
      case Views.EDITOR:
        return isEditor ? Views.EDITOR : Views.LIST;
      case Views.CONTRACTOR:
        return isContractor ? Views.CONTRACTOR : Views.LIST;
      case Views.GRID:
        return Views.GRID;
      default:
        let defaultView = Views.LIST;
        if (isEditor) {
          defaultView = Views.EDITOR;
        } else if (isContractor) {
          defaultView = Views.CONTRACTOR;
        }
        return defaultView;
    }
  };
  const [view, setView] = useState<Views>(initialView ? initialView : setInitialView());
  const history = useHistory();
  const success = useRouteMatch("/dashboard/success");
  const canceled = useRouteMatch("/dashboard/canceled");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    if (success) {
      showToast({
        severity: "info",
        content: "Bestellung erfolgreich! Sie erhalten in Kürze eine Email mit den weiteren Details!",
      });
    }
    if (canceled) {
      showToast({
        severity: "info",
        content: "Bestellung abgebrochen!",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    history.push("/dashboard?view=" + view);
  }, [history, view]);

  const DashboardBar = () => {
    const handleEditorSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
      setView(event.target.checked ? Views.EDITOR : Views.LIST);
    };

    const handleContractorSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
      setView(event.target.checked ? Views.CONTRACTOR : Views.LIST);
    };

    return (
      <ContentBar>
        {isEditor && (
          <FormControlLabel
            control={
              <Switch checked={view === Views.EDITOR} onChange={handleEditorSwitch} name="editorMode" color="primary" />
            }
            label="Editor Mode"
          />
        )}
        {isContractor && (
          <FormControlLabel
            control={
              <Switch
                checked={view === Views.CONTRACTOR}
                onChange={handleContractorSwitch}
                name="contractorMode"
                color="primary"
              />
            }
            label="Contractor Dashboard"
          />
        )}
        <>
          <IconButton
            color={view === Views.LIST ? "primary" : "secondary"}
            aria-label="menu"
            onClick={() => setView(Views.LIST)}
          >
            <ListIcon />
          </IconButton>
          <IconButton
            color={view === Views.GRID ? "primary" : "secondary"}
            aria-label="menu"
            onClick={() => setView(Views.GRID)}
          >
            <ViewCompactIcon />
          </IconButton>
        </>
      </ContentBar>
    );
  };

  const ViewOptions = () => {
    switch (view) {
      case Views.EDITOR:
        return <EditorListView />;
      case Views.CONTRACTOR:
        return <ContractorListView />;
      case Views.GRID:
        return <GridView />;
      case Views.LIST:
        return <ListView slice={isProfessionals ? 2 : 3} />;
      default:
        return <ListView slice={isProfessionals ? 2 : 3} />;
    }
  };

  const dropzoneMemoized = useMemo(() => <DropzoneCreateFloorplan refetch={refetch} />, [refetch]);

  return (
    <>
      {/*<ProfileHeader />*/}
      {view !== Views.CONTRACTOR && <DashboardBar />}
      <Grid container spacing={4}>
        <>
          {/* {view !== Views.EDITOR && view !== Views.CONTRACTOR && (
            <AddCard
              addFloorplan={() => {
                history.push("/checkout");
              }}
            />
          )} */}
          {view !== Views.EDITOR && view !== Views.CONTRACTOR && isProfessionals && (
            <AddCard
              cardTitle="Neues Pointcloud Projekt erstellen"
              addFloorplan={() => {
                history.push("/checkout?type=pointcloud");
              }}
            />
          )}
          <ViewOptions />
        </>
        {isDeveloper && <DeveloperPanel />}
      </Grid>

      {isEditor && (
        <FlrFab onClick={showDropzone} color="primary" aria-label="add">
          <AddIcon />
        </FlrFab>
      )}
      {dropzoneMemoized}
    </>
  );
};

export default Dashboard;
