import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Collapse,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  TextField,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  Edit,
  Save,
  ShoppingCart,
  Done,
  DoneAll,
  CloudDownload,
  Cancel,
  ArrowDropUp,
  ArrowDropDown,
  Person,
} from "@material-ui/icons";

import { FlrPaper } from "components/layout/StyledComponents";
import { Floorplan, FloorplanStatus, Order } from "models";
import { UpdateFloorplanInput } from "API";

import { useUpdateFloorplan } from "hooks/floorplan/UpdateFloorplanHooks";
import useImportImagesHook from "hooks/floorplannerApi/ImportImagesHook";
import { useToastProvider } from "providers/ToastProvider";
import { sendQANotify } from "hooks/mails/MailServiceHooks";
import { useEditorUrlDownload } from "hooks/exports/DownloadImagesHook";
import useCreatePresignedUrl from "hooks/exports/useCreatePresignedUrl";
import { useUser } from "providers/AuthContextProvider";
import CFPButton from "container/button/CreateFloorplannerProjectButton";

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    maxWidth: 380,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

type ContractorPanelProps = {
  floorplan: Floorplan;
  order: Order;
  paymentStatus: string;
};

const MESSAGE_FLOORPLAN_QA_ACCEPTED = "ACCEPTED: The floor plan has been accepted by the contractor!";
const MESSAGE_FLOORPLAN_QA_CREATED = "PLEASE REVIEW: The floor plan is ready to be reviewed!";
const MESSAGE_FLOORPLAN_QA_REJECTED = "REJECTED: The floor plan was rejected because of too poor quality.";

const ContractorPanel: React.FC<ContractorPanelProps> = ({ floorplan, order }: ContractorPanelProps) => {
  const { userAttributes } = useUser();
  const [floorplanStatus, setFloorplanStatus] = useState(floorplan.status);
  const [floorplannerId, setFloorplannerId] = useState(floorplan.floorplannerId);
  const [open, setOpen] = useState(false);

  const [orderLevel, setOrderLevel] = useState(order?.level);
  const [floorplanAccepted, setFloorplanAccepted] = useState(false);
  const [floorplanFinished, setFloorplanFinished] = useState(false);

  const { showSuccessToast, showErrorToast, showInfoToast } = useToastProvider();
  const { updateFloorplan } = useUpdateFloorplan();
  const { importImages } = useImportImagesHook();
  const { createPresignedURLs } = useCreatePresignedUrl();
  const { startEditorDownload } = useEditorUrlDownload(floorplan.id);

  useEffect(() => {
    setOrderLevel(order?.level);
  }, [order]);

  useEffect(() => {
    if (floorplan.status === FloorplanStatus.IN_PROGRESS) {
      setFloorplanAccepted(true);
    }
    if (floorplan.status === FloorplanStatus.CREATED) {
      setFloorplanFinished(true);
    }
  }, [floorplan]);

  const downloadOriginalImageAndModel = async () => {
    const filesToDownloadKeys = [
      floorplan.originalImage.key,
      floorplan.croppedImage.key,
      floorplan.floorplannerModel.key,
      floorplan.ifcModel?.key,
      floorplan.reimportedIfcModel?.key,
    ];
    const urls = await createPresignedURLs(filesToDownloadKeys, "GET");
    startEditorDownload(urls);
  };

  const updateFloorplanStatus = async (status: FloorplanStatus) => {
    const date = new Date();
    let updatedFloorplan: UpdateFloorplanInput = {
      id: floorplan.id,
      status: status,
    };

    if (status === FloorplanStatus.IN_PROGRESS) {
      updatedFloorplan.acceptedAt = date.toISOString();
    }

    if (status === FloorplanStatus.QA_CREATED) {
      updatedFloorplan.finishedAt = date.toISOString();
    }

    const res = await updateFloorplan(updatedFloorplan);
    setFloorplanStatus(res.updateFloorplan.status);
  };

  const handleSetFloorplannerId = async (floorplannerId: string) => {
    const updatedFloorplan: UpdateFloorplanInput = {
      id: floorplan.id,
      floorplannerId: floorplannerId,
    };
    const res = await updateFloorplan(updatedFloorplan);
    setFloorplannerId(res.updateFloorplan.floorplannerId);
  };

  const handleImport = async () => {
    try {
      await importImages(orderLevel, floorplannerId);
      showSuccessToast("Successfully imported images from Floorplanner!");
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  const handleQANotify = async (message: string) => {
    setFloorplanFinished(true);
    await sendQANotify(floorplan.customer, order, floorplan, userAttributes.email, message);
    showInfoToast("A notification has been sent to Vloor.");
  };

  return (
    <FlrPaper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2" align="center">
            Contractor Panel
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <List>
            <ListSubheader>1. Accept or reject the floor plan</ListSubheader>
            <ListItem key="cus_email">
              <HtmlTooltip
                placement="left"
                title={
                  <>
                    <Typography color="inherit">Customer Email:</Typography>
                    Please choose the corresponding template/branding when creating the floor plan
                  </>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <Person />
                  </Avatar>
                </ListItemAvatar>
              </HtmlTooltip>
              <ListItemText>{floorplan?.customer?.email}</ListItemText>
            </ListItem>
            {floorplanStatus && (
              <ListItem key="CPfloorplanStatus">
                <HtmlTooltip placement="left" title="FloorplanStatus">
                  <ListItemAvatar>
                    <Avatar>
                      <Edit />
                    </Avatar>
                  </ListItemAvatar>
                </HtmlTooltip>
                <ListItemText>{floorplanStatus}</ListItemText>
              </ListItem>
            )}
            {floorplanStatus && (
              <ListItem key="acceptance">
                {/* Added span to prevent MaterialUI warning for disabled Button */}
                <HtmlTooltip title="Accept floor plan to start working" placement="left">
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<Done />}
                      disabled={floorplanAccepted}
                      onClick={() => {
                        updateFloorplanStatus(FloorplanStatus.IN_PROGRESS);
                        handleQANotify(MESSAGE_FLOORPLAN_QA_ACCEPTED);
                        setFloorplanAccepted(true);
                      }}
                    >
                      Accept
                    </Button>
                  </span>
                </HtmlTooltip>
                <ListItemSecondaryAction>
                  <HtmlTooltip title="Reject floor plan and notify Vloor team" placement="left">
                    {/* Added span to prevent MaterialUI warning for disabled Button */}
                    <span>
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<Cancel />}
                        disabled={floorplanStatus === FloorplanStatus.REJECTED}
                        onClick={() => {
                          updateFloorplanStatus(FloorplanStatus.REJECTED);
                          handleQANotify(MESSAGE_FLOORPLAN_QA_REJECTED);
                          setFloorplanAccepted(false);
                        }}
                      >
                        Reject
                      </Button>
                    </span>
                  </HtmlTooltip>
                </ListItemSecondaryAction>
              </ListItem>
            )}

            <ListSubheader>2. Create Floorplanner project</ListSubheader>
            {orderLevel && (
              <ListItem key="orderlevel">
                <HtmlTooltip
                  placement="left"
                  title={
                    <>
                      <Typography color="inherit">Order Levels:</Typography>
                      <b>Basic: </b> basic furnishing, toilet, kitchen, bathroom <br />
                      <b>Basic Plus: </b> fully furnished <br />
                      <b>Professional: </b> fully furnished for 3D model <br />
                      <b>Premium: </b> staged for HD rendering <br />
                    </>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <ShoppingCart />
                    </Avatar>
                  </ListItemAvatar>
                </HtmlTooltip>
                <ListItemText>{orderLevel}</ListItemText>
              </ListItem>
            )}
            <HtmlTooltip title="Create floorplanner project" placement="left">
              <ListItem key="create_project">
                <CFPButton
                  floorplan={floorplan}
                  onSuccess={setFloorplannerId}
                  disabled={
                    (!floorplanAccepted && floorplanStatus === FloorplanStatus.INITIALIZED) || floorplannerId !== null
                  }
                />
              </ListItem>
            </HtmlTooltip>

            <ListSubheader>3. Navigate to Floorplanner</ListSubheader>
            <ListItem key={floorplannerId}>
              <HtmlTooltip title="Floorplanner Link" placement="left">
                <ListItemAvatar>
                  <Avatar>
                    <Edit />
                  </Avatar>
                </ListItemAvatar>
              </HtmlTooltip>
              {floorplannerId !== null && floorplannerId !== "" ? (
                <Link
                  href={`https://floorplanner.com/projects/${floorplannerId}/editor`}
                  target="_blank"
                  color={floorplanAccepted ? "primary" : "secondary"}
                >
                  Link to Floorplanner
                </Link>
              ) : (
                "No Id set"
              )}
            </ListItem>
            <ListItem
              style={{ display: "flex", justifyContent: "flex-end" }}
              key="showMore"
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open ? (
                <>
                  <Link>Show less</Link>
                  <ArrowDropUp />
                </>
              ) : (
                <>
                  <Link>Show more</Link>
                  <ArrowDropDown />
                </>
              )}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <HtmlTooltip title="Download the images and the FML file" placement="left">
                <ListItem key="download_fml">
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={downloadOriginalImageAndModel}
                    disabled={!floorplanAccepted && floorplanStatus === FloorplanStatus.INITIALIZED}
                    startIcon={<CloudDownload />}
                  >
                    Download FML file
                  </Button>
                </ListItem>
              </HtmlTooltip>
              <HtmlTooltip title="Floorplanner Project ID, you can find it in the URL" placement="left">
                <ListItem key="floorplannerId_set">
                  <TextField
                    required
                    label="Floorplanner ID"
                    name="floorplanner id"
                    value={floorplannerId || ""}
                    disabled={!floorplanAccepted}
                    variant="outlined"
                    onChange={(event) => {
                      setFloorplannerId(event.target.value);
                    }}
                    fullWidth
                  />

                  <HtmlTooltip title="Set Floorplanner ID" placement="top">
                    <ListItemIcon>
                      <IconButton
                        edge="end"
                        disabled={!floorplanAccepted}
                        onClick={() => {
                          handleSetFloorplannerId(floorplannerId);
                        }}
                      >
                        <Save color={floorplanAccepted ? "primary" : "secondary"} />
                      </IconButton>
                    </ListItemIcon>
                  </HtmlTooltip>
                </ListItem>
              </HtmlTooltip>
            </Collapse>

            <ListSubheader>4. Notify Vloor that floorplan is complete</ListSubheader>

            <HtmlTooltip title="Finish floorplan and notify Vloor team" placement="left">
              <ListItem key="finish">
                <Button
                  fullWidth
                  disabled={floorplannerId === null || floorplanFinished}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    updateFloorplanStatus(FloorplanStatus.QA_CREATED);
                    handleImport();
                    handleQANotify(MESSAGE_FLOORPLAN_QA_CREATED);
                  }}
                  startIcon={<DoneAll />}
                >
                  Floor plan is ready for review!
                </Button>
              </ListItem>
            </HtmlTooltip>
          </List>
        </Grid>
      </Grid>
    </FlrPaper>
  );
};

export default ContractorPanel;
