import wohnzimmer from "assets/vurnished/rooms/wohnzimmer.jpeg";
import schlafzimmer from "assets/vurnished/rooms/schlafzimmer.jpg";
import esszimmer from "assets/vurnished/rooms/esszimmer.jpg";
import { Room } from "./types";
import {
  bedroomBed,
  bedroomChair,
  bedroomSideTable,
  livingRoomCouch,
  livingRoomCouchTable,
  livingRoomLamp,
  livingRoomSideTable,
  diningRoomChair,
  diningRoomTable,
} from "./furniture";

const room1: Room = {
  id: 1,
  title: "Wohnzimmer Design",
  img: wohnzimmer,
  furniture: [
    { furniture: livingRoomCouch, position: { top: 49, left: 62 } },
    { furniture: livingRoomCouchTable, position: { top: 60, left: 30 } },
    { furniture: livingRoomLamp, position: { top: 27, left: 21 } },
    { furniture: livingRoomSideTable, position: { top: 35, left: 80 } },
  ],
};

const room2: Room = {
  id: 2,
  title: "Schlafzimmer Design",
  img: schlafzimmer,
  furniture: [
    { furniture: bedroomBed, position: { top: 55, left: 47 } },
    { furniture: bedroomChair, position: { top: 40, left: 76 } },
    { furniture: bedroomSideTable, position: { top: 58, left: 20 } },
  ],
};

const room3: Room = {
  id: 3,
  title: "Esszimmer Design",
  img: esszimmer,
  furniture: [
    { furniture: diningRoomChair, position: { top: 55, left: 22 } },
    { furniture: diningRoomTable, position: { top: 53, left: 55 } },
  ],
};

const rooms = {
  1: room1,
  2: room2,
  3: room3,
};

export default rooms;
