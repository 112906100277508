import { useEffect } from "react";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { Location } from "history";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
};

export type Event = ReactGA.EventArgs;

// initialize Google Analytics and Google Tag Manager
export const init = () => {
  // Enable debug mode on the local development environment - uncomment the next line to enable debug mode again
  const isDev = false; //!process.env.NODE_ENV || process.env.NODE_ENV === "development";
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, { debug: isDev });
  TagManager.initialize(tagManagerArgs);
};

export const sendPageview = (path: string) => {
  const title = path.substring(0, path.lastIndexOf("/"));
  ReactGA.set({ page: path });
  ReactGA.pageview(path, [], title);
};

export const setDimension = (key: string, value: string) => {
  ReactGA.set({ key: value });
};

export const sendEvent = (event: Event) => {
  ReactGA.event(event);
};

export const sendException = (details: ReactGA.FieldsObject) => {
  ReactGA.exception(details);
};

// initialize Google Analytics with useEffect
export const useAnalytics = () => {
  useEffect(() => {
    init();
  }, []);
};

// enable pageview tracking for a set of pages
// requires the location object from react-router-dom
export const usePageViewTracking = (location: Location) => {
  useEffect(() => {
    const currentPath = location.pathname + location.search;
    sendPageview(currentPath);
  }, [location]);
};
