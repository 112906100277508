// @ts-check
import { initSchema } from "@aws-amplify/datastore";
import { schema } from "./schema";

const OrderMailTemplateType = {
  ORDER_NEW: "ORDER_NEW",
  ORDER_PLACED: "ORDER_PLACED",
  ORDER_CONFIRMATION: "ORDER_CONFIRMATION",
  ORDER_DECLINED: "ORDER_DECLINED",
  ORDER_QA_NOTIFY: "ORDER_QA_NOTIFY",
  ORDER_PROCESSED: "ORDER_PROCESSED",
  ORDER_CHANGE_REQUEST: "ORDER_CHANGE_REQUEST",
  ORDER_CHANGE_RESPONSE: "ORDER_CHANGE_RESPONSE",
};

const RecognitionServiceEventType = {
  PREVIEW: "PREVIEW",
  RECOGNITION: "RECOGNITION",
  FLOORPLANNER_API_CALL: "FLOORPLANNER_API_CALL",
  FLOORPLANNER_REIMPORT: "FLOORPLANNER_REIMPORT",
};

const OriginalInputType = {
  SKETCH: "SKETCH",
  POINTCLOUD: "POINTCLOUD",
};

const FloorplanStatus = {
  INITIALIZED: "INITIALIZED",
  GENERATED: "GENERATED",
  IN_PROGRESS: "IN_PROGRESS",
  REJECTED: "REJECTED",
  QA_CREATED: "QA_CREATED",
  REWORK: "REWORK",
  CREATED: "CREATED",
};

const Level = {
  FML: "FML",
  BASIC: "BASIC",
  BASIC_PLUS: "BASIC_PLUS",
  PROFESSIONAL: "PROFESSIONAL",
  PREMIUM: "PREMIUM",
};

const PaymentStatus = {
  PENDING: "PENDING",
  PAID: "PAID",
  FAILED: "FAILED",
  NO_PAYMENT_REQUIRED: "NO_PAYMENT_REQUIRED",
};

const OrderStatus = {
  CONFIRMED: "CONFIRMED",
  DECLINED: "DECLINED",
  PROCESSED: "PROCESSED",
  INVOICED: "INVOICED",
  FINISHED: "FINISHED",
};

const {
  PreprocessImageResult,
  RecognitionServiceResult,
  ChatMessage,
  FloorplanProject,
  Floorplan,
  Room,
  FloorplanOrder,
  Order,
  Customer,
  Settings,
  Payment,
  GetPreSignedUrlsResult,
  S3Object,
  ExportFiles,
} = initSchema(schema);

export {
  PreprocessImageResult,
  RecognitionServiceResult,
  ChatMessage,
  FloorplanProject,
  Floorplan,
  Room,
  FloorplanOrder,
  Order,
  Customer,
  Settings,
  Payment,
  OrderMailTemplateType,
  RecognitionServiceEventType,
  OriginalInputType,
  FloorplanStatus,
  Level,
  PaymentStatus,
  OrderStatus,
  GetPreSignedUrlsResult,
  S3Object,
  ExportFiles,
};
