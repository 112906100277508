import styled from "styled-components";
import { GridListTile, GridListTileBar, IconButton } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: auto;
`;

const FlrGridListTile = styled(GridListTile)`
  cursor: pointer;
  ::marker {
    color: white;
  }
`;
const FlrGridListTileBar = styled(GridListTileBar)`
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%);
`;

const FlrIconButton = styled(IconButton)`
  color: white;
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

type LightboxImagesProps = {
  images: string[];
  tabs: TabProps[];
};

export interface TabProps {
  label: string;
  disabled?: boolean;
  selected?: boolean;
}

const LightboxImages: React.FC<LightboxImagesProps> = ({ images, tabs }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const selTab = tabs.filter((tab) => tab.selected);
  const selTabIndex = tabs.indexOf(selTab[0]);

  const [selectedTab, setSelectedTab] = useState(selTabIndex !== -1 ? selTabIndex : 0);
  const altTexts = tabs.map((tab) => tab.label);

  const openLightbox = (photoIndex: number) => {
    setPhotoIndex(photoIndex);
    setLightboxOpen(true);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setPhotoIndex(newValue);
    setSelectedTab(newValue);
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <FlrGridListTile cols={1}>
            <img
              src={images[props.index]}
              alt={altTexts[props.index]}
              onClick={() => openLightbox(props.index)}
              width={"100%"}
            />
            <FlrGridListTileBar
              titlePosition="bottom"
              actionIcon={
                <FlrIconButton aria-label={`Zoomn`} onClick={() => openLightbox(props.index)}>
                  <ZoomInIcon />
                </FlrIconButton>
              }
              actionPosition="left"
            />
          </FlrGridListTile>
        )}
      </div>
    );
  };

  return (
    <>
      <Root>
        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="image tabs"
        >
          {tabs.map((tab) => {
            return <Tab key={tab.label} label={tab.label} disabled={tab.disabled} />;
          })}
        </Tabs>
        {tabs.map((tab) => {
          return <TabPanel key={tab.label} value={selectedTab} index={tabs.indexOf(tab)} />;
        })}
      </Root>
      {lightboxOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          reactModalStyle={{
            overlay: {
              zIndex: 1300,
            },
          }}
        />
      )}
    </>
  );
};

export default LightboxImages;
