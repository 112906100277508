import { Button, Grid, Theme, Tooltip, Typography, withStyles } from "@material-ui/core";
import { Info, ExpandMore } from "@material-ui/icons/";
import React from "react";

import styled from "styled-components";

const NavBar = styled(Grid)`
  border-bottom: 1px solid lightgrey;
  border-radius: 14px;
  flex: 0 0 auto;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    maxWidth: 380,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

type Props = {
  onMinimize: () => void;
};

const ChatNav: React.FC<Props> = ({ onMinimize }) => {
  return (
    <NavBar item xs={12}>
      <HtmlTooltip
        placement="top-start"
        title={
          <>
            <Typography color="inherit">Bei Änderungswünschen können zusätzliche Kosten anfallen!</Typography>
            Kleine Änderungen sind beim ersten mal kostenlos. <br />
            Für Änderungswünsche die nicht klar aus dem Plan ersichtlich sind fällt eine Änderungspauschale von{" "}
            <b>{"1,95- € zzgl. MwSt"}</b> an.
          </>
        }
      >
        <Info />
      </HtmlTooltip>
      <Typography variant="h5" component="h2" align="center">
        Änderungswünsche
      </Typography>
      <Button onClick={onMinimize}>
        <ExpandMore />
      </Button>
    </NavBar>
  );
};

export default ChatNav;
