import React from "react";
import styled from "styled-components";

import EmptyLayout from "container/layout/EmptyLayout";
import IconLink from "components/layout/IconLink";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { ContentHeader, Headline } from "components/layout/StyledComponents";
import { Box, Grid } from "@material-ui/core";

import Logo from "../assets/logo_text_right_black_transparent.png";

const Root = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-bottom: 10px;
`;

export const NotFoundRoute = () => {
  return (
    <EmptyLayout>
      <Root container spacing={3}>
        <Grid item>
          <Box display="flex" justifyContent="center">
            <img src={Logo} alt="Logo" height="80" />
          </Box>
        </Grid>
        <Grid item>
          <ContentHeader>
            <IconLink tooltip="Zurück" to="/" icon={<ArrowBackIcon />} />
            <Headline variant="h4" component="h1">
              404: The page you where looking for could not be found!
            </Headline>
          </ContentHeader>
        </Grid>
      </Root>
    </EmptyLayout>
  );
};
