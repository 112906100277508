import couch1Img from "assets/vurnished/furniture/couch1.webp";
import couch2Img from "assets/vurnished/furniture/couch2.webp";
import couch3Img from "assets/vurnished/furniture/couch3.webp";
import roundDiningTable1Img from "assets/vurnished/furniture/roundDiningTable1.webp";
import roundDiningTable2Img from "assets/vurnished/furniture/roundDiningTable2.webp";
import roundDiningTable3Img from "assets/vurnished/furniture/roundDiningTable3.webp";
import chair1Img from "assets/vurnished/furniture/chair1.webp";
import chair2Img from "assets/vurnished/furniture/chair2.webp";
import carpet1Img from "assets/vurnished/furniture/carpet1.webp";
import livingRoomTable1Img from "assets/vurnished/furniture/livingRoomTable1.webp";
import bed1Img from "assets/vurnished/furniture/bed1.webp";
import basket1Img from "assets/vurnished/furniture/basket1.webp";
import basket2Img from "assets/vurnished/furniture/basket2.webp";
import sideboard1Img from "assets/vurnished/furniture/sideboard1.webp";
import ceilingLight1Img from "assets/vurnished/furniture/ceilingLight1.webp";

import bedroomBedImg from "assets/vurnished/furniture/bedroom_bed.png";
import bedroomChairImg from "assets/vurnished/furniture/bedroom_chair.png";
import bedroomSideImg from "assets/vurnished/furniture/bedroom_side_table.png";

import diningRoomChairImg from "assets/vurnished/furniture/dining_room_chair.png";
import diningRoomTableImg from "assets/vurnished/furniture/dining_room_table.png";

import livingRoomCouchTableImg from "assets/vurnished/furniture/living_room_couch_table.png";
import livingRoomCouchImg from "assets/vurnished/furniture/living_room_couch.png";
import livingRoomLampImg from "assets/vurnished/furniture/living_room_lamp.png";
import livingRoomSideTableImg from "assets/vurnished/furniture/living_room_side_table.png";

import { Furniture, FurnitureCategory } from "./types";
class FurnitureFactory {
  id: number;

  constructor() {
    this.id = 0;
  }

  build(title: string, link: string, img: string, price: number, category: FurnitureCategory): Furniture {
    const newId = this.id++;
    return { id: newId, title, link, img, price, category, checked: true };
  }
}

const ff = new FurnitureFactory();

export const couch1: Furniture = ff.build(
  "Ecksofa Summer",
  "https://www.home24.at/produkt/ecksofa-summer-ii-webstoff-hellgrau-longchair-davorstehend-rechts-bettkasten-schlaffunktion-fuer-dauerhafte-nutzung",
  couch1Img,
  919.99,
  FurnitureCategory.COUCH
);

export const couch2: Furniture = ff.build(
  "2-sitzer Sofa",
  "https://www.home24.at/produkt/ecksofa-summer-ii-webstoff-hellgrau-longchair-davorstehend-rechts-bettkasten-schlaffunktion-fuer-dauerhafte-nutzung",
  couch2Img,
  551.99,
  FurnitureCategory.COUCH
);

export const couch3: Furniture = ff.build(
  "Ecksofa Sola",
  "https://www.home24.at/produkt/ecksofa-summer-ii-webstoff-hellgrau-longchair-davorstehend-rechts-bettkasten-schlaffunktion-fuer-dauerhafte-nutzung",
  couch3Img,
  699.99,
  FurnitureCategory.COUCH
);

export const carpet1: Furniture = ff.build(
  "Hochflorteppich Kaunas",
  "https://www.home24.at/produkt/hochflorteppich-kaunas-160-x-230-cm",
  carpet1Img,
  299.99,
  FurnitureCategory.CARPET
);

export const livingRoomTable1: Furniture = ff.build(
  "Couchtisch Forsby",
  "https://www.home24.at/produkt/couchtisch-forsby-glas-eiche-massiv",
  livingRoomTable1Img,
  164.99,
  FurnitureCategory.LIVINGROOMTABLE
);

export const chair1: Furniture = ff.build(
  "Schaukelstuhl Bolands",
  "https://www.home24.at/produkt/schaukelstuhl-bolands",
  chair1Img,
  139.99,
  FurnitureCategory.CHAIR
);

export const chair2: Furniture = ff.build(
  "Sessel La Vieux",
  "https://www.home24.at/produkt/sessel-la-vieux-beige",
  chair2Img,
  379.99,
  FurnitureCategory.CHAIR
);

export const bed1: Furniture = ff.build(
  "Himmelbett Lisa",
  "https://www.home24.at/produkt/himmelbett-lisa-eiche-180-x-200cm",
  bed1Img,
  749.99,
  FurnitureCategory.BED
);

export const basket1: Furniture = ff.build(
  "Korb MODE",
  "https://www.home24.at/produkt/korb-mode-wasserhyazinthe-kunststoff-beige-weiss",
  basket1Img,
  47.99,
  FurnitureCategory.BASKET
);

export const basket2: Furniture = ff.build(
  "Dekokorb Naranjo",
  "https://www.home24.at/produkt/dekokorb-naranjo-naturfaser-creme",
  basket2Img,
  51.99,
  FurnitureCategory.BASKET
);

export const roundDiningTable1: Furniture = ff.build(
  "Esstisch Menden",
  "https://www.home24.at/produkt/esstisch-menden-marmor-metall-weiss",
  roundDiningTable1Img,
  579.99,
  FurnitureCategory.ROUNDDININGTABLE
);

export const roundDiningTable2: Furniture = ff.build(
  "Esstisch Clent",
  "https://www.home24.at/produkt/esstisch-clent-marmor-metall-mamor-messing",
  roundDiningTable2Img,
  399.99,
  FurnitureCategory.ROUNDDININGTABLE
);

export const roundDiningTable3: Furniture = ff.build(
  "Esstisch Laud",
  "https://www.home24.at/produkt/esstisch-laud-weiss",
  roundDiningTable3Img,
  264.99,
  FurnitureCategory.ROUNDDININGTABLE
);

export const sideboard1: Furniture = ff.build(
  "Sideboard Bianco II",
  "https://www.home24.at/produkt/sideboard-bianco-ii-hochglanz-weiss",
  sideboard1Img,
  469.99,
  FurnitureCategory.SIDEBOARD
);

export const ceilingLight1: Furniture = ff.build(
  "Pendelleuchte Brooklyn II",
  "https://www.home24.at/produkt/pendelleuchte-brooklyn-ii-metall-glas-5-flammig",
  ceilingLight1Img,
  134.99,
  FurnitureCategory.CEILINGLIGHT
);

export const bedroomBed: Furniture = ff.build("Bett Ella", "", bedroomBedImg, 749.99, FurnitureCategory.BED);

export const bedroomChair: Furniture = ff.build("Stuhl Algas", "", bedroomChairImg, 104.99, FurnitureCategory.CHAIR);

export const bedroomSideTable: Furniture = ff.build(
  "Nachttisch Bellia",
  "",
  bedroomSideImg,
  149.99,
  FurnitureCategory.BEDSIDETABLE
);

export const diningRoomChair: Furniture = ff.build(
  "4x Stuhl Galor",
  "",
  diningRoomChairImg,
  239.96,
  FurnitureCategory.CHAIR
);

export const diningRoomTable: Furniture = ff.build(
  "Esstisch Quono",
  "",
  diningRoomTableImg,
  799.99,
  FurnitureCategory.DININGTABLE
);

export const livingRoomCouchTable: Furniture = ff.build(
  "Couchtisch Emblem",
  "",
  livingRoomCouchTableImg,
  294.99,
  FurnitureCategory.LIVINGROOMTABLE
);

export const livingRoomCouch: Furniture = ff.build(
  "Ecksofa Mule",
  "",
  livingRoomCouchImg,
  999.99,
  FurnitureCategory.COUCH
);

export const livingRoomLamp: Furniture = ff.build(
  "Stehleuchte Lagar",
  "",
  livingRoomLampImg,
  179.99,
  FurnitureCategory.LAMP
);

export const livingRoomSideTable: Furniture = ff.build(
  "Kommode Yila",
  "",
  livingRoomSideTableImg,
  259.99,
  FurnitureCategory.SIDEBOARD
);

export const furnitures = [
  chair1,
  chair2,
  couch1,
  couch2,
  couch3,
  bed1,
  livingRoomTable1,
  carpet1,
  basket1,
  basket2,
  roundDiningTable1,
  roundDiningTable2,
  roundDiningTable3,
  sideboard1,
  ceilingLight1,
  bedroomBed,
  bedroomChair,
  bedroomSideTable,
  livingRoomCouch,
  livingRoomCouchTable,
  livingRoomLamp,
  livingRoomSideTable,
  diningRoomChair,
  diningRoomTable,
];

export const furnituresWithCategory = (category: FurnitureCategory) => {
  return furnitures.filter((f) => f.category === category);
};
