import React, { useState } from "react";
import { FlrPaper } from "../layout/StyledComponents";
import { Floorplan } from "../../models";
import {
  Avatar,
  Collapse,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Folder, Gesture, Storage, AssignmentInd, ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { S3_BUCKET, S3_REGION } from "../../constants";

type TechnicalInfoPanelProps = {
  floorplan: Floorplan;
};

type TechnicalInfo = {
  user_id: string;
  identityId: string;
  project_id: string;
  floorplan_id: string;
};

const environment = process.env.REACT_APP_ENV || "dev";

const TechnicalInfoPanel: React.FC<TechnicalInfoPanelProps> = ({ floorplan }) => {
  const [open, setOpen] = useState(false);

  const technicalInfo: TechnicalInfo = {
    user_id: floorplan.owner,
    identityId: floorplan.customer?.identityId,
    project_id: floorplan.projectId,
    floorplan_id: floorplan.id,
  };

  const s3_bucket_name = S3_BUCKET[environment];
  const s3Uri = `https://s3.console.aws.amazon.com/s3/buckets/${s3_bucket_name}?region=${S3_REGION}&prefix=private/${technicalInfo.identityId}/floorplans/${technicalInfo.project_id}/&showversions=false`;

  return (
    <FlrPaper>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2" align="center">
            Technical Info
          </Typography>
          {technicalInfo && (
            <List>
              <ListSubheader>S3 location IDs</ListSubheader>
              <ListItem key="TI_S3_link">
                <Tooltip title="Link to S3 Bucket" placement="left">
                  <ListItemAvatar>
                    <Avatar>
                      <Storage />
                    </Avatar>
                  </ListItemAvatar>
                </Tooltip>
                <Link href={s3Uri} target="_blank" color="primary">
                  Link to S3 Bucket
                </Link>
              </ListItem>
              <ListItem
                style={{ display: "flex", justifyContent: "flex-end" }}
                key="showMore"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                {open ? (
                  <>
                    <Link>Show less</Link>
                    <ArrowDropUp />
                  </>
                ) : (
                  <>
                    <Link>Show more</Link>
                    <ArrowDropDown />
                  </>
                )}
              </ListItem>

              <Collapse in={open} timeout="auto" unmountOnExit>
                <ListItem key="TI_userID">
                  <Tooltip title="User ID sub" placement="left">
                    <ListItemAvatar>
                      <Avatar></Avatar>
                    </ListItemAvatar>
                  </Tooltip>
                  <ListItemText primary={technicalInfo.user_id} />
                </ListItem>
                <ListItem key="TI_IdentityID">
                  <Tooltip title="IdentityID / S3 Bucket ID" placement="left">
                    <ListItemAvatar>
                      <Avatar>
                        <AssignmentInd />
                      </Avatar>
                    </ListItemAvatar>
                  </Tooltip>
                  <ListItemText primary={technicalInfo.identityId} />
                </ListItem>
                <ListItem key="TI_ProjectID">
                  <Tooltip title="Project ID" placement="left">
                    <ListItemAvatar>
                      <Avatar>
                        <Folder />
                      </Avatar>
                    </ListItemAvatar>
                  </Tooltip>
                  <ListItemText primary={technicalInfo.project_id} />
                </ListItem>
                <ListItem key="TI_FP_ID">
                  <Tooltip title="Floorplan ID" placement="left">
                    <ListItemAvatar>
                      <Avatar>
                        <Gesture />
                      </Avatar>
                    </ListItemAvatar>
                  </Tooltip>
                  <ListItemText primary={technicalInfo.floorplan_id} />
                </ListItem>
              </Collapse>
            </List>
          )}
        </Grid>
      </Grid>
    </FlrPaper>
  );
};

export default TechnicalInfoPanel;
