import { Send, AttachFile } from "@material-ui/icons/";
import { Badge, Button, CircularProgress, Grid, TextField } from "@material-ui/core";
import styled from "styled-components";
import { useCreateChatMessage } from "hooks/communication/ChatMessageHooks";
import { useEffect, useState } from "react";
import { CreateChatMessageInput } from "API";
import { useIsContractor, useIsEditor } from "providers/AuthContextProvider";
import { useFilePicker } from "use-file-picker";
import { Floorplan, FloorplanOrder } from "models";
import { imageToS3Object } from "utils/ChatMessageImageDBUtils";
import { useUploadImage } from "hooks/image/UploadImageHook";
import { sendChangeRequest, sendChangeResponse } from "hooks/mails/MailServiceHooks";

const ChatBoxContainer = styled(Grid)`
  border-top: 1px solid lightgrey;
  border-radius: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  flex: 0 0 auto;
  background-color: white;
`;

const ChatTextField = styled(TextField)`
  flex-grow: 1;
`;

type Props = {
  floorplan: Floorplan;
};

const ChatBox: React.FC<Props> = ({ floorplan }) => {
  const isContractor = useIsContractor();
  const isEditor = useIsEditor();
  const isCustomer = !isContractor && !isEditor;
  const [message, setMessage] = useState("");
  const [images, setImages] = useState([]);
  const { createChatMessage } = useCreateChatMessage();
  const { uploadImage, loading: uploading } = useUploadImage();
  const [openFileSelector, { plainFiles }] = useFilePicker({
    readAs: "DataURL",
    accept: "image/png, image/jpeg, application/pdf",
    multiple: true,
    // limitFilesConfig: { max: 5 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 50,
    // imageSizeRestrictions: {
    //   maxHeight: 900, // in pixels
    //   maxWidth: 1600,
    //   minHeight: 600,
    //   minWidth: 768,
    // },
  });

  useEffect(() => {
    setImages(plainFiles);
  }, [plainFiles]);

  const onSend = async () => {
    const trimmedMessage = message.trim();

    const s3Objects = [];

    images.forEach(async (file) => {
      const s3Object = imageToS3Object(file.name, floorplan);
      s3Objects.push(s3Object);
      const res = await uploadImage(s3Object, file);
      console.log(res);
    });

    const chatMessage: CreateChatMessageInput = {
      floorplanId: floorplan.id,
      message: trimmedMessage,
      sentByCustomer: isCustomer,
      read: false,
      owner: floorplan.owner,
      images: s3Objects,
    };
    setImages([]);
    setMessage("");
    await createChatMessage(chatMessage);
    if (isCustomer) {
      await sendChangeRequest(
        floorplan.customer,
        (floorplan.orders[0] as FloorplanOrder).order,
        floorplan,
        trimmedMessage
      );
    } else {
      await sendChangeResponse(
        floorplan.customer,
        (floorplan.orders[0] as FloorplanOrder).order,
        floorplan,
        trimmedMessage
      );
    }
  };

  return (
    <ChatBoxContainer item xs={12}>
      <ChatTextField
        id="chat-message"
        variant="outlined"
        placeholder="Ihre Anfrage..."
        multiline
        rowsMax={4}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      {isCustomer && (
        <Button onClick={() => openFileSelector()} disabled={uploading}>
          {uploading ? (
            <CircularProgress />
          ) : (
            <Badge badgeContent={images.length} color="secondary">
              <AttachFile />
            </Badge>
          )}
        </Button>
      )}
      <Button variant="contained" color="primary" disabled={uploading || message.trim() === ""} onClick={onSend}>
        <Send />
      </Button>
    </ChatBoxContainer>
  );
};

export default ChatBox;
