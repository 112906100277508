import { colors, Typography } from "@material-ui/core";
import MaterialTable, { Column, MTableBody, MTableToolbar } from "material-table";
import React, { useState } from "react";
import styled from "styled-components";
import { FlrPaper } from "../../components/layout/StyledComponents";
import { Room } from "../../models";
import getTableIcons from "../../utils/TableIcons";

type Props = {
  rooms: Room[];
};

type TableColumns = Array<Column<Row>>;
type TableData = Row[];
type Row = {
  id: string;
  name: string;
  type?: string;
  size: number;
  //windows: number;
  //doors: number;
};

const createTableData = (rooms: Room[]): TableData => {
  return rooms.map((room) => ({
    id: room.id,
    name: room.id,
    type: room.room_type,
    size: Number(room.room_size_sqm.toFixed(2)),
    //windows: room.windows,
    //doors: room.doors
  }));
};

const computeRoomTotal = (rooms: Room[]) => {
  const totalSize = rooms.reduce((prev, cur) => prev + cur.room_size_sqm, 0);
  return totalSize;
};

const Table = styled.div`
  min-width: 650px;
  margin-bottom: ${(p) => p.theme.spacing(4)}px;
`;

const Toolbar = styled(MTableToolbar)`
  padding-left: ${(p) => p.theme.spacing(0)};
`;

// TODO make roomtypes editable
// const createRoomTypes = (rooms: Room[]) => {
//   const types = {};
//   rooms.map((room) => ({
//     "1": room.room_type,
//   }));

//   return types;
// };

// const RoomTypes = {
//   1: "Raum",
//   2: "Bad",
//   3: "WC",
//   4: "Wohnzimmer",
//   5: "Schlafzimmer",
// };

const DetailRoomsTable: React.FC<Props> = ({ rooms }: Props) => {
  const tableIcons = getTableIcons();
  //const [roomtypes, setRoomTypes] = useState(() => createRoomTypes(rooms));
  const [data] = useState<TableData>(() => createTableData(rooms));
  const roomTotal = computeRoomTotal(rooms);
  const [columns] = useState<TableColumns>([
    { title: "Name", field: "name", editable: "never" },
    { title: "Raumtyp", field: "type", type: "string", editable: "never" }, //, lookup: RoomTypes },
    { title: "Größe (m²)", field: "size", type: "numeric", editable: "never" },
  ]);

  return (
    <FlrPaper>
      <Table>
        <MaterialTable
          title="Metadaten"
          columns={columns}
          components={{
            Container: (p) => p.children,
            Body: (props) => (
              <>
                <MTableBody {...props} />
              </>
            ),
            Toolbar: (props) => (
              <div>
                <Toolbar {...props} />
                <div style={{ padding: "0px 10px" }}>
                  <Typography>Total Room Size: {roomTotal.toFixed(2)} m2</Typography>
                </div>
              </div>
            ),
          }}
          data={data}
          icons={tableIcons}
          options={{
            columnsButton: true,
            draggable: false,
            emptyRowsWhenPaging: false,
            headerStyle: {
              fontWeight: 900,
            },
            padding: "default",
            rowStyle: (_, i) =>
              i % 2 === 0
                ? { paddingLeft: 4, paddingRight: 4 }
                : {
                    backgroundColor: colors.grey[200],
                    paddingLeft: 4,
                    paddingRight: 4,
                  },
            search: true,
          }}
          // todo implement update
          // editable={{
          //   onRowUpdate: (newData, oldData) =>
          //     new Promise((resolve: any, reject) => {
          //       setTimeout(() => {
          //         const dataUpdate = [...data];
          //         const index = oldData.id;
          //         dataUpdate[index] = newData;
          //         setData([...dataUpdate]);
          //         // trigger DB update here

          //         resolve();
          //       }, 1000);
          //     }),
          // }}
        />
      </Table>
    </FlrPaper>
  );
};

export default DetailRoomsTable;
