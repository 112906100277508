import { Route } from "react-router-dom";
import VurnishedPrice from "container/vurnished/VurnishedPrice";
import VurnishedFinished from "container/vurnished/VurnishedFinished";
import VurnishedOverview from "container/vurnished/VurnishedOverview";
// import VurnishedRoom from "../container/vurnished/VurnishedRoom";
import FurnitureConfigProvider from "providers/FurnitureConfigContextProvider";

export const VurnishedRoute = ({ match }) => {
  return (
    <FurnitureConfigProvider>
      <Route exact path={`${match.path}/:id/rent`} render={({ match }) => <VurnishedFinished id={match.params.id} />} />
      <Route exact path={`${match.path}/:id/detail`} render={({ match }) => <VurnishedPrice id={match.params.id} />} />
      {/* <Route exact path={`${match.path}/:id`} render={({ match }) => <VurnishedRoom id={match.params.id} />} /> */}
      <Route exact path={match.url} render={() => <VurnishedOverview />} />
    </FurnitureConfigProvider>
  );
};
