/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Grid, Typography } from "@material-ui/core";
import { Modes } from "container/view/FloorplanViewer";
import IFrame from "container/view/IFrame";
import styled from "styled-components";
import { ROOMPLANNER_FLOORPLANNER_BASE_URL } from "../../constants";
import { VIEWER_FLOORPLANNER_BASE_URL } from "../../constants";
import RoomplannerActionBlocker from "container/view/RoomplannerActionBlocker";
import MuiRouterLink from "components/links/MuiRouterLink";

import rooms from "../vurnished/mockData/rooms";
import { FlrPaper } from "components/layout/StyledComponents";
import BackLink from "components/links/BackLink";

type Props = {
  floorplannerId: string;
};

const RoomCubeGridItem = styled(Grid)`
  &:hover {
    * > img {
      border: 2px solid ${(props) => props.theme.palette.primary.main};
    }
  }
`;

const RoomCube = styled.img`
  margin: 5px;
  border-radius: 14px;
  width: 100%;
  border: 2px solid transparent;
`;

const FloorplanEstateMatterport: React.FC<Props> = ({ floorplannerId }) => {
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={2} sm={4} md={3}>
        <BackLink to={`/estate/${floorplannerId}/images?selected=0`} tooltip={"Zurück zur Übersicht"} />
      </Grid>

      <Grid
        item
        xs={2}
        sm={3}
        md={6}
        component={Box}
        style={{ justifyContent: "center", display: "flex", alignItems: "center" }}
      >
        <Typography variant="h4" align="center">
          Matterport Tour
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <IFrame link={`https://my.matterport.com/show/?m=6QA2SNbbnke`} key={floorplannerId} />
      </Grid>
    </Grid>
  );
};

export default FloorplanEstateMatterport;
