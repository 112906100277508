/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type Floorplan = {
  __typename: "Floorplan";
  id?: string;
  type?: string | null;
  owner?: string | null;
  projectId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  name?: string;
  address?: string | null;
  description?: string | null;
  originalImageName?: string | null;
  originalInputType?: OriginalInputType | null;
  shareable?: boolean | null;
  originalImage?: S3Object;
  croppedImage?: S3Object;
  preprocessedImage?: S3Object;
  visual2DImage?: S3Object;
  mlPredictionImage?: S3Object;
  ocrPredictionImage?: S3Object;
  postMlImage?: S3Object;
  internalModelImage?: S3Object;
  basicImage?: ExportFiles;
  basicPlusImage?: ExportFiles;
  professionalImageTop?: ExportFiles;
  professionalImageTilted?: ExportFiles;
  professionalImageNE?: ExportFiles;
  professionalImageNW?: ExportFiles;
  professionalImageSE?: ExportFiles;
  professionalImageSW?: ExportFiles;
  reactplannerModel?: S3Object;
  floorplannerModel?: S3Object;
  ifcModel?: S3Object;
  reimportedIfcModel?: S3Object;
  total_size?: number | null;
  amount_doors?: number | null;
  amount_rooms?: number | null;
  amount_windows?: number | null;
  total_room_size_m2?: number | null;
  total_room_size_px?: number | null;
  user_total_size?: number | null;
  user_scaling_selected_px?: number | null;
  user_scaling_cm?: number | null;
  api_scaling_factor_cm_per_px?: number | null;
  status?: FloorplanStatus | null;
  customerId?: string | null;
  floorplannerId?: string | null;
  acceptedAt?: string | null;
  finishedAt?: string | null;
  rooms?: ModelRoomConnection;
  orders?: ModelFloorplanOrderConnection;
  customer?: Customer;
  chatMessages?: ModelChatMessageConnection;
};

export enum OriginalInputType {
  SKETCH = "SKETCH",
  POINTCLOUD = "POINTCLOUD",
}

export type S3Object = {
  __typename: "S3Object";
  bucket?: string;
  region?: string;
  key?: string;
  status?: string | null;
};

export type ExportFiles = {
  __typename: "ExportFiles";
  png?: S3Object;
  jpeg?: S3Object;
  pdf?: S3Object;
};

export enum FloorplanStatus {
  INITIALIZED = "INITIALIZED",
  GENERATED = "GENERATED",
  IN_PROGRESS = "IN_PROGRESS",
  REJECTED = "REJECTED",
  QA_CREATED = "QA_CREATED",
  REWORK = "REWORK",
  CREATED = "CREATED",
}

export type ModelRoomConnection = {
  __typename: "ModelRoomConnection";
  items?: Array<Room | null> | null;
  nextToken?: string | null;
};

export type Room = {
  __typename: "Room";
  id?: string;
  floorplanId?: string;
  name?: string | null;
  room_size_sqm?: number | null;
  room_type?: string | null;
  createdAt?: string;
  updatedAt?: string;
  owner?: string | null;
};

export type ModelFloorplanOrderConnection = {
  __typename: "ModelFloorplanOrderConnection";
  items?: Array<FloorplanOrder | null> | null;
  nextToken?: string | null;
};

export type FloorplanOrder = {
  __typename: "FloorplanOrder";
  id?: string;
  floorplan_id?: string;
  order_id?: string;
  owner?: string;
  customer?: string | null;
  createdAt?: string;
  updatedAt?: string;
  floorplan?: Floorplan;
  order?: Order;
};

export type Order = {
  __typename: "Order";
  id?: string;
  user?: string;
  customer?: string;
  owner?: string;
  amount_total?: number | null;
  level?: Level | null;
  stripe_checkout_session_id?: string | null;
  paymentStatus?: PaymentStatus | null;
  orderStatus?: OrderStatus | null;
  createdAt?: string;
  updatedAt?: string;
  floorplans?: ModelFloorplanOrderConnection;
};

export enum Level {
  FML = "FML",
  BASIC = "BASIC",
  BASIC_PLUS = "BASIC_PLUS",
  PROFESSIONAL = "PROFESSIONAL",
  PREMIUM = "PREMIUM",
}

export enum PaymentStatus {
  PENDING = "PENDING",
  PAID = "PAID",
  FAILED = "FAILED",
  NO_PAYMENT_REQUIRED = "NO_PAYMENT_REQUIRED",
}

export enum OrderStatus {
  CONFIRMED = "CONFIRMED",
  DECLINED = "DECLINED",
  PROCESSED = "PROCESSED",
  INVOICED = "INVOICED",
  FINISHED = "FINISHED",
}

export type Customer = {
  __typename: "Customer";
  id?: string;
  user?: string;
  identityId?: string | null;
  email?: string;
  stripe_customer_id?: string | null;
  phone_number?: string | null;
  company?: string | null;
  company_address?: string | null;
  company_address_line2?: string | null;
  company_city?: string | null;
  company_taxnumber?: string | null;
  country?: string | null;
  zip?: string | null;
  accepted_terms?: boolean | null;
  accepted_gdpr?: boolean | null;
  createdAt?: string;
  updatedAt?: string;
  floorplans?: ModelFloorplanConnection;
};

export type ModelFloorplanConnection = {
  __typename: "ModelFloorplanConnection";
  items?: Array<Floorplan | null> | null;
  nextToken?: string | null;
};

export type ModelChatMessageConnection = {
  __typename: "ModelChatMessageConnection";
  items?: Array<ChatMessage | null> | null;
  nextToken?: string | null;
};

export type ChatMessage = {
  __typename: "ChatMessage";
  id?: string;
  floorplanId?: string;
  message?: string;
  sentByCustomer?: boolean;
  read?: boolean;
  createdAt?: string | null;
  owner?: string | null;
  images?: Array<S3Object | null> | null;
  updatedAt?: string;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export type ModelCustomerConnection = {
  __typename: "ModelCustomerConnection";
  items?: Array<Customer | null> | null;
  nextToken?: string | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelFloorplanFilterInput = {
  id?: ModelIDInput | null;
  type?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  projectId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  name?: ModelStringInput | null;
  address?: ModelStringInput | null;
  description?: ModelStringInput | null;
  originalImageName?: ModelStringInput | null;
  originalInputType?: ModelOriginalInputTypeInput | null;
  shareable?: ModelBooleanInput | null;
  total_size?: ModelFloatInput | null;
  amount_doors?: ModelIntInput | null;
  amount_rooms?: ModelIntInput | null;
  amount_windows?: ModelIntInput | null;
  total_room_size_m2?: ModelFloatInput | null;
  total_room_size_px?: ModelFloatInput | null;
  user_total_size?: ModelFloatInput | null;
  user_scaling_selected_px?: ModelIntInput | null;
  user_scaling_cm?: ModelIntInput | null;
  api_scaling_factor_cm_per_px?: ModelFloatInput | null;
  status?: ModelFloorplanStatusInput | null;
  customerId?: ModelIDInput | null;
  floorplannerId?: ModelIDInput | null;
  acceptedAt?: ModelStringInput | null;
  finishedAt?: ModelStringInput | null;
  and?: Array<ModelFloorplanFilterInput | null> | null;
  or?: Array<ModelFloorplanFilterInput | null> | null;
  not?: ModelFloorplanFilterInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelOriginalInputTypeInput = {
  eq?: OriginalInputType | null;
  ne?: OriginalInputType | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelFloorplanStatusInput = {
  eq?: FloorplanStatus | null;
  ne?: FloorplanStatus | null;
};

export type CreateFloorplanProjectInput = {
  id?: string | null;
  name: string;
  address?: string | null;
  description?: string | null;
  size?: number | null;
};

export type ModelFloorplanProjectConditionInput = {
  name?: ModelStringInput | null;
  address?: ModelStringInput | null;
  description?: ModelStringInput | null;
  size?: ModelIntInput | null;
  and?: Array<ModelFloorplanProjectConditionInput | null> | null;
  or?: Array<ModelFloorplanProjectConditionInput | null> | null;
  not?: ModelFloorplanProjectConditionInput | null;
};

export type FloorplanProject = {
  __typename: "FloorplanProject";
  id?: string;
  name?: string;
  address?: string | null;
  description?: string | null;
  size?: number | null;
  createdAt?: string;
  updatedAt?: string;
  owner?: string | null;
};

export type UpdateFloorplanProjectInput = {
  id: string;
  name?: string | null;
  address?: string | null;
  description?: string | null;
  size?: number | null;
};

export type DeleteFloorplanProjectInput = {
  id?: string | null;
};

export type CreateFloorplanInput = {
  id?: string | null;
  type?: string | null;
  owner?: string | null;
  projectId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  name: string;
  address?: string | null;
  description?: string | null;
  originalImageName?: string | null;
  originalInputType?: OriginalInputType | null;
  shareable?: boolean | null;
  originalImage?: S3ObjectInput | null;
  croppedImage?: S3ObjectInput | null;
  preprocessedImage?: S3ObjectInput | null;
  visual2DImage?: S3ObjectInput | null;
  mlPredictionImage?: S3ObjectInput | null;
  ocrPredictionImage?: S3ObjectInput | null;
  postMlImage?: S3ObjectInput | null;
  internalModelImage?: S3ObjectInput | null;
  basicImage?: ExportFilesInput | null;
  basicPlusImage?: ExportFilesInput | null;
  professionalImageTop?: ExportFilesInput | null;
  professionalImageTilted?: ExportFilesInput | null;
  professionalImageNE?: ExportFilesInput | null;
  professionalImageNW?: ExportFilesInput | null;
  professionalImageSE?: ExportFilesInput | null;
  professionalImageSW?: ExportFilesInput | null;
  reactplannerModel?: S3ObjectInput | null;
  floorplannerModel?: S3ObjectInput | null;
  ifcModel?: S3ObjectInput | null;
  reimportedIfcModel?: S3ObjectInput | null;
  total_size?: number | null;
  amount_doors?: number | null;
  amount_rooms?: number | null;
  amount_windows?: number | null;
  total_room_size_m2?: number | null;
  total_room_size_px?: number | null;
  user_total_size?: number | null;
  user_scaling_selected_px?: number | null;
  user_scaling_cm?: number | null;
  api_scaling_factor_cm_per_px?: number | null;
  status?: FloorplanStatus | null;
  customerId?: string | null;
  floorplannerId?: string | null;
  acceptedAt?: string | null;
  finishedAt?: string | null;
};

export type S3ObjectInput = {
  bucket: string;
  region: string;
  key: string;
  status?: string | null;
};

export type ExportFilesInput = {
  png?: S3ObjectInput | null;
  jpeg?: S3ObjectInput | null;
  pdf?: S3ObjectInput | null;
};

export type ModelFloorplanConditionInput = {
  type?: ModelStringInput | null;
  projectId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  name?: ModelStringInput | null;
  address?: ModelStringInput | null;
  description?: ModelStringInput | null;
  originalImageName?: ModelStringInput | null;
  originalInputType?: ModelOriginalInputTypeInput | null;
  shareable?: ModelBooleanInput | null;
  total_size?: ModelFloatInput | null;
  amount_doors?: ModelIntInput | null;
  amount_rooms?: ModelIntInput | null;
  amount_windows?: ModelIntInput | null;
  total_room_size_m2?: ModelFloatInput | null;
  total_room_size_px?: ModelFloatInput | null;
  user_total_size?: ModelFloatInput | null;
  user_scaling_selected_px?: ModelIntInput | null;
  user_scaling_cm?: ModelIntInput | null;
  api_scaling_factor_cm_per_px?: ModelFloatInput | null;
  status?: ModelFloorplanStatusInput | null;
  customerId?: ModelIDInput | null;
  floorplannerId?: ModelIDInput | null;
  acceptedAt?: ModelStringInput | null;
  finishedAt?: ModelStringInput | null;
  and?: Array<ModelFloorplanConditionInput | null> | null;
  or?: Array<ModelFloorplanConditionInput | null> | null;
  not?: ModelFloorplanConditionInput | null;
};

export type UpdateFloorplanInput = {
  id: string;
  type?: string | null;
  owner?: string | null;
  projectId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  name?: string | null;
  address?: string | null;
  description?: string | null;
  originalImageName?: string | null;
  originalInputType?: OriginalInputType | null;
  shareable?: boolean | null;
  originalImage?: S3ObjectInput | null;
  croppedImage?: S3ObjectInput | null;
  preprocessedImage?: S3ObjectInput | null;
  visual2DImage?: S3ObjectInput | null;
  mlPredictionImage?: S3ObjectInput | null;
  ocrPredictionImage?: S3ObjectInput | null;
  postMlImage?: S3ObjectInput | null;
  internalModelImage?: S3ObjectInput | null;
  basicImage?: ExportFilesInput | null;
  basicPlusImage?: ExportFilesInput | null;
  professionalImageTop?: ExportFilesInput | null;
  professionalImageTilted?: ExportFilesInput | null;
  professionalImageNE?: ExportFilesInput | null;
  professionalImageNW?: ExportFilesInput | null;
  professionalImageSE?: ExportFilesInput | null;
  professionalImageSW?: ExportFilesInput | null;
  reactplannerModel?: S3ObjectInput | null;
  floorplannerModel?: S3ObjectInput | null;
  ifcModel?: S3ObjectInput | null;
  reimportedIfcModel?: S3ObjectInput | null;
  total_size?: number | null;
  amount_doors?: number | null;
  amount_rooms?: number | null;
  amount_windows?: number | null;
  total_room_size_m2?: number | null;
  total_room_size_px?: number | null;
  user_total_size?: number | null;
  user_scaling_selected_px?: number | null;
  user_scaling_cm?: number | null;
  api_scaling_factor_cm_per_px?: number | null;
  status?: FloorplanStatus | null;
  customerId?: string | null;
  floorplannerId?: string | null;
  acceptedAt?: string | null;
  finishedAt?: string | null;
};

export type DeleteFloorplanInput = {
  id?: string | null;
};

export type CreateRoomInput = {
  id?: string | null;
  floorplanId: string;
  name?: string | null;
  room_size_sqm?: number | null;
  room_type?: string | null;
};

export type ModelRoomConditionInput = {
  floorplanId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  room_size_sqm?: ModelFloatInput | null;
  room_type?: ModelStringInput | null;
  and?: Array<ModelRoomConditionInput | null> | null;
  or?: Array<ModelRoomConditionInput | null> | null;
  not?: ModelRoomConditionInput | null;
};

export type UpdateRoomInput = {
  id: string;
  floorplanId?: string | null;
  name?: string | null;
  room_size_sqm?: number | null;
  room_type?: string | null;
};

export type DeleteRoomInput = {
  id?: string | null;
};

export type CreateFloorplanOrderInput = {
  id?: string | null;
  floorplan_id: string;
  order_id: string;
  owner: string;
  customer?: string | null;
};

export type ModelFloorplanOrderConditionInput = {
  floorplan_id?: ModelIDInput | null;
  order_id?: ModelIDInput | null;
  and?: Array<ModelFloorplanOrderConditionInput | null> | null;
  or?: Array<ModelFloorplanOrderConditionInput | null> | null;
  not?: ModelFloorplanOrderConditionInput | null;
};

export type UpdateFloorplanOrderInput = {
  id: string;
  floorplan_id?: string | null;
  order_id?: string | null;
  owner?: string | null;
  customer?: string | null;
};

export type DeleteFloorplanOrderInput = {
  id?: string | null;
};

export type CreateOrderInput = {
  id?: string | null;
  user: string;
  customer: string;
  owner: string;
  amount_total?: number | null;
  level?: Level | null;
  stripe_checkout_session_id?: string | null;
  paymentStatus?: PaymentStatus | null;
  orderStatus?: OrderStatus | null;
};

export type ModelOrderConditionInput = {
  user?: ModelStringInput | null;
  amount_total?: ModelFloatInput | null;
  level?: ModelLevelInput | null;
  stripe_checkout_session_id?: ModelStringInput | null;
  paymentStatus?: ModelPaymentStatusInput | null;
  orderStatus?: ModelOrderStatusInput | null;
  and?: Array<ModelOrderConditionInput | null> | null;
  or?: Array<ModelOrderConditionInput | null> | null;
  not?: ModelOrderConditionInput | null;
};

export type ModelLevelInput = {
  eq?: Level | null;
  ne?: Level | null;
};

export type ModelPaymentStatusInput = {
  eq?: PaymentStatus | null;
  ne?: PaymentStatus | null;
};

export type ModelOrderStatusInput = {
  eq?: OrderStatus | null;
  ne?: OrderStatus | null;
};

export type UpdateOrderInput = {
  id: string;
  user?: string | null;
  customer?: string | null;
  owner?: string | null;
  amount_total?: number | null;
  level?: Level | null;
  stripe_checkout_session_id?: string | null;
  paymentStatus?: PaymentStatus | null;
  orderStatus?: OrderStatus | null;
};

export type DeleteOrderInput = {
  id?: string | null;
};

export type CreateCustomerInput = {
  id?: string | null;
  user: string;
  identityId?: string | null;
  email: string;
  stripe_customer_id?: string | null;
  phone_number?: string | null;
  company?: string | null;
  company_address?: string | null;
  company_address_line2?: string | null;
  company_city?: string | null;
  company_taxnumber?: string | null;
  country?: string | null;
  zip?: string | null;
  accepted_terms?: boolean | null;
  accepted_gdpr?: boolean | null;
};

export type ModelCustomerConditionInput = {
  identityId?: ModelStringInput | null;
  email?: ModelStringInput | null;
  stripe_customer_id?: ModelStringInput | null;
  phone_number?: ModelStringInput | null;
  company?: ModelStringInput | null;
  company_address?: ModelStringInput | null;
  company_address_line2?: ModelStringInput | null;
  company_city?: ModelStringInput | null;
  company_taxnumber?: ModelStringInput | null;
  country?: ModelStringInput | null;
  zip?: ModelStringInput | null;
  accepted_terms?: ModelBooleanInput | null;
  accepted_gdpr?: ModelBooleanInput | null;
  and?: Array<ModelCustomerConditionInput | null> | null;
  or?: Array<ModelCustomerConditionInput | null> | null;
  not?: ModelCustomerConditionInput | null;
};

export type UpdateCustomerInput = {
  id: string;
  user?: string | null;
  identityId?: string | null;
  email?: string | null;
  stripe_customer_id?: string | null;
  phone_number?: string | null;
  company?: string | null;
  company_address?: string | null;
  company_address_line2?: string | null;
  company_city?: string | null;
  company_taxnumber?: string | null;
  country?: string | null;
  zip?: string | null;
  accepted_terms?: boolean | null;
  accepted_gdpr?: boolean | null;
};

export type DeleteCustomerInput = {
  id?: string | null;
};

export type CreateSettingsInput = {
  id?: string | null;
  theme?: string | null;
  displayName?: string | null;
};

export type ModelSettingsConditionInput = {
  theme?: ModelStringInput | null;
  displayName?: ModelStringInput | null;
  and?: Array<ModelSettingsConditionInput | null> | null;
  or?: Array<ModelSettingsConditionInput | null> | null;
  not?: ModelSettingsConditionInput | null;
};

export type Settings = {
  __typename: "Settings";
  id?: string;
  theme?: string | null;
  displayName?: string | null;
  createdAt?: string;
  updatedAt?: string;
  owner?: string | null;
};

export type UpdateSettingsInput = {
  id: string;
  theme?: string | null;
  displayName?: string | null;
};

export type DeleteSettingsInput = {
  id?: string | null;
};

export type CreateChatMessageInput = {
  id?: string | null;
  floorplanId: string;
  message: string;
  sentByCustomer: boolean;
  read: boolean;
  createdAt?: string | null;
  owner?: string | null;
  images?: Array<S3ObjectInput | null> | null;
};

export type ModelChatMessageConditionInput = {
  floorplanId?: ModelIDInput | null;
  message?: ModelStringInput | null;
  sentByCustomer?: ModelBooleanInput | null;
  read?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelChatMessageConditionInput | null> | null;
  or?: Array<ModelChatMessageConditionInput | null> | null;
  not?: ModelChatMessageConditionInput | null;
};

export type UpdateChatMessageInput = {
  floorplanId?: string | null;
  message?: string | null;
  sentByCustomer?: boolean | null;
  read?: boolean | null;
  createdAt?: string | null;
  owner?: string | null;
  images?: Array<S3ObjectInput | null> | null;
};

export type DeleteChatMessageInput = {
  id?: string | null;
};

export enum OrderMailTemplateType {
  ORDER_NEW = "ORDER_NEW",
  ORDER_PLACED = "ORDER_PLACED",
  ORDER_CONFIRMATION = "ORDER_CONFIRMATION",
  ORDER_DECLINED = "ORDER_DECLINED",
  ORDER_QA_NOTIFY = "ORDER_QA_NOTIFY",
  ORDER_PROCESSED = "ORDER_PROCESSED",
  ORDER_CHANGE_REQUEST = "ORDER_CHANGE_REQUEST",
  ORDER_CHANGE_RESPONSE = "ORDER_CHANGE_RESPONSE",
}

export type RecognitionServiceInput = {
  floorplan_id?: string | null;
  image?: string | null;
  event_type: RecognitionServiceEventType;
};

export enum RecognitionServiceEventType {
  PREVIEW = "PREVIEW",
  RECOGNITION = "RECOGNITION",
  FLOORPLANNER_API_CALL = "FLOORPLANNER_API_CALL",
  FLOORPLANNER_REIMPORT = "FLOORPLANNER_REIMPORT",
}

export type GetPreSignedUrlsInput = {
  keys: Array<string | null>;
  type: string;
  expiresIn?: number | null;
};

export type GetPreSignedUrlsResult = {
  __typename: "GetPreSignedUrlsResult";
  urls?: Array<string | null> | null;
};

export type NewsletterSubscriptionInput = {
  email?: string | null;
};

export type ModelFloorplanProjectFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  address?: ModelStringInput | null;
  description?: ModelStringInput | null;
  size?: ModelIntInput | null;
  and?: Array<ModelFloorplanProjectFilterInput | null> | null;
  or?: Array<ModelFloorplanProjectFilterInput | null> | null;
  not?: ModelFloorplanProjectFilterInput | null;
};

export type ModelFloorplanProjectConnection = {
  __typename: "ModelFloorplanProjectConnection";
  items?: Array<FloorplanProject | null> | null;
  nextToken?: string | null;
};

export type ModelRoomFilterInput = {
  id?: ModelIDInput | null;
  floorplanId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  room_size_sqm?: ModelFloatInput | null;
  room_type?: ModelStringInput | null;
  and?: Array<ModelRoomFilterInput | null> | null;
  or?: Array<ModelRoomFilterInput | null> | null;
  not?: ModelRoomFilterInput | null;
};

export type ModelFloorplanOrderFilterInput = {
  id?: ModelIDInput | null;
  floorplan_id?: ModelIDInput | null;
  order_id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  customer?: ModelStringInput | null;
  and?: Array<ModelFloorplanOrderFilterInput | null> | null;
  or?: Array<ModelFloorplanOrderFilterInput | null> | null;
  not?: ModelFloorplanOrderFilterInput | null;
};

export type ModelOrderFilterInput = {
  id?: ModelIDInput | null;
  user?: ModelStringInput | null;
  customer?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  amount_total?: ModelFloatInput | null;
  level?: ModelLevelInput | null;
  stripe_checkout_session_id?: ModelStringInput | null;
  paymentStatus?: ModelPaymentStatusInput | null;
  orderStatus?: ModelOrderStatusInput | null;
  and?: Array<ModelOrderFilterInput | null> | null;
  or?: Array<ModelOrderFilterInput | null> | null;
  not?: ModelOrderFilterInput | null;
};

export type ModelOrderConnection = {
  __typename: "ModelOrderConnection";
  items?: Array<Order | null> | null;
  nextToken?: string | null;
};

export type ModelCustomerFilterInput = {
  id?: ModelIDInput | null;
  user?: ModelStringInput | null;
  identityId?: ModelStringInput | null;
  email?: ModelStringInput | null;
  stripe_customer_id?: ModelStringInput | null;
  phone_number?: ModelStringInput | null;
  company?: ModelStringInput | null;
  company_address?: ModelStringInput | null;
  company_address_line2?: ModelStringInput | null;
  company_city?: ModelStringInput | null;
  company_taxnumber?: ModelStringInput | null;
  country?: ModelStringInput | null;
  zip?: ModelStringInput | null;
  accepted_terms?: ModelBooleanInput | null;
  accepted_gdpr?: ModelBooleanInput | null;
  and?: Array<ModelCustomerFilterInput | null> | null;
  or?: Array<ModelCustomerFilterInput | null> | null;
  not?: ModelCustomerFilterInput | null;
};

export type ModelSettingsFilterInput = {
  id?: ModelIDInput | null;
  theme?: ModelStringInput | null;
  displayName?: ModelStringInput | null;
  and?: Array<ModelSettingsFilterInput | null> | null;
  or?: Array<ModelSettingsFilterInput | null> | null;
  not?: ModelSettingsFilterInput | null;
};

export type ModelSettingsConnection = {
  __typename: "ModelSettingsConnection";
  items?: Array<Settings | null> | null;
  nextToken?: string | null;
};

export type ModelChatMessageFilterInput = {
  floorplanId?: ModelIDInput | null;
  message?: ModelStringInput | null;
  sentByCustomer?: ModelBooleanInput | null;
  read?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelChatMessageFilterInput | null> | null;
  or?: Array<ModelChatMessageFilterInput | null> | null;
  not?: ModelChatMessageFilterInput | null;
};

export type Payment = {
  __typename: "Payment";
  id?: string;
  date?: string;
  stripePaymentIntent?: string;
  paymentStatus?: string;
  amount?: string;
  user?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type ModelPaymentFilterInput = {
  id?: ModelIDInput | null;
  date?: ModelStringInput | null;
  stripePaymentIntent?: ModelStringInput | null;
  paymentStatus?: ModelStringInput | null;
  amount?: ModelStringInput | null;
  user?: ModelStringInput | null;
  and?: Array<ModelPaymentFilterInput | null> | null;
  or?: Array<ModelPaymentFilterInput | null> | null;
  not?: ModelPaymentFilterInput | null;
};

export type ModelPaymentConnection = {
  __typename: "ModelPaymentConnection";
  items?: Array<Payment | null> | null;
  nextToken?: string | null;
};

export type GetFloorplanOrdersQueryVariables = {
  id?: string;
};

export type GetFloorplanOrdersQuery = {
  getFloorplan?: {
    __typename: "Floorplan";
    id: string;
    name: string;
    address?: string | null;
    description?: string | null;
    rooms?: {
      __typename: "ModelRoomConnection";
      items?: Array<{
        __typename: "Room";
        id: string;
        floorplanId: string;
        name?: string | null;
        room_size_sqm?: number | null;
        room_type?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelFloorplanOrderConnection";
      items?: Array<{
        __typename: "FloorplanOrder";
        id: string;
        floorplan_id: string;
        order_id: string;
        order?: {
          __typename: "Order";
          id: string;
          user: string;
          customer: string;
          amount_total?: number | null;
          level?: Level | null;
          stripe_checkout_session_id?: string | null;
          paymentStatus?: PaymentStatus | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    owner?: string | null;
  } | null;
};

export type FloorplansByDateBasicQueryVariables = {
  limit?: number | null;
  sortDirection?: ModelSortDirection | null;
};

export type FloorplansByDateBasicQuery = {
  floorplansByDate?: {
    __typename: "ModelFloorplanConnection";
    items?: Array<{
      __typename: "Floorplan";
      id: string;
      name: string;
      createdAt?: string | null;
      status?: FloorplanStatus | null;
      orders?: {
        __typename: "ModelFloorplanOrderConnection";
        items?: Array<{
          __typename: "FloorplanOrder";
          customer?: string | null;
          order?: {
            __typename: "Order";
            level?: Level | null;
            orderStatus?: OrderStatus | null;
            paymentStatus?: PaymentStatus | null;
            id: string;
          } | null;
          id: string;
        } | null> | null;
      } | null;
      customer?: {
        __typename: "Customer";
        email: string;
        id: string;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type FloorplansByFloorplanStatusAfterDateQueryVariables = {
  status?: FloorplanStatus | null;
  createdAfter?: string | null;
  sortDirection?: ModelSortDirection | null;
};

export type FloorplansByFloorplanStatusAfterDateQuery = {
  floorplansByDate?: {
    __typename: "ModelFloorplanConnection";
    items?: Array<{
      __typename: "Floorplan";
      id: string;
      name: string;
      createdAt?: string | null;
      acceptedAt?: string | null;
      finishedAt?: string | null;
      status?: FloorplanStatus | null;
      orders?: {
        __typename: "ModelFloorplanOrderConnection";
        items?: Array<{
          __typename: "FloorplanOrder";
          customer?: string | null;
          order?: {
            __typename: "Order";
            level?: Level | null;
            orderStatus?: OrderStatus | null;
            id: string;
          } | null;
          id: string;
        } | null> | null;
      } | null;
      customer?: {
        __typename: "Customer";
        email: string;
        id: string;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type FloorplansAfterDateQueryVariables = {
  createdAfter?: string | null;
  sortDirection?: ModelSortDirection | null;
};

export type FloorplansAfterDateQuery = {
  floorplansByDate?: {
    __typename: "ModelFloorplanConnection";
    items?: Array<{
      __typename: "Floorplan";
      id: string;
      name: string;
      createdAt?: string | null;
      acceptedAt?: string | null;
      finishedAt?: string | null;
      status?: FloorplanStatus | null;
      orders?: {
        __typename: "ModelFloorplanOrderConnection";
        items?: Array<{
          __typename: "FloorplanOrder";
          customer?: string | null;
          order?: {
            __typename: "Order";
            level?: Level | null;
            orderStatus?: OrderStatus | null;
            id: string;
          } | null;
          id: string;
        } | null> | null;
      } | null;
      customer?: {
        __typename: "Customer";
        email: string;
        id: string;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type ListCustomersBasicQuery = {
  listCustomers?: {
    __typename: "ModelCustomerConnection";
    items?: Array<{
      __typename: "Customer";
      user: string;
      email: string;
      createdAt: string;
      id: string;
      identityId?: string | null;
      company_taxnumber?: string | null;
      country?: string | null;
      company_city?: string | null;
      company_address_line2?: string | null;
      company?: string | null;
      company_address?: string | null;
      accepted_terms?: boolean | null;
      accepted_gdpr?: boolean | null;
      phone_number?: string | null;
      stripe_customer_id?: string | null;
      updatedAt: string;
      zip?: string | null;
    } | null> | null;
  } | null;
};

export type FloorplansByOwnerBasicQueryVariables = {
  owner?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelFloorplanFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type FloorplansByOwnerBasicQuery = {
  floorplansByOwner?: {
    __typename: "ModelFloorplanConnection";
    items?: Array<{
      __typename: "Floorplan";
      id: string;
      owner?: string | null;
      projectId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      name: string;
      address?: string | null;
      description?: string | null;
      originalImageName?: string | null;
      originalImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      croppedImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      total_size?: number | null;
      amount_doors?: number | null;
      amount_rooms?: number | null;
      amount_windows?: number | null;
      total_room_size_m2?: number | null;
      total_room_size_px?: number | null;
      user_total_size?: number | null;
      user_scaling_selected_px?: number | null;
      user_scaling_cm?: number | null;
      status?: FloorplanStatus | null;
      rooms?: {
        __typename: "ModelRoomConnection";
        items?: Array<{
          __typename: "Room";
          id: string;
          floorplanId: string;
          name?: string | null;
          room_size_sqm?: number | null;
          room_type?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelFloorplanOrderConnection";
        items?: Array<{
          __typename: "FloorplanOrder";
          id: string;
          floorplan_id: string;
          order_id: string;
          customer?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      customerId?: string | null;
      customer?: {
        __typename: "Customer";
        id: string;
        user: string;
        identityId?: string | null;
        email: string;
        stripe_customer_id?: string | null;
        phone_number?: string | null;
        company?: string | null;
        company_address?: string | null;
        company_address_line2?: string | null;
        company_city?: string | null;
        company_taxnumber?: string | null;
        country?: string | null;
        zip?: string | null;
        accepted_terms?: boolean | null;
        accepted_gdpr?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type CreateFloorplanProjectMutationVariables = {
  input?: CreateFloorplanProjectInput;
  condition?: ModelFloorplanProjectConditionInput | null;
};

export type CreateFloorplanProjectMutation = {
  createFloorplanProject?: {
    __typename: "FloorplanProject";
    id: string;
    name: string;
    address?: string | null;
    description?: string | null;
    size?: number | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type UpdateFloorplanProjectMutationVariables = {
  input?: UpdateFloorplanProjectInput;
  condition?: ModelFloorplanProjectConditionInput | null;
};

export type UpdateFloorplanProjectMutation = {
  updateFloorplanProject?: {
    __typename: "FloorplanProject";
    id: string;
    name: string;
    address?: string | null;
    description?: string | null;
    size?: number | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type DeleteFloorplanProjectMutationVariables = {
  input?: DeleteFloorplanProjectInput;
  condition?: ModelFloorplanProjectConditionInput | null;
};

export type DeleteFloorplanProjectMutation = {
  deleteFloorplanProject?: {
    __typename: "FloorplanProject";
    id: string;
    name: string;
    address?: string | null;
    description?: string | null;
    size?: number | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type CreateFloorplanMutationVariables = {
  input?: CreateFloorplanInput;
  condition?: ModelFloorplanConditionInput | null;
};

export type CreateFloorplanMutation = {
  createFloorplan?: {
    __typename: "Floorplan";
    id: string;
    type?: string | null;
    owner?: string | null;
    projectId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    name: string;
    address?: string | null;
    description?: string | null;
    originalImageName?: string | null;
    originalInputType?: OriginalInputType | null;
    shareable?: boolean | null;
    originalImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    croppedImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    preprocessedImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    visual2DImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    mlPredictionImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    ocrPredictionImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    postMlImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    internalModelImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    basicImage?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    basicPlusImage?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageTop?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageTilted?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageNE?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageNW?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageSE?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageSW?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    reactplannerModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    floorplannerModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    ifcModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    reimportedIfcModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    total_size?: number | null;
    amount_doors?: number | null;
    amount_rooms?: number | null;
    amount_windows?: number | null;
    total_room_size_m2?: number | null;
    total_room_size_px?: number | null;
    user_total_size?: number | null;
    user_scaling_selected_px?: number | null;
    user_scaling_cm?: number | null;
    api_scaling_factor_cm_per_px?: number | null;
    status?: FloorplanStatus | null;
    customerId?: string | null;
    floorplannerId?: string | null;
    acceptedAt?: string | null;
    finishedAt?: string | null;
    rooms?: {
      __typename: "ModelRoomConnection";
      items?: Array<{
        __typename: "Room";
        id: string;
        floorplanId: string;
        name?: string | null;
        room_size_sqm?: number | null;
        room_type?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelFloorplanOrderConnection";
      items?: Array<{
        __typename: "FloorplanOrder";
        id: string;
        floorplan_id: string;
        order_id: string;
        owner: string;
        customer?: string | null;
        createdAt: string;
        updatedAt: string;
        floorplan?: {
          __typename: "Floorplan";
          id: string;
          type?: string | null;
          owner?: string | null;
          projectId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          name: string;
          address?: string | null;
          description?: string | null;
          originalImageName?: string | null;
          originalInputType?: OriginalInputType | null;
          shareable?: boolean | null;
          total_size?: number | null;
          amount_doors?: number | null;
          amount_rooms?: number | null;
          amount_windows?: number | null;
          total_room_size_m2?: number | null;
          total_room_size_px?: number | null;
          user_total_size?: number | null;
          user_scaling_selected_px?: number | null;
          user_scaling_cm?: number | null;
          api_scaling_factor_cm_per_px?: number | null;
          status?: FloorplanStatus | null;
          customerId?: string | null;
          floorplannerId?: string | null;
          acceptedAt?: string | null;
          finishedAt?: string | null;
        } | null;
        order?: {
          __typename: "Order";
          id: string;
          user: string;
          customer: string;
          owner: string;
          amount_total?: number | null;
          level?: Level | null;
          stripe_checkout_session_id?: string | null;
          paymentStatus?: PaymentStatus | null;
          orderStatus?: OrderStatus | null;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    customer?: {
      __typename: "Customer";
      id: string;
      user: string;
      identityId?: string | null;
      email: string;
      stripe_customer_id?: string | null;
      phone_number?: string | null;
      company?: string | null;
      company_address?: string | null;
      company_address_line2?: string | null;
      company_city?: string | null;
      company_taxnumber?: string | null;
      country?: string | null;
      zip?: string | null;
      accepted_terms?: boolean | null;
      accepted_gdpr?: boolean | null;
      createdAt: string;
      updatedAt: string;
      floorplans?: {
        __typename: "ModelFloorplanConnection";
        items?: Array<{
          __typename: "Floorplan";
          id: string;
          type?: string | null;
          owner?: string | null;
          projectId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          name: string;
          address?: string | null;
          description?: string | null;
          originalImageName?: string | null;
          originalInputType?: OriginalInputType | null;
          shareable?: boolean | null;
          total_size?: number | null;
          amount_doors?: number | null;
          amount_rooms?: number | null;
          amount_windows?: number | null;
          total_room_size_m2?: number | null;
          total_room_size_px?: number | null;
          user_total_size?: number | null;
          user_scaling_selected_px?: number | null;
          user_scaling_cm?: number | null;
          api_scaling_factor_cm_per_px?: number | null;
          status?: FloorplanStatus | null;
          customerId?: string | null;
          floorplannerId?: string | null;
          acceptedAt?: string | null;
          finishedAt?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null;
    chatMessages?: {
      __typename: "ModelChatMessageConnection";
      items?: Array<{
        __typename: "ChatMessage";
        id: string;
        floorplanId: string;
        message: string;
        sentByCustomer: boolean;
        read: boolean;
        createdAt?: string | null;
        owner?: string | null;
        images?: Array<{
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null> | null;
        updatedAt: string;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateFloorplanMutationVariables = {
  input?: UpdateFloorplanInput;
  condition?: ModelFloorplanConditionInput | null;
};

export type UpdateFloorplanMutation = {
  updateFloorplan?: {
    __typename: "Floorplan";
    id: string;
    type?: string | null;
    owner?: string | null;
    projectId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    name: string;
    address?: string | null;
    description?: string | null;
    originalImageName?: string | null;
    originalInputType?: OriginalInputType | null;
    shareable?: boolean | null;
    originalImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    croppedImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    preprocessedImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    visual2DImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    mlPredictionImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    ocrPredictionImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    postMlImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    internalModelImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    basicImage?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    basicPlusImage?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageTop?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageTilted?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageNE?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageNW?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageSE?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageSW?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    reactplannerModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    floorplannerModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    ifcModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    reimportedIfcModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    total_size?: number | null;
    amount_doors?: number | null;
    amount_rooms?: number | null;
    amount_windows?: number | null;
    total_room_size_m2?: number | null;
    total_room_size_px?: number | null;
    user_total_size?: number | null;
    user_scaling_selected_px?: number | null;
    user_scaling_cm?: number | null;
    api_scaling_factor_cm_per_px?: number | null;
    status?: FloorplanStatus | null;
    customerId?: string | null;
    floorplannerId?: string | null;
    acceptedAt?: string | null;
    finishedAt?: string | null;
    rooms?: {
      __typename: "ModelRoomConnection";
      items?: Array<{
        __typename: "Room";
        id: string;
        floorplanId: string;
        name?: string | null;
        room_size_sqm?: number | null;
        room_type?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelFloorplanOrderConnection";
      items?: Array<{
        __typename: "FloorplanOrder";
        id: string;
        floorplan_id: string;
        order_id: string;
        owner: string;
        customer?: string | null;
        createdAt: string;
        updatedAt: string;
        floorplan?: {
          __typename: "Floorplan";
          id: string;
          type?: string | null;
          owner?: string | null;
          projectId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          name: string;
          address?: string | null;
          description?: string | null;
          originalImageName?: string | null;
          originalInputType?: OriginalInputType | null;
          shareable?: boolean | null;
          total_size?: number | null;
          amount_doors?: number | null;
          amount_rooms?: number | null;
          amount_windows?: number | null;
          total_room_size_m2?: number | null;
          total_room_size_px?: number | null;
          user_total_size?: number | null;
          user_scaling_selected_px?: number | null;
          user_scaling_cm?: number | null;
          api_scaling_factor_cm_per_px?: number | null;
          status?: FloorplanStatus | null;
          customerId?: string | null;
          floorplannerId?: string | null;
          acceptedAt?: string | null;
          finishedAt?: string | null;
        } | null;
        order?: {
          __typename: "Order";
          id: string;
          user: string;
          customer: string;
          owner: string;
          amount_total?: number | null;
          level?: Level | null;
          stripe_checkout_session_id?: string | null;
          paymentStatus?: PaymentStatus | null;
          orderStatus?: OrderStatus | null;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    customer?: {
      __typename: "Customer";
      id: string;
      user: string;
      identityId?: string | null;
      email: string;
      stripe_customer_id?: string | null;
      phone_number?: string | null;
      company?: string | null;
      company_address?: string | null;
      company_address_line2?: string | null;
      company_city?: string | null;
      company_taxnumber?: string | null;
      country?: string | null;
      zip?: string | null;
      accepted_terms?: boolean | null;
      accepted_gdpr?: boolean | null;
      createdAt: string;
      updatedAt: string;
      floorplans?: {
        __typename: "ModelFloorplanConnection";
        items?: Array<{
          __typename: "Floorplan";
          id: string;
          type?: string | null;
          owner?: string | null;
          projectId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          name: string;
          address?: string | null;
          description?: string | null;
          originalImageName?: string | null;
          originalInputType?: OriginalInputType | null;
          shareable?: boolean | null;
          total_size?: number | null;
          amount_doors?: number | null;
          amount_rooms?: number | null;
          amount_windows?: number | null;
          total_room_size_m2?: number | null;
          total_room_size_px?: number | null;
          user_total_size?: number | null;
          user_scaling_selected_px?: number | null;
          user_scaling_cm?: number | null;
          api_scaling_factor_cm_per_px?: number | null;
          status?: FloorplanStatus | null;
          customerId?: string | null;
          floorplannerId?: string | null;
          acceptedAt?: string | null;
          finishedAt?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null;
    chatMessages?: {
      __typename: "ModelChatMessageConnection";
      items?: Array<{
        __typename: "ChatMessage";
        id: string;
        floorplanId: string;
        message: string;
        sentByCustomer: boolean;
        read: boolean;
        createdAt?: string | null;
        owner?: string | null;
        images?: Array<{
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null> | null;
        updatedAt: string;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteFloorplanMutationVariables = {
  input?: DeleteFloorplanInput;
  condition?: ModelFloorplanConditionInput | null;
};

export type DeleteFloorplanMutation = {
  deleteFloorplan?: {
    __typename: "Floorplan";
    id: string;
    type?: string | null;
    owner?: string | null;
    projectId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    name: string;
    address?: string | null;
    description?: string | null;
    originalImageName?: string | null;
    originalInputType?: OriginalInputType | null;
    shareable?: boolean | null;
    originalImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    croppedImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    preprocessedImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    visual2DImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    mlPredictionImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    ocrPredictionImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    postMlImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    internalModelImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    basicImage?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    basicPlusImage?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageTop?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageTilted?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageNE?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageNW?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageSE?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageSW?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    reactplannerModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    floorplannerModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    ifcModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    reimportedIfcModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    total_size?: number | null;
    amount_doors?: number | null;
    amount_rooms?: number | null;
    amount_windows?: number | null;
    total_room_size_m2?: number | null;
    total_room_size_px?: number | null;
    user_total_size?: number | null;
    user_scaling_selected_px?: number | null;
    user_scaling_cm?: number | null;
    api_scaling_factor_cm_per_px?: number | null;
    status?: FloorplanStatus | null;
    customerId?: string | null;
    floorplannerId?: string | null;
    acceptedAt?: string | null;
    finishedAt?: string | null;
    rooms?: {
      __typename: "ModelRoomConnection";
      items?: Array<{
        __typename: "Room";
        id: string;
        floorplanId: string;
        name?: string | null;
        room_size_sqm?: number | null;
        room_type?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelFloorplanOrderConnection";
      items?: Array<{
        __typename: "FloorplanOrder";
        id: string;
        floorplan_id: string;
        order_id: string;
        owner: string;
        customer?: string | null;
        createdAt: string;
        updatedAt: string;
        floorplan?: {
          __typename: "Floorplan";
          id: string;
          type?: string | null;
          owner?: string | null;
          projectId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          name: string;
          address?: string | null;
          description?: string | null;
          originalImageName?: string | null;
          originalInputType?: OriginalInputType | null;
          shareable?: boolean | null;
          total_size?: number | null;
          amount_doors?: number | null;
          amount_rooms?: number | null;
          amount_windows?: number | null;
          total_room_size_m2?: number | null;
          total_room_size_px?: number | null;
          user_total_size?: number | null;
          user_scaling_selected_px?: number | null;
          user_scaling_cm?: number | null;
          api_scaling_factor_cm_per_px?: number | null;
          status?: FloorplanStatus | null;
          customerId?: string | null;
          floorplannerId?: string | null;
          acceptedAt?: string | null;
          finishedAt?: string | null;
        } | null;
        order?: {
          __typename: "Order";
          id: string;
          user: string;
          customer: string;
          owner: string;
          amount_total?: number | null;
          level?: Level | null;
          stripe_checkout_session_id?: string | null;
          paymentStatus?: PaymentStatus | null;
          orderStatus?: OrderStatus | null;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    customer?: {
      __typename: "Customer";
      id: string;
      user: string;
      identityId?: string | null;
      email: string;
      stripe_customer_id?: string | null;
      phone_number?: string | null;
      company?: string | null;
      company_address?: string | null;
      company_address_line2?: string | null;
      company_city?: string | null;
      company_taxnumber?: string | null;
      country?: string | null;
      zip?: string | null;
      accepted_terms?: boolean | null;
      accepted_gdpr?: boolean | null;
      createdAt: string;
      updatedAt: string;
      floorplans?: {
        __typename: "ModelFloorplanConnection";
        items?: Array<{
          __typename: "Floorplan";
          id: string;
          type?: string | null;
          owner?: string | null;
          projectId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          name: string;
          address?: string | null;
          description?: string | null;
          originalImageName?: string | null;
          originalInputType?: OriginalInputType | null;
          shareable?: boolean | null;
          total_size?: number | null;
          amount_doors?: number | null;
          amount_rooms?: number | null;
          amount_windows?: number | null;
          total_room_size_m2?: number | null;
          total_room_size_px?: number | null;
          user_total_size?: number | null;
          user_scaling_selected_px?: number | null;
          user_scaling_cm?: number | null;
          api_scaling_factor_cm_per_px?: number | null;
          status?: FloorplanStatus | null;
          customerId?: string | null;
          floorplannerId?: string | null;
          acceptedAt?: string | null;
          finishedAt?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null;
    chatMessages?: {
      __typename: "ModelChatMessageConnection";
      items?: Array<{
        __typename: "ChatMessage";
        id: string;
        floorplanId: string;
        message: string;
        sentByCustomer: boolean;
        read: boolean;
        createdAt?: string | null;
        owner?: string | null;
        images?: Array<{
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null> | null;
        updatedAt: string;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateRoomMutationVariables = {
  input?: CreateRoomInput;
  condition?: ModelRoomConditionInput | null;
};

export type CreateRoomMutation = {
  createRoom?: {
    __typename: "Room";
    id: string;
    floorplanId: string;
    name?: string | null;
    room_size_sqm?: number | null;
    room_type?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type UpdateRoomMutationVariables = {
  input?: UpdateRoomInput;
  condition?: ModelRoomConditionInput | null;
};

export type UpdateRoomMutation = {
  updateRoom?: {
    __typename: "Room";
    id: string;
    floorplanId: string;
    name?: string | null;
    room_size_sqm?: number | null;
    room_type?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type DeleteRoomMutationVariables = {
  input?: DeleteRoomInput;
  condition?: ModelRoomConditionInput | null;
};

export type DeleteRoomMutation = {
  deleteRoom?: {
    __typename: "Room";
    id: string;
    floorplanId: string;
    name?: string | null;
    room_size_sqm?: number | null;
    room_type?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type CreateFloorplanOrderMutationVariables = {
  input?: CreateFloorplanOrderInput;
  condition?: ModelFloorplanOrderConditionInput | null;
};

export type CreateFloorplanOrderMutation = {
  createFloorplanOrder?: {
    __typename: "FloorplanOrder";
    id: string;
    floorplan_id: string;
    order_id: string;
    owner: string;
    customer?: string | null;
    createdAt: string;
    updatedAt: string;
    floorplan?: {
      __typename: "Floorplan";
      id: string;
      type?: string | null;
      owner?: string | null;
      projectId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      name: string;
      address?: string | null;
      description?: string | null;
      originalImageName?: string | null;
      originalInputType?: OriginalInputType | null;
      shareable?: boolean | null;
      originalImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      croppedImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      preprocessedImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      visual2DImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      mlPredictionImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      ocrPredictionImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      postMlImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      internalModelImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      basicImage?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      basicPlusImage?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageTop?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageTilted?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageNE?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageNW?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageSE?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageSW?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      reactplannerModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      floorplannerModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      ifcModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      reimportedIfcModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      total_size?: number | null;
      amount_doors?: number | null;
      amount_rooms?: number | null;
      amount_windows?: number | null;
      total_room_size_m2?: number | null;
      total_room_size_px?: number | null;
      user_total_size?: number | null;
      user_scaling_selected_px?: number | null;
      user_scaling_cm?: number | null;
      api_scaling_factor_cm_per_px?: number | null;
      status?: FloorplanStatus | null;
      customerId?: string | null;
      floorplannerId?: string | null;
      acceptedAt?: string | null;
      finishedAt?: string | null;
      rooms?: {
        __typename: "ModelRoomConnection";
        items?: Array<{
          __typename: "Room";
          id: string;
          floorplanId: string;
          name?: string | null;
          room_size_sqm?: number | null;
          room_type?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelFloorplanOrderConnection";
        items?: Array<{
          __typename: "FloorplanOrder";
          id: string;
          floorplan_id: string;
          order_id: string;
          owner: string;
          customer?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      customer?: {
        __typename: "Customer";
        id: string;
        user: string;
        identityId?: string | null;
        email: string;
        stripe_customer_id?: string | null;
        phone_number?: string | null;
        company?: string | null;
        company_address?: string | null;
        company_address_line2?: string | null;
        company_city?: string | null;
        company_taxnumber?: string | null;
        country?: string | null;
        zip?: string | null;
        accepted_terms?: boolean | null;
        accepted_gdpr?: boolean | null;
        createdAt: string;
        updatedAt: string;
        floorplans?: {
          __typename: "ModelFloorplanConnection";
          nextToken?: string | null;
        } | null;
      } | null;
      chatMessages?: {
        __typename: "ModelChatMessageConnection";
        items?: Array<{
          __typename: "ChatMessage";
          id: string;
          floorplanId: string;
          message: string;
          sentByCustomer: boolean;
          read: boolean;
          createdAt?: string | null;
          owner?: string | null;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null;
    order?: {
      __typename: "Order";
      id: string;
      user: string;
      customer: string;
      owner: string;
      amount_total?: number | null;
      level?: Level | null;
      stripe_checkout_session_id?: string | null;
      paymentStatus?: PaymentStatus | null;
      orderStatus?: OrderStatus | null;
      createdAt: string;
      updatedAt: string;
      floorplans?: {
        __typename: "ModelFloorplanOrderConnection";
        items?: Array<{
          __typename: "FloorplanOrder";
          id: string;
          floorplan_id: string;
          order_id: string;
          owner: string;
          customer?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateFloorplanOrderMutationVariables = {
  input?: UpdateFloorplanOrderInput;
  condition?: ModelFloorplanOrderConditionInput | null;
};

export type UpdateFloorplanOrderMutation = {
  updateFloorplanOrder?: {
    __typename: "FloorplanOrder";
    id: string;
    floorplan_id: string;
    order_id: string;
    owner: string;
    customer?: string | null;
    createdAt: string;
    updatedAt: string;
    floorplan?: {
      __typename: "Floorplan";
      id: string;
      type?: string | null;
      owner?: string | null;
      projectId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      name: string;
      address?: string | null;
      description?: string | null;
      originalImageName?: string | null;
      originalInputType?: OriginalInputType | null;
      shareable?: boolean | null;
      originalImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      croppedImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      preprocessedImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      visual2DImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      mlPredictionImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      ocrPredictionImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      postMlImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      internalModelImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      basicImage?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      basicPlusImage?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageTop?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageTilted?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageNE?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageNW?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageSE?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageSW?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      reactplannerModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      floorplannerModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      ifcModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      reimportedIfcModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      total_size?: number | null;
      amount_doors?: number | null;
      amount_rooms?: number | null;
      amount_windows?: number | null;
      total_room_size_m2?: number | null;
      total_room_size_px?: number | null;
      user_total_size?: number | null;
      user_scaling_selected_px?: number | null;
      user_scaling_cm?: number | null;
      api_scaling_factor_cm_per_px?: number | null;
      status?: FloorplanStatus | null;
      customerId?: string | null;
      floorplannerId?: string | null;
      acceptedAt?: string | null;
      finishedAt?: string | null;
      rooms?: {
        __typename: "ModelRoomConnection";
        items?: Array<{
          __typename: "Room";
          id: string;
          floorplanId: string;
          name?: string | null;
          room_size_sqm?: number | null;
          room_type?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelFloorplanOrderConnection";
        items?: Array<{
          __typename: "FloorplanOrder";
          id: string;
          floorplan_id: string;
          order_id: string;
          owner: string;
          customer?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      customer?: {
        __typename: "Customer";
        id: string;
        user: string;
        identityId?: string | null;
        email: string;
        stripe_customer_id?: string | null;
        phone_number?: string | null;
        company?: string | null;
        company_address?: string | null;
        company_address_line2?: string | null;
        company_city?: string | null;
        company_taxnumber?: string | null;
        country?: string | null;
        zip?: string | null;
        accepted_terms?: boolean | null;
        accepted_gdpr?: boolean | null;
        createdAt: string;
        updatedAt: string;
        floorplans?: {
          __typename: "ModelFloorplanConnection";
          nextToken?: string | null;
        } | null;
      } | null;
      chatMessages?: {
        __typename: "ModelChatMessageConnection";
        items?: Array<{
          __typename: "ChatMessage";
          id: string;
          floorplanId: string;
          message: string;
          sentByCustomer: boolean;
          read: boolean;
          createdAt?: string | null;
          owner?: string | null;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null;
    order?: {
      __typename: "Order";
      id: string;
      user: string;
      customer: string;
      owner: string;
      amount_total?: number | null;
      level?: Level | null;
      stripe_checkout_session_id?: string | null;
      paymentStatus?: PaymentStatus | null;
      orderStatus?: OrderStatus | null;
      createdAt: string;
      updatedAt: string;
      floorplans?: {
        __typename: "ModelFloorplanOrderConnection";
        items?: Array<{
          __typename: "FloorplanOrder";
          id: string;
          floorplan_id: string;
          order_id: string;
          owner: string;
          customer?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteFloorplanOrderMutationVariables = {
  input?: DeleteFloorplanOrderInput;
  condition?: ModelFloorplanOrderConditionInput | null;
};

export type DeleteFloorplanOrderMutation = {
  deleteFloorplanOrder?: {
    __typename: "FloorplanOrder";
    id: string;
    floorplan_id: string;
    order_id: string;
    owner: string;
    customer?: string | null;
    createdAt: string;
    updatedAt: string;
    floorplan?: {
      __typename: "Floorplan";
      id: string;
      type?: string | null;
      owner?: string | null;
      projectId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      name: string;
      address?: string | null;
      description?: string | null;
      originalImageName?: string | null;
      originalInputType?: OriginalInputType | null;
      shareable?: boolean | null;
      originalImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      croppedImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      preprocessedImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      visual2DImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      mlPredictionImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      ocrPredictionImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      postMlImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      internalModelImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      basicImage?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      basicPlusImage?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageTop?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageTilted?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageNE?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageNW?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageSE?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageSW?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      reactplannerModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      floorplannerModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      ifcModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      reimportedIfcModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      total_size?: number | null;
      amount_doors?: number | null;
      amount_rooms?: number | null;
      amount_windows?: number | null;
      total_room_size_m2?: number | null;
      total_room_size_px?: number | null;
      user_total_size?: number | null;
      user_scaling_selected_px?: number | null;
      user_scaling_cm?: number | null;
      api_scaling_factor_cm_per_px?: number | null;
      status?: FloorplanStatus | null;
      customerId?: string | null;
      floorplannerId?: string | null;
      acceptedAt?: string | null;
      finishedAt?: string | null;
      rooms?: {
        __typename: "ModelRoomConnection";
        items?: Array<{
          __typename: "Room";
          id: string;
          floorplanId: string;
          name?: string | null;
          room_size_sqm?: number | null;
          room_type?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelFloorplanOrderConnection";
        items?: Array<{
          __typename: "FloorplanOrder";
          id: string;
          floorplan_id: string;
          order_id: string;
          owner: string;
          customer?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      customer?: {
        __typename: "Customer";
        id: string;
        user: string;
        identityId?: string | null;
        email: string;
        stripe_customer_id?: string | null;
        phone_number?: string | null;
        company?: string | null;
        company_address?: string | null;
        company_address_line2?: string | null;
        company_city?: string | null;
        company_taxnumber?: string | null;
        country?: string | null;
        zip?: string | null;
        accepted_terms?: boolean | null;
        accepted_gdpr?: boolean | null;
        createdAt: string;
        updatedAt: string;
        floorplans?: {
          __typename: "ModelFloorplanConnection";
          nextToken?: string | null;
        } | null;
      } | null;
      chatMessages?: {
        __typename: "ModelChatMessageConnection";
        items?: Array<{
          __typename: "ChatMessage";
          id: string;
          floorplanId: string;
          message: string;
          sentByCustomer: boolean;
          read: boolean;
          createdAt?: string | null;
          owner?: string | null;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null;
    order?: {
      __typename: "Order";
      id: string;
      user: string;
      customer: string;
      owner: string;
      amount_total?: number | null;
      level?: Level | null;
      stripe_checkout_session_id?: string | null;
      paymentStatus?: PaymentStatus | null;
      orderStatus?: OrderStatus | null;
      createdAt: string;
      updatedAt: string;
      floorplans?: {
        __typename: "ModelFloorplanOrderConnection";
        items?: Array<{
          __typename: "FloorplanOrder";
          id: string;
          floorplan_id: string;
          order_id: string;
          owner: string;
          customer?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateOrderMutationVariables = {
  input?: CreateOrderInput;
  condition?: ModelOrderConditionInput | null;
};

export type CreateOrderMutation = {
  createOrder?: {
    __typename: "Order";
    id: string;
    user: string;
    customer: string;
    owner: string;
    amount_total?: number | null;
    level?: Level | null;
    stripe_checkout_session_id?: string | null;
    paymentStatus?: PaymentStatus | null;
    orderStatus?: OrderStatus | null;
    createdAt: string;
    updatedAt: string;
    floorplans?: {
      __typename: "ModelFloorplanOrderConnection";
      items?: Array<{
        __typename: "FloorplanOrder";
        id: string;
        floorplan_id: string;
        order_id: string;
        owner: string;
        customer?: string | null;
        createdAt: string;
        updatedAt: string;
        floorplan?: {
          __typename: "Floorplan";
          id: string;
          type?: string | null;
          owner?: string | null;
          projectId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          name: string;
          address?: string | null;
          description?: string | null;
          originalImageName?: string | null;
          originalInputType?: OriginalInputType | null;
          shareable?: boolean | null;
          total_size?: number | null;
          amount_doors?: number | null;
          amount_rooms?: number | null;
          amount_windows?: number | null;
          total_room_size_m2?: number | null;
          total_room_size_px?: number | null;
          user_total_size?: number | null;
          user_scaling_selected_px?: number | null;
          user_scaling_cm?: number | null;
          api_scaling_factor_cm_per_px?: number | null;
          status?: FloorplanStatus | null;
          customerId?: string | null;
          floorplannerId?: string | null;
          acceptedAt?: string | null;
          finishedAt?: string | null;
        } | null;
        order?: {
          __typename: "Order";
          id: string;
          user: string;
          customer: string;
          owner: string;
          amount_total?: number | null;
          level?: Level | null;
          stripe_checkout_session_id?: string | null;
          paymentStatus?: PaymentStatus | null;
          orderStatus?: OrderStatus | null;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateOrderMutationVariables = {
  input?: UpdateOrderInput;
  condition?: ModelOrderConditionInput | null;
};

export type UpdateOrderMutation = {
  updateOrder?: {
    __typename: "Order";
    id: string;
    user: string;
    customer: string;
    owner: string;
    amount_total?: number | null;
    level?: Level | null;
    stripe_checkout_session_id?: string | null;
    paymentStatus?: PaymentStatus | null;
    orderStatus?: OrderStatus | null;
    createdAt: string;
    updatedAt: string;
    floorplans?: {
      __typename: "ModelFloorplanOrderConnection";
      items?: Array<{
        __typename: "FloorplanOrder";
        id: string;
        floorplan_id: string;
        order_id: string;
        owner: string;
        customer?: string | null;
        createdAt: string;
        updatedAt: string;
        floorplan?: {
          __typename: "Floorplan";
          id: string;
          type?: string | null;
          owner?: string | null;
          projectId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          name: string;
          address?: string | null;
          description?: string | null;
          originalImageName?: string | null;
          originalInputType?: OriginalInputType | null;
          shareable?: boolean | null;
          total_size?: number | null;
          amount_doors?: number | null;
          amount_rooms?: number | null;
          amount_windows?: number | null;
          total_room_size_m2?: number | null;
          total_room_size_px?: number | null;
          user_total_size?: number | null;
          user_scaling_selected_px?: number | null;
          user_scaling_cm?: number | null;
          api_scaling_factor_cm_per_px?: number | null;
          status?: FloorplanStatus | null;
          customerId?: string | null;
          floorplannerId?: string | null;
          acceptedAt?: string | null;
          finishedAt?: string | null;
        } | null;
        order?: {
          __typename: "Order";
          id: string;
          user: string;
          customer: string;
          owner: string;
          amount_total?: number | null;
          level?: Level | null;
          stripe_checkout_session_id?: string | null;
          paymentStatus?: PaymentStatus | null;
          orderStatus?: OrderStatus | null;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteOrderMutationVariables = {
  input?: DeleteOrderInput;
  condition?: ModelOrderConditionInput | null;
};

export type DeleteOrderMutation = {
  deleteOrder?: {
    __typename: "Order";
    id: string;
    user: string;
    customer: string;
    owner: string;
    amount_total?: number | null;
    level?: Level | null;
    stripe_checkout_session_id?: string | null;
    paymentStatus?: PaymentStatus | null;
    orderStatus?: OrderStatus | null;
    createdAt: string;
    updatedAt: string;
    floorplans?: {
      __typename: "ModelFloorplanOrderConnection";
      items?: Array<{
        __typename: "FloorplanOrder";
        id: string;
        floorplan_id: string;
        order_id: string;
        owner: string;
        customer?: string | null;
        createdAt: string;
        updatedAt: string;
        floorplan?: {
          __typename: "Floorplan";
          id: string;
          type?: string | null;
          owner?: string | null;
          projectId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          name: string;
          address?: string | null;
          description?: string | null;
          originalImageName?: string | null;
          originalInputType?: OriginalInputType | null;
          shareable?: boolean | null;
          total_size?: number | null;
          amount_doors?: number | null;
          amount_rooms?: number | null;
          amount_windows?: number | null;
          total_room_size_m2?: number | null;
          total_room_size_px?: number | null;
          user_total_size?: number | null;
          user_scaling_selected_px?: number | null;
          user_scaling_cm?: number | null;
          api_scaling_factor_cm_per_px?: number | null;
          status?: FloorplanStatus | null;
          customerId?: string | null;
          floorplannerId?: string | null;
          acceptedAt?: string | null;
          finishedAt?: string | null;
        } | null;
        order?: {
          __typename: "Order";
          id: string;
          user: string;
          customer: string;
          owner: string;
          amount_total?: number | null;
          level?: Level | null;
          stripe_checkout_session_id?: string | null;
          paymentStatus?: PaymentStatus | null;
          orderStatus?: OrderStatus | null;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateCustomerMutationVariables = {
  input?: CreateCustomerInput;
  condition?: ModelCustomerConditionInput | null;
};

export type CreateCustomerMutation = {
  createCustomer?: {
    __typename: "Customer";
    id: string;
    user: string;
    identityId?: string | null;
    email: string;
    stripe_customer_id?: string | null;
    phone_number?: string | null;
    company?: string | null;
    company_address?: string | null;
    company_address_line2?: string | null;
    company_city?: string | null;
    company_taxnumber?: string | null;
    country?: string | null;
    zip?: string | null;
    accepted_terms?: boolean | null;
    accepted_gdpr?: boolean | null;
    createdAt: string;
    updatedAt: string;
    floorplans?: {
      __typename: "ModelFloorplanConnection";
      items?: Array<{
        __typename: "Floorplan";
        id: string;
        type?: string | null;
        owner?: string | null;
        projectId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        name: string;
        address?: string | null;
        description?: string | null;
        originalImageName?: string | null;
        originalInputType?: OriginalInputType | null;
        shareable?: boolean | null;
        originalImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        croppedImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        preprocessedImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        visual2DImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        mlPredictionImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        ocrPredictionImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        postMlImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        internalModelImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        reactplannerModel?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        floorplannerModel?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        ifcModel?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        reimportedIfcModel?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        total_size?: number | null;
        amount_doors?: number | null;
        amount_rooms?: number | null;
        amount_windows?: number | null;
        total_room_size_m2?: number | null;
        total_room_size_px?: number | null;
        user_total_size?: number | null;
        user_scaling_selected_px?: number | null;
        user_scaling_cm?: number | null;
        api_scaling_factor_cm_per_px?: number | null;
        status?: FloorplanStatus | null;
        customerId?: string | null;
        floorplannerId?: string | null;
        acceptedAt?: string | null;
        finishedAt?: string | null;
        rooms?: {
          __typename: "ModelRoomConnection";
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelFloorplanOrderConnection";
          nextToken?: string | null;
        } | null;
        customer?: {
          __typename: "Customer";
          id: string;
          user: string;
          identityId?: string | null;
          email: string;
          stripe_customer_id?: string | null;
          phone_number?: string | null;
          company?: string | null;
          company_address?: string | null;
          company_address_line2?: string | null;
          company_city?: string | null;
          company_taxnumber?: string | null;
          country?: string | null;
          zip?: string | null;
          accepted_terms?: boolean | null;
          accepted_gdpr?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        chatMessages?: {
          __typename: "ModelChatMessageConnection";
          nextToken?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateCustomerMutationVariables = {
  input?: UpdateCustomerInput;
  condition?: ModelCustomerConditionInput | null;
};

export type UpdateCustomerMutation = {
  updateCustomer?: {
    __typename: "Customer";
    id: string;
    user: string;
    identityId?: string | null;
    email: string;
    stripe_customer_id?: string | null;
    phone_number?: string | null;
    company?: string | null;
    company_address?: string | null;
    company_address_line2?: string | null;
    company_city?: string | null;
    company_taxnumber?: string | null;
    country?: string | null;
    zip?: string | null;
    accepted_terms?: boolean | null;
    accepted_gdpr?: boolean | null;
    createdAt: string;
    updatedAt: string;
    floorplans?: {
      __typename: "ModelFloorplanConnection";
      items?: Array<{
        __typename: "Floorplan";
        id: string;
        type?: string | null;
        owner?: string | null;
        projectId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        name: string;
        address?: string | null;
        description?: string | null;
        originalImageName?: string | null;
        originalInputType?: OriginalInputType | null;
        shareable?: boolean | null;
        originalImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        croppedImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        preprocessedImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        visual2DImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        mlPredictionImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        ocrPredictionImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        postMlImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        internalModelImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        reactplannerModel?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        floorplannerModel?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        ifcModel?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        reimportedIfcModel?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        total_size?: number | null;
        amount_doors?: number | null;
        amount_rooms?: number | null;
        amount_windows?: number | null;
        total_room_size_m2?: number | null;
        total_room_size_px?: number | null;
        user_total_size?: number | null;
        user_scaling_selected_px?: number | null;
        user_scaling_cm?: number | null;
        api_scaling_factor_cm_per_px?: number | null;
        status?: FloorplanStatus | null;
        customerId?: string | null;
        floorplannerId?: string | null;
        acceptedAt?: string | null;
        finishedAt?: string | null;
        rooms?: {
          __typename: "ModelRoomConnection";
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelFloorplanOrderConnection";
          nextToken?: string | null;
        } | null;
        customer?: {
          __typename: "Customer";
          id: string;
          user: string;
          identityId?: string | null;
          email: string;
          stripe_customer_id?: string | null;
          phone_number?: string | null;
          company?: string | null;
          company_address?: string | null;
          company_address_line2?: string | null;
          company_city?: string | null;
          company_taxnumber?: string | null;
          country?: string | null;
          zip?: string | null;
          accepted_terms?: boolean | null;
          accepted_gdpr?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        chatMessages?: {
          __typename: "ModelChatMessageConnection";
          nextToken?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteCustomerMutationVariables = {
  input?: DeleteCustomerInput;
  condition?: ModelCustomerConditionInput | null;
};

export type DeleteCustomerMutation = {
  deleteCustomer?: {
    __typename: "Customer";
    id: string;
    user: string;
    identityId?: string | null;
    email: string;
    stripe_customer_id?: string | null;
    phone_number?: string | null;
    company?: string | null;
    company_address?: string | null;
    company_address_line2?: string | null;
    company_city?: string | null;
    company_taxnumber?: string | null;
    country?: string | null;
    zip?: string | null;
    accepted_terms?: boolean | null;
    accepted_gdpr?: boolean | null;
    createdAt: string;
    updatedAt: string;
    floorplans?: {
      __typename: "ModelFloorplanConnection";
      items?: Array<{
        __typename: "Floorplan";
        id: string;
        type?: string | null;
        owner?: string | null;
        projectId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        name: string;
        address?: string | null;
        description?: string | null;
        originalImageName?: string | null;
        originalInputType?: OriginalInputType | null;
        shareable?: boolean | null;
        originalImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        croppedImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        preprocessedImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        visual2DImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        mlPredictionImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        ocrPredictionImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        postMlImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        internalModelImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        reactplannerModel?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        floorplannerModel?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        ifcModel?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        reimportedIfcModel?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        total_size?: number | null;
        amount_doors?: number | null;
        amount_rooms?: number | null;
        amount_windows?: number | null;
        total_room_size_m2?: number | null;
        total_room_size_px?: number | null;
        user_total_size?: number | null;
        user_scaling_selected_px?: number | null;
        user_scaling_cm?: number | null;
        api_scaling_factor_cm_per_px?: number | null;
        status?: FloorplanStatus | null;
        customerId?: string | null;
        floorplannerId?: string | null;
        acceptedAt?: string | null;
        finishedAt?: string | null;
        rooms?: {
          __typename: "ModelRoomConnection";
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelFloorplanOrderConnection";
          nextToken?: string | null;
        } | null;
        customer?: {
          __typename: "Customer";
          id: string;
          user: string;
          identityId?: string | null;
          email: string;
          stripe_customer_id?: string | null;
          phone_number?: string | null;
          company?: string | null;
          company_address?: string | null;
          company_address_line2?: string | null;
          company_city?: string | null;
          company_taxnumber?: string | null;
          country?: string | null;
          zip?: string | null;
          accepted_terms?: boolean | null;
          accepted_gdpr?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        chatMessages?: {
          __typename: "ModelChatMessageConnection";
          nextToken?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateSettingsMutationVariables = {
  input?: CreateSettingsInput;
  condition?: ModelSettingsConditionInput | null;
};

export type CreateSettingsMutation = {
  createSettings?: {
    __typename: "Settings";
    id: string;
    theme?: string | null;
    displayName?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type UpdateSettingsMutationVariables = {
  input?: UpdateSettingsInput;
  condition?: ModelSettingsConditionInput | null;
};

export type UpdateSettingsMutation = {
  updateSettings?: {
    __typename: "Settings";
    id: string;
    theme?: string | null;
    displayName?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type DeleteSettingsMutationVariables = {
  input?: DeleteSettingsInput;
  condition?: ModelSettingsConditionInput | null;
};

export type DeleteSettingsMutation = {
  deleteSettings?: {
    __typename: "Settings";
    id: string;
    theme?: string | null;
    displayName?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type CreateChatMessageMutationVariables = {
  input?: CreateChatMessageInput;
  condition?: ModelChatMessageConditionInput | null;
};

export type CreateChatMessageMutation = {
  createChatMessage?: {
    __typename: "ChatMessage";
    id: string;
    floorplanId: string;
    message: string;
    sentByCustomer: boolean;
    read: boolean;
    createdAt?: string | null;
    owner?: string | null;
    images?: Array<{
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null> | null;
    updatedAt: string;
  } | null;
};

export type UpdateChatMessageMutationVariables = {
  input?: UpdateChatMessageInput;
  condition?: ModelChatMessageConditionInput | null;
};

export type UpdateChatMessageMutation = {
  updateChatMessage?: {
    __typename: "ChatMessage";
    id: string;
    floorplanId: string;
    message: string;
    sentByCustomer: boolean;
    read: boolean;
    createdAt?: string | null;
    owner?: string | null;
    images?: Array<{
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null> | null;
    updatedAt: string;
  } | null;
};

export type DeleteChatMessageMutationVariables = {
  input?: DeleteChatMessageInput;
  condition?: ModelChatMessageConditionInput | null;
};

export type DeleteChatMessageMutation = {
  deleteChatMessage?: {
    __typename: "ChatMessage";
    id: string;
    floorplanId: string;
    message: string;
    sentByCustomer: boolean;
    read: boolean;
    createdAt?: string | null;
    owner?: string | null;
    images?: Array<{
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null> | null;
    updatedAt: string;
  } | null;
};

export type SendOrderMailQueryVariables = {
  template?: OrderMailTemplateType | null;
  orderMailInput?: string | null;
};

export type SendOrderMailQuery = {
  sendOrderMail?: string | null;
};

export type DigitizeFloorplanQueryVariables = {
  input?: RecognitionServiceInput | null;
};

export type DigitizeFloorplanQuery = {
  digitizeFloorplan?: string | null;
};

export type GetPreSignedUrlsQueryVariables = {
  input?: GetPreSignedUrlsInput | null;
};

export type GetPreSignedUrlsQuery = {
  getPreSignedUrls?: {
    __typename: "GetPreSignedUrlsResult";
    urls?: Array<string | null> | null;
  } | null;
};

export type SignUpNewsletterQueryVariables = {
  input?: NewsletterSubscriptionInput | null;
};

export type SignUpNewsletterQuery = {
  signUpNewsletter?: string | null;
};

export type GetFloorplanProjectQueryVariables = {
  id?: string;
};

export type GetFloorplanProjectQuery = {
  getFloorplanProject?: {
    __typename: "FloorplanProject";
    id: string;
    name: string;
    address?: string | null;
    description?: string | null;
    size?: number | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type ListFloorplanProjectsQueryVariables = {
  filter?: ModelFloorplanProjectFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListFloorplanProjectsQuery = {
  listFloorplanProjects?: {
    __typename: "ModelFloorplanProjectConnection";
    items?: Array<{
      __typename: "FloorplanProject";
      id: string;
      name: string;
      address?: string | null;
      description?: string | null;
      size?: number | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetFloorplanQueryVariables = {
  id?: string;
};

export type GetFloorplanQuery = {
  getFloorplan?: {
    __typename: "Floorplan";
    id: string;
    type?: string | null;
    owner?: string | null;
    projectId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    name: string;
    address?: string | null;
    description?: string | null;
    originalImageName?: string | null;
    originalInputType?: OriginalInputType | null;
    shareable?: boolean | null;
    originalImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    croppedImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    preprocessedImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    visual2DImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    mlPredictionImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    ocrPredictionImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    postMlImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    internalModelImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    basicImage?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    basicPlusImage?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageTop?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageTilted?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageNE?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageNW?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageSE?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageSW?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    reactplannerModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    floorplannerModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    ifcModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    reimportedIfcModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    total_size?: number | null;
    amount_doors?: number | null;
    amount_rooms?: number | null;
    amount_windows?: number | null;
    total_room_size_m2?: number | null;
    total_room_size_px?: number | null;
    user_total_size?: number | null;
    user_scaling_selected_px?: number | null;
    user_scaling_cm?: number | null;
    api_scaling_factor_cm_per_px?: number | null;
    status?: FloorplanStatus | null;
    customerId?: string | null;
    floorplannerId?: string | null;
    acceptedAt?: string | null;
    finishedAt?: string | null;
    rooms?: {
      __typename: "ModelRoomConnection";
      items?: Array<{
        __typename: "Room";
        id: string;
        floorplanId: string;
        name?: string | null;
        room_size_sqm?: number | null;
        room_type?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelFloorplanOrderConnection";
      items?: Array<{
        __typename: "FloorplanOrder";
        id: string;
        floorplan_id: string;
        order_id: string;
        owner: string;
        customer?: string | null;
        createdAt: string;
        updatedAt: string;
        floorplan?: {
          __typename: "Floorplan";
          id: string;
          type?: string | null;
          owner?: string | null;
          projectId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          name: string;
          address?: string | null;
          description?: string | null;
          originalImageName?: string | null;
          originalInputType?: OriginalInputType | null;
          shareable?: boolean | null;
          total_size?: number | null;
          amount_doors?: number | null;
          amount_rooms?: number | null;
          amount_windows?: number | null;
          total_room_size_m2?: number | null;
          total_room_size_px?: number | null;
          user_total_size?: number | null;
          user_scaling_selected_px?: number | null;
          user_scaling_cm?: number | null;
          api_scaling_factor_cm_per_px?: number | null;
          status?: FloorplanStatus | null;
          customerId?: string | null;
          floorplannerId?: string | null;
          acceptedAt?: string | null;
          finishedAt?: string | null;
        } | null;
        order?: {
          __typename: "Order";
          id: string;
          user: string;
          customer: string;
          owner: string;
          amount_total?: number | null;
          level?: Level | null;
          stripe_checkout_session_id?: string | null;
          paymentStatus?: PaymentStatus | null;
          orderStatus?: OrderStatus | null;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    customer?: {
      __typename: "Customer";
      id: string;
      user: string;
      identityId?: string | null;
      email: string;
      stripe_customer_id?: string | null;
      phone_number?: string | null;
      company?: string | null;
      company_address?: string | null;
      company_address_line2?: string | null;
      company_city?: string | null;
      company_taxnumber?: string | null;
      country?: string | null;
      zip?: string | null;
      accepted_terms?: boolean | null;
      accepted_gdpr?: boolean | null;
      createdAt: string;
      updatedAt: string;
      floorplans?: {
        __typename: "ModelFloorplanConnection";
        items?: Array<{
          __typename: "Floorplan";
          id: string;
          type?: string | null;
          owner?: string | null;
          projectId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          name: string;
          address?: string | null;
          description?: string | null;
          originalImageName?: string | null;
          originalInputType?: OriginalInputType | null;
          shareable?: boolean | null;
          total_size?: number | null;
          amount_doors?: number | null;
          amount_rooms?: number | null;
          amount_windows?: number | null;
          total_room_size_m2?: number | null;
          total_room_size_px?: number | null;
          user_total_size?: number | null;
          user_scaling_selected_px?: number | null;
          user_scaling_cm?: number | null;
          api_scaling_factor_cm_per_px?: number | null;
          status?: FloorplanStatus | null;
          customerId?: string | null;
          floorplannerId?: string | null;
          acceptedAt?: string | null;
          finishedAt?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null;
    chatMessages?: {
      __typename: "ModelChatMessageConnection";
      items?: Array<{
        __typename: "ChatMessage";
        id: string;
        floorplanId: string;
        message: string;
        sentByCustomer: boolean;
        read: boolean;
        createdAt?: string | null;
        owner?: string | null;
        images?: Array<{
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null> | null;
        updatedAt: string;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type ListFloorplansQueryVariables = {
  filter?: ModelFloorplanFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListFloorplansQuery = {
  listFloorplans?: {
    __typename: "ModelFloorplanConnection";
    items?: Array<{
      __typename: "Floorplan";
      id: string;
      type?: string | null;
      owner?: string | null;
      projectId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      name: string;
      address?: string | null;
      description?: string | null;
      originalImageName?: string | null;
      originalInputType?: OriginalInputType | null;
      shareable?: boolean | null;
      originalImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      croppedImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      preprocessedImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      visual2DImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      mlPredictionImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      ocrPredictionImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      postMlImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      internalModelImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      basicImage?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      basicPlusImage?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageTop?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageTilted?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageNE?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageNW?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageSE?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageSW?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      reactplannerModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      floorplannerModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      ifcModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      reimportedIfcModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      total_size?: number | null;
      amount_doors?: number | null;
      amount_rooms?: number | null;
      amount_windows?: number | null;
      total_room_size_m2?: number | null;
      total_room_size_px?: number | null;
      user_total_size?: number | null;
      user_scaling_selected_px?: number | null;
      user_scaling_cm?: number | null;
      api_scaling_factor_cm_per_px?: number | null;
      status?: FloorplanStatus | null;
      customerId?: string | null;
      floorplannerId?: string | null;
      acceptedAt?: string | null;
      finishedAt?: string | null;
      rooms?: {
        __typename: "ModelRoomConnection";
        items?: Array<{
          __typename: "Room";
          id: string;
          floorplanId: string;
          name?: string | null;
          room_size_sqm?: number | null;
          room_type?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelFloorplanOrderConnection";
        items?: Array<{
          __typename: "FloorplanOrder";
          id: string;
          floorplan_id: string;
          order_id: string;
          owner: string;
          customer?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      customer?: {
        __typename: "Customer";
        id: string;
        user: string;
        identityId?: string | null;
        email: string;
        stripe_customer_id?: string | null;
        phone_number?: string | null;
        company?: string | null;
        company_address?: string | null;
        company_address_line2?: string | null;
        company_city?: string | null;
        company_taxnumber?: string | null;
        country?: string | null;
        zip?: string | null;
        accepted_terms?: boolean | null;
        accepted_gdpr?: boolean | null;
        createdAt: string;
        updatedAt: string;
        floorplans?: {
          __typename: "ModelFloorplanConnection";
          nextToken?: string | null;
        } | null;
      } | null;
      chatMessages?: {
        __typename: "ModelChatMessageConnection";
        items?: Array<{
          __typename: "ChatMessage";
          id: string;
          floorplanId: string;
          message: string;
          sentByCustomer: boolean;
          read: boolean;
          createdAt?: string | null;
          owner?: string | null;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type FloorplansByDateQueryVariables = {
  type?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelFloorplanFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type FloorplansByDateQuery = {
  floorplansByDate?: {
    __typename: "ModelFloorplanConnection";
    items?: Array<{
      __typename: "Floorplan";
      id: string;
      type?: string | null;
      owner?: string | null;
      projectId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      name: string;
      address?: string | null;
      description?: string | null;
      originalImageName?: string | null;
      originalInputType?: OriginalInputType | null;
      shareable?: boolean | null;
      originalImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      croppedImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      preprocessedImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      visual2DImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      mlPredictionImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      ocrPredictionImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      postMlImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      internalModelImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      basicImage?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      basicPlusImage?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageTop?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageTilted?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageNE?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageNW?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageSE?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageSW?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      reactplannerModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      floorplannerModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      ifcModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      reimportedIfcModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      total_size?: number | null;
      amount_doors?: number | null;
      amount_rooms?: number | null;
      amount_windows?: number | null;
      total_room_size_m2?: number | null;
      total_room_size_px?: number | null;
      user_total_size?: number | null;
      user_scaling_selected_px?: number | null;
      user_scaling_cm?: number | null;
      api_scaling_factor_cm_per_px?: number | null;
      status?: FloorplanStatus | null;
      customerId?: string | null;
      floorplannerId?: string | null;
      acceptedAt?: string | null;
      finishedAt?: string | null;
      rooms?: {
        __typename: "ModelRoomConnection";
        items?: Array<{
          __typename: "Room";
          id: string;
          floorplanId: string;
          name?: string | null;
          room_size_sqm?: number | null;
          room_type?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelFloorplanOrderConnection";
        items?: Array<{
          __typename: "FloorplanOrder";
          id: string;
          floorplan_id: string;
          order_id: string;
          owner: string;
          customer?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      customer?: {
        __typename: "Customer";
        id: string;
        user: string;
        identityId?: string | null;
        email: string;
        stripe_customer_id?: string | null;
        phone_number?: string | null;
        company?: string | null;
        company_address?: string | null;
        company_address_line2?: string | null;
        company_city?: string | null;
        company_taxnumber?: string | null;
        country?: string | null;
        zip?: string | null;
        accepted_terms?: boolean | null;
        accepted_gdpr?: boolean | null;
        createdAt: string;
        updatedAt: string;
        floorplans?: {
          __typename: "ModelFloorplanConnection";
          nextToken?: string | null;
        } | null;
      } | null;
      chatMessages?: {
        __typename: "ModelChatMessageConnection";
        items?: Array<{
          __typename: "ChatMessage";
          id: string;
          floorplanId: string;
          message: string;
          sentByCustomer: boolean;
          read: boolean;
          createdAt?: string | null;
          owner?: string | null;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type FloorplansByOwnerQueryVariables = {
  owner?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelFloorplanFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type FloorplansByOwnerQuery = {
  floorplansByOwner?: {
    __typename: "ModelFloorplanConnection";
    items?: Array<{
      __typename: "Floorplan";
      id: string;
      type?: string | null;
      owner?: string | null;
      projectId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      name: string;
      address?: string | null;
      description?: string | null;
      originalImageName?: string | null;
      originalInputType?: OriginalInputType | null;
      shareable?: boolean | null;
      originalImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      croppedImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      preprocessedImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      visual2DImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      mlPredictionImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      ocrPredictionImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      postMlImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      internalModelImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      basicImage?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      basicPlusImage?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageTop?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageTilted?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageNE?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageNW?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageSE?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageSW?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      reactplannerModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      floorplannerModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      ifcModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      reimportedIfcModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      total_size?: number | null;
      amount_doors?: number | null;
      amount_rooms?: number | null;
      amount_windows?: number | null;
      total_room_size_m2?: number | null;
      total_room_size_px?: number | null;
      user_total_size?: number | null;
      user_scaling_selected_px?: number | null;
      user_scaling_cm?: number | null;
      api_scaling_factor_cm_per_px?: number | null;
      status?: FloorplanStatus | null;
      customerId?: string | null;
      floorplannerId?: string | null;
      acceptedAt?: string | null;
      finishedAt?: string | null;
      rooms?: {
        __typename: "ModelRoomConnection";
        items?: Array<{
          __typename: "Room";
          id: string;
          floorplanId: string;
          name?: string | null;
          room_size_sqm?: number | null;
          room_type?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelFloorplanOrderConnection";
        items?: Array<{
          __typename: "FloorplanOrder";
          id: string;
          floorplan_id: string;
          order_id: string;
          owner: string;
          customer?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      customer?: {
        __typename: "Customer";
        id: string;
        user: string;
        identityId?: string | null;
        email: string;
        stripe_customer_id?: string | null;
        phone_number?: string | null;
        company?: string | null;
        company_address?: string | null;
        company_address_line2?: string | null;
        company_city?: string | null;
        company_taxnumber?: string | null;
        country?: string | null;
        zip?: string | null;
        accepted_terms?: boolean | null;
        accepted_gdpr?: boolean | null;
        createdAt: string;
        updatedAt: string;
        floorplans?: {
          __typename: "ModelFloorplanConnection";
          nextToken?: string | null;
        } | null;
      } | null;
      chatMessages?: {
        __typename: "ModelChatMessageConnection";
        items?: Array<{
          __typename: "ChatMessage";
          id: string;
          floorplanId: string;
          message: string;
          sentByCustomer: boolean;
          read: boolean;
          createdAt?: string | null;
          owner?: string | null;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type FloorplansByFloorplannerIdQueryVariables = {
  floorplannerId?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelFloorplanFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type FloorplansByFloorplannerIdQuery = {
  floorplansByFloorplannerId?: {
    __typename: "ModelFloorplanConnection";
    items?: Array<{
      __typename: "Floorplan";
      id: string;
      type?: string | null;
      owner?: string | null;
      projectId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      name: string;
      address?: string | null;
      description?: string | null;
      originalImageName?: string | null;
      originalInputType?: OriginalInputType | null;
      shareable?: boolean | null;
      originalImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      croppedImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      preprocessedImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      visual2DImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      mlPredictionImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      ocrPredictionImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      postMlImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      internalModelImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      basicImage?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      basicPlusImage?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageTop?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageTilted?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageNE?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageNW?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageSE?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageSW?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      reactplannerModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      floorplannerModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      ifcModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      reimportedIfcModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      total_size?: number | null;
      amount_doors?: number | null;
      amount_rooms?: number | null;
      amount_windows?: number | null;
      total_room_size_m2?: number | null;
      total_room_size_px?: number | null;
      user_total_size?: number | null;
      user_scaling_selected_px?: number | null;
      user_scaling_cm?: number | null;
      api_scaling_factor_cm_per_px?: number | null;
      status?: FloorplanStatus | null;
      customerId?: string | null;
      floorplannerId?: string | null;
      acceptedAt?: string | null;
      finishedAt?: string | null;
      rooms?: {
        __typename: "ModelRoomConnection";
        items?: Array<{
          __typename: "Room";
          id: string;
          floorplanId: string;
          name?: string | null;
          room_size_sqm?: number | null;
          room_type?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelFloorplanOrderConnection";
        items?: Array<{
          __typename: "FloorplanOrder";
          id: string;
          floorplan_id: string;
          order_id: string;
          owner: string;
          customer?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      customer?: {
        __typename: "Customer";
        id: string;
        user: string;
        identityId?: string | null;
        email: string;
        stripe_customer_id?: string | null;
        phone_number?: string | null;
        company?: string | null;
        company_address?: string | null;
        company_address_line2?: string | null;
        company_city?: string | null;
        company_taxnumber?: string | null;
        country?: string | null;
        zip?: string | null;
        accepted_terms?: boolean | null;
        accepted_gdpr?: boolean | null;
        createdAt: string;
        updatedAt: string;
        floorplans?: {
          __typename: "ModelFloorplanConnection";
          nextToken?: string | null;
        } | null;
      } | null;
      chatMessages?: {
        __typename: "ModelChatMessageConnection";
        items?: Array<{
          __typename: "ChatMessage";
          id: string;
          floorplanId: string;
          message: string;
          sentByCustomer: boolean;
          read: boolean;
          createdAt?: string | null;
          owner?: string | null;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetRoomQueryVariables = {
  id?: string;
};

export type GetRoomQuery = {
  getRoom?: {
    __typename: "Room";
    id: string;
    floorplanId: string;
    name?: string | null;
    room_size_sqm?: number | null;
    room_type?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type ListRoomsQueryVariables = {
  filter?: ModelRoomFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListRoomsQuery = {
  listRooms?: {
    __typename: "ModelRoomConnection";
    items?: Array<{
      __typename: "Room";
      id: string;
      floorplanId: string;
      name?: string | null;
      room_size_sqm?: number | null;
      room_type?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetFloorplanOrderQueryVariables = {
  id?: string;
};

export type GetFloorplanOrderQuery = {
  getFloorplanOrder?: {
    __typename: "FloorplanOrder";
    id: string;
    floorplan_id: string;
    order_id: string;
    owner: string;
    customer?: string | null;
    createdAt: string;
    updatedAt: string;
    floorplan?: {
      __typename: "Floorplan";
      id: string;
      type?: string | null;
      owner?: string | null;
      projectId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      name: string;
      address?: string | null;
      description?: string | null;
      originalImageName?: string | null;
      originalInputType?: OriginalInputType | null;
      shareable?: boolean | null;
      originalImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      croppedImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      preprocessedImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      visual2DImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      mlPredictionImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      ocrPredictionImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      postMlImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      internalModelImage?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      basicImage?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      basicPlusImage?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageTop?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageTilted?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageNE?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageNW?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageSE?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      professionalImageSW?: {
        __typename: "ExportFiles";
        png?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        jpeg?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        pdf?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
      } | null;
      reactplannerModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      floorplannerModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      ifcModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      reimportedIfcModel?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      total_size?: number | null;
      amount_doors?: number | null;
      amount_rooms?: number | null;
      amount_windows?: number | null;
      total_room_size_m2?: number | null;
      total_room_size_px?: number | null;
      user_total_size?: number | null;
      user_scaling_selected_px?: number | null;
      user_scaling_cm?: number | null;
      api_scaling_factor_cm_per_px?: number | null;
      status?: FloorplanStatus | null;
      customerId?: string | null;
      floorplannerId?: string | null;
      acceptedAt?: string | null;
      finishedAt?: string | null;
      rooms?: {
        __typename: "ModelRoomConnection";
        items?: Array<{
          __typename: "Room";
          id: string;
          floorplanId: string;
          name?: string | null;
          room_size_sqm?: number | null;
          room_type?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelFloorplanOrderConnection";
        items?: Array<{
          __typename: "FloorplanOrder";
          id: string;
          floorplan_id: string;
          order_id: string;
          owner: string;
          customer?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      customer?: {
        __typename: "Customer";
        id: string;
        user: string;
        identityId?: string | null;
        email: string;
        stripe_customer_id?: string | null;
        phone_number?: string | null;
        company?: string | null;
        company_address?: string | null;
        company_address_line2?: string | null;
        company_city?: string | null;
        company_taxnumber?: string | null;
        country?: string | null;
        zip?: string | null;
        accepted_terms?: boolean | null;
        accepted_gdpr?: boolean | null;
        createdAt: string;
        updatedAt: string;
        floorplans?: {
          __typename: "ModelFloorplanConnection";
          nextToken?: string | null;
        } | null;
      } | null;
      chatMessages?: {
        __typename: "ModelChatMessageConnection";
        items?: Array<{
          __typename: "ChatMessage";
          id: string;
          floorplanId: string;
          message: string;
          sentByCustomer: boolean;
          read: boolean;
          createdAt?: string | null;
          owner?: string | null;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null;
    order?: {
      __typename: "Order";
      id: string;
      user: string;
      customer: string;
      owner: string;
      amount_total?: number | null;
      level?: Level | null;
      stripe_checkout_session_id?: string | null;
      paymentStatus?: PaymentStatus | null;
      orderStatus?: OrderStatus | null;
      createdAt: string;
      updatedAt: string;
      floorplans?: {
        __typename: "ModelFloorplanOrderConnection";
        items?: Array<{
          __typename: "FloorplanOrder";
          id: string;
          floorplan_id: string;
          order_id: string;
          owner: string;
          customer?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListFloorplanOrdersQueryVariables = {
  filter?: ModelFloorplanOrderFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListFloorplanOrdersQuery = {
  listFloorplanOrders?: {
    __typename: "ModelFloorplanOrderConnection";
    items?: Array<{
      __typename: "FloorplanOrder";
      id: string;
      floorplan_id: string;
      order_id: string;
      owner: string;
      customer?: string | null;
      createdAt: string;
      updatedAt: string;
      floorplan?: {
        __typename: "Floorplan";
        id: string;
        type?: string | null;
        owner?: string | null;
        projectId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        name: string;
        address?: string | null;
        description?: string | null;
        originalImageName?: string | null;
        originalInputType?: OriginalInputType | null;
        shareable?: boolean | null;
        originalImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        croppedImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        preprocessedImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        visual2DImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        mlPredictionImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        ocrPredictionImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        postMlImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        internalModelImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        reactplannerModel?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        floorplannerModel?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        ifcModel?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        reimportedIfcModel?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        total_size?: number | null;
        amount_doors?: number | null;
        amount_rooms?: number | null;
        amount_windows?: number | null;
        total_room_size_m2?: number | null;
        total_room_size_px?: number | null;
        user_total_size?: number | null;
        user_scaling_selected_px?: number | null;
        user_scaling_cm?: number | null;
        api_scaling_factor_cm_per_px?: number | null;
        status?: FloorplanStatus | null;
        customerId?: string | null;
        floorplannerId?: string | null;
        acceptedAt?: string | null;
        finishedAt?: string | null;
        rooms?: {
          __typename: "ModelRoomConnection";
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelFloorplanOrderConnection";
          nextToken?: string | null;
        } | null;
        customer?: {
          __typename: "Customer";
          id: string;
          user: string;
          identityId?: string | null;
          email: string;
          stripe_customer_id?: string | null;
          phone_number?: string | null;
          company?: string | null;
          company_address?: string | null;
          company_address_line2?: string | null;
          company_city?: string | null;
          company_taxnumber?: string | null;
          country?: string | null;
          zip?: string | null;
          accepted_terms?: boolean | null;
          accepted_gdpr?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        chatMessages?: {
          __typename: "ModelChatMessageConnection";
          nextToken?: string | null;
        } | null;
      } | null;
      order?: {
        __typename: "Order";
        id: string;
        user: string;
        customer: string;
        owner: string;
        amount_total?: number | null;
        level?: Level | null;
        stripe_checkout_session_id?: string | null;
        paymentStatus?: PaymentStatus | null;
        orderStatus?: OrderStatus | null;
        createdAt: string;
        updatedAt: string;
        floorplans?: {
          __typename: "ModelFloorplanOrderConnection";
          nextToken?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type ListOrdersQueryVariables = {
  filter?: ModelOrderFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListOrdersQuery = {
  listOrders?: {
    __typename: "ModelOrderConnection";
    items?: Array<{
      __typename: "Order";
      id: string;
      user: string;
      customer: string;
      owner: string;
      amount_total?: number | null;
      level?: Level | null;
      stripe_checkout_session_id?: string | null;
      paymentStatus?: PaymentStatus | null;
      orderStatus?: OrderStatus | null;
      createdAt: string;
      updatedAt: string;
      floorplans?: {
        __typename: "ModelFloorplanOrderConnection";
        items?: Array<{
          __typename: "FloorplanOrder";
          id: string;
          floorplan_id: string;
          order_id: string;
          owner: string;
          customer?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetOrderQueryVariables = {
  id?: string;
};

export type GetOrderQuery = {
  getOrder?: {
    __typename: "Order";
    id: string;
    user: string;
    customer: string;
    owner: string;
    amount_total?: number | null;
    level?: Level | null;
    stripe_checkout_session_id?: string | null;
    paymentStatus?: PaymentStatus | null;
    orderStatus?: OrderStatus | null;
    createdAt: string;
    updatedAt: string;
    floorplans?: {
      __typename: "ModelFloorplanOrderConnection";
      items?: Array<{
        __typename: "FloorplanOrder";
        id: string;
        floorplan_id: string;
        order_id: string;
        owner: string;
        customer?: string | null;
        createdAt: string;
        updatedAt: string;
        floorplan?: {
          __typename: "Floorplan";
          id: string;
          type?: string | null;
          owner?: string | null;
          projectId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          name: string;
          address?: string | null;
          description?: string | null;
          originalImageName?: string | null;
          originalInputType?: OriginalInputType | null;
          shareable?: boolean | null;
          total_size?: number | null;
          amount_doors?: number | null;
          amount_rooms?: number | null;
          amount_windows?: number | null;
          total_room_size_m2?: number | null;
          total_room_size_px?: number | null;
          user_total_size?: number | null;
          user_scaling_selected_px?: number | null;
          user_scaling_cm?: number | null;
          api_scaling_factor_cm_per_px?: number | null;
          status?: FloorplanStatus | null;
          customerId?: string | null;
          floorplannerId?: string | null;
          acceptedAt?: string | null;
          finishedAt?: string | null;
        } | null;
        order?: {
          __typename: "Order";
          id: string;
          user: string;
          customer: string;
          owner: string;
          amount_total?: number | null;
          level?: Level | null;
          stripe_checkout_session_id?: string | null;
          paymentStatus?: PaymentStatus | null;
          orderStatus?: OrderStatus | null;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type GetOrderByCheckoutSessionQueryVariables = {
  stripe_checkout_session_id?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelOrderFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetOrderByCheckoutSessionQuery = {
  getOrderByCheckoutSession?: {
    __typename: "ModelOrderConnection";
    items?: Array<{
      __typename: "Order";
      id: string;
      user: string;
      customer: string;
      owner: string;
      amount_total?: number | null;
      level?: Level | null;
      stripe_checkout_session_id?: string | null;
      paymentStatus?: PaymentStatus | null;
      orderStatus?: OrderStatus | null;
      createdAt: string;
      updatedAt: string;
      floorplans?: {
        __typename: "ModelFloorplanOrderConnection";
        items?: Array<{
          __typename: "FloorplanOrder";
          id: string;
          floorplan_id: string;
          order_id: string;
          owner: string;
          customer?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type ListCustomersQueryVariables = {
  filter?: ModelCustomerFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListCustomersQuery = {
  listCustomers?: {
    __typename: "ModelCustomerConnection";
    items?: Array<{
      __typename: "Customer";
      id: string;
      user: string;
      identityId?: string | null;
      email: string;
      stripe_customer_id?: string | null;
      phone_number?: string | null;
      company?: string | null;
      company_address?: string | null;
      company_address_line2?: string | null;
      company_city?: string | null;
      company_taxnumber?: string | null;
      country?: string | null;
      zip?: string | null;
      accepted_terms?: boolean | null;
      accepted_gdpr?: boolean | null;
      createdAt: string;
      updatedAt: string;
      floorplans?: {
        __typename: "ModelFloorplanConnection";
        items?: Array<{
          __typename: "Floorplan";
          id: string;
          type?: string | null;
          owner?: string | null;
          projectId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          name: string;
          address?: string | null;
          description?: string | null;
          originalImageName?: string | null;
          originalInputType?: OriginalInputType | null;
          shareable?: boolean | null;
          total_size?: number | null;
          amount_doors?: number | null;
          amount_rooms?: number | null;
          amount_windows?: number | null;
          total_room_size_m2?: number | null;
          total_room_size_px?: number | null;
          user_total_size?: number | null;
          user_scaling_selected_px?: number | null;
          user_scaling_cm?: number | null;
          api_scaling_factor_cm_per_px?: number | null;
          status?: FloorplanStatus | null;
          customerId?: string | null;
          floorplannerId?: string | null;
          acceptedAt?: string | null;
          finishedAt?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetCustomerQueryVariables = {
  id?: string;
};

export type GetCustomerQuery = {
  getCustomer?: {
    __typename: "Customer";
    id: string;
    user: string;
    identityId?: string | null;
    email: string;
    stripe_customer_id?: string | null;
    phone_number?: string | null;
    company?: string | null;
    company_address?: string | null;
    company_address_line2?: string | null;
    company_city?: string | null;
    company_taxnumber?: string | null;
    country?: string | null;
    zip?: string | null;
    accepted_terms?: boolean | null;
    accepted_gdpr?: boolean | null;
    createdAt: string;
    updatedAt: string;
    floorplans?: {
      __typename: "ModelFloorplanConnection";
      items?: Array<{
        __typename: "Floorplan";
        id: string;
        type?: string | null;
        owner?: string | null;
        projectId?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        name: string;
        address?: string | null;
        description?: string | null;
        originalImageName?: string | null;
        originalInputType?: OriginalInputType | null;
        shareable?: boolean | null;
        originalImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        croppedImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        preprocessedImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        visual2DImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        mlPredictionImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        ocrPredictionImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        postMlImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        internalModelImage?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        reactplannerModel?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        floorplannerModel?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        ifcModel?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        reimportedIfcModel?: {
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null;
        total_size?: number | null;
        amount_doors?: number | null;
        amount_rooms?: number | null;
        amount_windows?: number | null;
        total_room_size_m2?: number | null;
        total_room_size_px?: number | null;
        user_total_size?: number | null;
        user_scaling_selected_px?: number | null;
        user_scaling_cm?: number | null;
        api_scaling_factor_cm_per_px?: number | null;
        status?: FloorplanStatus | null;
        customerId?: string | null;
        floorplannerId?: string | null;
        acceptedAt?: string | null;
        finishedAt?: string | null;
        rooms?: {
          __typename: "ModelRoomConnection";
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelFloorplanOrderConnection";
          nextToken?: string | null;
        } | null;
        customer?: {
          __typename: "Customer";
          id: string;
          user: string;
          identityId?: string | null;
          email: string;
          stripe_customer_id?: string | null;
          phone_number?: string | null;
          company?: string | null;
          company_address?: string | null;
          company_address_line2?: string | null;
          company_city?: string | null;
          company_taxnumber?: string | null;
          country?: string | null;
          zip?: string | null;
          accepted_terms?: boolean | null;
          accepted_gdpr?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        chatMessages?: {
          __typename: "ModelChatMessageConnection";
          nextToken?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type GetCustomerByUsernameQueryVariables = {
  user?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCustomerFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetCustomerByUsernameQuery = {
  getCustomerByUsername?: {
    __typename: "ModelCustomerConnection";
    items?: Array<{
      __typename: "Customer";
      id: string;
      user: string;
      identityId?: string | null;
      email: string;
      stripe_customer_id?: string | null;
      phone_number?: string | null;
      company?: string | null;
      company_address?: string | null;
      company_address_line2?: string | null;
      company_city?: string | null;
      company_taxnumber?: string | null;
      country?: string | null;
      zip?: string | null;
      accepted_terms?: boolean | null;
      accepted_gdpr?: boolean | null;
      createdAt: string;
      updatedAt: string;
      floorplans?: {
        __typename: "ModelFloorplanConnection";
        items?: Array<{
          __typename: "Floorplan";
          id: string;
          type?: string | null;
          owner?: string | null;
          projectId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          name: string;
          address?: string | null;
          description?: string | null;
          originalImageName?: string | null;
          originalInputType?: OriginalInputType | null;
          shareable?: boolean | null;
          total_size?: number | null;
          amount_doors?: number | null;
          amount_rooms?: number | null;
          amount_windows?: number | null;
          total_room_size_m2?: number | null;
          total_room_size_px?: number | null;
          user_total_size?: number | null;
          user_scaling_selected_px?: number | null;
          user_scaling_cm?: number | null;
          api_scaling_factor_cm_per_px?: number | null;
          status?: FloorplanStatus | null;
          customerId?: string | null;
          floorplannerId?: string | null;
          acceptedAt?: string | null;
          finishedAt?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetCustomerByEmailQueryVariables = {
  email?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelCustomerFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type GetCustomerByEmailQuery = {
  getCustomerByEmail?: {
    __typename: "ModelCustomerConnection";
    items?: Array<{
      __typename: "Customer";
      id: string;
      user: string;
      identityId?: string | null;
      email: string;
      stripe_customer_id?: string | null;
      phone_number?: string | null;
      company?: string | null;
      company_address?: string | null;
      company_address_line2?: string | null;
      company_city?: string | null;
      company_taxnumber?: string | null;
      country?: string | null;
      zip?: string | null;
      accepted_terms?: boolean | null;
      accepted_gdpr?: boolean | null;
      createdAt: string;
      updatedAt: string;
      floorplans?: {
        __typename: "ModelFloorplanConnection";
        items?: Array<{
          __typename: "Floorplan";
          id: string;
          type?: string | null;
          owner?: string | null;
          projectId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          name: string;
          address?: string | null;
          description?: string | null;
          originalImageName?: string | null;
          originalInputType?: OriginalInputType | null;
          shareable?: boolean | null;
          total_size?: number | null;
          amount_doors?: number | null;
          amount_rooms?: number | null;
          amount_windows?: number | null;
          total_room_size_m2?: number | null;
          total_room_size_px?: number | null;
          user_total_size?: number | null;
          user_scaling_selected_px?: number | null;
          user_scaling_cm?: number | null;
          api_scaling_factor_cm_per_px?: number | null;
          status?: FloorplanStatus | null;
          customerId?: string | null;
          floorplannerId?: string | null;
          acceptedAt?: string | null;
          finishedAt?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetSettingsQueryVariables = {
  id?: string;
};

export type GetSettingsQuery = {
  getSettings?: {
    __typename: "Settings";
    id: string;
    theme?: string | null;
    displayName?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type ListSettingssQueryVariables = {
  filter?: ModelSettingsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSettingssQuery = {
  listSettingss?: {
    __typename: "ModelSettingsConnection";
    items?: Array<{
      __typename: "Settings";
      id: string;
      theme?: string | null;
      displayName?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetChatMessageQueryVariables = {
  id?: string;
};

export type GetChatMessageQuery = {
  getChatMessage?: {
    __typename: "ChatMessage";
    id: string;
    floorplanId: string;
    message: string;
    sentByCustomer: boolean;
    read: boolean;
    createdAt?: string | null;
    owner?: string | null;
    images?: Array<{
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null> | null;
    updatedAt: string;
  } | null;
};

export type ListChatMessagesQueryVariables = {
  filter?: ModelChatMessageFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListChatMessagesQuery = {
  listChatMessages?: {
    __typename: "ModelChatMessageConnection";
    items?: Array<{
      __typename: "ChatMessage";
      id: string;
      floorplanId: string;
      message: string;
      sentByCustomer: boolean;
      read: boolean;
      createdAt?: string | null;
      owner?: string | null;
      images?: Array<{
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null> | null;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type ChatMessagesByFloorplanIdQueryVariables = {
  floorplanId?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelChatMessageFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ChatMessagesByFloorplanIdQuery = {
  chatMessagesByFloorplanId?: {
    __typename: "ModelChatMessageConnection";
    items?: Array<{
      __typename: "ChatMessage";
      id: string;
      floorplanId: string;
      message: string;
      sentByCustomer: boolean;
      read: boolean;
      createdAt?: string | null;
      owner?: string | null;
      images?: Array<{
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null> | null;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetPaymentQueryVariables = {
  id?: string;
};

export type GetPaymentQuery = {
  getPayment?: {
    __typename: "Payment";
    id: string;
    date: string;
    stripePaymentIntent: string;
    paymentStatus: string;
    amount: string;
    user: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListPaymentsQueryVariables = {
  filter?: ModelPaymentFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListPaymentsQuery = {
  listPayments?: {
    __typename: "ModelPaymentConnection";
    items?: Array<{
      __typename: "Payment";
      id: string;
      date: string;
      stripePaymentIntent: string;
      paymentStatus: string;
      amount: string;
      user: string;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type PreprocessImageLambdaQueryVariables = {
  image?: string | null;
};

export type PreprocessImageLambdaQuery = {
  preprocessImageLambda?: string | null;
};

export type CallRecognitionServiceQueryVariables = {
  input?: RecognitionServiceInput | null;
};

export type CallRecognitionServiceQuery = {
  callRecognitionService?: string | null;
};

export type OnChatMessageByFloorplanIdSubscriptionVariables = {
  floorplanId?: string;
};

export type OnChatMessageByFloorplanIdSubscription = {
  onChatMessageByFloorplanId?: {
    __typename: "ChatMessage";
    id: string;
    floorplanId: string;
    message: string;
    sentByCustomer: boolean;
    read: boolean;
    createdAt?: string | null;
    owner?: string | null;
    images?: Array<{
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null> | null;
    updatedAt: string;
  } | null;
};

export type OnCreateFloorplanProjectSubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateFloorplanProjectSubscription = {
  onCreateFloorplanProject?: {
    __typename: "FloorplanProject";
    id: string;
    name: string;
    address?: string | null;
    description?: string | null;
    size?: number | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnUpdateFloorplanProjectSubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateFloorplanProjectSubscription = {
  onUpdateFloorplanProject?: {
    __typename: "FloorplanProject";
    id: string;
    name: string;
    address?: string | null;
    description?: string | null;
    size?: number | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnDeleteFloorplanProjectSubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteFloorplanProjectSubscription = {
  onDeleteFloorplanProject?: {
    __typename: "FloorplanProject";
    id: string;
    name: string;
    address?: string | null;
    description?: string | null;
    size?: number | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnCreateFloorplanSubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateFloorplanSubscription = {
  onCreateFloorplan?: {
    __typename: "Floorplan";
    id: string;
    type?: string | null;
    owner?: string | null;
    projectId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    name: string;
    address?: string | null;
    description?: string | null;
    originalImageName?: string | null;
    originalInputType?: OriginalInputType | null;
    shareable?: boolean | null;
    originalImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    croppedImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    preprocessedImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    visual2DImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    mlPredictionImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    ocrPredictionImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    postMlImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    internalModelImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    basicImage?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    basicPlusImage?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageTop?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageTilted?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageNE?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageNW?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageSE?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageSW?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    reactplannerModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    floorplannerModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    ifcModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    reimportedIfcModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    total_size?: number | null;
    amount_doors?: number | null;
    amount_rooms?: number | null;
    amount_windows?: number | null;
    total_room_size_m2?: number | null;
    total_room_size_px?: number | null;
    user_total_size?: number | null;
    user_scaling_selected_px?: number | null;
    user_scaling_cm?: number | null;
    api_scaling_factor_cm_per_px?: number | null;
    status?: FloorplanStatus | null;
    customerId?: string | null;
    floorplannerId?: string | null;
    acceptedAt?: string | null;
    finishedAt?: string | null;
    rooms?: {
      __typename: "ModelRoomConnection";
      items?: Array<{
        __typename: "Room";
        id: string;
        floorplanId: string;
        name?: string | null;
        room_size_sqm?: number | null;
        room_type?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelFloorplanOrderConnection";
      items?: Array<{
        __typename: "FloorplanOrder";
        id: string;
        floorplan_id: string;
        order_id: string;
        owner: string;
        customer?: string | null;
        createdAt: string;
        updatedAt: string;
        floorplan?: {
          __typename: "Floorplan";
          id: string;
          type?: string | null;
          owner?: string | null;
          projectId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          name: string;
          address?: string | null;
          description?: string | null;
          originalImageName?: string | null;
          originalInputType?: OriginalInputType | null;
          shareable?: boolean | null;
          total_size?: number | null;
          amount_doors?: number | null;
          amount_rooms?: number | null;
          amount_windows?: number | null;
          total_room_size_m2?: number | null;
          total_room_size_px?: number | null;
          user_total_size?: number | null;
          user_scaling_selected_px?: number | null;
          user_scaling_cm?: number | null;
          api_scaling_factor_cm_per_px?: number | null;
          status?: FloorplanStatus | null;
          customerId?: string | null;
          floorplannerId?: string | null;
          acceptedAt?: string | null;
          finishedAt?: string | null;
        } | null;
        order?: {
          __typename: "Order";
          id: string;
          user: string;
          customer: string;
          owner: string;
          amount_total?: number | null;
          level?: Level | null;
          stripe_checkout_session_id?: string | null;
          paymentStatus?: PaymentStatus | null;
          orderStatus?: OrderStatus | null;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    customer?: {
      __typename: "Customer";
      id: string;
      user: string;
      identityId?: string | null;
      email: string;
      stripe_customer_id?: string | null;
      phone_number?: string | null;
      company?: string | null;
      company_address?: string | null;
      company_address_line2?: string | null;
      company_city?: string | null;
      company_taxnumber?: string | null;
      country?: string | null;
      zip?: string | null;
      accepted_terms?: boolean | null;
      accepted_gdpr?: boolean | null;
      createdAt: string;
      updatedAt: string;
      floorplans?: {
        __typename: "ModelFloorplanConnection";
        items?: Array<{
          __typename: "Floorplan";
          id: string;
          type?: string | null;
          owner?: string | null;
          projectId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          name: string;
          address?: string | null;
          description?: string | null;
          originalImageName?: string | null;
          originalInputType?: OriginalInputType | null;
          shareable?: boolean | null;
          total_size?: number | null;
          amount_doors?: number | null;
          amount_rooms?: number | null;
          amount_windows?: number | null;
          total_room_size_m2?: number | null;
          total_room_size_px?: number | null;
          user_total_size?: number | null;
          user_scaling_selected_px?: number | null;
          user_scaling_cm?: number | null;
          api_scaling_factor_cm_per_px?: number | null;
          status?: FloorplanStatus | null;
          customerId?: string | null;
          floorplannerId?: string | null;
          acceptedAt?: string | null;
          finishedAt?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null;
    chatMessages?: {
      __typename: "ModelChatMessageConnection";
      items?: Array<{
        __typename: "ChatMessage";
        id: string;
        floorplanId: string;
        message: string;
        sentByCustomer: boolean;
        read: boolean;
        createdAt?: string | null;
        owner?: string | null;
        images?: Array<{
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null> | null;
        updatedAt: string;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnUpdateFloorplanSubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateFloorplanSubscription = {
  onUpdateFloorplan?: {
    __typename: "Floorplan";
    id: string;
    type?: string | null;
    owner?: string | null;
    projectId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    name: string;
    address?: string | null;
    description?: string | null;
    originalImageName?: string | null;
    originalInputType?: OriginalInputType | null;
    shareable?: boolean | null;
    originalImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    croppedImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    preprocessedImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    visual2DImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    mlPredictionImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    ocrPredictionImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    postMlImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    internalModelImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    basicImage?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    basicPlusImage?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageTop?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageTilted?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageNE?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageNW?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageSE?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageSW?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    reactplannerModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    floorplannerModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    ifcModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    reimportedIfcModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    total_size?: number | null;
    amount_doors?: number | null;
    amount_rooms?: number | null;
    amount_windows?: number | null;
    total_room_size_m2?: number | null;
    total_room_size_px?: number | null;
    user_total_size?: number | null;
    user_scaling_selected_px?: number | null;
    user_scaling_cm?: number | null;
    api_scaling_factor_cm_per_px?: number | null;
    status?: FloorplanStatus | null;
    customerId?: string | null;
    floorplannerId?: string | null;
    acceptedAt?: string | null;
    finishedAt?: string | null;
    rooms?: {
      __typename: "ModelRoomConnection";
      items?: Array<{
        __typename: "Room";
        id: string;
        floorplanId: string;
        name?: string | null;
        room_size_sqm?: number | null;
        room_type?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelFloorplanOrderConnection";
      items?: Array<{
        __typename: "FloorplanOrder";
        id: string;
        floorplan_id: string;
        order_id: string;
        owner: string;
        customer?: string | null;
        createdAt: string;
        updatedAt: string;
        floorplan?: {
          __typename: "Floorplan";
          id: string;
          type?: string | null;
          owner?: string | null;
          projectId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          name: string;
          address?: string | null;
          description?: string | null;
          originalImageName?: string | null;
          originalInputType?: OriginalInputType | null;
          shareable?: boolean | null;
          total_size?: number | null;
          amount_doors?: number | null;
          amount_rooms?: number | null;
          amount_windows?: number | null;
          total_room_size_m2?: number | null;
          total_room_size_px?: number | null;
          user_total_size?: number | null;
          user_scaling_selected_px?: number | null;
          user_scaling_cm?: number | null;
          api_scaling_factor_cm_per_px?: number | null;
          status?: FloorplanStatus | null;
          customerId?: string | null;
          floorplannerId?: string | null;
          acceptedAt?: string | null;
          finishedAt?: string | null;
        } | null;
        order?: {
          __typename: "Order";
          id: string;
          user: string;
          customer: string;
          owner: string;
          amount_total?: number | null;
          level?: Level | null;
          stripe_checkout_session_id?: string | null;
          paymentStatus?: PaymentStatus | null;
          orderStatus?: OrderStatus | null;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    customer?: {
      __typename: "Customer";
      id: string;
      user: string;
      identityId?: string | null;
      email: string;
      stripe_customer_id?: string | null;
      phone_number?: string | null;
      company?: string | null;
      company_address?: string | null;
      company_address_line2?: string | null;
      company_city?: string | null;
      company_taxnumber?: string | null;
      country?: string | null;
      zip?: string | null;
      accepted_terms?: boolean | null;
      accepted_gdpr?: boolean | null;
      createdAt: string;
      updatedAt: string;
      floorplans?: {
        __typename: "ModelFloorplanConnection";
        items?: Array<{
          __typename: "Floorplan";
          id: string;
          type?: string | null;
          owner?: string | null;
          projectId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          name: string;
          address?: string | null;
          description?: string | null;
          originalImageName?: string | null;
          originalInputType?: OriginalInputType | null;
          shareable?: boolean | null;
          total_size?: number | null;
          amount_doors?: number | null;
          amount_rooms?: number | null;
          amount_windows?: number | null;
          total_room_size_m2?: number | null;
          total_room_size_px?: number | null;
          user_total_size?: number | null;
          user_scaling_selected_px?: number | null;
          user_scaling_cm?: number | null;
          api_scaling_factor_cm_per_px?: number | null;
          status?: FloorplanStatus | null;
          customerId?: string | null;
          floorplannerId?: string | null;
          acceptedAt?: string | null;
          finishedAt?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null;
    chatMessages?: {
      __typename: "ModelChatMessageConnection";
      items?: Array<{
        __typename: "ChatMessage";
        id: string;
        floorplanId: string;
        message: string;
        sentByCustomer: boolean;
        read: boolean;
        createdAt?: string | null;
        owner?: string | null;
        images?: Array<{
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null> | null;
        updatedAt: string;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnDeleteFloorplanSubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteFloorplanSubscription = {
  onDeleteFloorplan?: {
    __typename: "Floorplan";
    id: string;
    type?: string | null;
    owner?: string | null;
    projectId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    name: string;
    address?: string | null;
    description?: string | null;
    originalImageName?: string | null;
    originalInputType?: OriginalInputType | null;
    shareable?: boolean | null;
    originalImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    croppedImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    preprocessedImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    visual2DImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    mlPredictionImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    ocrPredictionImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    postMlImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    internalModelImage?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    basicImage?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    basicPlusImage?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageTop?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageTilted?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageNE?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageNW?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageSE?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    professionalImageSW?: {
      __typename: "ExportFiles";
      png?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      jpeg?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
      pdf?: {
        __typename: "S3Object";
        bucket: string;
        region: string;
        key: string;
        status?: string | null;
      } | null;
    } | null;
    reactplannerModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    floorplannerModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    ifcModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    reimportedIfcModel?: {
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null;
    total_size?: number | null;
    amount_doors?: number | null;
    amount_rooms?: number | null;
    amount_windows?: number | null;
    total_room_size_m2?: number | null;
    total_room_size_px?: number | null;
    user_total_size?: number | null;
    user_scaling_selected_px?: number | null;
    user_scaling_cm?: number | null;
    api_scaling_factor_cm_per_px?: number | null;
    status?: FloorplanStatus | null;
    customerId?: string | null;
    floorplannerId?: string | null;
    acceptedAt?: string | null;
    finishedAt?: string | null;
    rooms?: {
      __typename: "ModelRoomConnection";
      items?: Array<{
        __typename: "Room";
        id: string;
        floorplanId: string;
        name?: string | null;
        room_size_sqm?: number | null;
        room_type?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelFloorplanOrderConnection";
      items?: Array<{
        __typename: "FloorplanOrder";
        id: string;
        floorplan_id: string;
        order_id: string;
        owner: string;
        customer?: string | null;
        createdAt: string;
        updatedAt: string;
        floorplan?: {
          __typename: "Floorplan";
          id: string;
          type?: string | null;
          owner?: string | null;
          projectId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          name: string;
          address?: string | null;
          description?: string | null;
          originalImageName?: string | null;
          originalInputType?: OriginalInputType | null;
          shareable?: boolean | null;
          total_size?: number | null;
          amount_doors?: number | null;
          amount_rooms?: number | null;
          amount_windows?: number | null;
          total_room_size_m2?: number | null;
          total_room_size_px?: number | null;
          user_total_size?: number | null;
          user_scaling_selected_px?: number | null;
          user_scaling_cm?: number | null;
          api_scaling_factor_cm_per_px?: number | null;
          status?: FloorplanStatus | null;
          customerId?: string | null;
          floorplannerId?: string | null;
          acceptedAt?: string | null;
          finishedAt?: string | null;
        } | null;
        order?: {
          __typename: "Order";
          id: string;
          user: string;
          customer: string;
          owner: string;
          amount_total?: number | null;
          level?: Level | null;
          stripe_checkout_session_id?: string | null;
          paymentStatus?: PaymentStatus | null;
          orderStatus?: OrderStatus | null;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    customer?: {
      __typename: "Customer";
      id: string;
      user: string;
      identityId?: string | null;
      email: string;
      stripe_customer_id?: string | null;
      phone_number?: string | null;
      company?: string | null;
      company_address?: string | null;
      company_address_line2?: string | null;
      company_city?: string | null;
      company_taxnumber?: string | null;
      country?: string | null;
      zip?: string | null;
      accepted_terms?: boolean | null;
      accepted_gdpr?: boolean | null;
      createdAt: string;
      updatedAt: string;
      floorplans?: {
        __typename: "ModelFloorplanConnection";
        items?: Array<{
          __typename: "Floorplan";
          id: string;
          type?: string | null;
          owner?: string | null;
          projectId?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          name: string;
          address?: string | null;
          description?: string | null;
          originalImageName?: string | null;
          originalInputType?: OriginalInputType | null;
          shareable?: boolean | null;
          total_size?: number | null;
          amount_doors?: number | null;
          amount_rooms?: number | null;
          amount_windows?: number | null;
          total_room_size_m2?: number | null;
          total_room_size_px?: number | null;
          user_total_size?: number | null;
          user_scaling_selected_px?: number | null;
          user_scaling_cm?: number | null;
          api_scaling_factor_cm_per_px?: number | null;
          status?: FloorplanStatus | null;
          customerId?: string | null;
          floorplannerId?: string | null;
          acceptedAt?: string | null;
          finishedAt?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
    } | null;
    chatMessages?: {
      __typename: "ModelChatMessageConnection";
      items?: Array<{
        __typename: "ChatMessage";
        id: string;
        floorplanId: string;
        message: string;
        sentByCustomer: boolean;
        read: boolean;
        createdAt?: string | null;
        owner?: string | null;
        images?: Array<{
          __typename: "S3Object";
          bucket: string;
          region: string;
          key: string;
          status?: string | null;
        } | null> | null;
        updatedAt: string;
      } | null> | null;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnCreateRoomSubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateRoomSubscription = {
  onCreateRoom?: {
    __typename: "Room";
    id: string;
    floorplanId: string;
    name?: string | null;
    room_size_sqm?: number | null;
    room_type?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnUpdateRoomSubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateRoomSubscription = {
  onUpdateRoom?: {
    __typename: "Room";
    id: string;
    floorplanId: string;
    name?: string | null;
    room_size_sqm?: number | null;
    room_type?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnDeleteRoomSubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteRoomSubscription = {
  onDeleteRoom?: {
    __typename: "Room";
    id: string;
    floorplanId: string;
    name?: string | null;
    room_size_sqm?: number | null;
    room_type?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnCreateSettingsSubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateSettingsSubscription = {
  onCreateSettings?: {
    __typename: "Settings";
    id: string;
    theme?: string | null;
    displayName?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnUpdateSettingsSubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateSettingsSubscription = {
  onUpdateSettings?: {
    __typename: "Settings";
    id: string;
    theme?: string | null;
    displayName?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnDeleteSettingsSubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteSettingsSubscription = {
  onDeleteSettings?: {
    __typename: "Settings";
    id: string;
    theme?: string | null;
    displayName?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnCreateChatMessageSubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateChatMessageSubscription = {
  onCreateChatMessage?: {
    __typename: "ChatMessage";
    id: string;
    floorplanId: string;
    message: string;
    sentByCustomer: boolean;
    read: boolean;
    createdAt?: string | null;
    owner?: string | null;
    images?: Array<{
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null> | null;
    updatedAt: string;
  } | null;
};

export type OnUpdateChatMessageSubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateChatMessageSubscription = {
  onUpdateChatMessage?: {
    __typename: "ChatMessage";
    id: string;
    floorplanId: string;
    message: string;
    sentByCustomer: boolean;
    read: boolean;
    createdAt?: string | null;
    owner?: string | null;
    images?: Array<{
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null> | null;
    updatedAt: string;
  } | null;
};

export type OnDeleteChatMessageSubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteChatMessageSubscription = {
  onDeleteChatMessage?: {
    __typename: "ChatMessage";
    id: string;
    floorplanId: string;
    message: string;
    sentByCustomer: boolean;
    read: boolean;
    createdAt?: string | null;
    owner?: string | null;
    images?: Array<{
      __typename: "S3Object";
      bucket: string;
      region: string;
      key: string;
      status?: string | null;
    } | null> | null;
    updatedAt: string;
  } | null;
};
