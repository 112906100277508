import React, { useContext, useEffect, useState } from "react";
import { FlrPaper } from "components/layout/StyledComponents";
import { FurnitureConfigContext } from "providers/FurnitureConfigContextProvider";
import styled from "styled-components";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { ExtendedFurniture } from "./mockData/types";
import CloseIcon from "@material-ui/icons/Close";

const Bubble = styled.div`
  background-color: ${(props) => props.theme.palette.primary.main + "3E"};
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  top: ${({ $top }) => $top}%;
  left: ${({ $left }) => $left}%;
  position: absolute;
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const SmallerBubble = styled.div`
  background-color: ${(props) => props.theme.palette.primary.main};
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
`;

type PropTypesBubble = {
  furniture: ExtendedFurniture;
  onClick: Function;
};

const OverlayBubble: React.FC<PropTypesBubble> = ({ furniture, onClick }) => {
  return (
    <Bubble $top={furniture.position.top} $left={furniture.position.left} onClick={onClick}>
      <SmallerBubble />
    </Bubble>
  );
};

type PropTypesOverlayInfo = {
  furniture: ExtendedFurniture;
  onClose: Function;
};

const InfoPopup = styled(FlrPaper)`
  position: absolute;
  top: ${({ $top }) => ($top > 55 ? 55 : $top)}%;
  left: ${({ $left }) => ($left + 5 > 80 ? 80 : $left + 5)}%;
  width: 13rem;
  overflow: visible;
  > img {
    padding: 5px;
    margin: 5px;
    border: 2px solid lightgrey;
    border-radius: 14px;
  }
`;

const OverlayInfo: React.FC<PropTypesOverlayInfo> = ({ furniture, onClose }) => {
  return (
    <InfoPopup $top={furniture.position.top} $left={furniture.position.left}>
      <Grid container>
        <Grid item xs={12}>
          <Typography>{furniture.furniture.title}</Typography>
        </Grid>
      </Grid>
      <img src={furniture.furniture.img} alt="furniture" />
      <IconButton
        aria-label="exchange"
        color="primary"
        onClick={() => onClose()}
        style={{
          position: "absolute",
          top: "-1em",
          right: "-1em",
          background: "white",
          border: "2px solid lightgrey",
          zIndex: 3,
        }}
      >
        <CloseIcon />
      </IconButton>
    </InfoPopup>
  );
};

type PropTypes = {
  img: string;
};

const RoomImage: React.FC<PropTypes> = ({ img }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = useContext(FurnitureConfigContext);
  const [clickedFurniture, setClickedFurniture] = useState<ExtendedFurniture>(undefined);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [img]);

  return (
    <FlrPaper style={{ position: "relative", overflow: "visible" }}>
      <img src={img} alt="room" style={{ borderRadius: "14px" }} />
      {state.room &&
        state.room.furniture.map((fn) => {
          return (
            <OverlayBubble
              key={fn.furniture.id}
              furniture={fn}
              onClick={() => {
                setIsOpen(true);
                setClickedFurniture(fn);
              }}
            />
          );
        })}
      {isOpen && (
        <OverlayInfo
          furniture={clickedFurniture}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      )}
    </FlrPaper>
  );
};

export default RoomImage;
