import { FlrFab, FlrPaper } from "components/layout/StyledComponents";
import { LiveHelp } from "@material-ui/icons/";
import { Suspense, useState } from "react";
import { Floorplan } from "models";
import styled from "styled-components";
import ChatBox from "./ChatBox";
import ChatNav from "container/communication/ChatNav";
import ChatView from "./ChatView";
import { Badge, Tooltip } from "@material-ui/core";
import { useQueryParams } from "utils/UrlUtils";
import { LoadingAnimation } from "components/layout/LoadingAnimations";

type Props = {
  floorplan: Floorplan;
};

const ChatDialog = styled(FlrPaper)`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1250;
  width: 30%;
  height: 70%;
  min-height: 500px;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0;
  > * {
    padding: 16px;
  }
  background-color: ${(p) => p.theme.palette.background.default};
`;

const LiveHelpIcon = styled(LiveHelp)`
  margin-left: 8px;
`;

const Chat: React.FC<Props> = ({ floorplan }) => {
  const query = useQueryParams();
  const chatQueryParam = query.get("chat");
  const [isOpen, setIsOpen] = useState(chatQueryParam !== null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [unread, setUnread] = useState(0);

  return (
    <>
      <Tooltip title="Support und Änderungswünsche" placement="top">
        <FlrFab onClick={() => setIsOpen(true)} color="primary" aria-label="help" variant="extended">
          <Badge badgeContent={unread} color="secondary">
            Änderungswünsche
            <LiveHelpIcon />
          </Badge>
        </FlrFab>
      </Tooltip>
      {isOpen && (
        <ChatDialog>
          <ChatNav
            onMinimize={() => {
              setIsOpen(false);
            }}
          />
          <Suspense fallback={<LoadingAnimation />}>
            <ChatView floorplanId={floorplan.id} />
          </Suspense>
          <ChatBox floorplan={floorplan} />
        </ChatDialog>
      )}
    </>
  );
};

export default Chat;
