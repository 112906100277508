import { Box, Container } from "@material-ui/core";
import { CheckoutHeader } from "../../components/checkout/CheckoutHeader";
import styled from "styled-components";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
`;

const Main = styled.main`
  flex-grow: 1;
  margin-bottom: 8px;
`;

const CheckoutLayout = ({ children }) => {
  return (
    <Root>
      <CheckoutHeader />
      <Main>
        <Container maxWidth="lg">
          <Box>{children}</Box>
        </Container>
      </Main>
    </Root>
  );
};

export default CheckoutLayout;
