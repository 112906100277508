import React, { ReactElement } from "react";

import Logo from "../../assets/logo_text_right_black_transparent.png";
import { Container, Box } from "@material-ui/core";
import styled from "styled-components";

const Root = styled.div`
  margin: 8px;
`;

export const CheckoutHeader: React.FC = (): ReactElement => {
  return (
    <Root>
      <Container>
        <Box display="flex" justifyContent="center">
          <img src={Logo} alt="Logo" height="80" />
        </Box>
      </Container>
    </Root>
  );
};
