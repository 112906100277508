import { FILE_TYPE, MIME_TYPE } from "../constants";

export const getMimeType = (fileType: FILE_TYPE): MIME_TYPE => {
  switch (fileType) {
    case FILE_TYPE.PNG:
      return MIME_TYPE.PNG;
    case FILE_TYPE.JPG:
      return MIME_TYPE.JPEG;
    case FILE_TYPE.JPEG:
      return MIME_TYPE.JPEG;
    case FILE_TYPE.PDF:
      return MIME_TYPE.PDF;
    default:
      return undefined;
  }
};

export const getFileType = (mimeType: MIME_TYPE): FILE_TYPE => {
  switch (mimeType) {
    case MIME_TYPE.PNG:
      return FILE_TYPE.PNG;
    case MIME_TYPE.JPG:
      return FILE_TYPE.JPEG;
    case MIME_TYPE.JPEG:
      return FILE_TYPE.JPEG;
    case MIME_TYPE.PDF:
      return FILE_TYPE.PDF;
    default:
      return undefined;
  }
};
