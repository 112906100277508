import React from "react";
import { Auth } from "aws-amplify";
import { useUser } from "providers/AuthContextProvider";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { Formik, Field, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";

import { PASSWORD_REGEX } from "../../constants";
import { useToastProvider } from "providers/ToastProvider";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
  subtext: {
    margin: theme.spacing(1, 0, 1),
  },
  textButton: {
    textTransform: "none",
  },
}));
interface Values {
  oldPassword: string;
  newPassword: string;
}

const ChangePassword = () => {
  const classes = useStyles();
  const { user } = useUser();
  const history = useHistory();
  const { showToast } = useToastProvider();
  const forgotPasswordButtonText = "Passwort vergessen? Ausloggen und zurücksetzten ";

  const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const result = await Auth.changePassword(user, oldPassword, newPassword);
      if (result)
        showToast({
          severity: "success",
          content: "Ihr Passwort wurde erfolgreich geändert.",
        });
    } catch (err) {
      handleError(err);
    }
  };

  const handleError = (err: { message: any; code: any }) => {
    console.log(err);
    if (err.message && err.code) {
      let message = "Unbekannter Fehler";

      switch (err.code) {
        case "NotAuthorizedException":
          message = "Bitte geben Sie Ihr altes Passwort korrekt ein.";
          break;
        case "LimitExceededException":
          message = "Eingabelimit überschritten, bitte versuchen Sie es später erneut";
          break;
        default:
          break;
      }
      showToast({
        severity: "error",
        content: "Beim Ändern Ihres Passworts ist ein Fehler aufgetreten: " + message,
      });
    }
  };

  const handleForgotPassword = () => {
    history.push(`/resetpassword`);
    signOut();
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  return (
    <>
      <Typography component="h2" variant="h5">
        Passwort ändern
      </Typography>
      <Formik
        enableReinitialize
        initialValues={{
          oldPassword: "",
          newPassword: "",
        }}
        validationSchema={Yup.object({
          oldPassword: Yup.string()
            .required("Bitte geben Sie ihr altes Passwort an.")
            .matches(PASSWORD_REGEX, "Mind. 8 Zeichen, 1 Großbuchstabe, 1 Kleinbuchstabe und 1 Zahl"),
          newPassword: Yup.string()
            .required("Bitte geben Sie ihr Neues Passwort an.")
            .matches(PASSWORD_REGEX, "Mind. 8 Zeichen, 1 Großbuchstabe, 1 Kleinbuchstabe und 1 Zahl"),
        })}
        onSubmit={(values: Values, { setSubmitting, resetForm }: FormikHelpers<Values>) => {
          setTimeout(() => {
            changePassword(values.oldPassword, values.newPassword);
            setSubmitting(false);
            resetForm();
          }, 500);
        }}
      >
        <Form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                required
                component={TextField}
                variant="outlined"
                fullWidth
                id="oldPassword"
                name="oldPassword"
                label="Altes Passwort"
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                required
                component={TextField}
                variant="outlined"
                fullWidth
                id="newPassword"
                name="newPassword"
                label="Neues Passwort"
                type="password"
              />
            </Grid>
          </Grid>
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            Passwort ändern
          </Button>
        </Form>
      </Formik>
      <Grid container justify="flex-end">
        <Grid item>
          <Typography variant={"body2"} className={classes.subtext}>
            <Button className={classes.textButton} color="primary" onClick={handleForgotPassword}>
              {forgotPasswordButtonText} <ExitToAppIcon />
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ChangePassword;
