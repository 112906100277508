/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onChatMessageByFloorplanId = /* GraphQL */ `
  subscription OnChatMessageByFloorplanId($floorplanId: ID!) {
    onChatMessageByFloorplanId(floorplanId: $floorplanId) {
      id
      floorplanId
      message
      sentByCustomer
      read
      createdAt
      owner
      images {
        bucket
        region
        key
        status
      }
      updatedAt
    }
  }
`;
export const onCreateFloorplanProject = /* GraphQL */ `
  subscription OnCreateFloorplanProject($owner: String) {
    onCreateFloorplanProject(owner: $owner) {
      id
      name
      address
      description
      size
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateFloorplanProject = /* GraphQL */ `
  subscription OnUpdateFloorplanProject($owner: String) {
    onUpdateFloorplanProject(owner: $owner) {
      id
      name
      address
      description
      size
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteFloorplanProject = /* GraphQL */ `
  subscription OnDeleteFloorplanProject($owner: String) {
    onDeleteFloorplanProject(owner: $owner) {
      id
      name
      address
      description
      size
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateFloorplan = /* GraphQL */ `
  subscription OnCreateFloorplan($owner: String) {
    onCreateFloorplan(owner: $owner) {
      id
      type
      owner
      projectId
      createdAt
      updatedAt
      name
      address
      description
      originalImageName
      originalInputType
      shareable
      originalImage {
        bucket
        region
        key
        status
      }
      croppedImage {
        bucket
        region
        key
        status
      }
      preprocessedImage {
        bucket
        region
        key
        status
      }
      visual2DImage {
        bucket
        region
        key
        status
      }
      mlPredictionImage {
        bucket
        region
        key
        status
      }
      ocrPredictionImage {
        bucket
        region
        key
        status
      }
      postMlImage {
        bucket
        region
        key
        status
      }
      internalModelImage {
        bucket
        region
        key
        status
      }
      basicImage {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      basicPlusImage {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageTop {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageTilted {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageNE {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageNW {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageSE {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageSW {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      reactplannerModel {
        bucket
        region
        key
        status
      }
      floorplannerModel {
        bucket
        region
        key
        status
      }
      ifcModel {
        bucket
        region
        key
        status
      }
      reimportedIfcModel {
        bucket
        region
        key
        status
      }
      total_size
      amount_doors
      amount_rooms
      amount_windows
      total_room_size_m2
      total_room_size_px
      user_total_size
      user_scaling_selected_px
      user_scaling_cm
      api_scaling_factor_cm_per_px
      status
      customerId
      floorplannerId
      acceptedAt
      finishedAt
      rooms {
        items {
          id
          floorplanId
          name
          room_size_sqm
          room_type
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      orders {
        items {
          id
          floorplan_id
          order_id
          owner
          customer
          createdAt
          updatedAt
          floorplan {
            id
            type
            owner
            projectId
            createdAt
            updatedAt
            name
            address
            description
            originalImageName
            originalInputType
            shareable
            total_size
            amount_doors
            amount_rooms
            amount_windows
            total_room_size_m2
            total_room_size_px
            user_total_size
            user_scaling_selected_px
            user_scaling_cm
            api_scaling_factor_cm_per_px
            status
            customerId
            floorplannerId
            acceptedAt
            finishedAt
          }
          order {
            id
            user
            customer
            owner
            amount_total
            level
            stripe_checkout_session_id
            paymentStatus
            orderStatus
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      customer {
        id
        user
        identityId
        email
        stripe_customer_id
        phone_number
        company
        company_address
        company_address_line2
        company_city
        company_taxnumber
        country
        zip
        accepted_terms
        accepted_gdpr
        createdAt
        updatedAt
        floorplans {
          items {
            id
            type
            owner
            projectId
            createdAt
            updatedAt
            name
            address
            description
            originalImageName
            originalInputType
            shareable
            total_size
            amount_doors
            amount_rooms
            amount_windows
            total_room_size_m2
            total_room_size_px
            user_total_size
            user_scaling_selected_px
            user_scaling_cm
            api_scaling_factor_cm_per_px
            status
            customerId
            floorplannerId
            acceptedAt
            finishedAt
          }
          nextToken
        }
      }
      chatMessages {
        items {
          id
          floorplanId
          message
          sentByCustomer
          read
          createdAt
          owner
          images {
            bucket
            region
            key
            status
          }
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateFloorplan = /* GraphQL */ `
  subscription OnUpdateFloorplan($owner: String) {
    onUpdateFloorplan(owner: $owner) {
      id
      type
      owner
      projectId
      createdAt
      updatedAt
      name
      address
      description
      originalImageName
      originalInputType
      shareable
      originalImage {
        bucket
        region
        key
        status
      }
      croppedImage {
        bucket
        region
        key
        status
      }
      preprocessedImage {
        bucket
        region
        key
        status
      }
      visual2DImage {
        bucket
        region
        key
        status
      }
      mlPredictionImage {
        bucket
        region
        key
        status
      }
      ocrPredictionImage {
        bucket
        region
        key
        status
      }
      postMlImage {
        bucket
        region
        key
        status
      }
      internalModelImage {
        bucket
        region
        key
        status
      }
      basicImage {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      basicPlusImage {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageTop {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageTilted {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageNE {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageNW {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageSE {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageSW {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      reactplannerModel {
        bucket
        region
        key
        status
      }
      floorplannerModel {
        bucket
        region
        key
        status
      }
      ifcModel {
        bucket
        region
        key
        status
      }
      reimportedIfcModel {
        bucket
        region
        key
        status
      }
      total_size
      amount_doors
      amount_rooms
      amount_windows
      total_room_size_m2
      total_room_size_px
      user_total_size
      user_scaling_selected_px
      user_scaling_cm
      api_scaling_factor_cm_per_px
      status
      customerId
      floorplannerId
      acceptedAt
      finishedAt
      rooms {
        items {
          id
          floorplanId
          name
          room_size_sqm
          room_type
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      orders {
        items {
          id
          floorplan_id
          order_id
          owner
          customer
          createdAt
          updatedAt
          floorplan {
            id
            type
            owner
            projectId
            createdAt
            updatedAt
            name
            address
            description
            originalImageName
            originalInputType
            shareable
            total_size
            amount_doors
            amount_rooms
            amount_windows
            total_room_size_m2
            total_room_size_px
            user_total_size
            user_scaling_selected_px
            user_scaling_cm
            api_scaling_factor_cm_per_px
            status
            customerId
            floorplannerId
            acceptedAt
            finishedAt
          }
          order {
            id
            user
            customer
            owner
            amount_total
            level
            stripe_checkout_session_id
            paymentStatus
            orderStatus
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      customer {
        id
        user
        identityId
        email
        stripe_customer_id
        phone_number
        company
        company_address
        company_address_line2
        company_city
        company_taxnumber
        country
        zip
        accepted_terms
        accepted_gdpr
        createdAt
        updatedAt
        floorplans {
          items {
            id
            type
            owner
            projectId
            createdAt
            updatedAt
            name
            address
            description
            originalImageName
            originalInputType
            shareable
            total_size
            amount_doors
            amount_rooms
            amount_windows
            total_room_size_m2
            total_room_size_px
            user_total_size
            user_scaling_selected_px
            user_scaling_cm
            api_scaling_factor_cm_per_px
            status
            customerId
            floorplannerId
            acceptedAt
            finishedAt
          }
          nextToken
        }
      }
      chatMessages {
        items {
          id
          floorplanId
          message
          sentByCustomer
          read
          createdAt
          owner
          images {
            bucket
            region
            key
            status
          }
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteFloorplan = /* GraphQL */ `
  subscription OnDeleteFloorplan($owner: String) {
    onDeleteFloorplan(owner: $owner) {
      id
      type
      owner
      projectId
      createdAt
      updatedAt
      name
      address
      description
      originalImageName
      originalInputType
      shareable
      originalImage {
        bucket
        region
        key
        status
      }
      croppedImage {
        bucket
        region
        key
        status
      }
      preprocessedImage {
        bucket
        region
        key
        status
      }
      visual2DImage {
        bucket
        region
        key
        status
      }
      mlPredictionImage {
        bucket
        region
        key
        status
      }
      ocrPredictionImage {
        bucket
        region
        key
        status
      }
      postMlImage {
        bucket
        region
        key
        status
      }
      internalModelImage {
        bucket
        region
        key
        status
      }
      basicImage {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      basicPlusImage {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageTop {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageTilted {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageNE {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageNW {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageSE {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      professionalImageSW {
        png {
          bucket
          region
          key
          status
        }
        jpeg {
          bucket
          region
          key
          status
        }
        pdf {
          bucket
          region
          key
          status
        }
      }
      reactplannerModel {
        bucket
        region
        key
        status
      }
      floorplannerModel {
        bucket
        region
        key
        status
      }
      ifcModel {
        bucket
        region
        key
        status
      }
      reimportedIfcModel {
        bucket
        region
        key
        status
      }
      total_size
      amount_doors
      amount_rooms
      amount_windows
      total_room_size_m2
      total_room_size_px
      user_total_size
      user_scaling_selected_px
      user_scaling_cm
      api_scaling_factor_cm_per_px
      status
      customerId
      floorplannerId
      acceptedAt
      finishedAt
      rooms {
        items {
          id
          floorplanId
          name
          room_size_sqm
          room_type
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      orders {
        items {
          id
          floorplan_id
          order_id
          owner
          customer
          createdAt
          updatedAt
          floorplan {
            id
            type
            owner
            projectId
            createdAt
            updatedAt
            name
            address
            description
            originalImageName
            originalInputType
            shareable
            total_size
            amount_doors
            amount_rooms
            amount_windows
            total_room_size_m2
            total_room_size_px
            user_total_size
            user_scaling_selected_px
            user_scaling_cm
            api_scaling_factor_cm_per_px
            status
            customerId
            floorplannerId
            acceptedAt
            finishedAt
          }
          order {
            id
            user
            customer
            owner
            amount_total
            level
            stripe_checkout_session_id
            paymentStatus
            orderStatus
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      customer {
        id
        user
        identityId
        email
        stripe_customer_id
        phone_number
        company
        company_address
        company_address_line2
        company_city
        company_taxnumber
        country
        zip
        accepted_terms
        accepted_gdpr
        createdAt
        updatedAt
        floorplans {
          items {
            id
            type
            owner
            projectId
            createdAt
            updatedAt
            name
            address
            description
            originalImageName
            originalInputType
            shareable
            total_size
            amount_doors
            amount_rooms
            amount_windows
            total_room_size_m2
            total_room_size_px
            user_total_size
            user_scaling_selected_px
            user_scaling_cm
            api_scaling_factor_cm_per_px
            status
            customerId
            floorplannerId
            acceptedAt
            finishedAt
          }
          nextToken
        }
      }
      chatMessages {
        items {
          id
          floorplanId
          message
          sentByCustomer
          read
          createdAt
          owner
          images {
            bucket
            region
            key
            status
          }
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateRoom = /* GraphQL */ `
  subscription OnCreateRoom($owner: String) {
    onCreateRoom(owner: $owner) {
      id
      floorplanId
      name
      room_size_sqm
      room_type
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateRoom = /* GraphQL */ `
  subscription OnUpdateRoom($owner: String) {
    onUpdateRoom(owner: $owner) {
      id
      floorplanId
      name
      room_size_sqm
      room_type
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteRoom = /* GraphQL */ `
  subscription OnDeleteRoom($owner: String) {
    onDeleteRoom(owner: $owner) {
      id
      floorplanId
      name
      room_size_sqm
      room_type
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateSettings = /* GraphQL */ `
  subscription OnCreateSettings($owner: String) {
    onCreateSettings(owner: $owner) {
      id
      theme
      displayName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateSettings = /* GraphQL */ `
  subscription OnUpdateSettings($owner: String) {
    onUpdateSettings(owner: $owner) {
      id
      theme
      displayName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteSettings = /* GraphQL */ `
  subscription OnDeleteSettings($owner: String) {
    onDeleteSettings(owner: $owner) {
      id
      theme
      displayName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateChatMessage = /* GraphQL */ `
  subscription OnCreateChatMessage($owner: String) {
    onCreateChatMessage(owner: $owner) {
      id
      floorplanId
      message
      sentByCustomer
      read
      createdAt
      owner
      images {
        bucket
        region
        key
        status
      }
      updatedAt
    }
  }
`;
export const onUpdateChatMessage = /* GraphQL */ `
  subscription OnUpdateChatMessage($owner: String) {
    onUpdateChatMessage(owner: $owner) {
      id
      floorplanId
      message
      sentByCustomer
      read
      createdAt
      owner
      images {
        bucket
        region
        key
        status
      }
      updatedAt
    }
  }
`;
export const onDeleteChatMessage = /* GraphQL */ `
  subscription OnDeleteChatMessage($owner: String) {
    onDeleteChatMessage(owner: $owner) {
      id
      floorplanId
      message
      sentByCustomer
      read
      createdAt
      owner
      images {
        bucket
        region
        key
        status
      }
      updatedAt
    }
  }
`;
