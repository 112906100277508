import { Route } from "react-router-dom";
import FloorplanDetail from "../container/detail/FloorplanDetail";

export const DetailRoute = ({ match }) => {
  return (
    <>
      <Route path={`${match.path}/:id`} render={({ match }) => <FloorplanDetail id={match.params.id} />} />
      <Route exact path={match.url} render={() => <div>Please select a floorplan.</div>} />
    </>
  );
};
