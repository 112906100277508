import MaterialTable, { Column } from "material-table";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import getTableIcons from "../../utils/TableIcons";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { toLocaleDateShort } from "../../utils/DateUtils";
import { Floorplan, FloorplanOrder, Order, FloorplanStatus, OrderStatus, Level } from "../../models";

type Props = {
  floorplans: Floorplan[];
  pageSize?: number;
  setPageSize?: (pageSize: number) => void;
  pageSizeOptions?: number[];
  refetchEntries: () => void;
};

type TableColumns = Array<Column<RowData>>;
type TableData = RowData[];
type RowData = {
  id: string;
  name: string;
  owner: string;
  createdAt: string;
  acceptedAt: string | "";
  finishedAt: string | "";
  status?: Status;
  references?: References;
  customerEmail?: string;
  tableData?: any;
};

type Status = {
  floorplanStatus?: FloorplanStatus | string;
  level?: Level;
  orderStatus?: OrderStatus;
};

type References = {
  floorplanOrderId?: string;
  orderId?: string;
  customerId?: string;
};

// returns the floorplan if its orderLevel is NOT of the given orderLevel
const filterFloorplanbyOrderLevel = (floorplan: Floorplan, orderLevel: Level): Floorplan => {
  const orders = floorplan.orders.filter((fo) => fo.order.level !== orderLevel);
  return orders.length > 0 ? floorplan : null;
};

const setFloorplanStatusLabel = (floorplan: Floorplan): FloorplanStatus | string => {
  const NEW_LABEL = "NEW";
  const IN_REVIEW = "IN REVIEW";
  let label = floorplan?.status || NEW_LABEL;

  switch (floorplan?.status) {
    case FloorplanStatus.INITIALIZED:
      label = NEW_LABEL;
      break;
    case FloorplanStatus.GENERATED:
      label = NEW_LABEL;
      break;
    case FloorplanStatus.QA_CREATED:
      label = IN_REVIEW;
      break;
  }

  return label;
};

const createTableData = (floorplans: Floorplan[]): TableData => {
  return floorplans
    .filter((fp) => filterFloorplanbyOrderLevel(fp, Level.FML))
    .map((fp) => {
      let floorplan: RowData = {
        id: fp.id,
        name: fp.name,
        owner: fp.owner,
        createdAt: fp.createdAt,
        acceptedAt: fp?.acceptedAt,
        finishedAt: fp?.finishedAt,
        customerEmail: fp.customer?.email,
      };

      const floorplanOrders = fp?.orders;

      let references: References = {
        orderId: "string",
        customerId: fp?.customer?.id,
      };

      let stati: Status[];
      if (floorplanOrders.length > 0) {
        stati = floorplanOrders
          .filter((e) => e.order != null && e.order.level !== Level.FML)
          .map((fpo: FloorplanOrder): Status => {
            const order: Order = fpo?.order;
            const status: Status = {
              floorplanStatus: setFloorplanStatusLabel(fp),
              level: Level[order?.level],
              orderStatus: OrderStatus[order?.orderStatus],
            };

            return status;
          });

        references.floorplanOrderId = floorplanOrders[0].id;
        references.orderId = floorplanOrders[0].order?.id;
      }
      floorplan.status = stati ? stati[0] : {};
      floorplan.references = references;

      return floorplan;
    });
};

const ContractorFloorplanList: React.FC<Props> = ({ floorplans, refetchEntries }: Props) => {
  const history = useHistory();
  const tableIcons = getTableIcons();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, setData] = useState<TableData>(() => createTableData(floorplans));
  const [columns] = useState<TableColumns>([
    { title: "Floorplan status", field: "status.floorplanStatus", editable: "never" },
    { title: "Order level", field: "status.level", editable: "never" },
    { title: "Name", field: "name", editable: "never" },
    { title: "Customer email", field: "customerEmail", editable: "never" },
    {
      title: "created at",
      field: "createdAt",
      editable: "never",
      defaultSort: "desc",
      render: (rowData) => toLocaleDateShort(rowData.createdAt),
    },
    {
      title: "accepted at",
      field: "acceptedAt",
      editable: "never",
      defaultSort: "desc",
      render: (rowData) => (rowData.acceptedAt ? toLocaleDateShort(rowData.acceptedAt) : ""),
    },
    {
      title: "finished at",
      field: "finishedAt",
      editable: "never",
      defaultSort: "desc",
      render: (rowData) => (rowData.finishedAt ? toLocaleDateShort(rowData.finishedAt) : ""),
    },
  ]);

  const viewContent = (id: string) => {
    return history.push(`/detail/${id}`);
  };

  return (
    <MaterialTable
      components={{
        Container: (p) => p.children,
      }}
      title="New floor plans"
      columns={columns}
      data={data}
      icons={tableIcons}
      //onChangeRowsPerPage={(pageSize) => setPageSize(pageSize)}
      options={{
        paging: true,
        pageSize: 10, // make initial page size
        emptyRowsWhenPaging: true, //to make page size fix in case of less data rows
        pageSizeOptions: [10, 20, 30, 40], // rows selection options
      }}
      actions={[
        {
          icon: ChevronRightIcon,
          tooltip: "Details",
          onClick: (_event, rowData: RowData) => {
            viewContent(rowData.id);
          },
        },
      ]}
      localization={{
        header: {
          actions: "Actions",
        },
      }}
    />
  );
};

export default ContractorFloorplanList;
