import { IconButton } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

const SignOut = () => {
  const history = useHistory();

  const signOut = async () => {
    try {
      await Auth.signOut();
      history.push("/");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  return (
    <IconButton
      aria-label="signout"
      onClick={() => {
        signOut();
      }}
    >
      <ExitToAppIcon />
    </IconButton>
  );
};

export default SignOut;
