import React, { useEffect, useState } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import { Floorplan, Order, OrderStatus } from "../../models";
import {
  CloudDownload,
  CloudUpload,
  ImportExport,
  Check,
  Cancel,
  Edit,
  Delete,
  Functions,
  CameraAlt,
  Add,
  Update,
  Autorenew,
} from "@material-ui/icons";
import useCreatePresignedUrl from "../../hooks/exports/useCreatePresignedUrl";
import { useEditorUrlDownload } from "../../hooks/exports/DownloadImagesHook";
import { useUpdateFloorplan } from "../../hooks/floorplan/UpdateFloorplanHooks";
import { useDigitizeFloorplan } from "../../hooks/floorplan/useDigitizeFloorplan";
import { useReimportFloorplan } from "../../hooks/floorplan/RecognitionServiceHooks";
import { useDeleteFloorplan } from "../../hooks/floorplan/DeleteFloorplanHooks";
import { sendOrderDeclined, sendOrderProcessed } from "hooks/mails/MailServiceHooks";
import { useUpdateOrder } from "hooks/order/UpdateOrderHooks";
import { useDeleteRoom } from "hooks/floorplan/DeleteRoomHooks";
import { useDeleteFloorplanOrder } from "hooks/order/DeleteFloorplanOrderHooks";
import { useDeleteOrder } from "hooks/order/DeleteOrderHooks";
import useImportImagesHook from "hooks/floorplannerApi/ImportImagesHook";
import { UpdateFloorplanInput, UpdateOrderInput } from "../../API";
import EditorUploadImagesDialog from "./EditorUploadImagesDialog";
import { FlrPaper } from "../../components/layout/StyledComponents";
import EditorInfoList from "../../components/floorplan-detail/EditorInfoList";
import DeleteConfirmationDialog from "../../components/floorplan-detail/DeleteConfirmationDialog";
import DropzoneReplaceImage from "../../components/floorplan-detail/DropzoneReplaceImage";
import { useToastProvider } from "../../providers/ToastProvider";
import { useDropzoneStore } from "../../providers/Store";
import { useHistory } from "react-router-dom";
import useCreateProjectHook from "hooks/floorplannerApi/createProjectHook";
import useUpdateDesignHook from "hooks/floorplannerApi/updateDesignHook";

type EditorPanelProps = {
  floorplan: Floorplan;
  order: Order;
  paymentStatus: string;
};

type EditorInfo = {
  stripe_id: string;
  email: string;
  phone: string;
  orderLevel: string;
  paymentStatus: string;
  floorplanner_id: string;
  floorplanStatus: string;
  orderStatus: string;
  floorplanShareable: boolean;
};

const EditorPanel: React.FC<EditorPanelProps> = ({ floorplan, order, paymentStatus }) => {
  const [floorplanStatus, setFloorplanStatus] = useState(floorplan.status);
  const [orderStatus, setOrderStatus] = useState(order?.orderStatus);
  const [orderLevel, setOrderLevel] = useState(order?.level);
  const [orderFulfilled, setOrderFulfilled] = useState(false);
  const [floorplanShareable, setFloorplanShareable] = useState(floorplan.shareable);
  const [floorplannerId, setFloorplannerId] = useState(floorplan.floorplannerId);

  useEffect(() => {
    setOrderLevel(order?.level);
    setOrderStatus(order?.orderStatus);
  }, [order]);

  const editorInfo: EditorInfo = {
    stripe_id: floorplan.customer?.stripe_customer_id,
    email: floorplan.customer?.email,
    phone: floorplan.customer?.phone_number,
    orderLevel: orderLevel,
    paymentStatus: paymentStatus,
    floorplanner_id: floorplannerId,
    floorplanStatus: floorplanStatus,
    orderStatus: orderStatus,
    floorplanShareable: floorplanShareable,
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const { createPresignedURLs } = useCreatePresignedUrl();
  const { startEditorDownload } = useEditorUrlDownload(floorplan.id);
  const { updateFloorplan } = useUpdateFloorplan();
  const { updateOrder } = useUpdateOrder();
  const { digitizeFloorplan } = useDigitizeFloorplan();
  const { deleteFloorplan } = useDeleteFloorplan();
  const { deleteRooms } = useDeleteRoom();
  const { deleteOrder } = useDeleteOrder();
  const { deleteFloorplanOrders } = useDeleteFloorplanOrder();
  const { importImages } = useImportImagesHook();
  const { createFloorplannerProject } = useCreateProjectHook();
  const { updateFloorplannerDesign } = useUpdateDesignHook();
  const { reimportFloorplan } = useReimportFloorplan();

  const { showSuccessToast, showInfoToast, showErrorToast } = useToastProvider();
  const history = useHistory();
  const { showDropzone } = useDropzoneStore();

  const downloadOriginalImageAndModel = async () => {
    const filesToDownloadKeys = [
      floorplan.originalImage.key,
      floorplan.floorplannerModel.key,
      floorplan.preprocessedImage.key,
      floorplan.visual2DImage.key,
      floorplan.croppedImage.key,
      floorplan.ifcModel?.key,
      floorplan.reimportedIfcModel?.key,
    ];
    const urls = await createPresignedURLs(filesToDownloadKeys, "GET");
    startEditorDownload(urls);
  };

  const handleFloorplanStatusChange = async (event) => {
    const updatedFloorplan: UpdateFloorplanInput = {
      id: floorplan.id,
      status: event.target.value,
    };
    const res = await updateFloorplan(updatedFloorplan);
    setFloorplanStatus(res.updateFloorplan.status);
  };

  const handleOrderStatusChange = async (event) => {
    const updatedOrder: UpdateOrderInput = {
      id: order.id,
      orderStatus: event.target.value,
    };
    const res = await updateOrder(updatedOrder);
    setOrderStatus(res.updateOrder.orderStatus);
    setOrderFulfilled(false);
  };

  const handleOrderLevelChange = async (event) => {
    const updatedOrder: UpdateOrderInput = {
      id: order.id,
      level: event.target.value,
    };
    const res = await updateOrder(updatedOrder);
    setOrderLevel(res.updateOrder.level);
  };

  const digitize = async () => {
    try {
      await digitizeFloorplan(floorplan);
      showSuccessToast("Grundriss wird digitalisiert. Dies kann ein paar Minuten dauern.");
    } catch (err) {
      showErrorToast("Fehler beim aufrufen des Digitalisierungs-Service.");
      console.error("handleDigitizeFloorplan:", err);
    }
  };

  const handleOrderFulfilled = async () => {
    setOrderFulfilled(true);

    const updatedOrder: UpdateOrderInput = {
      id: order.id,
      orderStatus: OrderStatus.PROCESSED,
    };
    const res = await updateOrder(updatedOrder);
    setOrderStatus(res.updateOrder.orderStatus);

    await sendOrderProcessed(floorplan.customer, order, floorplan);
    showSuccessToast("Order fertiggestellt. Es wurde eine Email an den Kunden verschickt!");
  };

  const handleShareableSwitch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedFloorplan: UpdateFloorplanInput = {
      id: floorplan.id,
      shareable: event.target.checked,
    };
    const res = await updateFloorplan(updatedFloorplan);
    setFloorplanShareable(res.updateFloorplan.shareable);
    res.updateFloorplan.shareable
      ? showSuccessToast(
          "Project is now shareable. Customer has access to 3D Editor. Please refresh page to see the Share Panel."
        )
      : showInfoToast("Project is not shareable anymore.");
  };

  const handleDeleteFloorplan = async () => {
    deleteFloorplan(floorplan.id);
    deleteRooms(floorplan?.rooms);
    deleteFloorplanOrders(floorplan?.orders);
    deleteOrder(order.id);
  };

  const handleSetFloorplannerId = async (floorplannerId: string) => {
    const updatedFloorplan: UpdateFloorplanInput = {
      id: floorplan.id,
      floorplannerId: floorplannerId,
    };
    const res = await updateFloorplan(updatedFloorplan);
    setFloorplannerId(res.updateFloorplan.floorplannerId);
  };

  const handleCreateProject = async () => {
    try {
      const response = await createFloorplannerProject(floorplan.id);
      setFloorplannerId(response.floorplannerId);
      showSuccessToast("Floorplanner Projekt erfolgreich erstellt");
    } catch (error) {
      showErrorToast(`Floorplanner Projekt erstellen hat leider nicht geklappt: ${error.message}`);
    }
  };

  const handleUpdateDesign = async () => {
    try {
      const response = await updateFloorplannerDesign(floorplan.id);
      console.log(response);
      showSuccessToast("Floorplanner Design erfolgreich aktualisiert");
    } catch (error) {
      showErrorToast(`Floorplanner Design updaten hat leider nicht geklappt: ${error.message}`);
    }
  };

  const handleImport = async () => {
    try {
      await importImages(orderLevel, floorplannerId);
      showSuccessToast("Import aufgegeben. In ein paar Minuten sollten die neuen Bilder verfügbar sein!");
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  const handleReimportFML = async () => {
    try {
      await reimportFloorplan(floorplan);
      showSuccessToast("Reimport FML gestartet. Das neue FML ist bald zum download bereit!");
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  return (
    <FlrPaper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2" align="center">
            Editor
          </Typography>
          {editorInfo && (
            <EditorInfoList
              editorInfo={editorInfo}
              handleOrderLevelChange={handleOrderLevelChange}
              handleFloorplanStatusChange={handleFloorplanStatusChange}
              handleOrderStatusChange={handleOrderStatusChange}
              handleSetFloorplannerId={handleSetFloorplannerId}
              handleShareableSwitch={handleShareableSwitch}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h2" align="center">
            Editor Actions
          </Typography>

          <Grid container justify="space-around">
            <Button
              variant="contained"
              color="primary"
              onClick={downloadOriginalImageAndModel}
              startIcon={<CloudDownload />}
            >
              Download
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setDialogOpen(true);
              }}
              startIcon={<CloudUpload />}
            >
              Upload
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="space-around">
            <Button variant="contained" color="primary" startIcon={<Functions />} onClick={digitize}>
              Digitalisieren
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Delete />}
              onClick={() => {
                setDeleteConfirmOpen(true);
              }}
            >
              Löschen
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="space-around">
            <Button
              variant="contained"
              color="primary"
              startIcon={<Edit />}
              onClick={() => {
                history.push(`/editor/${floorplan.id}`);
              }}
            >
              Editieren
            </Button>
            <Button variant="contained" color="primary" startIcon={<CameraAlt />} onClick={showDropzone}>
              Bild ersetzen
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="space-around">
            <Button
              variant="contained"
              color="primary"
              startIcon={<Cancel />}
              disabled={orderStatus !== "DECLINED"}
              onClick={() => {
                sendOrderDeclined(floorplan.customer, order, floorplan);
              }}
            >
              Ablehnen
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Check />}
              disabled={orderStatus !== OrderStatus.PROCESSED || orderFulfilled}
              onClick={handleOrderFulfilled}
            >
              Fertigstellen
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="space-around">
            <Button
              disabled={floorplannerId === null}
              variant="contained"
              color="primary"
              onClick={handleImport}
              startIcon={<ImportExport />}
            >
              Import Floorplanner Images
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="space-around">
            <Button
              disabled={floorplannerId !== null}
              variant="contained"
              color="primary"
              onClick={handleCreateProject}
              startIcon={<Add />}
            >
              Create Floorplanner Project
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="space-around">
            <Button
              disabled={floorplannerId === null}
              variant="contained"
              color="primary"
              onClick={handleUpdateDesign}
              startIcon={<Update />}
            >
              Update Floorplanner Design
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="space-around">
            <Button
              disabled={floorplannerId === null}
              variant="contained"
              color="primary"
              onClick={handleReimportFML}
              startIcon={<Autorenew />}
            >
              Reimport Floorplanner FML
            </Button>
          </Grid>
        </Grid>

        <EditorUploadImagesDialog
          floorplan={floorplan}
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
          }}
        />
        <DeleteConfirmationDialog
          isOpen={deleteConfirmOpen}
          setOpen={setDeleteConfirmOpen}
          confirmCallback={handleDeleteFloorplan}
        />
        <DropzoneReplaceImage imageKey={floorplan.originalImage.key} setImageKey={() => {}} />
      </Grid>
    </FlrPaper>
  );
};

export default EditorPanel;
