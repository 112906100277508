import { Furniture, Room } from "container/vurnished/mockData/types";
import { createContext, useReducer } from "react";

export enum FurnitureActionTypes {
  SWAP_FURNITURE = "SWAP_FURNITURE",
  CHECK_FURNITURE = "CHECK_FURNITURE",
  SET_ROOM = "SET_ROOM",
  SET_EDITOR_LINK = "SET_EDITOR_LINK",
}

type SetRoomAction = {
  type: typeof FurnitureActionTypes.SET_ROOM;
  room: Room;
};

type SwapFurnitureAction = {
  type: typeof FurnitureActionTypes.SWAP_FURNITURE;
  newFurniture: Furniture;
  oldFurniture: Furniture;
};

type CheckFurnitureAction = {
  type: typeof FurnitureActionTypes.CHECK_FURNITURE;
  furniture: Furniture;
  checked: boolean;
};

type SetEditorLinkAction = {
  type: typeof FurnitureActionTypes.SET_EDITOR_LINK;
  editorLink: string;
};

type FurnitureActions = SwapFurnitureAction | CheckFurnitureAction | SetRoomAction | SetEditorLinkAction;

type FurnitureConfig = {
  furniture: Furniture[];
  room: Room;
  editorLink: string;
};

const initialState: FurnitureConfig = {
  furniture: [],
  room: undefined,
  editorLink: undefined,
};

export const FurnitureConfigContext = createContext<[FurnitureConfig, React.Dispatch<FurnitureActions>]>([
  initialState,
  () => {},
]);

const reducer = (state: FurnitureConfig, action: FurnitureActions) => {
  switch (action.type) {
    case FurnitureActionTypes.SWAP_FURNITURE:
      return {
        ...state,
        furniture: state.furniture.map((fn) => (fn.id === action.oldFurniture.id ? action.newFurniture : fn)),
        room: state.room,
      };
    case FurnitureActionTypes.SET_ROOM:
      return {
        ...state,
        furniture: action.room.furniture.map((fn) => fn.furniture),
        room: action.room,
      };
    case FurnitureActionTypes.CHECK_FURNITURE:
      const newFurniture = action.furniture;
      newFurniture.checked = action.checked;
      return {
        ...state,
        furniture: state.furniture.map((fn) => (fn.id === action.furniture.id ? newFurniture : fn)),
        room: state.room,
      };
    case FurnitureActionTypes.SET_EDITOR_LINK:
      return { ...state, editorLink: action.editorLink };
    default:
      throw new Error();
  }
};

type Props = {
  children: React.ReactNode;
};

const FurnitureConfigProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <FurnitureConfigContext.Provider value={[state, dispatch]}>{children}</FurnitureConfigContext.Provider>;
};

export default FurnitureConfigProvider;
